import { createSelector } from 'reselect'
import { RootState } from '../../app/store'

export const getDataCatalogListState = (state: RootState) => state.dataCatalog

export const getDatasetsState = createSelector(
  getDataCatalogListState,
  (state) => state.datasets
)

// export const getDatasetByIdState = (dataset_id: string) => {
//   return createSelector(
//     getDatasetsState,
//     (datasets) =>
//       datasets.filter((dataset) => dataset.dataset_id === dataset_id)[0]
//   )
// }

export const getLoadingState = createSelector(
  getDataCatalogListState,
  (state) => state.loading
)
