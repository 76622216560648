import { FC } from 'react'
import { Typography, FileUpload, VirtualizedRTable } from 'shared'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import papaparser from 'papaparse'
import classnames from 'classnames'
import { Accept } from 'react-dropzone'

const csvPreview = (result: any, set: any, delimiter: string) => {
  if (!result || result.length <= 2)
    return set(<Typography>This file is empty</Typography>)

  let parsed: any = papaparser.parse(result, {
    header: true,
    delimiter,
  })

  const keys = Object.keys(parsed.data[0])

  const columns = keys.map((key) => ({
    Header: key,
    accessor: key,
  }))

  set(
    <div className="flex w-full">
      <VirtualizedRTable
        tableClassName="w-full"
        data={parsed.data}
        columns={columns}
        contentHeight={300}
        itemSize={30}
        fullWidth
        getHeaderProps={(header, i) => ({
          className: classnames(
            'text-md text-white p-t15 bg-primary-light first:rounded-tl last:rounded-tr'
          ),
          style: {
            width: header.width,
            minWidth: header.minWidth,
          },
        })}
        getCellProps={(cell, i) => ({
          className: classnames(
            'text-md py-t8 px-t15 text-left truncate border-b text-default-700'
          ),
          style: {
            width: cell.column.width,
            minWidth: cell.column.minWidth,
          },
        })}
      />
    </div>
  )
}

interface FileUploadComponentProps {
  accept: Accept
  type: 'file' | 'url'
  setFile: (file: any) => void
  placeholder?: string
}

interface StateProps {}

type DispatchProps = typeof mapDispathToProps

const FileUploadComponent: FC<
  FileUploadComponentProps & StateProps & DispatchProps
> = ({ accept, type, placeholder, setFile }) => {
  return (
    <FileUpload
      accept={accept}
      type={type}
      placeholder={placeholder}
      setFile={setFile}
      getPreview={(result, set) => csvPreview(result, set, ',')}
    />
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({})

const mapDispathToProps = {}

export default connect(mapStateToProps, mapDispathToProps)(FileUploadComponent)
