import { FC } from 'react'
import HeaderContainer from './containers/HeaderContainer'
import { MainContainer } from './containers/MainContainer'
import PRootContainer from 'containers/Page/PRootContainer'
import { connect, ConnectedProps } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { useParams } from 'react-router-dom'
import { getWorkspacesState } from 'containers/Sidebar/selectors'
import { IWorkspace } from 'containers/Sidebar/types'
import { RootState } from 'app/store'

interface StateProps {
  workspaces: IWorkspace[]
}

type PropsFromRedux = ConnectedProps<typeof connector>

const Workspace: FC<PropsFromRedux> = ({ workspaces }) => {
  const { workspace_id } = useParams<any>()

  const workspace = workspaces.find((w) => w.workspace_id === workspace_id)

  return (
    <PRootContainer
      data-label="resources-container"
      className="flex flex-col h-full px-20 py-5"
    >
      <HeaderContainer workspace={workspace} />
      <MainContainer workspace={workspace} />
    </PRootContainer>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  workspaces: getWorkspacesState,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Workspace)
