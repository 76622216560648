import React, { FunctionComponent } from 'react'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'

const HeaderContainer: FunctionComponent = () => {
  return (
    <PHeaderContainer>
      <div className="flex space-x-t50 items-center">
        <PageName name="Monitoring" />
      </div>
    </PHeaderContainer>
  )
}

export default HeaderContainer
