import classnames from 'classnames'
import { COLOR_TYPES, wSize } from 'utils/theme'
import { SearchIcon } from 'assets/general'
import { InputProps, Input } from './Input'

interface InputSearchProps extends InputProps {
  className?: string | undefined
  iconClass?: string
  inputClass?: string
  width?: string | undefined
  placeholder?: string
  value?: string
  color?: COLOR_TYPES
  onChange?: (e: any) => void
}

export const InputSearch: React.FunctionComponent<InputSearchProps> = ({
  size = 'sm',
  color = 'white',
  className = '',
  iconClass = '',
  inputClass = '',
  width = undefined,
  placeholder = 'Search',
  value = '',
  onChange,
}) => {
  return (
    <div
      data-label="search-header"
      className={classnames('relative', width && wSize(width), className)}
    >
      <SearchIcon
        className={classnames(
          'absolute left-4 top-1/2 -mt-2 w-4 h-4',
          iconClass
        )}
        size={null}
      />
      <Input
        width={width}
        size={size}
        color={color}
        placeholder={placeholder}
        className={classnames('pl-10', inputClass)}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
