import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { signInActions, SignInState } from './reducer'
import { getLoadingState, getErrorState } from './selectors'
import { LogoIconLogin } from 'assets/logos'

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

type SignInProps = SelectedProps & DispatchProps

const SignIn: React.FunctionComponent<SignInProps> = ({
  loading,
  error,
  signIn,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(true)

  const login = (e: any, data: any) => {
    signIn(data)
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-default-50 pt-0 py-40 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="w-max m-auto">
            <LogoIconLogin size={null} color={null} />
          </div>
          <h2 className="mt-6 text-center text-4xl font-bold text-default-900">
            Sign in to your account
          </h2>
          {/* <p className="mt-2 text-center text-sm text-default-600">
            Or &nbsp;
            <a
              href="startfreetrial"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              start your 14-day free trial
            </a>
          </p> */}
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            login(e, { email, password, rememberMe })
          }}
        >
          <input type="hidden" name="remember" value="true" />
          <div className="rounded shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="text" // type="email"
                autoComplete="email"
                required
                className="h-H appearance-none rounded-none relative block w-full px-3 text-md border border-default-300 placeholder-default-500 text-default-900 rounded-t focus:z-10 sm:text-md"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="h-H appearance-none rounded-none relative block w-full px-3 text-md border border-default-300 placeholder-default-500 text-default-900 rounded-b focus:z-10 sm:text-md"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center disabled">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-default-300 rounded"
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-md text-default-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-md disabled">
              <a
                href="forgotpassword"
                className="font-medium text-primary-light hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={
                'group relative w-full h-H flex items-center justify-center px-4 border border-transparent text-md font-medium rounded text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ' +
                (loading ? 'disabled' : '')
              }
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {/* TODO import svg as component */}

                <svg
                  className="h-5 w-5 text-primary-150 group-hover:text-primary-100"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              {loading ? (
                <svg
                  className="-ml-1 h-5 mr-3 text-white w-5 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Sign in
            </button>
            {error?.message ? (
              <div className="text-secondary">{error.message}</div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, SignInState>({
  loading: getLoadingState,
  error: getErrorState,
})

const mapDispatchToProps = {
  signIn: signInActions.signIn,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
