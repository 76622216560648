import { makeRequest } from './http'

const api = {
  async getDatasets() {
    const response = await makeRequest({
      path: `/datasets`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },
}

export default api
