import { FC, ReactNode } from 'react'

interface IContentLayoutProps {
  children: ReactNode
}

const ContentLayout: FC<IContentLayoutProps> = ({ children }) => {
  return (
    <div className="content flex flex-1 flex-col overflow-hidden">
      {children}
    </div>
  )
}

export default ContentLayout
