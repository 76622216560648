import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ConnectionsItemType,
  ConnectionsTypeTypeT,
  CreateConnectionPayload,
  DeleteConnectionPayloadT,
  LoadingType,
  TypeFilterT,
  UpdateConnectionPayload,
} from './types'

const prefix = 'connections'

export interface ConnectionsState {
  loading: LoadingType
  connections: ConnectionsItemType[]
  connectionType: ConnectionsTypeTypeT
  checked: boolean
  selectedConnection: ConnectionsItemType | undefined
  searchValue: string
  typeFilterValue: TypeFilterT
}

const initialState: ConnectionsState = {
  loading: { is: false, name: '' },
  connections: [],
  connectionType: '',
  checked: false,
  selectedConnection: undefined,
  searchValue: '',
  typeFilterValue: undefined,
}

const connectionsReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchConnections: (state) => {},
    didFetchConnections: (
      state,
      { payload }: PayloadAction<ConnectionsItemType[]>
    ) => {
      state.connections = payload
    },
    selectConnectionType: (
      state,
      { payload }: PayloadAction<ConnectionsTypeTypeT>
    ) => {
      state.selectedConnection = undefined
      state.connectionType = payload
    },
    createConnection: (
      state,
      { payload }: PayloadAction<CreateConnectionPayload>
    ) => {},
    didCreateConnection: (
      state,
      { payload }: PayloadAction<ConnectionsItemType>
    ) => {
      state.connections.push(payload)
    },
    updateConnection: (
      state,
      { payload }: PayloadAction<UpdateConnectionPayload>
    ) => {},
    didUpdateConnection: (
      state,
      { payload }: PayloadAction<UpdateConnectionPayload>
    ) => {
      const idx = state.connections.findIndex(
        (d) => d.connection_id === payload.connection_id
      )
      state.connections[idx] = { ...state.connections[idx], ...payload }
    },
    deleteConnection: (
      state,
      { payload }: PayloadAction<DeleteConnectionPayloadT>
    ) => {},
    didDeleteConnection: (
      state,
      { payload }: PayloadAction<DeleteConnectionPayloadT>
    ) => {
      state.connections = state.connections.filter(
        (d) => d.connection_id !== payload
      )
    },
    checkConnection: (
      state,
      { payload }: PayloadAction<CreateConnectionPayload>
    ) => {},
    didCheckConnection: (state, { payload }: PayloadAction<any>) => {
      state.checked = payload
    },
    selectConnection: (
      state,
      { payload }: PayloadAction<ConnectionsItemType | undefined>
    ) => {
      state.connectionType = ''
      state.selectedConnection = payload
    },
    setSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.searchValue = payload
    },
    setTypeFilterValue: (state, { payload }: PayloadAction<TypeFilterT>) => {
      state.typeFilterValue = payload
    },
    startLoading: (state, { payload }: PayloadAction<string>) => {
      state.loading = { is: true, name: payload }
    },
    stopLoading: (state) => {
      state.loading = initialState.loading
    },
  },
})

export const connectionsActions = connectionsReducer.actions

export default connectionsReducer.reducer
