import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'
import { omit } from 'lodash'

import User from 'app/auth/User'
import { authService } from 'utils/services'
import { Routes, pushRoute } from 'utils/router'

import { signInActions, SignInPayload } from './reducer'

function* handleSignIn({
  payload,
}: PayloadAction<SignInPayload>): Generator<any> {
  // TODO delete and move clean up in component
  const body = omit(payload, 'rememberMe')
  //
  // yield put(globalActions.resetStore())
  try {
    const data = yield call(authService.userLogin, body)
    User.login(data)

    yield put(signInActions.didSignIn())

    // TODO add check location.state || history.location.pathname
    pushRoute(Routes.url.Root())
  } catch (error: any) {
    // TODO check error unauthorized, bad email or password
    yield put(
      signInActions.didSignInFail({
        message: error.message ? error.message : 'Invalid email or password!',
      })
    )
  }
}

function* handleSignOut() {
  try {
    yield call(authService.userLogout)
    User.logout()
    pushRoute(Routes.url.SignIn())
    // TODO add reset store ation
    // yield put(globalActions.resetStore())
  } catch (error) {}
}

export default function* watchSignInSaga() {
  yield takeLatest(signInActions.signIn.type, handleSignIn)
  yield takeLatest(signInActions.signOut.type, handleSignOut)
}
