import { FC, useState } from 'react'
import { ButtonSimple, Popover } from 'shared'
import { PlusIcon } from 'assets/general'
import AddNewColumnPopoverContainer from './AddNewColumnPopoverContainer'

interface AddNewColumnButtonProps {}

const AddNewColumnButton: FC<AddNewColumnButtonProps> = () => {
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const handleShowPopover = (value: boolean) => {
    setShowPopover(value)
  }
  return (
    <div className="relative flex justify-end">
      <ButtonSimple
        className="bg-default-100 hover:bg-default-300 text-default-700 text-md px-t10 py-t4 rounded-3"
        onClick={() => handleShowPopover(true)}
      >
        <PlusIcon />
        Add Column
      </ButtonSimple>
      <Popover
        className="right-0 -top-t50"
        show={showPopover}
        handleShow={handleShowPopover}
      >
        <AddNewColumnPopoverContainer handleClose={handleShowPopover} />
      </Popover>
    </div>
  )
}

export default AddNewColumnButton
