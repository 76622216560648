import { FunctionComponent, useState } from 'react'
import { Button, Input, Label, TextArea } from 'shared'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import ModalContentContainer from 'containers/Modal/ModalContentContainer'
import ModalFooterContainer from 'containers/Modal/ModalFooterContainer'
import { projectActions } from '../reducer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getModalLoadingState,
  getModalParamsState,
} from 'containers/Modal/selectors'

type InputsType = {
  type: string
  name: string
  label: string
  placeholder: string
  variant?: string
  options?: any
  disabled?: boolean
}

const inputs: InputsType[] = [
  {
    type: 'text',
    name: 'dataset_id',
    label: 'Dataset:',
    placeholder: 'Dataset',
    disabled: true,
  },
  {
    type: 'text',
    name: 'name',
    label: 'Name:',
    placeholder: 'Name',
  },
  {
    type: 'text',
    name: 'description',
    label: 'Description:',
    placeholder: 'Description',
  },
  {
    type: 'text',
    name: 'schema',
    label: 'Schema:',
    placeholder: 'Schema',
    variant: 'textarea',
  },
]

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const CreateNewDataset: FunctionComponent<SelectedProps & DispatchProps> = ({
  loading,
  params,
  hideModal,
  createDataset,
  updateDataset,
  deleteDataset,
}) => {
  // TODO make better!
  const [inputValue, setInputValue] = useState<any>({
    dataset_id: params?.dataset?.dataset_id ? params.dataset.dataset_id : '',
    name: params?.dataset?.name ? params.dataset.name : '',
    description: params?.dataset?.description ? params.dataset.description : '',
    schema: params?.dataset?.schema ? params.dataset.schema : '',
  })

  const handleChange = (value: any, name: string) => {
    setInputValue((prev: any) => {
      return { ...prev, [name]: value }
    })
  }

  return (
    <div data-label="modal-new-dataset">
      <ModalContentContainer className="grid grid-cols-12">
        <div className="flex flex-col items-start col-span-10">
          {inputs.map((d: InputsType) => (
            <Label
              key={d.name}
              className="justify-end mt-t15 first:mt-0"
              label={d.label}
              alignLabel="left"
              size="lg"
              weight="bold"
            >
              {d.variant === 'textarea' ? (
                <TextArea
                  type={d.type}
                  name={d.name}
                  value={inputValue[d.name]}
                  placeholder={d.placeholder}
                  disabled={params?.dataset ? d.disabled : false}
                  onChange={(e: any) => handleChange(e.target.value, d.name)}
                />
              ) : (
                <Input
                  type={d.type}
                  name={d.name}
                  value={inputValue[d.name]}
                  placeholder={d.placeholder}
                  disabled={params?.dataset ? d.disabled : false}
                  onChange={(e: any) => handleChange(e.target.value, d.name)}
                />
              )}
            </Label>
          ))}
        </div>
        <div className="col-span-2"></div>
      </ModalContentContainer>
      <ModalFooterContainer>
        <Button
          className="mr-t20"
          variant="OUTLINED"
          onClick={hideModal}
          // ref={cancelButtonRef}
        >
          Cancel
        </Button>
        <div className="flex space-x-4">
          {params?.dataset ? (
            <Button
              loading={loading.name === 'delete' && loading.is}
              disabled={loading.is}
              onClick={() => deleteDataset(params.dataset)}
            >
              Delete
            </Button>
          ) : null}
          <Button
            loading={
              (loading.name === 'save' || loading.name === 'create') &&
              loading.is
            }
            disabled={loading.is || !inputValue.dataset_id}
            color="primary"
            onClick={() => {
              params?.dataset
                ? updateDataset({
                    project_id: params?.dataset.project_id,
                    ...inputValue,
                  })
                : createDataset({
                    project_id: params?.project_id,
                    ...inputValue,
                  })
            }}
          >
            {params?.dataset ? 'Save' : 'Create'}
          </Button>
        </div>
      </ModalFooterContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  loading: getModalLoadingState,
  params: getModalParamsState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
  createDataset: projectActions.createDataset,
  updateDataset: projectActions.updateDataset,
  deleteDataset: projectActions.deleteDataset,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewDataset)
