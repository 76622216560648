import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import SwitcherContainer from 'pages/Dataset/containers/SwitcherContainer'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'
import { getActiveViewState } from '../selectors'
import { projectsActions } from '../reducer'
import { ButtonNew } from 'shared'
import { PlusIcon } from 'assets/general/PlusIcon'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'

interface HeaderContainerProps {}

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const HeaderContainer: FunctionComponent<
  HeaderContainerProps & SelectedProps & DispatchProps
> = ({ activeView, changeView, showModal }) => {
  return (
    <PHeaderContainer>
      <div className="flex space-x-t50 items-center">
        <PageName name="Projects" />
        <ButtonNew
          onClick={() =>
            showModal({
              type: ModalTypes.CreateNewProject,
              config: { ...ModalOptions.CreateNewProject },
            })
          }
          className="bg-primary-100 text-primary-light hover:bg-primary-150 hover:text-primary"
        >
          <PlusIcon className="mr-xs text-primary-light group-hover:text-primary" size={5}/>
          Add Project
        </ButtonNew>
      </div>
      <SwitcherContainer activeView={activeView} onClick={changeView} />
    </PHeaderContainer>
  )
}

const mapStateToProps = createStructuredSelector<any, any>({
  activeView: getActiveViewState,
})

const mapDispatchToProps = {
  changeView: projectsActions.changeView,
  showModal: modalActions.showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
