import { FC, Fragment, useRef } from 'react'
import { Typography } from 'shared/typography'
import classnames from 'classnames'
import { Menu as TMenu, Transition } from '@headlessui/react'
import cn from 'classnames'
import { useDetectOutsideClick } from 'hooks'

export type MenuItemsType = {
  label: string
  onClick: (event: any) => void
  iconComponent?: any
  disabled?: boolean
}

interface MenuProps {
  children: any
  items: MenuItemsType[]
  className?: string
  position?: 'static' | 'fixed' | 'absolute' | 'relative' | 'sticky'
}

export const Menu: React.FunctionComponent<MenuProps> = ({
  children,
  items,
  className,
  position = 'relative',
}) => {
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const handleShowHide = () => setIsActive(!isActive)

  return (
    <div className={classnames(position, className)}>
      {children(handleShowHide, isActive)}

      {/*
        'Solutions' flyout menu, show/hide based on flyout menu state.

        Entering: "transition ease-out duration-200"
          From: "opacity-0 translate-y-1"
          To: "opacity-100 translate-y-0"
        Leaving: "transition ease-in duration-150"
          From: "opacity-100 translate-y-0"
          To: "opacity-0 translate-y-1"
      */}

      {/* If add Transition not work ref outside click */}

      {/* <Transition
        as={Fragment}
        show={isActive}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      > */}
      <div
        ref={dropdownRef}
        className={`${
          isActive ? '' : 'hidden'
        } absolute right-1 py-1 z-50 mt-md rounded bg-white shadow`}
      >
        <ul>
          {items.map((item) => (
            <li
              key={item.label}
              onClick={(e: any) => {
                e.stopPropagation()
                e.preventDefault()
                item.onClick(e)
                handleShowHide()
              }}
              className={classnames(
                'whitespace-nowrap px-md py-sm flex items-center cursor-pointer hover:bg-default-100',
                { disabled: item.disabled }
              )}
            >
              {item.iconComponent ? (
                <item.iconComponent
                  className="w-5 h-5 mr-2"
                  aria-hidden="true"
                />
              ) : null}
              <Typography size="lg">{item.label}</Typography>
            </li>
          ))}
        </ul>
      </div>
      {/* </Transition> */}
    </div>
  )
}

export type MenuNewItemsType = {
  label: string
  onClick: (event: any) => void
  iconComponent?: any
  disabled?: boolean
}

interface MenuNewProps {
  children: any
  items: MenuNewItemsType[]
  className?: string
}

export const MenuNew: FC<MenuNewProps> = ({ children, items, className }) => {
  return (
    <TMenu
      as="div"
      className={cn('relative inline-block text-left', className)}
    >
      <div
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <TMenu.Button
          as="div"
          className="rounded-full flex items-center focus:outline-none focus:ring-0"
        >
          <span className="sr-only">Open menu</span>
          {children}
        </TMenu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <TMenu.Items className="origin-top-right absolute z-40 right-0 mt-2 w-32 rounded-sm border shadow-lg bg-white ring-0 focus:outline-none">
          <div className="py-1">
            {items.map((item: MenuItemsType) => (
              <TMenu.Item key={item.label} disabled={item.disabled}>
                <div
                  onClick={(e: any) => item.onClick(e)}
                  className={classnames(
                    'whitespace-nowrap px-3 py-1 flex items-center cursor-pointer hover:bg-default-100'
                  )}
                >
                  {item.iconComponent ? (
                    <item.iconComponent
                      className="w-5 h-5 mr-2"
                      aria-hidden="true"
                    />
                  ) : null}
                  <Typography size="md">{item.label}</Typography>
                </div>
              </TMenu.Item>
            ))}
          </div>
        </TMenu.Items>
      </Transition>
    </TMenu>
  )
}
