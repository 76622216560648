import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { clustersActions } from '../reducer'
import HeaderContainer from './HeaderContainer'
import MainContainer from './MainContainer'
import PRootContainer from 'containers/Page/PRootContainer'

type DispatchProps = typeof mapDispatchToProps

const ClustersContainer: FunctionComponent<DispatchProps> = ({
  fetchClusters,
}) => {
  useEffect(() => {
    fetchClusters()
  }, [fetchClusters])

  return (
    <PRootContainer
      data-label="clusters-container"
      className="flex flex-col pl-t30 h-full"
    >
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchClusters: clustersActions.fetchClusters,
}

export default connect(null, mapDispatchToProps)(ClustersContainer)
