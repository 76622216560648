import React, { FC } from 'react'
import ReactMaskedInput from 'react-text-mask'

import { getMask } from './helpers'

interface MaskedInputProps {
  //inputRef: any
  name?: string
  [propName: string]: any
}

export const MaskedInput: FC<MaskedInputProps> = ({ /*inputRef,*/ name, ...other }) => {
  //const { inputRef, name, ...other } = props

  return (
    <ReactMaskedInput
      {...other}
      name={name}
      // ref={(ref: any) => {
      //   inputRef(ref ? ref.inputElement : null)
      // }}
      keepCharPositions={true}
      mask={getMask(name)}
      //mask={props.mask || [/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, ' ', /[0-9]/, /[0-9]/, ',', ' ', /[0-9]/, /[0-9]/]}
    />
  )
}
