import { RootState } from 'app/store'
import { connectionsConfig } from 'pages/Connections/containers/drawer/config'
import { getConnectionTypeState } from 'pages/Connections/selectors'
import { ConnectionsTypeTypeT } from 'pages/Connections/types'
import { FunctionComponent, useMemo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { SwitchCase } from 'shared'
import BlankPrepare from './BlankPrepare'
import ConnectionsPrepare from './ConnectionsPrepare'
import CSVPrepare from './CSVPrepare'

interface StateProps {
  connectionType: ConnectionsTypeTypeT
}

const DBPrepare: FunctionComponent<StateProps> = ({ connectionType }) => {
  const dbsContents = (db: string) => {
    switch (db) {
      case 'blank':
        return <BlankPrepare />
      case 'json':
        return <div>JSON</div>
      case 'csv':
        return <CSVPrepare />
      case 'postgresql':
      case 'mysql':
      case 'clickhouse':
      case 'google_sheets':
        return <ConnectionsPrepare />
      default:
        return null
    }
  }
  const cases = useMemo(() => {
    const obj: any = {}
    connectionsConfig.forEach((db) => {
      obj[db.name] = dbsContents(connectionType)
    })
    return obj
  }, [connectionType])

  return (
    <div className="grow mt-t20">
      <SwitchCase value={connectionType}>{cases}</SwitchCase>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
})

export default connect(mapStateToProps)(DBPrepare)
