import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Input } from 'shared'
import classNames from 'classnames'

import { MaskedInput } from './DatePickerMaskedInput'
import {
  convertDates,
  getRange,
  getDatesAndRange,
  getTitleTypeTimeFormat,
  parseTitle,
} from './helpers'
import {
  DatePickerInputProps,
  DatesIntervalType,
  ACTIVE_TYPES,
  VALUE_TYPES,
  TITLE_TYPES,
  ValueType,
} from './types'

export const DatePickerInput: FC<DatePickerInputProps> = ({
  value,
  preValue,
  hoverValue,
  viewDate,
  activeType,
  dates,
  error,
  setValue,
  setPreValue,
  setError,
  setActiveType,
}) => {
  const [active, setActive] = useState<boolean>()

  useEffect(() => {
    if (
      (!active || activeType === ACTIVE_TYPES.NONE) &&
      preValue?.times.dates
    ) {
      setPreValue(null)
      setError('')
    }
  }, [active, activeType, preValue, setPreValue, setError])

  const handleFocus = (is: boolean): void => {
    setActive(is)
  }

  const handleChange = useCallback(
    (event: any) => {
      const { dates, error }: { dates: DatesIntervalType; error: string } =
        parseTitle(event.target.value)

      const newValue: ValueType = {
        title: event.target.value,
        times: { dates: convertDates({ start: dates.start, end: dates.end }) },
        range: getRange({ start: dates.start, end: dates.end }),
        type: VALUE_TYPES.ABSOLUTE,
      }

      setPreValue(newValue)

      if (!error && event.target.value) setValue(newValue)

      setError(error)
      setActiveType(ACTIVE_TYPES.DEFAULT)
    },
    [setValue, setPreValue, setError, setActiveType]
  )

  const name = useMemo(
    () =>
      getTitleTypeTimeFormat(
        hoverValue?.times
          ? getDatesAndRange(hoverValue.times).dates
          : preValue?.times
          ? getDatesAndRange(preValue.times).dates
          : dates
      ).type === TITLE_TYPES.YEAR
        ? 'datepicker-year'
        : 'datepicker',
    [hoverValue, preValue, dates]
  )

  const inputValue: string | undefined = useMemo(
    () =>
      active
        ? hoverValue?.title ||
          (preValue?.times.dates ? preValue.title : value.view || viewDate)
        : activeType
        ? hoverValue?.title || preValue?.title || value.view || viewDate
        : preValue?.title || value.view || value.title || viewDate,
    [active, activeType, hoverValue, preValue, value, viewDate]
  )

  return (
    <Input
      data-label="date-picker-input-component"
      name={name}
      className={classNames(
        'pl-t75',
        activeType === ACTIVE_TYPES.NONE ? 'pointer-events-none' : ''
      )}
      width="t350"
      value={inputValue}
      color="default-200"
      placeholder="___ __, __:__ __ - ___ __, __:__ __"
      readOnly={activeType === ACTIVE_TYPES.NONE || hoverValue}
      error={Boolean(error) ? true : false}
      onChange={handleChange}
      onFocus={() => handleFocus(true)}
      onBlur={() => handleFocus(false)}
      InputComponent={activeType ? MaskedInput : undefined}
    />
  )
}
