import { FunctionComponent } from 'react'
import { Badge, Typography } from 'shared'
import { COLOR_TYPES, getStatusColor } from 'utils/theme'
import {
  CLUSTERS_PROVIDER_TYPES,
  ClustersItemType,
  CLUSTERS_TYPE_TYPES,
} from '../types'
import { ClockIcon } from 'assets/general'
import {
  AzureIcon,
  GoogleCloudPlatformIcon,
  AWSIcon,
  ClickhouseIcon,
  PostgreSqlIcon,
} from 'assets/integrations'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'

const providerConfig: { [name: string]: any } = {
  [CLUSTERS_PROVIDER_TYPES.AWS]: { icon: AWSIcon, color: 'yellow-500' },
  [CLUSTERS_PROVIDER_TYPES.AZURE]: {
    icon: AzureIcon,
    color: 'blue-500',
  },
  [CLUSTERS_PROVIDER_TYPES.GCP]: {
    icon: GoogleCloudPlatformIcon,
    color: 'yellow-400',
  },
}

const typeIcons: { [name: string]: any } = {
  [CLUSTERS_TYPE_TYPES.CLICKHOUSE]: ClickhouseIcon,
  [CLUSTERS_TYPE_TYPES.POSTGRESQL]: PostgreSqlIcon,
}

const credentialsOption: { [name: string]: string } = {
  url: 'Url',
  host: 'Host',
  port: 'Port',
  user: 'User',
  password: 'Password',
}

interface ClustersItemProps {
  item: ClustersItemType
}

type DispatchProps = typeof mapDispatchToProps

const OptionItem: FunctionComponent<{
  item: {
    name: string
    value: string | number | undefined
  }
  color?: COLOR_TYPES
}> = ({ item, color = 'default-600' }) => {
  return (
    <div className="relative mt-t10 first:mt-0">
      <Typography weight="bold" size="md">
        {item.name}:
      </Typography>
      <Typography
        className="absolute top-0 left-16 truncate"
        color={color}
        size="md"
      >
        {item.value}
      </Typography>
    </div>
  )
}

const FooterItem: FunctionComponent<ClustersItemProps> = ({ item }) => {
  return (
    <div>
      <Typography
        className="flex items-center mb-t15"
        color="default-500"
        size="md"
      >
        <ClockIcon className="mr-sm" color="default-400" />
        {item.created_at}
      </Typography>
      <div className="flex justify-between pt-t15 border-t">
        <div className="flex items-center">
          <Badge color={getStatusColor(item.status)}>
            <Typography color="white" size="md">
              {item.status}
            </Typography>
          </Badge>
        </div>
        <div className="flex items-center">
          <Typography
            size="4xl"
            weight="bold"
            color="secondary"
          >{`$${item.price}`}</Typography>
          <Typography size="lg" color="default-600">
            {'/mon'}
          </Typography>
        </div>
      </div>
    </div>
  )
}

const ClustersItem: FunctionComponent<ClustersItemProps & DispatchProps> = ({
  item,
  showModal,
}) => {
  const providerColor = item.provider
    ? providerConfig[item.provider].color
    : 'default-900'
  const TypeIcon = item.type ? typeIcons[item.type] : null
  return (
    <div
      data-label="cluster-item"
      className="relative flex flex-col justify-between px-t20 py-t25 rounded shadow bg-white cursor-pointer"
      onClick={() =>
        showModal({
          type: ModalTypes.CreateNewCluster,
          config: {
            ...ModalOptions.CreateNewCluster,
            headerName: 'Update cluster',
          },
          params: {
            cluster: item,
          },
        })
      }
    >
      <div>
        <div className="absolute right-t20 top-t25">
          {TypeIcon ? <TypeIcon width="50px" size={null} /> : null}
        </div>
        <Typography size="xl" color="primary-500" weight="bold">
          {item.name}
        </Typography>
        <Typography className="mt-t5" color="default-600" size="md">
          {item.description}
        </Typography>
        <div className="py-t15">
          <OptionItem
            item={{
              name: 'System',
              value: item.provider,
            }}
            color={providerColor}
          />
          {Object.keys(item.credentials).map((cr, i) => (
            <OptionItem
              key={i}
              item={{
                name: credentialsOption[cr],
                value: item.credentials[cr],
              }}
            />
          ))}
          <OptionItem
            item={{ name: 'Projects', value: item.number_of_projects }}
          />
          <OptionItem
            item={{ name: 'Datasets', value: item.number_of_datasets }}
          />
        </div>
      </div>
      <FooterItem item={item} />
    </div>
  )
}

const mapDispatchToProps = {
  showModal: modalActions.showModal,
}

export default connect(null, mapDispatchToProps)(ClustersItem)
