import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { PageTypes } from '../Page/types'

const ModalFooterContainer: FunctionComponent<PageTypes> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classnames(
        'bg-default-50 px-5 py-4 flex justify-between',
        className
      )}
    >
      {children}
    </div>
  )
}

export default ModalFooterContainer
