import { FC } from 'react'
import classnames from 'classnames'
import { hSize, SIZE_TYPES, wSize } from 'utils/theme'

interface AvatarProps {
  src?: string
  size?: SIZE_TYPES | 'H' | number
  className?: string
  onClick?: (e: any) => void
}

export const Avatar: FC<AvatarProps> = ({
  src = 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  size = 8,
  className = 'rounded-full',
  onClick,
}) => {
  return (
    <div
      className={classnames(
        'flex overflow-hidden',
        { 'cursor-pointer': onClick },
        wSize(size),
        hSize(size),
        className
      )}
      onClick={onClick}
    >
      <img className="inline-block ring-2 ring-white" src={src} alt="" />
    </div>
  )
}
