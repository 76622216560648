import React from 'react'
import classnames from 'classnames'
import { SIZE_TYPES } from 'utils/theme'
import { LoaderIcon } from 'assets/general'

interface ButtonProps {
  children: React.ReactNode
  onClick: (e?: any) => void
  size?: SIZE_TYPES
  loaderSize?: SIZE_TYPES
  className?: string
  loading?: boolean
  disabled?: boolean
  [propName: string]: any
}

export const ButtonNew: React.FunctionComponent<ButtonProps> = ({
  children,
  size = 'sm',
  className = '',
  loaderSize = 'md',
  loading = false,
  disabled = false,
  onClick,
  ...other
}) => {
  return (
    <button
      type="button"
      className={classnames(
        'group relative self-start flex items-center justify-center border-transparent text-md focus:outline-none duration-200',
        { disabled: disabled || loading },
        size === 'xs'
          ? 'px-t10 py-t5 h-auto rounded-3'
          : 'h-H w-full rounded px-t16 sm:w-auto',
        className
      )}
      onClick={(e: any) => onClick(e)}
      {...other}
    >
      {children}
      {loading ? (
        <LoaderIcon className="ml-t10" size={loaderSize} color="default-300" />
      ) : null}
    </button>
  )
}
