import { FunctionComponent } from 'react'
import { Indicator, Typography } from 'shared'
import { stats } from '../constants'

const DatasetListView: FunctionComponent = () => {
  return (
    <div className="mt-t20 px-t15 py-t20 rounded bg-default-100 flex justify-between">
      <div>
        <div>
          <Typography className="flex items-center" size="2xl" weight="bold">
            policy_number
            <Indicator
              variant={Indicator.variant.SQUARE}
              className="ml-md"
              color="default-500"
              size="md"
            />
          </Typography>
          <Typography className="mt-t5" size="lg" color="default-600">
            Integer
          </Typography>
        </div>
        <div className="mt-t10">
          <Typography size="lg" weight="bold">
            Sample Values:
          </Typography>
          <Typography className="mt-t7" size="md" color="default-600">
            112343, 234415, 31535324, 234532
          </Typography>
        </div>
        <div className="mt-t10">
          <Typography size="lg" weight="bold">
            Details:
          </Typography>
          <Typography className="mt-t7" size="md" color="default-600">
            Policy number of the insured from Active Directory
          </Typography>
        </div>
      </div>
      <div className="border-l-2 border-default-300 px-t30 flex flex-col justify-evenly">
        {stats.maxmin.map((d) => (
          <div key={d.label} className="grid grid-cols-2 gap-2 first:mt-0">
            <Typography size="lg" weight="bold">
              {d.label}:
            </Typography>
            <Typography className="self-end" size="md" color="default-600">
              {d.value}
            </Typography>
          </div>
        ))}
      </div>
      <div className="border-l-2 border-default-300 pl-t30 flex flex-col justify-evenly">
        {stats.unique.map((d) => (
          <div key={d.label} className="grid grid-cols-2 gap-2 first:mt-0">
            <Typography size="lg" weight="bold">
              {d.label}:
            </Typography>
            <Typography className="self-end" size="md" color="default-600">
              {d.value}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DatasetListView
