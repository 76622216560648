import { FC, useEffect, useRef, useState } from 'react'
import Editor, { loader } from '@monaco-editor/react'

import { MonacoEditorProps, MONACO_LANGUAGES_TYPES } from './types'

export const MonacoEditor: FC<MonacoEditorProps> = ({
  value,
  defaultValue,
  onChange = () => null,
  onValidate = () => null,
  language = MONACO_LANGUAGES_TYPES.JS,
  classes = {},
  readOnly,
  minimap = true,
  lineNumbers = true,
  errorWords,
}) => {
  const [monaco, setMonaco] = useState<any>(loader.__getMonacoInstance())
  const editorRef = useRef<any>()

  // const monaco = useMonaco()

  useEffect(() => {
    if (!loader.__getMonacoInstance())
      loader.init().then((_monaco) => setMonaco(_monaco))
  }, [])

  useEffect(() => {
    if (errorWords) {
      const markers: any = []

      errorWords.forEach((error: any) => {
        const regexp = new RegExp(error.word || '""', 'g')

        let result
        while (
          (result = regexp.exec(editorRef.current?.getModel().getValue()))
        ) {
          const position = editorRef.current
            .getModel()
            .getPositionAt(result.index)

          markers.push({
            startLineNumber: position.lineNumber,
            startColumn: position.column,
            endLineNumber: position.lineNumber,
            endColumn: position.column + error.word.length,
            message: error.message,
            severity: monaco.MarkerSeverity.Error,
          })
        }
      })

      monaco.editor.setModelMarkers(
        editorRef.current.getModel(),
        'errorWords',
        markers
      )
    }
  }, [monaco, errorWords])

  const onMount = (editor: any, monaco: any) => {
    editorRef.current = editor
  }

  return (
    <Editor
      // wrapperClassName={classes.root}
      className={classes.editor}
      options={{
        readOnly: readOnly,
        minimap: { enabled: minimap },
        lineNumbers,
        scrollbar: { horizontalScrollbarSize: 5, verticalScrollbarSize: 5 },
      }}
      defaultLanguage={language}
      language={language}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onValidate={onValidate}
      onMount={onMount}
    />
  )
}
