import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import User from './auth/User'
import { Routes } from 'utils/router'

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (User.isAuth()) {
          return Component
        }
        return (
          <Redirect
            to={{
              pathname: Routes.url.SignIn(),
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(PrivateRoute)
