import { FunctionComponent } from 'react'
import Counter from './components/counter/Counter'
import { Badge } from 'shared'

const Main: FunctionComponent = () => {
  return (
    <div className="main">
      <div className="mt-md mb-md text-center">
        <Badge variant="OUTLINED" color="secondary" size="sm">
          Badge
        </Badge>
      </div>
      <Counter />
    </div>
  )
}

export default Main
