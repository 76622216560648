import { FC, FunctionComponent, ReactNode } from 'react'
import { Typography } from 'shared'
import { DatasetsItemType } from '../types'
import { connect } from 'react-redux'
import { slideOverActions } from 'containers/SlideOver/reducer'
import { typeIcons } from 'pages/Connections/containers/drawer/config'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSelectedDatasetState } from '../selectors'
import classnames from 'classnames'

interface InfoSectionContainerProps {
  children: ReactNode
  className?: string
}

const InfoSectionContainer: FunctionComponent<InfoSectionContainerProps> = ({
  children,
  className,
}) => {
  return <div className={classnames('mt-t20', className)}>{children}</div>
}

interface InfoSectionHeaderProps {
  name: string
  className?: string
}

const InfoSectionHeader: FC<InfoSectionHeaderProps> = ({ name, className }) => {
  return (
    <Typography
      className={classnames('uppercase tracking-widest', className)}
      color="default-500"
      size="md"
      weight="bold"
    >
      {name}
    </Typography>
  )
}

interface InfoSectionContentProps {
  content: any
  className?: string
}

const InfoSectionContent: FC<InfoSectionContentProps> = ({
  content,
  className,
}) => {
  return (
    <Typography
      className={classnames('mt-t7', className)}
      color="default-700"
      size="md"
      weight="bold"
    >
      {content}
    </Typography>
  )
}

interface DatasetInfoContainerProps {
  children?: ReactNode
  className?: string
}

const DatasetInfoContainer: FC<DatasetInfoContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classnames(
        'h-full px-t15 py-t20 rounded shadow bg-white',
        className
      )}
    >
      {children}
    </div>
  )
}

interface DatasetInfoProps {}

interface StateProps {
  selectedDataset: DatasetsItemType | null
}

type DispatchProps = typeof mapDispatchToProps

const DatasetInfo: FunctionComponent<
  DatasetInfoProps & StateProps & DispatchProps
> = ({ selectedDataset }) => {
  const TypeIcon =
    selectedDataset && selectedDataset.type
      ? typeIcons[selectedDataset.type]
      : null
  if (selectedDataset === null) return <DatasetInfoContainer />
  return (
    <DatasetInfoContainer>
      <div className="flex justify-between">
        <Typography color="primary-500" size="xl" weight="bold">
          {selectedDataset.name}
        </Typography>
        {TypeIcon ? <TypeIcon width="20px" size={null} /> : null}
      </div>
      <InfoSectionContainer>
        <InfoSectionHeader name="Description" />
        <InfoSectionContent
          content={selectedDataset.description || 'No description'}
        />
      </InfoSectionContainer>
      <InfoSectionContainer>
        <InfoSectionHeader name="Last updated" />
        <InfoSectionContent
          content={
            selectedDataset.updated_at ||
            selectedDataset.created_at ||
            'No information'
          }
        />
      </InfoSectionContainer>
      <InfoSectionContainer>
        <InfoSectionHeader name="Owner" />
        <InfoSectionContent content={selectedDataset.owner_id || 'No owner'} />
      </InfoSectionContainer>
    </DatasetInfoContainer>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedDataset: getSelectedDatasetState,
})

const mapDispatchToProps = {
  showSlideOver: slideOverActions.showSlideOver,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetInfo)
