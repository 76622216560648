import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { Button, ButtonSimple, Input, MenuNew } from 'shared'
import {
  LoadingButtonsTypes,
  QueriesItemType,
  QueryParamsType,
  SavedParamsTypes,
} from '../../types'
import { createStructuredSelector } from 'reselect'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from 'app/store'
import {
  getIsShowQueryParamsDrawerState,
  getLoadingButtonsState,
  getQueryIdState,
  getSavedParamsItemState,
  getSavedParamsState,
  getSelectedParamsState,
  getSelectedTabState,
  getTabsState,
} from '../../selectors'
import { queriesActions } from '../../reducer'
import Queries from '../../storage'
import { debounce } from 'lodash'
import { ChevronDownIcon } from 'assets/general'
import { duplicateParamsLogic } from 'pages/Queries/utils'
import { updateParamsLogic } from '../../utils'

interface QueryParamsDrawerProps {
  className?: string
}

type StateProps = {
  queryId: string
  savedParams: SavedParamsTypes[]
  selectedParams: string | null
  savedParamsItem: SavedParamsTypes
  isShowQueryParamsDrawer: boolean
  selectedTab: QueriesItemType | null
  loadingButtons: LoadingButtonsTypes
  tabs: QueriesItemType[]
}

type DispatchProps = typeof mapDispatchToProps

const DrawerFooter: FunctionComponent<
  QueryParamsDrawerProps & StateProps & DispatchProps
> = ({
  queryId,
  tabs,
  savedParams,
  savedParamsItem,
  selectedParams,
  selectedTab,
  loadingButtons,
  updateSavedParams,
  checkChangedTab,
}) => {
  const [paramsName, setParamsName] = useState(savedParamsItem?.name || '')
  const handleUpdateParams = useCallback(
    (value: QueryParamsType | string, name: string) => {
      checkChangedTab({
        query_id: queryId,
        data: {
          name: 'saved_params',
          value: updateParamsLogic(savedParams, selectedParams, name, value),
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryId, savedParams, selectedParams]
  )

  const handleChange = (value: string) => {
    setParamsName(value)
    debouncedUpdate(value)
  }

  const debouncedUpdate = useMemo(
    () =>
      debounce((value) => {
        handleUpdateParams(value, 'name')
      }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleUpdateParams]
  )

  useEffect(
    () => {
      setParamsName(savedParamsItem?.name || '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedParams]
  )
  return (
    <div className="flex flex-1 items-center">
      {/* <Label
        label={'Name:'}
        alignLabel="left"
        size='md'
        weight='bold'
      > */}
      <Input
        width="full"
        height="7"
        value={paramsName}
        placeholder="Enter name..."
        onChange={(e) => handleChange(e.target.value)}
        className="text-sm rounded-sm"
      />
      {/* </Label> */}
      <div className="flex ml-1">
        <Button
          loading={loadingButtons.name === 'save_params' && loadingButtons.is}
          disabled={!selectedTab?.changed || loadingButtons.is}
          className="self-center px-1.5"
          size="xs"
          loaderSize={'3'}
          onClick={() => {
            const tab = Queries.getTabById(queryId)
            const newParamsId = uuidv4()
            updateSavedParams({
              query_id: queryId,
              params_id: selectedParams === null ? newParamsId : selectedParams,
              data: {
                saved_params: Object.keys(tab).includes('saved_params')
                  ? tab.saved_params.map((d: SavedParamsTypes) =>
                      !d.id ? { ...d, id: newParamsId } : d
                    )
                  : selectedTab?.saved_params || [],
              },
            })
          }}
        >
          Save
        </Button>
        <MenuNew
          items={[
            {
              label: 'Save As...',
              onClick: () => {
                const newId = uuidv4()
                updateSavedParams({
                  query_id: queryId,
                  params_id: newId,
                  data: {
                    saved_params: selectedTab?.saved_params
                      ? [
                          ...duplicateParamsLogic(
                            tabs,
                            selectedTab.saved_params,
                            queryId,
                            savedParamsItem.id
                          ),
                          { ...savedParamsItem, id: newId },
                        ]
                      : [],
                  },
                })
              },
            },
          ]}
        >
          <ButtonSimple
            loading={
              loadingButtons.name === 'add_new_params' && loadingButtons.is
            }
            className="bg-secondary hover:shadow-secondary text-white text-md self-auto px-1.5 py-2 h-auto rounded-tr-sm rounded-br-sm ml-[1px]"
            self="auto"
            disabled={
              !selectedTab?.changed ||
              loadingButtons.is ||
              selectedParams === null
            }
            onClick={() => {}}
          >
            <ChevronDownIcon size="sm" color="white" />
          </ButtonSimple>
        </MenuNew>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  queryId: getQueryIdState,
  savedParams: getSavedParamsState,
  selectedParams: getSelectedParamsState,
  savedParamsItem: getSavedParamsItemState,
  isShowQueryParamsDrawer: getIsShowQueryParamsDrawerState,
  selectedTab: getSelectedTabState,
  loadingButtons: getLoadingButtonsState,
  tabs: getTabsState,
})

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
  updateSavedParams: queriesActions.updateSavedParams,
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerFooter)
