import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { SwitchCase } from 'shared'
import { queriesActions } from '../reducer'
import { QueriesItemType } from '../types'
import TabContentHeader from './TabContentHeader'
import CollectionTabContent from './tabContents/CollectionTabContent'
import FolderTabContent from './tabContents/FolderTabContent'
import QueryTabContent from './tabContents/QueryTabContent'

interface TabContentProps {
  selectedTab: QueriesItemType
  className?: string
}

type DispatchProps = typeof mapDispatchToProps

const TabContent: FunctionComponent<TabContentProps & DispatchProps> =
  React.memo(
    ({
      selectedTab,
      updateQuery,
      updateQueryCollection,
      // className,
    }) => {
      return (
        <div
          data-label="tab-content-container"
          className="border border-l-0 flex flex-col h-full"
        >
          <TabContentHeader
            selectedTab={selectedTab}
            handleUpdate={
              selectedTab.type === 'query' ? updateQuery : updateQueryCollection
            }
          />
          <SwitchCase value={selectedTab.type}>
            {{
              collection: <CollectionTabContent selectedTab={selectedTab} />,
              folder: <FolderTabContent selectedTab={selectedTab} />,
              query: <QueryTabContent selectedTab={selectedTab} />,
            }}
          </SwitchCase>
        </div>
      )
    },
    (prev, next) =>
      JSON.parse(JSON.stringify(prev.selectedTab)) ===
      JSON.parse(JSON.stringify(next.selectedTab))
  )

const mapDispatchToProps = {
  updateQuery: queriesActions.updateQuery,
  updateQueryCollection: queriesActions.updateQueryCollection,
}

export default connect(null, mapDispatchToProps)(TabContent)
