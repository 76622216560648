import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { PageTypes } from './types'

const PMainContainer: FunctionComponent<PageTypes> = ({
  children,
  className = '',
}) => {
  return (
    <div
      data-label="page-main-container"
      className={classnames(
        'mt-5 rounded', // bg-default-50 p-6
        className
      )}
    >
      {children}
    </div>
  )
}

export default PMainContainer
