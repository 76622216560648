import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'slide-over'

export interface LoadingType {
  is: boolean
  name: string
}

export interface SlideOverState {
  type: string | undefined
  isOpen?: boolean
  config?: any
  loading?: LoadingType
  params?: any
}

const initialState: SlideOverState = {
  isOpen: false,
  type: undefined,
  config: {},
  loading: { is: false, name: '' },
  params: {},
}

const slideOverReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    showSlideOver: (
      state: SlideOverState,
      { payload }: PayloadAction<SlideOverState>
    ) => {
      state.isOpen = true
      state.type = payload.type
      state.config = payload.config
      state.params = payload.params
    },
    hideSlideOver: (state: SlideOverState) => {
      return { ...initialState }
    },
    startLoading: (
      state: SlideOverState,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = { is: true, name: payload }
    },
    stopLoading: (state: SlideOverState) => {
      state.loading = { is: false, name: '' }
    },
  },
})

export const slideOverActions = slideOverReducer.actions

export default slideOverReducer.reducer
