import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import MainContainer from './containers/MainContainer'
import HeaderContainer from './containers/HeaderContainer'
import PRootContainer from 'containers/Page/PRootContainer'
import { projectsActions } from './reducer'

type DispatchProps = typeof mapDispatchToProps

const ProjectsN: FunctionComponent<DispatchProps> = ({ fetchProjects }) => {
  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  return (
    <PRootContainer data-label="projects-container" className="flex flex-col px-t80 py-t20">
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchProjects: projectsActions.fetchProjects,
}

export default connect(null, mapDispatchToProps)(ProjectsN)
