import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'dataset'

interface DatasetState {
  activeView: number
}

const initialState: DatasetState = {
  activeView: 0
}

const datasetReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    changeView: (state, { payload }: PayloadAction<any>) => {
      state.activeView = payload
    },
  },
})

export const datasetActions = datasetReducer.actions

export default datasetReducer.reducer
