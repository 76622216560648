import classNames from 'classnames'
import { bgColor, COLOR_TYPES, hSize, SIZE_TYPES, wSize } from 'utils/theme'

enum VARIANT {
  SQUARE,
  CIRCLE,
}

const VARIANT_MAPS: Record<VARIANT, string> = {
  [VARIANT.SQUARE]: '',
  [VARIANT.CIRCLE]: 'rounded-full',
}

const indicatorSize = (size: SIZE_TYPES | string) => `
  ${wSize(size)} 
  ${hSize(size)} 
`

const indicatorColor = (color: COLOR_TYPES) => `
  ${bgColor(color)}
`

interface IndicatorProps {
  variant?: VARIANT
  color?: COLOR_TYPES
  size?: SIZE_TYPES | string
  className?: string
  [propName: string]: any
}

export function Indicator({
  variant = VARIANT.CIRCLE,
  color = 'black',
  size = 'xs',
  className = '',
  ...other
}: IndicatorProps) {
  return (
    <div
      className={classNames(
        'inline-flex',
        VARIANT_MAPS[variant],
        indicatorColor(color),
        indicatorSize(size),
        className
      )}
      {...other}
    ></div>
  )
}

Indicator.variant = VARIANT
