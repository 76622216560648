import { SelectValueType } from 'shared'

export interface ConnectionsItemType {
  connection_id: string | number
  owner_id: string
  name: string
  description: string
  status: STATUS_TYPES
  type: ConnectionsTypeTypeT
  credentials: ConnectionsCredentialsTypeT
  config: any
  props: any
  created_at: string
  updated_at: string
  file?: any
  tables?: ConnectionsTablesType[]
}

export interface ConnectionsTablesType {
  id: number | string
  name: string
  data: any[]
}

export type STATUS_TYPES = 'active' | 'inactive'

export const CONNECTIONS_TYPE_TYPES = {
  BLANK: 'blank',
  JSON: 'json',
  CSV: 'csv',
  CLICKHOUSE: 'clickhouse',
  POSTGRESQL: 'postgresql',
  MYSQL: 'mysql',
  GOOGLE_SHEETS: 'google_sheets',
} as const

export type ConnectionsTypeTypeT =
  | typeof CONNECTIONS_TYPE_TYPES[keyof typeof CONNECTIONS_TYPE_TYPES]
  | string

export const _CONNECTIONS_TYPE_TYPES = {
  BLANK: 'blank',
  JSON: 'json',
  CSV: 'csv',
  CLICKHOUSE: 'clickhouse',
  POSTGRES: 'postgres',
  MYSQL: 'mysql',
  SQLITE: 'sqlite',
  MONGODB: 'mongodb',
  DB2: 'db2',
  GOOGLE_SHEETS: 'google_sheets',
  SOLANA: 'solana',
} as const

export type _ConnectionsTypeTypeT =
  | typeof _CONNECTIONS_TYPE_TYPES[keyof typeof _CONNECTIONS_TYPE_TYPES]

export interface ConnectionsClickhouseCredentialsType {
  url: string
  user: string
  password: string
  [name: string]: string
}

export interface ConnectionsPostgresqlCredentialsType {
  host: string
  port: string
  user: string
  password: string
  [name: string]: string
}

export type ConnectionsCredentialsTypeT =
  | ConnectionsClickhouseCredentialsType
  | ConnectionsPostgresqlCredentialsType
  | { [name: string]: undefined }

export interface CreateConnectionPayload {
  type: ConnectionsTypeTypeT
  credentials: ConnectionsCredentialsTypeT
  name?: string
  description?: string
  config?: any
  props?: any
}

export interface UpdateConnectionPayload {
  connection_id: string | number
  credentials: ConnectionsCredentialsTypeT
  type?: ConnectionsTypeTypeT
  name?: string
  description?: string
  config?: any
  props?: any
}

export type DeleteConnectionPayloadT = string | number

export interface LoadingType {
  is: boolean
  name: string
}

export type TypeFilterT = SelectValueType | undefined
