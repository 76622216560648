import React, { FunctionComponent } from 'react'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'

const HeaderContainer: FunctionComponent = () => {
  return (
    <PHeaderContainer>
      <PageName name="Connections" />
    </PHeaderContainer>
  )
}

export default HeaderContainer
