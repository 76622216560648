import { FC, Fragment, ReactNode } from 'react'
import { Popover as TPopover, Transition } from '@headlessui/react'
import cn from 'classnames'

interface PopoverProps {
  children: ReactNode
  show: boolean
  handleShow: (value: boolean) => void
  className?: string
  button?: ReactNode
}

export const Popover: FC<PopoverProps> = ({
  children,
  className = '',
  show,
  button,
  handleShow,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <TPopover className={cn('absolute z-40 mt-8', className)}>
        {button ? (
          <TPopover.Button as="div" onClick={() => handleShow(true)}>
            {button}
          </TPopover.Button>
        ) : null}

        <div className="fixed inset-0" onClick={() => handleShow(false)} />

        <Transition.Child
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <TPopover.Panel>{children}</TPopover.Panel>
        </Transition.Child>
      </TPopover>
    </Transition.Root>
    // <Transition.Root show={show} as={Fragment}>
    //   <div
    //     className={classnames(
    //       'absolute z-30 shadow', // overflow-hidden
    //       className
    //     )}
    //   >
    //     <Transition.Child as={Fragment}>
    //       <div
    //         className="fixed inset-0 bg-transparent bg-opacity-75 transition-opacity"
    //         onClick={() => handleShow(false)}
    //       ></div>
    //     </Transition.Child>
    //     <div className="relative">{children}</div>
    //   </div>
    // </Transition.Root>
  )
}

export const PopoverNew: FC<any> = ({
  children,
  className = '',
  show,
  button,
  buttonClass = '',
  handleShow,
}) => {
  return (
    <TPopover as="div" className="relative">
      {/* {({ open }) => ( */}
      <>
        <div
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <TPopover.Button as="div" className={buttonClass}>
            {button ? button : 'Popover'}
          </TPopover.Button>
        </div>

        {/* <div className="fixed inset-0" onClick={() => handleShow(false)} /> */}

        {/* TODO with Transition position not absolute. resource tables dots */}
        {/* <Transition
          // className='scale-0'
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        > */}
        <TPopover.Panel
          className="origin-top-right absolute z-40 top-[125%] w-max"
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {({ close }) => children(close)}
        </TPopover.Panel>
        {/* </Transition> */}
      </>
      {/* )} */}
    </TPopover>
  )
}
