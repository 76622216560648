import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PMainContainer from 'containers/Page/PMainContainer'
import { RootState } from 'app/store'
import { NoFound } from 'containers/NoFound'
import { getClustersState, getLoadingState } from '../selectors'
import { LoaderWithOverlay } from 'shared'
import ClustersItem from './ClustersItem'

interface MainContainerProps {
  clusters: any[]
  loading: boolean
}

const MainContainer: FunctionComponent<MainContainerProps> = ({
  clusters,
  loading = false,
}) => {
  return (
    <PMainContainer className="grid auto-rows-max grid-cols-3 gap-t20 flex-1 relative">
      <LoaderWithOverlay loading={loading} top="1/4" />
      {clusters && clusters.length ? (
        clusters.map((item) => (
          <ClustersItem key={item.cluster_id} item={item} />
        ))
      ) : (
        <NoFound name="No Clusters Found" />
      )}
    </PMainContainer>
  )
}

const mapStateToProps = createStructuredSelector<RootState, MainContainerProps>(
  {
    clusters: getClustersState,
    loading: getLoadingState,
  }
)

export default connect(mapStateToProps)(MainContainer)
