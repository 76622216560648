/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect } from 'react'
import PRootContainer from 'containers/Page/PRootContainer'
import { Tabs, Typography } from 'shared'
import ClustersContainer from './containers/ClustersContainer'
import classnames from 'classnames'
import Connections from 'pages/Connections'
import { connectionsActions } from 'pages/Connections/reducer'
import { connect } from 'react-redux'

const tabs = [
  {
    id: 'clusters',
    title: 'Clusters',
    content: <ClustersContainer />,
  },
  {
    id: 'connections',
    title: 'Connections',
    content: <Connections />,
  },
]

type DispatchProps = typeof mapDispatchToProps

const Clusters: FunctionComponent<DispatchProps> = ({
  selectConnectionType,
}) => {
  useEffect(() => {
    return () => {
      selectConnectionType('')
    }
  }, [])

  return (
    <PRootContainer
      data-label="clusters"
      className="flex flex-col px-t80 py-t20 h-full"
    >
      <Tabs
        className="h-full flex"
        headerClassName="flex flex-col border-r"
        tabs={tabs}
        tabComponent={({
          tab,
          activeTab,
          disabled,
          color,
          handleChange,
        }: any) => (
          <div
            className={classnames(
              'cursor-pointer p-t25 border-r-4 border-white rounded-tl rounded-bl duration-300',
              activeTab === tab.id
                ? classnames('border-primary bg-default-100')
                : 'hover:bg-default-50 hover:border-default-50',
              { disabled }
            )}
            onClick={() => handleChange(tab.id)}
          >
            <Typography
              className="flex items-center truncate"
              color={activeTab === tab.id ? 'primary' : 'default-600'}
              size="lg"
            >
              {tab.title}
            </Typography>
          </div>
        )}
      />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  selectConnectionType: connectionsActions.selectConnectionType,
}

export default connect(null, mapDispatchToProps)(Clusters)
