import React from 'react'
import classnames from 'classnames'
import { Loader } from 'shared/loader'

interface ButtonSimpleProps {
  children: React.ReactNode
  onClick: (e?: any) => void
  className?: string
  loading?: boolean
  disabled?: boolean
  self?: string
  [propName: string]: any
}

export const ButtonSimple: React.FunctionComponent<ButtonSimpleProps> = ({
  children,
  className = '',
  self = 'start',
  loading = false,
  disabled = false,
  onClick,
  ...other
}) => {
  return (
    <button
      type="button"
      className={classnames(
        'group relative flex items-center justify-center border-transparent focus:outline-none duration-200',
        `self-${self}`,
        { disabled: disabled || loading },
        className
      )}
      onClick={(e: any) => onClick(e)}
      {...other}
    >
      <Loader loading={loading} className="left-1/2 -ml-3" />
      {children}
    </button>
  )
}
