/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Typography } from 'shared/typography'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { Button, Input, Tabs } from 'shared'
import { getSchemeDataState } from '../selectors'
import { sliderActions } from '../reducer'
import { v4 as uuidv4 } from 'uuid'
import FormulaTab from './FormulaTab'
import SourceColumnTab from './SourceColumnTab'
import { cloneDeep } from 'lodash'

const _selects = [
  {
    name: 'source',
    label: 'Source',
    value: undefined,
    options: [],
  },
  {
    name: 'source_column',
    label: 'Source Column',
    value: undefined,
    options: [],
  },
  {
    name: 'type',
    label: 'Column type',
    value: undefined,
    options: [{ id: 0, name: 'string', title: 'string' }],
  },
]

interface AddNewColumnPopoverContainerProps {
  handleClose: (value: boolean) => void
}

interface StateProps {
  schemeData: any[]
}

type DispatchProps = typeof mapDispatchToProps

const AddNewColumnPopoverContainer: FC<
  AddNewColumnPopoverContainerProps & StateProps & DispatchProps
> = ({ schemeData, handleClose, setSchemeData }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [formulaValue, setFormulaValue] = useState<string>('')
  const [selects, setSelects] = useState<any[]>(() => cloneDeep(_selects))

  const handleChangeInput = (value: string) => {
    setInputValue(value)
  }

  const handleSave = () => {
    const newName = inputValue || selects[1].value.name
    const _schemeData = [...schemeData]
    _schemeData.push({
      id: uuidv4(),
      name: selects[1].value.name,
      new_name: newName,
      type: selects[2]?.value?.name || 'string',
      description: `${newName} default description`,
      show: true,
    })
    setSchemeData(_schemeData)
  }

  const tabs = useMemo(() => {
    return [
      {
        id: 'source_column',
        title: 'Column from source',
        content: <SourceColumnTab selects={selects} setSelects={setSelects} />,
      },
      {
        id: 'formula',
        title: 'Formula',
        content: (
          <FormulaTab
            formulaValue={formulaValue}
            setFormulaValue={setFormulaValue}
          />
        ),
      },
    ]
  }, [selects, formulaValue])

  const isSaveDisabled: boolean =
    inputValue && selects[0].value && selects[1].value ? false : true

  useEffect(() => {
    return () => {
      setInputValue('')
      setSelects(_selects)
      setFormulaValue('')
    }
  }, [])

  return (
    <div className="relative h-full flex flex-col p-t10 bg-white shadow">
      <div className="mb-t10">
        <Typography size="xl" weight="bold">
          Add New Column
        </Typography>
      </div>
      <div className="pb-t20 mb-t20 border-b">
        <Input
          type="text"
          name="name"
          width="full"
          value={inputValue}
          placeholder={'Column name'}
          onChange={(e: any) => handleChangeInput(e.target.value)}
        />
      </div>
      <Tabs
        className="h-full flex flex-col grow"
        headerClassName="flex justify-center space-x-t15 px-t20"
        tabs={tabs}
        size="lg"
      ></Tabs>
      <div className="flex justify-between mt-t20">
        <Button color="default-500" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isSaveDisabled}
          onClick={() => {
            handleSave()
            handleClose(false)
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  schemeData: getSchemeDataState,
})

const mapDispatchToProps = {
  setSchemeData: sliderActions.setSchemeData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewColumnPopoverContainer)
