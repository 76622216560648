import { DotsVerticalIcon } from '@heroicons/react/outline'
import { FC, useEffect, useState } from 'react'
import { Button, IconButton, Input, Label, PopoverNew } from 'shared'
import cn from 'classnames'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getResourceTablesState, getSelectedTableState } from '../selectors'
import { RootState } from 'app/store'
import { resourceActions } from '../reducer'
import { IUpdateTablePayload } from '../types'

interface ITableListProps {}

interface StateProps {
  tables: any[]
  selectedTable: string | null
}

type DispatchProps = typeof mapDispatchToProps

const TableList: FC<ITableListProps & StateProps & DispatchProps> = ({
  tables = [],
  selectedTable,
  setSelectedTable,
  deleteTable,
  updateTable,
}) => {
  if (!tables.length)
    return <p className="text-center text-default-400">No tables yet.</p>
  return (
    <div className="bg-white border overflow-auto sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {tables.map((table) => (
          <li
            key={table.table_name}
            className={cn('block cursor-pointer hover:bg-gray-50', {
              'bg-indigo-50 hover:bg-indigo-50': table.table_name === selectedTable,
            })}
            onClick={() => setSelectedTable(table.table_name)}
          >
            <div className="px-4 py-3 flex items-center sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate">
                  <div className="flex text-sm">
                    <p className="font-bold text-indigo-700 truncate">
                      {table.table_name}
                    </p>
                    {/* <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                        in {table.department}
                      </p> */}
                  </div>
                  <div className="mt-1 flex space-x-2">
                    <div className="flex items-center space-x-0.5 text-xs text-gray-500">
                      <p className="shrink-0 font-normal text-gray-500">
                        Rows:
                      </p>
                      <p className="shrink-0 font-medium text-secondary">
                        {table.rows_n}
                      </p>
                    </div>
                    <div className="flex items-center space-x-0.5 text-xs text-gray-500">
                      <p className="shrink-0 font-normal text-gray-500">
                        Cols:
                      </p>
                      <p className="shrink-0 font-medium text-secondary">
                        {table.cols_n}
                      </p>
                    </div>
                    {/* <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Created at{' '}
                          <time dateTime={table.created_at}>
                            {table.updated_at}
                          </time>
                        </p>
                      </div> */}
                  </div>
                </div>
              </div>
              <div className="ml-5 flex-shrink-0">
                <TableOptions
                  table_name={table.table_name}
                  updateTable={updateTable}
                  deleteTable={deleteTable}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  tables: getResourceTablesState,
  selectedTable: getSelectedTableState,
})

const mapDispatchToProps = {
  setSelectedTable: resourceActions.setSelectedTable,
  deleteTable: resourceActions.deleteTable,
  updateTable: resourceActions.updateTable,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableList)

interface ITableOptionsProps {
  table_name: string
  deleteTable: (name: string) => void
  updateTable: (obj: IUpdateTablePayload) => void
}

export const TableOptions: FC<ITableOptionsProps> = ({
  table_name,
  updateTable,
  deleteTable,
}) => {
  const [new_table_name, setNewTableName] = useState<string>(table_name || '')
  useEffect(() => {
    setNewTableName(table_name)
  }, [table_name])

  return (
    <PopoverNew
      button={
        <IconButton className="hover:bg-default-200">
          <DotsVerticalIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </IconButton>
      }
    >
      {(close: () => void) => (
        <div className="relative space-y-4 h-full flex flex-col p-2.5 bg-white shadow">
          <div className="">
            <Label label={'New table name'} size="md" weight="medium" />
            <Input
              type="text"
              name="name"
              width="full"
              value={new_table_name}
              placeholder={'New table name'}
              onChange={(e: any) => setNewTableName(e.target.value)}
            />
          </div>
          <div className="flex justify-between space-x-2">
            <Button
              color="default-500"
              onClick={() => {
                setNewTableName(table_name) // TODO move Popover outside table and one for all rows
                close()
              }}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                deleteTable(table_name)
                setNewTableName('') // TODO move Popover outside table and one for all rows
                close()
              }}
            >
              Delete
            </Button>
            <Button
              color="primary"
              disabled={!new_table_name}
              onClick={() => {
                updateTable({ table_name, new_table_name })
                setNewTableName(new_table_name) // TODO move Popover outside table and one for all rows
                close()
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </PopoverNew>
  )
}
