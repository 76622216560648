import { RootState } from 'app/store'
import { getConnectionTypeState } from 'pages/Connections/selectors'
import { ConnectionsTypeTypeT } from 'pages/Connections/types'
import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PreviewContainer from '../PreviewContainer'
import AddNewColumnButton from '../schemeTab/AddNewColumnButton'
import SchemeTab from '../schemeTab/SchemeTab'

interface StateProps {
  connectionType: ConnectionsTypeTypeT
}

const DBSchema: FunctionComponent<StateProps> = ({ connectionType }) => {
  return (
    <div className="flex flex-col grow mt-t20">
      <AddNewColumnButton />
      <SchemeTab />
      {connectionType !== 'blank' ? <PreviewContainer /> : null}
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
})

export default connect(mapStateToProps)(DBSchema)
