import { createSelector } from 'reselect'
import { RootState } from '../../app/store'
import { IResources, ResourceId } from './types'

export const getResourcesListState = (state: RootState) => state.resources

export const getResourcesState = createSelector(
  getResourcesListState,
  (state) => state.resources
)

export const getResourceByIdState = (resource_id: ResourceId) => {
  return createSelector(
    getResourcesState,
    (resources: IResources[]) =>
      resources.filter(
        (resource: IResources) => resource.resource_id === resource_id
      )[0]
  )
}

export const getCheckedState = createSelector(
  getResourcesListState,
  (state) => state.checked
)
