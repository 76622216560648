import React from 'react'
import { Button } from 'shared'
import { buttonsGroup } from '../constants'

interface ButtonsContainerProps {}

export const ButtonsContainer: React.FunctionComponent<
  ButtonsContainerProps
> = () => {
  return (
    <div data-label="buttons container" className="flex flex-wrap mt-t30">
      {buttonsGroup.map((d) => {
        const isActive = d.id === 2
        return (
          <Button
            key={d.id + d.name}
            className={`mr-t20 hover:shadow-none hover:bg-primary-light hover:text-white`}
            color={isActive ? 'primary-light' : 'default-300'}
            isShadow={false}
            onClick={() => {}}
          >
            {d.name}
          </Button>
        )
      })}
    </div>
  )
}
