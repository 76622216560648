import React from 'react'
import classnames from 'classnames'
import {
  COLOR_TYPES,
  SIZE_TYPES,
  VARIANT_TYPES,
  buttonColor,
} from 'utils/theme'

interface BadgeProps {
  children: React.ReactNode
  color?: COLOR_TYPES
  size?: SIZE_TYPES
  variant?: VARIANT_TYPES
  className?: string
  isShadow?: boolean
}

export const Badge: React.FunctionComponent<BadgeProps> = ({
  children,
  color = 'default-300',
  size = 'xs',
  variant = 'CONTAINED',
  className = '',
  isShadow = true,
}) => {
  return (
    <div
      className={classnames(
        'inline-flex items-center rounded-full whitespace-no-wrap cursor-default',
        buttonColor(variant, color, isShadow),
        // buttonSize(size),
        'bg-default-600',
        'px-t12 h-t20',
        className
      )}
    >
      {children}
    </div>
  )
}
