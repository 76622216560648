import React from 'react'

type SwitcherItemsProps = {
  id: number
  iconComponent: any
}

interface SwitcherProps {
  items: SwitcherItemsProps[]
  active: number
  onClick: (id: number) => void
}

export const Switcher: React.FunctionComponent<SwitcherProps> = ({
  items = [],
  active,
  onClick,
}) => {
  return (
    <div className="flex self-end">
      {items.map((item) => (
        <div
          key={item.id}
          className={`${
            active === item.id
              ? 'bg-primary-light'
              : 'bg-default-100 hover:bg-default-300'
          } h-H flex items-center duration-200 px-md py-xs cursor-pointer first:rounded-tl first:rounded-bl last:rounded-tr last:rounded-br`}
          onClick={() => onClick(item.id)}
        >
          <item.iconComponent
            size={6}
            color={active === item.id ? 'white' : 'primary-light'}
          />
        </div>
      ))}
    </div>
  )
}
