import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import { modalActions } from 'containers/Modal/reducer'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { projectsService } from 'utils/services'
import { projectsActions } from './reducer'
import { getProjectsByIdState } from './selectors'
import { CreateProjectModalType, ProjectsItemType } from './types'

function* handleFetchProjects() {
  try {
    const data: ProjectsItemType[] = yield call(projectsService.getProjects)
    yield put(projectsActions.didFetchProjects(data))
  } catch (error) {
    yield put(projectsActions.didFetchProjectsFail())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleCreateProject({
  payload,
}: PayloadAction<CreateProjectModalType>) {
  try {
    yield put(modalActions.startLoading('create'))
    yield call(projectsService.createProject, payload)
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )

    // TODO add projects loading
    // yield put(projectsActions.startLoading())
    const data: ProjectsItemType[] = yield call(projectsService.getProjects)
    yield put(projectsActions.didFetchProjects(data))
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
    // yield put(projectsActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleUpdateProject({ payload }: PayloadAction<any>): any {
  try {
    yield put(modalActions.startLoading('save'))
    yield call(projectsService.updateProject, payload)
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )

    // TODO add projects loading
    // yield put(projectsActions.startLoading())
    const data: ProjectsItemType[] = yield call(projectsService.getProjects)
    yield put(projectsActions.didFetchProjects(data))
  } catch (error) {
    const project: any = yield select(getProjectsByIdState(payload.project_id))
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${project.name} not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
    // yield put(projectsActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleDeleteProject({ payload }: PayloadAction<any>) {
  try {
    yield put(modalActions.startLoading('delete'))
    yield call(projectsService.deleteProject, payload.project_id)
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )

    // yield put(projectsActions.startLoading())
    const data: ProjectsItemType[] = yield call(projectsService.getProjects)
    yield put(projectsActions.didFetchProjects(data))
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
    // yield put(projectsActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

export default function* watchProjectsSaga() {
  yield takeLatest(projectsActions.fetchProjects.type, handleFetchProjects)
  yield takeLatest(projectsActions.createProject.type, handleCreateProject)
  yield takeLatest(projectsActions.updateProject.type, handleUpdateProject)
  yield takeLatest(projectsActions.deleteProject.type, handleDeleteProject)
}
