import { useState, FC } from 'react'

import classNames from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/general'
import { SIZE_TYPES } from 'utils/theme'

interface SelectProps {
  id?: string
  classes?: {
    root?: string
    leftButton?: string
    rightButton?: string
    number?: string
  }
  page?: number
  maxPage?: number
  setPage?: (page: number, maxPage: number) => void
  size?: SIZE_TYPES
}

export const Pagination: FC<SelectProps> = ({
  classes = {},
  page = 1,
  maxPage = 3,
  setPage,
  size = 'md',
}) => {
  const [_page, _setPage] = useState(page)

  const click = (value: number) => {
    _setPage(value)
    if (setPage) setPage(value, maxPage)
  }

  return (
    <div
      data-label="pagination-contianer"
      className={classNames(
        'flex flex-row items-center justify-between',
        classes.root
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-center w-H h-H cursor-pointer rounded border-transparent duration-200 bg-white shadow',
          _page <= 1 ? 'pointer-events-none' : 'pointer-events-auto',
          classes.leftButton
        )}
        onClick={() => click(_page - 1)}
      >
        <ChevronLeftIcon
          size={size}
          color={_page > 1 ? 'default-700' : 'default-400'}
        />
      </div>
      <span
        className={classNames(
          'block mx-t5 w-t40 text-center truncate text-xl text-default-900',
          classes.number
        )}
      >
        {_page}
      </span>
      <div
        className={classNames(
          'flex items-center justify-center w-H h-H cursor-pointer rounded border-transparent duration-200 bg-white shadow',
          _page >= maxPage ? 'pointer-events-none' : 'pointer-events-auto',
          classes.rightButton
        )}
        onClick={() => click(_page + 1)}
      >
        <ChevronRightIcon
          size={size}
          color={_page < maxPage ? 'default-700' : 'default-400'}
        />
      </div>
    </div>
  )
}
