import React from 'react'
import classnames from 'classnames'
import {
  VARIANT_TYPES,
  SIZE_TYPES,
  COLOR_TYPES,
  buttonColor,
  // buttonSize,
} from 'utils/theme'
import { LoaderIcon } from 'assets/general'

interface ButtonProps {
  children: React.ReactNode
  onClick: (e?: any) => void
  color?: COLOR_TYPES
  size?: SIZE_TYPES
  variant?: VARIANT_TYPES
  loaderSize?: string
  className?: string
  isShadow?: boolean
  loading?: boolean
  disabled?: boolean
  [propName: string]: any
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  color = 'secondary',
  size = 'sm',
  variant = 'CONTAINED',
  className = '',
  loaderSize = 'md',
  isShadow = true,
  loading = false,
  disabled = false,
  onClick,
  ...other
}) => {
  return (
    <button
      type="button"
      className={classnames(
        'group relative self-start flex items-center justify-center text-sm focus:outline-none duration-200',
        { disabled: disabled || loading },
        buttonColor(variant, color, isShadow),
        size === 'xs'
          ? 'px-2.5 py-1 h-auto rounded-sm'
          : 'h-H w-full rounded px-4 sm:w-auto',
        className
      )}
      onClick={(e: any) => onClick(e)}
      {...other}
    >
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child as React.ReactElement<any>, {
              className: classnames(
                'group-hover:text-white duration-200',
                child.props.className
              ),
              size: child.props.size || 5,
              color: variant === 'OUTLINED' ? color : 'white',
            })
          : child
      )}
      {loading ? (
        <LoaderIcon className="ml-2.5" size={loaderSize} color="default-300" />
      ) : null}
    </button>
  )
}
