import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateDatasetPayload,
  DatasetsItemType,
  DeleteDatasetPayload,
} from './types'
import sliderReducer from './containers/slider/reducer'

const prefix = 'project'

export interface ProjectState {
  datasets: DatasetsItemType[]
  loading: { is: boolean; name: string }
  selectedDataset: DatasetsItemType | null
}

const initialState: ProjectState = {
  datasets: [],
  loading: { is: false, name: '' },
  selectedDataset: null,
}

const projectReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchDatasets: (state, action: PayloadAction<string>) => {
      state.loading = { is: true, name: '' }
    },
    didFetchDatasets: (
      state,
      { payload }: PayloadAction<DatasetsItemType[]>
    ) => {
      state.datasets = payload
      state.loading = { is: false, name: '' }
    },
    didFetchDatasetsFail: (state) => {
      state.loading = { is: false, name: '' }
    },
    createDataset: (
      state,
      { payload }: PayloadAction<CreateDatasetPayload>
    ) => {},
    didCreateDataset: (state, { payload }: PayloadAction<DatasetsItemType>) => {
      state.datasets.push(payload)
    },
    updateDataset: (
      state,
      { payload }: PayloadAction<CreateDatasetPayload>
    ) => {},
    didUpdateDataset: (
      state,
      { payload }: PayloadAction<CreateDatasetPayload>
    ) => {
      const index = state.datasets.findIndex(
        (d) => d.dataset_id === payload.dataset_id
      )
      state.datasets.splice(index, 1, { ...state.datasets[index], ...payload })
    },
    deleteDataset: (
      state,
      { payload }: PayloadAction<DeleteDatasetPayload>
    ) => {},
    didDeleteDataset: (
      state,
      { payload }: PayloadAction<DeleteDatasetPayload>
    ) => {
      state.datasets = state.datasets.filter(
        (d) => d.dataset_id !== payload.dataset_id
      )
    },
    setSelectedDataset: (
      state,
      { payload }: PayloadAction<DatasetsItemType>
    ) => {
      state.selectedDataset = payload
    },
    startLoading: (state, { payload }: PayloadAction<string>) => {
      state.loading = { is: true, name: payload }
    },
    stopLoading: (state) => {
      state.loading = { is: false, name: '' }
    },
  },
})

export const projectActions = projectReducer.actions

export default combineReducers({
  projectReducer: projectReducer.reducer,
  sliderReducer,
})
