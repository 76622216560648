import { FunctionComponent, useState } from 'react'
import {
  Button,
  ButtonNew,
  IconButton,
  Input,
  Label,
  TextArea,
  Typography,
} from 'shared'
import { InputsType } from 'pages/Connections/containers/drawer/config'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getPreviewDataState,
  getSchemeDataState,
  getSelectedConnectionIdState,
  getSelectedConnectionsState,
} from './selectors'
import { connect } from 'react-redux'
import { projectActions } from 'pages/Project/reducer'
import { LoadingType } from 'containers/SlideOver/reducer'
import {
  getSlideOverLoadingState,
  getSlideOverParamsState,
} from 'containers/SlideOver/selectors'
import {
  ConnectionsItemType,
  ConnectionsTypeTypeT,
} from 'pages/Connections/types'
import { ChevronLeftIcon, TrashIcon } from 'assets/general'
import { StepperButtons } from './stepper'
import { getConnectionTypeState } from 'pages/Connections/selectors'

const infoInputs: InputsType[] = [
  {
    type: 'text',
    name: 'dataset_id',
    label: 'Dataset id',
    placeholder: 'Dataset id',
    required: true,
  },
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    placeholder: 'Name',
    required: true,
  },
  {
    type: 'text',
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    variant: 'textarea',
  },
]

const makeInitInputsValue = (params?: any) => {
  let obj: any = {}
  infoInputs.forEach((d) => {
    obj[d.name] = params[d.name] || ''
  })
  return obj
}

interface InputsValueType {
  dataset_id: string
  name: string
  description: string
  [name: string]: string
}

interface ManageDatasetInfoProps {
  prevStep?: () => void
}

interface StateProps {
  previewData: any[]
  schemeData: any[]
  loading: LoadingType | undefined
  params: any
  selectedConnections: ConnectionsItemType[]
  selectedConnectionId: string | number
  connectionType: ConnectionsTypeTypeT
}

type DispatchProps = typeof mapDispatchToProps

const ManageDatasetInfo: FunctionComponent<
  ManageDatasetInfoProps & StateProps & DispatchProps
> = ({
  loading = { is: false, name: '' },
  params,
  previewData,
  schemeData,
  selectedConnections,
  selectedConnectionId,
  connectionType,
  prevStep = () => {},
  createDataset,
  updateDataset,
  deleteDataset,
}) => {
  const { idProject, dataset } = params

  const [inputsValue, setInputsValue] = useState<InputsValueType>(() =>
    makeInitInputsValue(dataset || {})
  )
  const [errors, setErrors] = useState<any>({}) // TODO add FORMIK and validate scheme. Temp solutions

  const handleChangeInputs = (value: any, name: string) => {
    setInputsValue((prev: any) => {
      return { ...prev, [name]: value }
    })
  }

  return (
    <>
      <div className="flex grow mt-t20 justify-between">
        <div className="flex flex-col">
          {infoInputs.map((d, i) => (
            <Label
              key={i}
              alignLabel="left"
              className="justify-end mb-t20 first:ml-0 last:mb-0"
              label={`${d.label}:`}
              size="lg"
              weight="bold"
              width="auto"
            >
              {d.variant === 'textarea' ? (
                <TextArea
                  type={d.type}
                  name={d.name}
                  width="t250"
                  rows={4}
                  value={inputsValue[d.name]}
                  placeholder={d.placeholder}
                  onChange={(e: any) =>
                    handleChangeInputs(e.target.value, d.name)
                  }
                />
              ) : (
                <Input
                  type={d.type}
                  name={d.name}
                  width={'t250'}
                  value={inputsValue[d.name]}
                  placeholder={d.placeholder}
                  error={d.required && errors[d.name]}
                  onChange={(e: any) => {
                    if (d.required) {
                      setErrors((prev: any) => ({
                        ...prev,
                        [d.name]: d.required && !e.target.value,
                      }))
                    }
                    handleChangeInputs(e.target.value, d.name)
                  }}
                />
              )}
            </Label>
          ))}
        </div>
      </div>
      <StepperButtons>
        {dataset ? (
          <IconButton
            className="group absolute top-t26 right-t60 p-t5 mr-t10 rounded-full bg-default-100"
            // loading={loading.name === 'delete' && loading.is}
            disabled={loading.is}
            onClick={() =>
              deleteDataset({
                project_id: idProject,
                dataset_id: inputsValue.dataset_id,
                name: inputsValue.name,
              })
            }
          >
            <TrashIcon className="group-hover:text-secondary" />
          </IconButton>
        ) : null}
        <Button
          onClick={() => prevStep()}
          color="default-500"
          variant="OUTLINED"
        >
          <ChevronLeftIcon color="secondary" />
          <Typography>Back</Typography>
        </Button>

        <ButtonNew
          className="bg-green-200 hover:bg-green-300 text-green-900"
          loading={
            loading.name === 'create-dataset' ||
            (loading.name === 'save' && loading.is)
          }
          disabled={loading.is || Object.values(errors).includes(true)}
          onClick={() => {
            if (
              Boolean(
                infoInputs.filter((d) => d.required).length &&
                  Object.keys(errors).length
              )
            ) {
              const obj = {
                project_id: idProject,
                ...inputsValue,
                schema: schemeData,
                connections: selectedConnections,
                selectedConnectionId: selectedConnectionId || '',
                type: connectionType,
              }
              if (dataset) {
                updateDataset(obj)
              } else {
                createDataset(obj)
              }
            } else {
              setErrors((prev: any) => {
                let obj: any = {}
                infoInputs
                  .filter((d) => d.required)
                  .forEach((d) => {
                    obj[d.name] = !inputsValue[d.name]
                  })
                return obj
              })
            }
          }}
        >
          {dataset ? 'Save' : 'Create'}
        </ButtonNew>
      </StepperButtons>
    </>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  previewData: getPreviewDataState,
  schemeData: getSchemeDataState,
  loading: getSlideOverLoadingState,
  params: getSlideOverParamsState,
  selectedConnections: getSelectedConnectionsState,
  selectedConnectionId: getSelectedConnectionIdState,
  connectionType: getConnectionTypeState,
})

const mapDispatchToProps = {
  createDataset: projectActions.createDataset,
  updateDataset: projectActions.updateDataset,
  deleteDataset: projectActions.deleteDataset,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDatasetInfo)
