import { FC, ReactNode } from 'react'

interface IAppLayoutProps {
  children: ReactNode
}

const AppLayout: FC<IAppLayoutProps> = ({ children }) => {
  return <div className="app w-screen h-screen flex overflow-hidden">{children}</div>
}

export default AppLayout
