import React from 'react'
import classnames from 'classnames'
import {
  SIZE_TYPES,
  bgColor,
  // textSize,
  // pxSize,
  borderColor,
  wSize,
} from 'utils/theme'
import { InputProps } from 'shared/input'

const SIZE_MAPS: Record<SIZE_TYPES, string> = {
  xs: 'py-xs',
  sm: 'py-xs',
  md: 'px-4 text-lg',
  md_plus: 'px-4 text-lg',
  lg: 'py-md',
  xl: 'py-lg',
  '2xl': 'py-xl',
  '3xl': 'py-2xl',
  '4xl': 'py-3xl',
}

export interface TextAreaProps extends InputProps {}

export const TextArea: React.FunctionComponent<TextAreaProps> = ({
  size = 'sm',
  color = 'default-100',
  type = 'text',
  name = '',
  onChange = () => {},
  className,
  placeholder = '',
  width = '64',
  error = false,
  disabled = false,
  rows = 3,
  ...other
}) => {
  return (
    <textarea
      id={name}
      name={name}
      rows={rows}
      tabIndex={-1}
      className={classnames(
        'min-h-H block border-2 text-default-900 placeholder-default-500 rounded',
        { disabled: disabled },
        SIZE_MAPS[size],
        // pxSize(size),
        // textSize(size),
        wSize(width),
        bgColor(color),
        borderColor(error ? 'secondary' : color),
        className
      )}
      placeholder={placeholder}
      onChange={onChange}
      {...other}
    />
  )
}
