import { useRef, useMemo, FC } from 'react'
import ReactCalendar from 'react-calendar'

import './style.css'

import classnames from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/general'

const getEnd = (end: Date): Date => {
  end.setSeconds(59)
  return end
}

const comparisons = (a: Date, b: Date): boolean => {
  const is =
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()

  return is
}

const getIsBetween = (date: Date, start: Date, end: Date): boolean => {
  return start.getTime() <= date.getTime() && date.getTime() <= end.getTime()
}

interface SimpleCalendarProps {
  dates: { start: Date; end: Date }
  range: string
  setDates: (dates: { start: Date; end: Date | undefined }) => void
}

export const Calendar: FC<SimpleCalendarProps> = ({
  dates,
  range,
  setDates,
}) => {
  const rootRef = useRef<any>()

  const value: [Date, Date] | Date | undefined = useMemo(
    () =>
      range === '1d' &&
      dates.start.getHours() === 0 &&
      dates.start.getMinutes() === 0 &&
      dates.start.getSeconds() === 0
        ? dates.start
        : dates.start.getHours() === 0 &&
          dates.start.getMinutes() === 0 &&
          dates.start.getSeconds() === 0 &&
          dates.end.getHours() === 23 &&
          dates.end.getMinutes() === 59 &&
          dates.end.getSeconds() === 59
        ? [dates.start, dates.end]
        : undefined,
    [dates, range]
  )

  const getTileClassName = (date: Date, activeStartDate: Date): string => {
    const isSelected: boolean =
      (value instanceof Date && comparisons(value, date)) ||
      (value instanceof Array &&
        (comparisons(value[0], date) || comparisons(value[1], date)))

    const isBetween: boolean =
      value instanceof Array && getIsBetween(date, ...value)

    const isFuture: boolean = date.getTime() > new Date().getTime()

    const isOtherMonth: boolean = date.getMonth() !== activeStartDate.getMonth()

    const className: string = isSelected
      ? 'bg-primary text-white'
      : isBetween
      ? 'bg-default-200'
      : isFuture
      ? 'text-default-300'
      : isOtherMonth
      ? 'text-default-500'
      : ''

    return classnames('hover:border-primary', className)
  }

  return (
    <div
      data-label="calendar-component"
      ref={rootRef}
      //className='w-full p-t10'
    >
      <ReactCalendar
        tileClassName={({ activeStartDate, date, view }) => {
          return getTileClassName(date, activeStartDate)
        }}
        //tileClassName="hover:border-primary"
        //className='w-full'
        //defaultValue={defaultValue}
        value={value}
        onChange={(value: any[]) => {
          setDates({
            start: value[0],
            end: value[1] ? getEnd(value[1]) : undefined,
          })
        }}
        selectRange={true}
        allowPartialRange={true}
        maxDate={new Date()}
        next2Label={null}
        prev2Label={null}
        calendarType="US"
        locale="en-EN"
        view="month"
        prevLabel={<ChevronLeftIcon size="lg" className="text-current" />}
        nextLabel={<ChevronRightIcon size="lg" className="text-current" />}
        // onViewChange={(e: any) => {
        //   console.log(e)
        // }}
      />
    </div>
  )
}
