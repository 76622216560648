import React from 'react'
import classNames from 'classnames'
import { COLOR_TYPES, SIZE_TYPES } from 'utils/theme'

function Size(size: string | number) {
  return `
    w-${size} 
    h-${size}
  `
}

function Color(color: string) {
  return `
    text-${color}
  `
}

export interface IconProps {
  color?: COLOR_TYPES | null
  size?: SIZE_TYPES | string | number | null
  className?: string
  [propName: string]: any
}

interface IconBoxProps {
  children: React.ReactNode
  color?: COLOR_TYPES | null
  size?: SIZE_TYPES | string | number | null
  className?: string
  isChildrenClass?: boolean
  [propName: string]: any
}

///
//The size and color value of zero is needed to control the size and color outside of this component
///

export const Icon: React.FunctionComponent<IconBoxProps> = ({
  children,
  color = 'default-700',
  size = 'md',
  className = '',
  isChildrenClass = false,
  ...otherProps
}) => {
  return React.isValidElement(children) ? (
    <>
      {React.cloneElement(children as React.ReactElement<any>, {
        className: classNames(
          isChildrenClass && children.props.className, // TODO if set size w/h < children.props w/h then will be children.props w/h, not size w/h
          color ? Color(color) : '',
          size ? Size(size) : '',
          className
        ),
        ...otherProps,
      })}
    </>
  ) : (
    <>{children}</>
  )
}
