import { FunctionComponent } from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import FieldsTab from './FieldsTab'
import { Tabs } from 'shared'
import SavedParamsTab from './SavedParamsTab'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSavedParamsState } from 'pages/Queries/selectors'
import { SavedParamsTypes } from 'pages/Queries/types'
import { Rnd } from 'react-rnd'

interface RightPanelProps {
  className?: string
}

type StateProps = {
  savedParams: SavedParamsTypes[]
}

const RightPanel: FunctionComponent<RightPanelProps & StateProps> = ({
  className,
  savedParams,
}) => {
  const tabs = [
    {
      id: 'params',
      title: `Saved Params ${
        savedParams.length
          ? '(' + savedParams.filter((d) => 'id' in d).length + ')'
          : ''
      }`,
      content: <SavedParamsTab />,
    },
    {
      id: 'fields',
      title: 'Schema',
      content: <FieldsTab />,
    },
  ]

  return (
    <Rnd
      className={cn('flex flex-col', className)}
      default={{
        width: 250,
        height: '100%',
        x: 0,
        y: 0,
      }}
      maxWidth={500}
      minWidth={200}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      disableDragging={true}
    >
      <Tabs
        className="grow flex flex-col"
        headerClassName="relative flex space-x-4 px-4 py-2.5 border-b"
        tabs={tabs}
      ></Tabs>
    </Rnd>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  savedParams: getSavedParamsState,
})

export default connect(mapStateToProps)(RightPanel)
