import { all } from 'redux-saga/effects'
import mainSaga from 'pages/Main/sagas'
import signInSaga from 'pages/SignIn/sagas'
import projectsSaga from 'pages/ProjectsN/sagas'
import projectSaga from 'pages/Project/sagas'
import queriesSaga from 'pages/Queries/sagas'
import dataCatalogSaga from 'pages/DataCatalog/sagas'
import clustersSaga from 'pages/Clusters/sagas'
import connectionsSaga from 'pages/Connections/sagas'
import sidebarSaga from 'containers/Sidebar/sagas'
import resourcesSaga from 'pages/Resources/sagas'
import resourceSaga from 'pages/Resource/sagas'

export default function* rootSaga() {
  yield all([
    mainSaga(),
    signInSaga(),
    projectsSaga(),
    projectSaga(),
    queriesSaga(),
    dataCatalogSaga(),
    clustersSaga(),
    connectionsSaga(),
    sidebarSaga(),
    resourcesSaga(),
    resourceSaga(),
  ])
}
