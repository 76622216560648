/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from 'app/store'
import {
  ConnectionsTablesType,
  ConnectionsTypeTypeT,
} from 'pages/Connections/types'
import { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Input, Label, Select } from 'shared'
import { getPreviewDataMemoryState, getSelectedTablesState } from '../selectors'
import TabContentContainer from './TabContentContainer'
import { getConnectionTypeState } from 'pages/Connections/selectors'

interface SourceColumnTabProps {
  selects: any[]
  setSelects: (value: any[] | ((prevState: any[]) => any[])) => void
}

interface StateProps {
  selectedTables: ConnectionsTablesType[]
  connectionType: ConnectionsTypeTypeT
  previewDataMemory: any
}

const SourceColumnTab: FunctionComponent<SourceColumnTabProps & StateProps> = ({
  selects,
  setSelects,
  selectedTables,
  connectionType,
  previewDataMemory,
}) => {
  const handleSetSourceColumns = (prev: any) => {
    const keys =
      previewDataMemory !== null ? Object.keys(previewDataMemory.data[0]) : []
    prev[1].options = keys.map((d: any, i: number) => ({
      id: i,
      name: d,
      title: d,
    }))
  }

  const handleChangeSelect = (value: any, index: number) => {
    if (index === 0 && value) {
      setSelects((prev) => {
        const copyPrev = [...prev]
        handleSetSourceColumns(copyPrev)
        return copyPrev
      })
    } else if (index === 0 && !value) {
      setSelects((prev) => {
        const copyPrev = [...prev]
        copyPrev[1].options = []
        copyPrev[1].value = undefined
        return copyPrev
      })
    }
    setSelects((prev) => {
      const copyPrev = [...prev]
      copyPrev[index].value = value
      return copyPrev
    })
  }

  useEffect(() => {
    if (
      connectionType === 'blank' ||
      connectionType === 'csv' ||
      connectionType === 'json'
    ) {
      setSelects((prev: any[]) => {
        const copyPrev = [...prev]
        copyPrev[0].options = [
          {
            id: 0,
            name: connectionType,
            title: connectionType,
          },
        ]
        copyPrev[0].value = copyPrev[0].options[0]
        if (connectionType === 'csv') {
          handleSetSourceColumns(copyPrev)
        }
        return copyPrev
      })
    } else if (selectedTables.length) {
      setSelects((prev: any[]) => {
        const copyPrev = [...prev]
        copyPrev[0].options = selectedTables.map((d, i) => ({
          id: d.id,
          name: d.name,
          title: d.name,
        }))
        return copyPrev
      })
    }
  }, [])

  return (
    <TabContentContainer className="flex flex-col space-y-t10">
      {selects.map((d, i) => (
        <Label
          key={d.name}
          className="flex-nowrap justify-end mt-t15 first:mt-0"
          classNameLabel="whitespace-nowrap"
          label={d.label + ':'}
          alignLabel="left"
          size="lg"
          weight="bold"
        >
          {i === 1 &&
          (connectionType === 'blank' || connectionType === 'json') ? (
            <Input
              type={'text'}
              name={d.name}
              width={'t220'}
              value={d.value?.name || ''}
              placeholder={d.label}
              color="default-200"
              onChange={(e: any) =>
                handleChangeSelect({ name: e.target.value }, i)
              }
            />
          ) : (
            <Select
              classes={{ buttonTitle: 'pr-t30 truncate' }}
              options={d.options}
              size={220}
              placeholder={d.label}
              value={d.value}
              disabled={
                (i === 0 &&
                  (connectionType === 'blank' ||
                    connectionType === 'csv' ||
                    connectionType === 'json')) ||
                (i === 1 && !selects[0].value)
              }
              onChange={(value: any) => handleChangeSelect(value, i)}
            />
          )}
        </Label>
      ))}
    </TabContentContainer>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedTables: getSelectedTablesState,
  connectionType: getConnectionTypeState,
  previewDataMemory: getPreviewDataMemoryState,
})

export default connect(mapStateToProps)(SourceColumnTab)
