import { FunctionComponent, useEffect, useState } from 'react'
import classnames from 'classnames'
import {
  IconButton,
  Button,
  MenuNew,
  MONACO_LANGUAGES_TYPES,
  Label,
  Input,
} from 'shared'
import { MenuVerticalDotIcon } from 'assets/general'
import Queries from '../storage'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getLoadingButtonsState, getSelectedParamsState } from '../selectors'
import { connect } from 'react-redux'
import { LoadingButtonsTypes, SavedParamsTypes } from '../types'
import { v4 as uuidv4 } from 'uuid'

interface ActionContainerProps {
  query: string | any
  items: any[]
  handleUpdate: (data: any) => void
  className?: string
  run?: any
}

interface StateProps {
  loadingButtons: LoadingButtonsTypes
  selectedParams: string | null
}

const ActionContainer: FunctionComponent<ActionContainerProps & StateProps> = ({
  query,
  items,
  handleUpdate,
  className = '',
  loadingButtons,
  selectedParams,
}) => {
  const [queryId, setQueryId] = useState<string>(query.query_id)

  useEffect(() => {
    setQueryId(query.query_id)
  }, [query.query_id])

  return (
    <div
      className={classnames(
        'flex items-center space-x-2.5',
        { 'pointer-events-none': query.deleted },
        className
      )}
    >
      <Label
        width="fit"
        label="Query id:"
        size="sm"
        alignLabel="left"
        className="mr-2"
      >
        <Input
          readOnly
          type="text"
          name="post_query"
          className="!w-[22rem] !bg-yellow-100 !border-yellow-100"
          placeholder="Query id(s)"
          value={queryId}
          onChange={(e: any) => setQueryId(e.target.value)}
          // onBlur={handleSetPostQuery}
        />
      </Label>
      <Button
        loading={loadingButtons.name === 'save' && loadingButtons.is}
        size="xs"
        loaderSize="3"
        className="!self-center"
        disabled={!query.changed || loadingButtons.is}
        onClick={() => {
          const tab = Queries.getTabById(query.query_id)
          const keys = Object.keys(tab)
          const newParamsId = uuidv4()
          const apiPath = keys.includes('api_path')
            ? tab.api_path
            : query.api_path || ''
          const data =
            tab.type === 'query'
              ? {
                  name: query.name,
                  params_id:
                    selectedParams === null ? newParamsId : selectedParams,
                  query_params: keys.includes('query_params')
                    ? tab.query_params
                    : query.query_params,
                  query_string: keys.includes('query_string')
                    ? tab.query_string
                    : query.query_string,
                  lang: keys.includes('lang')
                    ? tab.lang
                    : query.lang || MONACO_LANGUAGES_TYPES.SQL,
                  api_path: apiPath,
                  api_type: keys.includes('api_type')
                    ? tab.api_type
                    : query.api_type || 'GET',
                  api_url: query.path_api + apiPath,
                  saved_params: keys.includes('saved_params')
                    ? tab.saved_params.map((d: SavedParamsTypes) =>
                        !d.id ? { ...d, id: newParamsId } : d
                      )
                    : query.saved_params,
                  auth: keys.includes('auth') ? tab.auth : query.auth,
                }
              : {
                  api_path: apiPath,
                }
          handleUpdate({
            query_id: query.query_id,
            data,
          })
        }}
      >
        Save
      </Button>
      <MenuNew items={items.filter(Boolean)}>
        <IconButton className="hover:bg-default-100">
          <MenuVerticalDotIcon size="4" />
        </IconButton>
      </MenuNew>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  loadingButtons: getLoadingButtonsState,
  selectedParams: getSelectedParamsState,
})

export default connect(mapStateToProps)(ActionContainer)
