import React, { FunctionComponent, useCallback } from 'react'
import { useTable, useBlockLayout } from 'react-table'
import { FixedSizeList } from 'react-window'
import classnames from 'classnames'
import scrollbarWidth from './scrollbarWidth'
import {
  VirtualizedRTableProps,
  CellTypeT,
  RowTypeT,
  HeaderGroupTypeT,
} from './types'

// Create a default prop getter
const defaultPropGetter = () => ({})

export const VirtualizedRTable: FunctionComponent<VirtualizedRTableProps> = ({
  columns,
  data,
  contentHeight = 200,
  itemSize = 30,
  fullWidth = false,
  tableClassName = '',
  theadClassName = '',
  tbodyClassName = '',
  fixedListClassName = '',
  getHeaderGroupProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      width: 130,
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  )

  const RenderRow = useCallback(
    ({
      index,
      style,
    }: {
      index: number
      style: React.CSSProperties
    }): React.ReactElement<any, any> => {
      const row: RowTypeT = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps([
            { style, className: 'tr' },
            getRowProps(row, index),
          ])}
        >
          {row.cells.map((cell: CellTypeT, ind: number) => {
            return (
              <div
                {...cell.getCellProps([
                  { className: 'td inline-block' },
                  getCellProps(cell, ind, index),
                ])}
              >
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )

  return (
    <div {...getTableProps()} className={classnames('table', tableClassName)}>
      <div className={classnames(theadClassName)}>
        {headerGroups.map((headerGroup: HeaderGroupTypeT, ind: number) => (
          <div
            {...headerGroup.getHeaderGroupProps([
              { className: 'tr' },
              getHeaderGroupProps(headerGroup, ind),
            ])}
          >
            {headerGroup.headers.map((column: HeaderGroupTypeT, i: number) => (
              <div
                {...column.getHeaderProps([
                  { className: 'th inline-block' },
                  getHeaderProps(column, i, ind),
                ])}
              >
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()} className={classnames(tbodyClassName)}>
        <FixedSizeList
          className={fixedListClassName}
          height={(rows.length || 1) * itemSize} // {contentHeight}
          itemCount={rows.length}
          itemSize={itemSize}
          width={fullWidth ? '100%' : totalColumnsWidth + scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
  )
}
