import { createSelector } from 'reselect'
import { RootState } from '../../app/store'
import Queries from './storage'

export const getQueriesListState = (state: RootState) => state.queries

export const getQueriesState = createSelector(
  getQueriesListState,
  (state) => state.queries
)

// export const getQueriesByIdState = (query_id: string) => {
//   return createSelector(
//     getQueriesState,
//     (queries) =>
//       queries.filter((query) => query.query_id === query_id)[0]
//   )
// }

export const getLoadingState = createSelector(
  getQueriesListState,
  (state) => state.loading
)

export const getLoadingQueriesTreeState = createSelector(
  getQueriesListState,
  (state) => state.loadingQueriesTree
)

export const getLoadingButtonsState = createSelector(
  getQueriesListState,
  (state) => state.loadingButtons
)

export const getLoadingQueryState = createSelector(
  getQueriesListState,
  (state) => state.loadingQuery
)

export const getTabsState = createSelector(
  getQueriesListState,
  (state) => state.tabs
)

export const getSelectedTabState = createSelector(
  getQueriesListState,
  (state) => state.selectedTab
)

export const getQueryParamsState = createSelector(
  getSelectedTabState,
  (state) => state?.query_params || {}
)

export const getSavedParamsState = createSelector(
  getSelectedTabState,
  (state) => state?.saved_params || [{ params: {}, body: {} }]
)

export const getSelectedParamsState = createSelector(
  getQueriesListState,
  (state) => state.selectedParams
)

export const getSavedParamsItemState = createSelector(
  getSavedParamsState,
  getSelectedParamsState,
  (savedParams, selectedParams) =>
    selectedParams !== null || Queries.selectedParams !== null
      ? savedParams.filter(
          (d) => d.id === (selectedParams || Queries.selectedParams)
        )[0]
      : savedParams[0] || { params: {}, body: {} }
)

export const getQueryIdState = createSelector(
  getSelectedTabState,
  (state) => state?.query_id || ''
)

export const getQueryCollectionsState = createSelector(
  getQueriesListState,
  (state) => state.queryCollections
)

export const getQueryCollectionByIdState = (query_collection_id: string) => {
  return createSelector(
    getQueryCollectionsState,
    (collections) =>
      collections.filter(
        (collection) => collection.query_collection_id === query_collection_id
      )[0]
  )
}

export const getDatasetsTreeDataState = createSelector(
  getQueriesListState,
  (state) => state.datasetsTreeData
)

export const getHoveredPathState = createSelector(
  getQueriesListState,
  (state) => state.hoveredPath
)

export const getIsShowQueryParamsDrawerState = createSelector(
  getQueriesListState,
  (state) => state.isShowQueryParamsDrawer
)

export const getHoveredPathSelectedState = createSelector(
  getHoveredPathState,
  (state) => state.selected
)

export const getHoveredPathActiveState = createSelector(
  getHoveredPathState,
  (state) => state.active
)
