import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectionsItemType } from 'pages/Connections/types'

const prefix = 'project-slider'

export interface ProjectState {
  selectedConnections: ConnectionsItemType[]
  selectedConnectionId: string | number
  selectedTables: any[]
  previewData: any
  previewDataMemory: any
  schemeData: any[]
  isShowPreviewData: boolean
  isShowConnectionPopover: boolean
  activeStep: any
}

const initialState: ProjectState = {
  selectedConnections: [],
  selectedConnectionId: '',
  selectedTables: [],
  previewData: null,
  previewDataMemory: null,
  schemeData: [],
  isShowPreviewData: true,
  isShowConnectionPopover: false,
  activeStep: 'first',
}

const sliderReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setSelectedConnections: (
      state,
      { payload }: PayloadAction<ConnectionsItemType[]>
    ) => {
      state.selectedConnections = payload
    },
    setSelectedConnectionId: (
      state,
      { payload }: PayloadAction<string | number>
    ) => {
      state.selectedConnectionId = payload
      state.selectedTables = []
    },
    setSelectedTables: (state, { payload }: PayloadAction<any[]>) => {
      state.selectedTables = payload
    },
    setPreviewData: (state, { payload }: PayloadAction<any>) => {
      state.previewData = payload
    },
    setPreviewDataMemory: (state, { payload }: PayloadAction<any>) => {
      state.previewDataMemory = payload
    },
    setSchemeData: (state, { payload }: PayloadAction<any>) => {
      state.schemeData = payload
    },
    showPreviewData: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowPreviewData = payload
    },
    showConnectionPopover: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowConnectionPopover = payload
    },
    setActiveStep: (state, { payload }: PayloadAction<any>) => {
      state.activeStep = payload
    },
    resetState: (state) => {
      return initialState
    },
  },
})

export const sliderActions = sliderReducer.actions

export default sliderReducer.reducer
