/* eslint-disable react-hooks/exhaustive-deps */
import classnames from 'classnames'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { IconButton, Typography, VirtualizedRTable } from 'shared'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getPreviewDataMemoryState,
  getPreviewDataState,
  getSchemeDataState,
  getSelectedConnectionIdState,
  getSelectedConnectionsState,
  getSelectedTablesState,
} from './selectors'
import { NoFound } from 'containers/NoFound'
import { PlusIcon } from 'assets/general'
import { sliderActions } from './reducer'
import { Rnd } from 'react-rnd'

import './styles.css'
import { getSlideOverParamsState } from 'containers/SlideOver/selectors'
import { fileReaderCSV } from 'utils/helpers'
import {
  ConnectionsItemType,
  ConnectionsTablesType,
  ConnectionsTypeTypeT,
} from 'pages/Connections/types'
import { v4 as uuidv4 } from 'uuid'
import { isEqual } from 'lodash'
import { getConnectionTypeState } from 'pages/Connections/selectors'

interface StateProps {
  connectionType: ConnectionsTypeTypeT
  selectedConnections: ConnectionsItemType[]
  selectedConnectionId: string | number
  selectedTables: ConnectionsTablesType[]
  previewData: any
  previewDataMemory: any
  schemeData: any[]
  params: any
}

type DispatchProps = typeof mapDispatchToProps

const PreviewContainer: FunctionComponent<StateProps & DispatchProps> = ({
  connectionType,
  selectedConnections,
  selectedConnectionId,
  selectedTables,
  previewData,
  previewDataMemory,
  schemeData,
  params,
  showPreviewData,
  setSchemeData,
  setPreviewData,
  setPreviewDataMemory,
}) => {
  const { dataset } = params
  const [resizeState, setResizeState] = useState<{
    width: number | string
    height: number
    x: number
    y: number
  }>({
    width: '100%',
    height: 200,
    x: 0,
    y: 0,
  })
  const columns = useMemo(() => {
    if (previewData !== null) {
      const keys = Object.keys(previewData.data[0])

      const cols: any = keys
        .filter((d) => !previewData.hideColumns.includes(d))
        .map((key) => ({
          Header: key,
          accessor: key,
        }))
      return cols
    }
  }, [previewData])

  useEffect(() => {
    if (
      dataset &&
      dataset.connections &&
      dataset.connections.length &&
      dataset.selectedConnectionId
    ) {
      const connection = dataset.connections.find(
        (d: ConnectionsItemType) =>
          d.connection_id === dataset.selectedConnectionId
      )
      fileReaderCSV(connection.file)
        .then((result: any) => {
          setSchemeData(dataset.schema)
        })
        .catch((e) => {
          setPreviewData(null)
          setSchemeData(dataset.schema)
        })
    } else {
      const connection = selectedConnections.find(
        (d: ConnectionsItemType) => d.connection_id === selectedConnectionId
      )
      if (connection?.file)
        fileReaderCSV(connection.file)
          .then((result: any) => {
            if (result.data) {
              const schema = Object.keys(result.data[0]).map((d) => ({
                id: uuidv4(),
                name: d,
                new_name: d,
                type: typeof d,
                description: `${d} default description`,
                show: true,
              }))
              setSchemeData(schema)
            }
          })
          .catch((e) => {
            setPreviewData(null)
            setSchemeData([])
          })
    }
  }, [selectedConnectionId])

  useEffect(() => {
    if (selectedTables.length && selectedTables[0].data) {
      // if move back from scheme to prepare step not needed update scheme
      if (isEqual(previewDataMemory?.data || [], selectedTables[0].data)) {
      } else {
        const schema = Object.keys(selectedTables[0].data[0]).map((d) => ({
          id: uuidv4(),
          name: d,
          new_name: d,
          type: typeof d,
          description: `${d} default description`,
          show: true,
        }))
        setPreviewDataMemory({ data: selectedTables[0].data })
        setSchemeData(schema)
      }
    } else {
      if (connectionType !== 'csv') {
        setPreviewDataMemory(null)
        setPreviewData(null)
        setSchemeData([])
      }
    }
  }, [selectedTables])

  useEffect(() => {
    const _previewData = previewDataMemory !== null ? previewDataMemory : {}
    if (schemeData && _previewData && _previewData.data) {
      const newData = [..._previewData.data].map((d: any, i: number) => {
        let obj: any = {}
        const schemaKeys = schemeData.map((dd: any) => ({
          name: dd.name,
          new_name: dd.new_name,
        }))
        schemaKeys.forEach((f: { name: string; new_name: string }) => {
          obj[f.new_name] = d[f.name]
        })

        return obj
      })
      setPreviewData({
        ..._previewData,
        data: newData,
        hideColumns: schemeData.filter((f) => !f.show).map((d) => d.new_name),
      })
    }
  }, [schemeData])

  return (
    <Rnd
      className={'bg-default-100'}
      minHeight={'25px'}
      size={{
        width: resizeState.width,
        height: resizeState.height,
      }}
      position={{
        x: resizeState.x,
        y: resizeState.y,
      }}
      onResize={(e, direction, ref, delta, position) => {
        if (position.y !== resizeState.y)
          setResizeState((prev) => ({
            ...prev,
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            ...position,
          }))
      }}
      enableResizing={{
        top: true,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      disableDragging={true}
    >
      <div className="relative px-t20 py-t4 bg-primary-light">
        <Typography size="lg" color="white">
          Preview
        </Typography>
        <IconButton
          className="absolute z-10 right-0 top-0 bottom-0 bg-default-100"
          onClick={() => showPreviewData(false)}
        >
          <PlusIcon
            className="transform rotate-45"
            size="lg"
            color="secondary"
          />
        </IconButton>
      </div>
      <div className="relative flex h-full w-full overflow-x-auto">
        {previewData?.data ? (
          <VirtualizedRTable
            tableClassName="w-full h-full"
            tbodyClassName="overflow-x-auto"
            data={previewData?.data || []}
            columns={columns}
            contentHeight={resizeState.height - 75}
            itemSize={30}
            fullWidth
            getHeaderProps={(header, i) => ({
              className: classnames(
                'text-md border-b-2 text-default-700 truncate p-t15  first:rounded-tl last:rounded-tr'
              ),
              style: {
                width: header.width,
                minWidth: header.minWidth,
              },
            })}
            getCellProps={(cell, i) => ({
              className: classnames(
                'text-md py-t8 px-t15 text-left truncate border-b text-default-700'
              ),
              style: {
                width: cell.column.width,
                minWidth: cell.column.minWidth,
              },
            })}
          />
        ) : (
          <NoFound name="No Preview Data Found" />
        )}
      </div>
    </Rnd>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
  selectedConnections: getSelectedConnectionsState,
  selectedConnectionId: getSelectedConnectionIdState,
  selectedTables: getSelectedTablesState,
  previewData: getPreviewDataState,
  previewDataMemory: getPreviewDataMemoryState,
  params: getSlideOverParamsState,
  schemeData: getSchemeDataState,
})

const mapDispatchToProps = {
  showPreviewData: sliderActions.showPreviewData,
  setSchemeData: sliderActions.setSchemeData,
  setPreviewData: sliderActions.setPreviewData,
  setPreviewDataMemory: sliderActions.setPreviewDataMemory,
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainer)
