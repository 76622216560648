import { FC } from 'react'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'
import { connect } from 'react-redux'
import { IWorkspace } from 'containers/Sidebar/types'

interface HeaderContainerProps {
  workspace: IWorkspace | undefined
}

type DispatchProps = typeof mapDispatchToProps

export const HeaderContainer: FC<HeaderContainerProps & DispatchProps> = ({
  workspace,
}) => {
  return (
    <div data-label="resources-header-container">
      <PHeaderContainer>
        <PageName name={`${workspace?.name} Workspace`} />
      </PHeaderContainer>
    </div>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(HeaderContainer)
