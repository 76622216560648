import React, { FunctionComponent } from 'react'
import PRootContainer from 'containers/Page/PRootContainer'
import HeaderContainer from './contaners/HeaderContainer'
import MainContainer from './contaners/MainContainer'

const Monitoring: FunctionComponent = () => {
  return (
    <PRootContainer
      data-label="monitoring-container"
      className="flex flex-col h-full px-t80 py-t20"
    >
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

export default Monitoring
