import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'

import classnames from 'classnames'
import { ClockIcon, CloseIcon } from 'assets/general'
import { Typography, IconButton, Calendar } from 'shared'

import { DatePickerInput } from './DatePickerInput'
import {
  convertDates,
  getRange,
  getDatesAndRange,
  getTitleTypeTimeFormat,
} from './helpers'
import {
  DatapickerProps,
  ValueType,
  DatesIntervalType,
  LeftBlockType,
  LeftBlockChildType,
  RightElementType,
  ACTIVE_TYPES,
  VALUE_TYPES,
  DatesType,
} from './types'
import { bgColor } from 'utils/theme'

const leftBlocks: LeftBlockType[] = [
  {
    title: 'Relative',
    type: VALUE_TYPES.RELATIVE,
    children: [
      {
        title: '45m',
        times: {
          delta: {
            minute: 45,
          },
        },
      },
      {
        title: '12 hours',
        times: {
          delta: {
            hour: 12,
          },
        },
      },
      {
        title: '1 Month',
        times: {
          delta: {
            month: 1,
          },
        },
      },
      {
        title: 'Last Year',
        times: {
          delta: {
            year: 1,
          },
        },
      },
      {
        title: 'Last week',
        times: {
          delta: {
            day: 7,
          },
        },
      },
    ],
  },
  {
    title: 'Fixed',
    type: VALUE_TYPES.FIXED,
    children: [
      {
        title: 'Sep 1',
        times: {
          dates: {
            start: {
              month: 9,
              day: 1,
            },
          },
        },
      },
      {
        title: '9/1 - 9/2',
        times: {
          dates: {
            start: {
              month: 9,
              day: 1,
            },
            end: {
              month: 9,
              day: 2,
            },
          },
        },
      },
      {
        title: '9/2019 - 12/2020',
        times: {
          dates: {
            start: {
              year: 2019,
              month: 9,
            },
            end: {
              year: 2020,
              month: 12,
            },
          },
        },
      },
      {
        title: '2/2019',
        times: {
          dates: {
            start: {
              year: 2019,
              month: 2,
            },
          },
        },
      },
      {
        title: 'Sep',
        times: {
          dates: {
            start: {
              month: 9,
            },
          },
        },
      },
      {
        title: '2019',
        times: {
          dates: {
            start: {
              year: 2019,
            },
          },
        },
      },
      {
        title: '9/10/2020',
        times: {
          dates: {
            start: {
              year: 2020,
              month: 9,
              day: 10,
            },
          },
        },
      },
      {
        title: '9/10/2020 - 9/20/2020',
        times: {
          dates: {
            start: {
              year: 2020,
              month: 9,
              day: 10,
            },
            end: {
              year: 2020,
              month: 9,
              day: 20,
            },
          },
        },
      },
    ],
  },
  {
    title: 'Unix timestamps',
    type: VALUE_TYPES.UNIX,
    children: [
      {
        title: '1000000 - 10000000',
        times: {
          unix: {
            start: 1e12,
            end: 2e12,
          },
        },
      },
      {
        title: '1000000 - today',
        times: {
          unix: {
            start: 1e12,
          },
        },
      },
    ],
  },
]

const rightElements: RightElementType[] = [
  {
    title: 'Past 15 minutes',
    name: '15m',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        minute: 15,
      },
    },
  },
  {
    title: 'Past 1 hour',
    name: '1h',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        hour: 1,
      },
    },
  },
  {
    title: 'Past 4 Hours',
    name: '4h',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        hour: 4,
      },
    },
  },
  {
    title: 'Past 1 Day',
    name: '1d',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        day: 1,
      },
    },
  },
  {
    title: 'Past 2 Days',
    name: '2d',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        day: 2,
      },
    },
  },
  {
    title: 'Past 7 Days',
    name: '7d',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        day: 7,
      },
    },
  },
  {
    title: 'Past 1 Month',
    name: '1mo',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        month: 1,
      },
    },
  },
  {
    title: 'Past 3 Months',
    name: '3mo',
    type: VALUE_TYPES.RELATIVE,
    times: {
      delta: {
        month: 3,
      },
    },
  },
  {
    title: 'Select from Calendar...',
    icon: <ClockIcon color="white" />,
    onClick: (setActiveType) => setActiveType(ACTIVE_TYPES.CALENDER),
    backgroundColor: 'primary-dark',
  },
  {
    title: 'Custom Times',
    icon: <Typography color="white">New</Typography>,
    backgroundColor: 'primary-dark',
  },
]

export const DatePicker: FC<DatapickerProps> = ({
  value,
  setValue,
  preValue,
  setPreValue,
}) => {
  const [activeType, setActiveType] = useState<ACTIVE_TYPES>(ACTIVE_TYPES.NONE)

  const [error, setError] = useState<string>('')
  const [hoverValue, setHoverValue] = useState<ValueType | null>(null)

  const rootRef = useRef<any>()

  const dates: DatesIntervalType = useMemo(
    () => getDatesAndRange(value.times).dates,
    [value]
  )

  const viewDate: string = useMemo(
    () => getTitleTypeTimeFormat(dates).title,
    [dates]
  )

  useEffect(() => {
    window.addEventListener('mousedown', eventClick)
    window.addEventListener('click', eventClick)
    return () => {
      window.removeEventListener('mousedown', eventClick)
      window.removeEventListener('click', eventClick)
    }
  })

  const eventClick = useCallback(
    (e: any) => {
      if (
        rootRef &&
        !e.path.find((el: Node) => el === rootRef.current) &&
        activeType
      ) {
        setActiveType(ACTIVE_TYPES.NONE)
      }
    },
    [activeType]
  )

  const handleClick = useCallback(
    () => (activeType ? '' : setActiveType(ACTIVE_TYPES.DEFAULT)),
    [activeType, setActiveType]
  )

  const renderCalender = useCallback(() => {
    const { dates, range }: { dates: DatesIntervalType; range: string } =
      preValue && preValue.times
        ? getDatesAndRange(preValue.times)
        : getDatesAndRange(value.times)

    return (
      <div className="relative pt-t15">
        <Calendar
          dates={dates}
          range={range}
          setDates={({ start, end }: DatesType) => {
            const dates = convertDates({ start, end })

            setValue({
              title: getTitleTypeTimeFormat(getDatesAndRange({ dates }).dates)
                .title,
              times: { dates },
              range: getDatesAndRange({ dates }).range,
              type: VALUE_TYPES.ABSOLUTE,
            })
            setPreValue(null)
            setError('')
          }}
        />

        <IconButton
          className="absolute right-t7 top-t7"
          onClick={() => setActiveType(ACTIVE_TYPES.DEFAULT)}
        >
          <CloseIcon size="md" color="secondary" />
        </IconButton>
      </div>
    )
  }, [value, preValue, setPreValue, setValue])

  const renderLeftBox = useMemo(
    () => (
      <>
        <div className="w-1/2 border-r pb-t8 px-t15 my-t8">
          <Typography size="xl">Type custom times like:</Typography>

          {leftBlocks.map((el: LeftBlockType, i) => (
            <div key={i} className="mt-t10">
              <Typography className="mb-t8" size="lg">
                {el.title}
              </Typography>

              <div className="flex flex-wrap">
                {el.children.map((ch: LeftBlockChildType, ind) => {
                  const isActiveEl =
                    getTitleTypeTimeFormat(getDatesAndRange(ch.times).dates)
                      .title ===
                    getTitleTypeTimeFormat(getDatesAndRange(value.times).dates)
                      .title

                  return (
                    <div
                      key={ind}
                      className={classnames(
                        'group cursor-pointer rounded-3 py-t7 px-t12 my-t3 mr-t6',
                        isActiveEl
                          ? 'bg-primary'
                          : 'bg-default-200 hover:bg-primary'
                      )}
                      onClick={() => {
                        setValue({
                          type: el.type,
                          times: ch.times,
                          title: ch.title,
                          range: getDatesAndRange(ch.times).range,
                        })
                        setPreValue(null)
                        setError('')
                      }}
                      onMouseEnter={() => {
                        const dates: DatesIntervalType = getDatesAndRange(
                          ch.times
                        ).dates
                        setHoverValue({
                          title: getTitleTypeTimeFormat(dates).title,
                          times: ch.times,
                          range: getRange(dates),
                          type: el.type,
                        })
                        //setError("");
                      }}
                      onMouseLeave={() => {
                        setHoverValue(null)
                      }}
                    >
                      <Typography
                        className={isActiveEl ? '' : 'group-hover:text-white'}
                        size="md"
                        color={isActiveEl ? 'white' : 'default-900'}
                      >
                        {ch.title}
                      </Typography>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </>
    ),
    [value.times, setValue, setPreValue]
  )

  const renderRightBox = useMemo(
    () => (
      <>
        <div className="w-1/2 py-t16">
          {rightElements.map((el: RightElementType, i) => {
            const isActiveEl: boolean | undefined =
              el.times &&
              getTitleTypeTimeFormat(getDatesAndRange(el.times).dates).title ===
                getTitleTypeTimeFormat(getDatesAndRange(value.times).dates)
                  .title

            const className: string = classnames(
              'group cursor-pointer flex items-center p-t8',
              isActiveEl ? 'bg-primary' : 'hover:bg-primary'
            )

            const props =
              el.times && el.type
                ? {
                    className,
                    onClick: () => {
                      if (el.times && el.type) {
                        setValue({
                          type: el.type,
                          times: el.times,
                          title: el.title,
                          range: getDatesAndRange(el.times).range,
                        })
                        setPreValue(null)
                        setError('')
                      }
                    },
                    onMouseEnter: () => {
                      if (el.times && el.type) {
                        const dates: DatesIntervalType = getDatesAndRange(
                          el.times
                        ).dates
                        setHoverValue({
                          title: getTitleTypeTimeFormat(dates).title,
                          times: el.times,
                          range: getRange(dates),
                          type: el.type,
                        })
                      }
                      //setError("");
                    },
                    onMouseLeave: () => {
                      setHoverValue(null)
                    },
                  }
                : {
                    className,
                    onClick: () =>
                      el.onClick ? el.onClick(setActiveType) : null,
                  }

            return (
              <div key={i} {...props}>
                <div
                  className={classnames(
                    'flex items-center justify-center rounded-3 w-t42 py-t7 px-t5 mr-t10',
                    bgColor(el.backgroundColor || 'default-300')
                  )}
                >
                  {el.name ? (
                    <Typography size="md">{el.name}</Typography>
                  ) : (
                    el.icon
                  )}
                </div>
                <Typography
                  className={isActiveEl ? '' : 'group-hover:text-white'}
                  size="md"
                  color={isActiveEl ? 'white' : 'default-900'}
                >
                  {el.title}
                </Typography>
              </div>
            )
          })}
        </div>
      </>
    ),
    [value.times, setValue, setPreValue]
  )

  const renderDrop = useCallback(
    () => (
      <>
        {renderLeftBox}

        {renderRightBox}

        <div className="cursor-pointer absolute right-t8 top-t8 z-10 py-t5 px-t10 bg-white rounded shadow-LIGHT hover:shadow-primary">
          <Typography color="primary">View Docs</Typography>
        </div>
      </>
    ),
    [renderLeftBox, renderRightBox]
  )

  return (
    <div
      data-label="date-picker-component"
      ref={rootRef}
      className={classnames(
        'relative w-min',
        activeType === ACTIVE_TYPES.NONE ? 'cursor-pointer' : ''
      )}
      onClick={handleClick}
    >
      <DatePickerInput
        dates={dates}
        value={value}
        preValue={preValue}
        hoverValue={hoverValue}
        viewDate={viewDate}
        activeType={activeType}
        error={error}
        setValue={setValue}
        setPreValue={setPreValue}
        setActiveType={setActiveType}
        setError={setError}
      />

      <Typography className="absolute -top-t17 left-0" color="secondary">
        {error}
      </Typography>
      <div className="absolute top-0 left-0  h-full w-t65 pl-t4 py-t4">
        <Typography
          className="flex items-center justify-center bg-primary rounded-3 w-full h-full px-t4"
          size="lg"
          color="white"
        >
          {hoverValue?.range || preValue?.range || value.range}
        </Typography>
      </div>
      {activeType ? (
        <div className="flex absolute r-0 top-t50 z-10 w-t600 bg-white shadow rounded-3">
          {activeType === ACTIVE_TYPES.DEFAULT
            ? renderDrop()
            : renderCalender()}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
