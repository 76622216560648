import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IState,
  IUpdateWorkspace,
  IWorkspace,
  SelectedWorkspace,
  WorkspaceId,
} from './types'

const prefix = 'sidebar'

const initialState: IState = {
  workspaces: [],
  selectedWorkspace: null,
}

const sidebarReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchWorkspaces: () => {},
    didFetchWorkspaces: (state, { payload }: PayloadAction<IWorkspace[]>) => {
      state.workspaces = payload || []
    },
    createWorkspace: () => {},
    didCreateWorkspace: (state, { payload }: PayloadAction<IWorkspace>) => {
      state.workspaces.push(payload)
      state.selectedWorkspace = payload.workspace_id
    },
    updateWorkspace: () => {},
    didUpdateWorkspace: (
      state,
      { payload }: PayloadAction<IUpdateWorkspace>
    ) => {
      const idx: number = state.workspaces.findIndex(
        (d) => d.workspace_id === payload.workspace_id
      )
      state.workspaces[idx] = { ...state.workspaces[idx], ...payload.data }
    },
    deleteWorkspace: () => {},
    didDeleteWorkspace: (state, { payload }: PayloadAction<WorkspaceId>) => {
      state.workspaces = state.workspaces.filter(
        (d: IWorkspace) => d.workspace_id !== payload
      )
      if (state.selectedWorkspace === payload) state.selectedWorkspace = null
    },
    selectWorkspace: (state, { payload }: PayloadAction<SelectedWorkspace>) => {
      state.selectedWorkspace = payload
    },
  },
})

export const sidebarActions = sidebarReducer.actions

export default sidebarReducer.reducer
