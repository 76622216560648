import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react'

import classnames from 'classnames'

import { QueryParamsType, VariableProps } from '../../types'
import {
  isUniqueName,
  getUniqueName,
  validateValue,
  checkСompliance,
  variablesTypeToJsonType,
} from './helpers'

import { EditTable } from 'shared'
import { tryStringToJSON } from 'utils/helpers'

const valueToString = (variables: VariableProps[]) =>
  variables.map((el) =>
    typeof el.value !== 'string'
      ? { ...el, value: JSON.stringify(el.value) }
      : el
  )

const getInputClassName = (rowData: any) => {
  return classnames(
    'w-full py-1 px-2 outline-none border-2',
    validateValue(rowData.value) === undefined ||
      (rowData.column.id === 'name' &&
        !isUniqueName(rowData.value, rowData.data))
      ? 'border-secondary focus:border-secondary'
      : 'border-white focus:border-primary',
    rowData.column.id === 'value'
      ? typeof tryStringToJSON(rowData.value) === 'string'
        ? 'text-primary'
        : Number(rowData.value)
        ? 'text-success-dark'
        : 'text-secondary-dark'
      : ''
  )
}

interface TableViewProps {
  variables: VariableProps[] | undefined
  setVariables: (variables: QueryParamsType) => void
  className?: string
}

const TableView: FunctionComponent<TableViewProps> = ({
  variables = [],
  setVariables = () => null,
  className = '',
}) => {
  const oldVariablesRef = useRef<any>()

  const handleTableChange = useCallback(
    (data: any[]) => {
      if (data.findIndex((el) => validateValue(el.value) === undefined) !== -1)
        oldVariablesRef.current = variables

      setVariables(
        variablesTypeToJsonType(
          data.map((el, i) => {
            return {
              ...el,
              name: checkСompliance(el.name)
                ? el.name
                : (variables[i] || { name: '' }).name,
              value: tryStringToJSON(el.value),
            }
          })
        )
      )
    },
    [variables, setVariables]
  )

  const handleBlurTable = useCallback(
    (e: any, rowData: any) => {
      const data = rowData.data
        .filter((el: any, i: any) => i !== rowData.data.length - 1)
        .map((el: any) => ({
          ...el,
          value: tryStringToJSON(el.value),
        }))
      const i = rowData.row.index
      const el = data[i]

      if (el) {
        const value = validateValue(el.value)

        data[i] = {
          ...el,
          name: getUniqueName(el.name, data, isUniqueName),
          value: value !== undefined ? value : oldVariablesRef.current[i].value,
        }

        setVariables(variablesTypeToJsonType(data))
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
    ],
    []
  )

  const dataTable = useMemo(() => valueToString(variables), [variables])

  return (
    <div className={className}>
      <EditTable
        data={dataTable}
        setData={handleTableChange}
        onBlur={handleBlurTable}
        columns={columns}
        tableClassName="w-full border-l border-t"
        inputClassName={getInputClassName}
        getHeaderProps={() => ({
          className: classnames(
            'bg-default-100 text-left font-medium border-b border-r text-sm py-1 px-2'
          ),
        })}
        getRowProps={() => ({
          className: classnames('group relative'),
        })}
        getCellProps={(cell, i, rowI) => ({
          className: classnames(
            'text-xs text-left truncate border-b border-r text-default-700 p-0'
          ),
        })}
      />
    </div>
  )
}

export default TableView
