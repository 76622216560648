import React, { FunctionComponent } from 'react'
import { PageTypes } from './types'
import classnames from 'classnames'

interface PRootContainerProps {
  'data-label': string
  style?: object
}

type CombinedProps = PageTypes & PRootContainerProps

const PRootContainer: FunctionComponent<CombinedProps> = ({
  children,
  className = '',
  'data-label': dataLabel = '',
  style
}) => {
  return (
    <div
      data-label={dataLabel}
      className={classnames('flex-1', className)}
      style={style}
    >
      {children}
    </div>
  )
}

export default PRootContainer
