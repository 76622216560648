import React from 'react'
import {
  ClickhouseIcon,
  CsvIcon,
  GoogleSheetsIcon,
  JsonIcon,
  MySqlIcon,
  PostgreSqlIcon,
  MongodbIcon,
  SqLiteIcon,
  Db2Icon,
  SolanaIcon
} from 'assets/integrations'
import { IconProps } from 'assets/Icon'
import {
  ConnectionsCredentialsTypeT,
  ConnectionsItemType,
  ConnectionsTypeTypeT,
  CONNECTIONS_TYPE_TYPES,
  _ConnectionsTypeTypeT,
  _CONNECTIONS_TYPE_TYPES,
} from '../../types'
import { DataCatalogIcon } from 'assets/general'

interface ConnectionsConfigType {
  name: ConnectionsTypeTypeT
  label: string
  Icon: React.FunctionComponent<IconProps>
  enabled: boolean
}

interface _ConnectionsConfigType {
  name: _ConnectionsTypeTypeT
  label: string
  Icon: React.FunctionComponent<IconProps>
  enabled: boolean
}

export const connectionsConfig: ConnectionsConfigType[] = [
  {
    name: 'blank',
    label: 'Blank',
    Icon: DataCatalogIcon,
    enabled: true,
  },
  {
    name: 'json',
    label: 'JSON',
    Icon: JsonIcon,
    enabled: true,
  },
  {
    name: 'csv',
    label: 'CSV',
    Icon: CsvIcon,
    enabled: true,
  },
  {
    name: 'postgresql',
    label: 'PostgreSql',
    Icon: PostgreSqlIcon,
    enabled: true,
  },
  {
    name: 'mysql',
    label: 'MySql',
    Icon: MySqlIcon,
    enabled: true,
  },
  {
    name: 'clickhouse',
    label: 'Clickhouse',
    Icon: ClickhouseIcon,
    enabled: true,
  },
  {
    name: 'google_sheets',
    label: 'Google Sheets',
    Icon: GoogleSheetsIcon,
    enabled: true,
  },
]

export const _connectionsConfig: _ConnectionsConfigType[] = [
  {
    name: 'blank',
    label: 'Blank',
    Icon: DataCatalogIcon,
    enabled: false,
  },
  {
    name: 'json',
    label: 'JSON',
    Icon: JsonIcon,
    enabled: true,
  },
  {
    name: 'csv',
    label: 'CSV',
    Icon: CsvIcon,
    enabled: true,
  },
  {
    name: 'postgres',
    label: 'Postgres',
    Icon: PostgreSqlIcon,
    enabled: true,
  },
  {
    name: 'mysql',
    label: 'MySql',
    Icon: MySqlIcon,
    enabled: true,
  },
  {
    name: 'clickhouse',
    label: 'Clickhouse',
    Icon: ClickhouseIcon,
    enabled: true,
  },
  {
    name: 'sqlite',
    label: 'SqLite',
    Icon: SqLiteIcon,
    enabled: true,
  },
  {
    name: 'mongodb',
    label: 'MongoDB',
    Icon: MongodbIcon,
    enabled: true,
  },
  {
    name: 'db2',
    label: 'Db2',
    Icon: Db2Icon,
    enabled: false,
  },
  {
    name: 'google_sheets',
    label: 'Google Sheets',
    Icon: GoogleSheetsIcon,
    enabled: true,
  },
  {
    name: 'solana',
    label: 'Solana',
    Icon: SolanaIcon,
    enabled: true,
  },
]

export interface InputsType {
  type: string
  name: string
  label: string
  placeholder: string
  required?: boolean
  variant?: string
  options?: any
  disabled?: boolean
  textarea?: boolean
}

export const inputs: InputsType[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    placeholder: 'Name',
  },
  {
    type: 'text',
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
  },
]

export const getCredentialsInputs = (
  type: ConnectionsTypeTypeT
): InputsType[] => {
  switch (type) {
    case CONNECTIONS_TYPE_TYPES.CLICKHOUSE:
      return [
        {
          type: 'text',
          name: 'url',
          label: 'Url',
          placeholder: 'Url',
        },
        {
          type: 'text',
          name: 'user',
          label: 'User',
          placeholder: 'User',
        },
        {
          type: 'password',
          name: 'password',
          label: 'Password',
          placeholder: 'Password',
        },
      ]
    case CONNECTIONS_TYPE_TYPES.POSTGRESQL:
      return [
        {
          type: 'text',
          name: 'host',
          label: 'Host',
          placeholder: 'Host',
        },
        {
          type: 'text',
          name: 'port',
          label: 'Port',
          placeholder: 'Port',
        },
        {
          type: 'text',
          name: 'user',
          label: 'User',
          placeholder: 'User',
        },
        {
          type: 'password',
          name: 'password',
          label: 'Password',
          placeholder: 'Password',
        },
      ]
    default:
      return [] as InputsType[]
  }
}

export const _getCredentialsInputs = (
  type: _ConnectionsTypeTypeT
): InputsType[] => {
  switch (type) {
    case _CONNECTIONS_TYPE_TYPES.BLANK:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
      ]
    case _CONNECTIONS_TYPE_TYPES.CSV:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
        {
          type: 'file',
          name: 'file',
          label: 'CSV file',
          placeholder: 'Select or drop a file!',
        },
      ]
    case _CONNECTIONS_TYPE_TYPES.JSON:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
      ]
    case _CONNECTIONS_TYPE_TYPES.GOOGLE_SHEETS:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
      ]
    case _CONNECTIONS_TYPE_TYPES.MYSQL:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
      ]
    case _CONNECTIONS_TYPE_TYPES.CLICKHOUSE:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
        {
          type: 'text',
          name: 'url',
          label: 'Url',
          placeholder: 'Url',
        },
        {
          type: 'text',
          name: 'user',
          label: 'User',
          placeholder: 'User',
        },
        {
          type: 'password',
          name: 'password',
          label: 'Password',
          placeholder: 'Password',
        },
      ]
    case _CONNECTIONS_TYPE_TYPES.POSTGRES:
      return [
        {
          type: 'text',
          name: 'name',
          label: 'Resource Name',
          placeholder: 'Resource Name',
        },
        {
          type: 'text',
          name: 'description',
          label: 'Resource Description',
          placeholder: 'Resource Description',
          textarea: true,
        },
        {
          type: 'text',
          name: 'host',
          label: 'Host',
          placeholder: 'Host',
        },
        {
          type: 'text',
          name: 'port',
          label: 'Port',
          placeholder: 'Port',
        },
        {
          type: 'text',
          name: 'database',
          label: 'Database name',
          placeholder: 'Database name',
        },
        {
          type: 'text',
          name: 'user',
          label: 'Database username',
          placeholder: 'Database username',
        },
        {
          type: 'password',
          name: 'password',
          label: 'Database password',
          placeholder: 'Database password',
        },
      ]
    default:
      return [] as InputsType[]
  }
}

// Connections type icons
export const typeIcons: { [name: string]: React.FunctionComponent<IconProps> } =
  {
    [CONNECTIONS_TYPE_TYPES.CLICKHOUSE]: ClickhouseIcon,
    [CONNECTIONS_TYPE_TYPES.POSTGRESQL]: PostgreSqlIcon,
    [CONNECTIONS_TYPE_TYPES.JSON]: JsonIcon,
    [CONNECTIONS_TYPE_TYPES.CSV]: CsvIcon,
    [CONNECTIONS_TYPE_TYPES.MYSQL]: MySqlIcon,
    [CONNECTIONS_TYPE_TYPES.GOOGLE_SHEETS]: GoogleSheetsIcon,
    [CONNECTIONS_TYPE_TYPES.BLANK]: DataCatalogIcon,
  }

export const getCredentialsObject = (
  type: ConnectionsTypeTypeT,
  params?: ConnectionsItemType
): ConnectionsCredentialsTypeT => {
  let obj: ConnectionsCredentialsTypeT = {} as ConnectionsCredentialsTypeT
  if (type)
    getCredentialsInputs(type).forEach((d) => {
      obj[d.name] = params?.credentials[d.name] || ''
    })
  return obj
}
