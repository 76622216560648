import { createSelector } from 'reselect'
import { RootState } from '../../app/store'
import { IWorkspace, WorkspaceId } from './types'

export const getSidebarListState = (state: RootState) => state.sidebar

export const getWorkspacesState = createSelector(
  getSidebarListState,
  (state) => state.workspaces
)

export const getSelectedWorkspaceState = createSelector(
  getSidebarListState,
  (state) => state.selectedWorkspace
)

export const getWorkspaceByIdState = (workspace_id: WorkspaceId) => {
  return createSelector(
    getWorkspacesState,
    (workspaces: IWorkspace[]) =>
      workspaces.filter(
        (workspace: IWorkspace) => workspace.workspace_id === workspace_id
      )[0]
  )
}
