import React from 'react'
import classNames from 'classnames'

import { IconProps, Icon } from '../Icon'

export const AzureIcon: React.FunctionComponent<IconProps> = (props) => {
  return (
    <Icon {...props} className={classNames('fill-current', props.className)}>
      <svg
        data-label="azure-icon"
        viewBox="0 0 161.67 129"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m88.33 0-47.66 41.33-40.67 73h36.67zm6.34 9.67-20.34 57.33 39 49-75.66 13h124z"
          fill="#0072c6"
        />
      </svg>
    </Icon>
  )
}
