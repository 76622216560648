import Queries from './storage'
import { nodeCasesTypes } from './constants'
import { cloneDeep, isArray, isString } from 'lodash'
import { QueriesItemType, QueryParamsType, SavedParamsTypes } from './types'

export const checkChangedFields = (query_id: string) => {
  const q_tab = Queries.tabs.filter((d: any) => d.query_id === query_id)[0]

  delete q_tab.query_id
  delete q_tab.name
  delete q_tab.type
  if (q_tab.changed) delete q_tab.changed
  if ('temp' in q_tab) delete q_tab.temp
  if (q_tab.data) delete q_tab.data

  return q_tab
}

export const transformArrToTree: any = (group: any, lvl: number) => {
  let level = lvl
  return group instanceof Map
    ? Array.from(group, ([key, value]) => {
        return {
          key,
          items: transformArrToTree(value, level + 1),
          level,
          type: nodeCasesTypes[level],
          name: key,
          id: key + Math.floor(Math.random() * 10000000),
        }
      })
    : !(group instanceof Map) && isArray(group)
    ? group.map((d) => ({
        ...d,
        id: d.name + Math.floor(Math.random() * 10000000),
        level,
        type: nodeCasesTypes[level],
      }))
    : group
}

export const checkHoveredPath = (path: string) => {
  let newPath = path
  const lastChar = newPath[newPath.length - 1]
  if (lastChar === '/' && newPath.length > 1) newPath = newPath.slice(0, -1)
  return newPath
}

export const VariableNameSymbols = /[a-zA-Z0-9_-]/

export const variablesParser = (text: string, variablesArr: any[]) => {
  const variables: any = {}

  variablesArr.forEach((el) => {
    variables[el.name] = el.value
  })

  // find all vars in braces ${...} and replace all " to '
  const regexp = /\${([^}]+)}/g
  const res = text
    .replace(regexp, (match, name) => {
      const value = variables[name.trim()]
      if (isString(value) || isArray(value)) return `${JSON.stringify(value)}`
      return value
    })
    .replace(/"/g, "'")

  return res
}

export const duplicateParamsLogic = (
  tabs: QueriesItemType[],
  saved_params: SavedParamsTypes[],
  queryId: string,
  paramsId: string | undefined
) => {
  const tab = tabs.filter((d) => d.query_id === queryId)[0]
  const newParams = tab?.saved_params
    ? tab.saved_params.filter((d) => d.id === paramsId)[0]
    : { params: {}, body: {} }
  const idxParams = tab?.saved_params
    ? tab.saved_params?.findIndex((d) => d.id === paramsId)
    : -1
  const copyParams = cloneDeep(saved_params || [])
  copyParams.splice(idxParams, 1, newParams)
  return copyParams
}

export const updateParamsLogic = (
  savedParams: SavedParamsTypes[],
  paramsId: string | null,
  name: string,
  value: QueryParamsType | string
) => {
  const copySavedParams = cloneDeep(savedParams)
  const idx = copySavedParams.findIndex((d) =>
    paramsId !== null && d.id ? d.id === paramsId : !d.id
  )
  if (idx !== -1)
    copySavedParams.splice(idx, 1, {
      ...copySavedParams[idx],
      [name]: value,
    })
  return copySavedParams
}
