import { FunctionComponent, useCallback, useMemo } from 'react'
import cn from 'classnames'
import {
  ButtonSimple,
  LoaderWithOverlay,
  MONACO_LANGUAGES_TYPES,
  Tabs,
  Typography,
  VirtualizedRTable,
} from 'shared'
import {
  LoadingButtonsTypes,
  QueriesItemType,
  SavedParamsTypes,
} from '../types'
import { NoFound } from 'containers/NoFound'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getLoadingButtonsState, getSavedParamsItemState } from '../selectors'
import { connect } from 'react-redux'
import { queriesActions } from '../reducer'
import Queries from '../storage'
import _ from 'app/auth/constants'
import { isArray } from 'lodash'
import { Rnd } from 'react-rnd'

interface QueriyResultContainerProps {
  selectedTab: QueriesItemType
  tab?: string
  className?: string
}

interface StateProps {
  savedParamsItem: SavedParamsTypes
  loadingButtons: LoadingButtonsTypes
}

type DispatchProps = typeof mapDispatchToProps

const QueryResultsContainer: FunctionComponent<
  QueriyResultContainerProps & StateProps & DispatchProps
> = ({
  tab,
  selectedTab,
  savedParamsItem,
  loadingButtons,
  className,
  runQuery,
  runApi,
}) => {
  const getColumns = useCallback(
    () =>
      selectedTab.data && selectedTab.data[0]
        ? [
            {
              Header: '#',
              accessor: (row: any, i: any) => i,
              type: 'Number',
              width: 50,
            },
            ...Object.keys(selectedTab.data[0]).map((key) => ({
              Header: key,
              accessor: key,
              type: 'String',
              Cell: (rowData: any) => (
                <div
                  className={cn(
                    'whitespace-nowrap',
                    rowData.value !== null
                      ? 'text-default-900'
                      : 'text-default-300'
                  )}
                >
                  {JSON.stringify(rowData.value)}
                </div>
              ),
            })),
          ]
        : [],
    [selectedTab.data]
  )

  const getColumnNames = useCallback(
    () =>
      selectedTab.data && selectedTab.data[0]
        ? [
            {
              Header: '#',
              accessor: (row: any, i: any) => i,
              width: 50,
            },
            {
              Header: 'Name',
              accessor: 'Header',
            },
            {
              Header: 'Type',
              accessor: 'type',
            },
          ]
        : [],
    [selectedTab.data]
  )

  const table = useMemo(
    () => (
      <div className="grow relative overflow-x-auto">
        <LoaderWithOverlay
          loading={loadingButtons.name === 'run' && loadingButtons.is}
          className="items-center"
        />
        {selectedTab.data && isArray(selectedTab.data) ? (
          selectedTab.data.length ? (
            <div className="absolute top-0 left-0 h-full">
              <VirtualizedRTable
                tableClassName="w-full h-full overflow-hidden border border-collapse"
                tbodyClassName="h-[calc(100%-37px)] overflow-auto"
                fixedListClassName="!h-full overflow-hidden"
                data={selectedTab.data}
                columns={getColumns()}
                getHeaderProps={(cell, i) => ({
                  className: cn('border-b border-r text-sm p-2 bg-default-100'),
                })}
                getCellProps={(cell, i) => ({
                  className: cn(
                    'text-sm p-2 text-left border-b border-r text-default-700 overflow-x-auto overflow-y-hidden scroll-hide',
                    i === 0 ? 'bg-default-100' : ''
                  ),
                })}
              />
            </div>
          ) : (
            <NoFound name="No data found." />
          )
        ) : selectedTab.data ? (
          <div className="text-default-700 text-sm">
            <pre>{JSON.stringify(selectedTab.data, null, 2)}</pre>
          </div>
        ) : (
          <NoFound name="No data found." />
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab.data, getColumns, loadingButtons]
  )

  const columnsTable = useMemo(
    () => (
      <div className="grow relative overflow-x-auto overflow-y-hidden">
        <LoaderWithOverlay
          loading={loadingButtons.name === 'run' && loadingButtons.is}
          className="items-center"
        />
        {selectedTab.data && selectedTab.data.length ? (
          <div className="absolute top-0 left-0 h-full">
            <VirtualizedRTable
              tableClassName="w-full h-full overflow-hidden  border border-collapse"
              tbodyClassName="h-[calc(100%-37px)] overflow-auto"
              fixedListClassName="!h-full overflow-hidden"
              data={getColumns()}
              columns={getColumnNames()}
              getHeaderProps={(cell, i) => ({
                className: cn('border-b border-r text-sm p-2 bg-default-100'),
              })}
              getCellProps={(cell, i) => ({
                className: cn(
                  'text-sm p-2 text-left border-b border-r text-default-700 overflow-x-auto overflow-y-hidden scroll-hide',
                  i === 0 ? 'bg-default-100' : ''
                ),
              })}
            />
          </div>
        ) : (
          <NoFound name="No data found." />
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab.data, getColumns, loadingButtons]
  )

  const tabs = [
    {
      id: 'query_result',
      title: 'Query Result',
      content: table,
    },
    {
      id: 'columns',
      title: 'Columns',
      content: columnsTable,
    },
  ]

  return (
    <Rnd
      className={cn('flex flex-col', className)}
      default={{
        width: '100%',
        height: 235,
        x: 0,
        y: 0,
      }}
      maxHeight="100%"
      minHeight="45px"
      maxWidth="100%"
      minWidth="1"
      bounds=".boundary"
      enableResizing={{
        top: true,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      disableDragging={true}
    >
      {/* <div
        data-label="query-result-container"
        className={cn('flex flex-col', className)}
      > */}
      <div className="relative h-full">
        <div className="absolute top-2 left-2 z-10">
          <ButtonSimple
            disabled={
              selectedTab.deleted ||
              loadingButtons.is ||
              (tab === 'api' &&
                selectedTab.auth?.type === 'Bearer' &&
                !selectedTab.auth.token)
            }
            className="bg-green-200 hover:bg-green-300 text-green-900 text-sm px-4 py-1 rounded-sm"
            onClick={() => {
              const parsedApiPath =
                `${selectedTab.path_api}${selectedTab.api_path}`
                  .split('/')
                  .map((d) =>
                    d.includes(':')
                      ? savedParamsItem?.params[d.substring(1)]
                      : d
                  )
                  .join('/')

              if (tab === 'api')
                runApi({
                  query_id: selectedTab.query_id,
                  url: parsedApiPath,
                  method: selectedTab.api_type,
                  params: savedParamsItem?.body || {},
                  headers: selectedTab.auth?.token
                    ? {
                        Authorization: `Bearer ${selectedTab.auth.token}`,
                      }
                    : {},
                })
              else
                runQuery({
                  resource_id: selectedTab.resource_id || '',
                  query_id: selectedTab.query_id,
                  query_string:
                    Queries.getTabById(selectedTab.query_id)?.query_string ||
                    selectedTab.query_string,
                  query_params: {
                    ...savedParamsItem?.params,
                    ...savedParamsItem?.body,
                  },
                  lang:
                    Queries.getTabById(selectedTab.query_id)?.lang ||
                    selectedTab.lang ||
                    MONACO_LANGUAGES_TYPES.SQL,
                  post_query: selectedTab.post_query || [],
                })
            }}
          >
            {tab === 'api' ? 'Run API' : 'Run Query'}
          </ButtonSimple>
        </div>
        <Tabs
          className="h-full flex flex-col"
          headerClassName="flex space-x-4 pl-28 p-2.5"
          tabs={tabs}
        ></Tabs>
        <div className="absolute top-[14px] right-2.5">
          {selectedTab.data && selectedTab.data[0] ? (
            <Typography color="primary" className="font-medium">
              0.01 sec | {selectedTab.data.length} rows |{' '}
              {Object.keys(selectedTab.data[0]).length} cols | 4 KB
            </Typography>
          ) : (
            <Typography color="primary" className="font-medium">
              ---
            </Typography>
          )}
        </div>
      </div>
      {/* </div> */}
    </Rnd>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  savedParamsItem: getSavedParamsItemState,
  loadingButtons: getLoadingButtonsState,
})

const mapDispatchToProps = {
  runQuery: queriesActions.runQuery,
  runApi: queriesActions.runApi,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryResultsContainer)
