import React from 'react'

import classNames from 'classnames'
import { IconProps, Icon } from '../Icon'

export const DataCatalogIcon: React.FunctionComponent<IconProps> = (props) => {
  return (
    <Icon {...props} className={classNames('fill-current' ,props.className )}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 11h4"
        />
      </svg>
    </Icon>
  )
}
