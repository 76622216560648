import React, { FunctionComponent } from 'react'

const TabContentSkeleton: FunctionComponent = () => {
  return (
    <div data-label="tab-content-skeleton" className="h-full w-full p-4 pb-0">
      <div className="animate-pulse h-full space-y-4 flex flex-col">
        <div className="h-10 bg-default-100 rounded w-full"></div>
        <div className="grid grid-cols-12 gap-4 h-full">
          <div className="col-span-8 grid grid-rows-2 grid-flow-col gap-4">
            <div className="h-full bg-default-100 rounded w-full"></div>
            <div className="h-full bg-default-100 rounded w-full"></div>
          </div>
          <div className="col-span-4">
            <div className="h-full bg-default-100 rounded w-full"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabContentSkeleton
