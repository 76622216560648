import { FC } from 'react'
import PMainContainer from 'containers/Page/PMainContainer'
import ResourcesTable from './ResourcesTable'

export const MainContainer: FC = () => {
  return (
    <PMainContainer className="h-full">
      <ResourcesTable />
    </PMainContainer>
  )
}
