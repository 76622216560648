import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import ProjectsItem from './ProjectsItem'
import PMainContainer from 'containers/Page/PMainContainer'
import { getLoadingState, getProjectsState } from '../selectors'
import { createStructuredSelector } from 'reselect'
import { ProjectsItemType } from '../types'
import { RootState } from 'app/store'
import { NoFound } from 'containers/NoFound'
import { LoaderWithOverlay } from 'shared'

interface MainContainerProps {
  projects: ProjectsItemType[]
  loading: boolean
}

const MainContainer: FunctionComponent<MainContainerProps> = ({
  projects,
  loading = false,
}) => {
  return (
    <PMainContainer className="grid auto-rows-max grid-cols-4 gap-t20 flex-1 relative">
      <LoaderWithOverlay loading={loading} top="1/4" />
      {projects && projects.length ? (
        projects.map((item, i) => (
          <ProjectsItem key={i} item={item} />
        ))
      ) : (
        <NoFound name="No Projects Found" />
      )}
    </PMainContainer>
  )
}

const mapStateToProps = createStructuredSelector<RootState, MainContainerProps>(
  {
    projects: getProjectsState,
    loading: getLoadingState,
  }
)

export default connect(mapStateToProps)(MainContainer)
