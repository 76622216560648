import React, { FunctionComponent } from 'react'
import MainContainer from './containers/MainContainer'
import HeaderContainer from './containers/HeaderContainer'
import PRootContainer from 'containers/Page/PRootContainer'

const Connections: FunctionComponent = () => {
  return (
    <PRootContainer
      data-label="connections-container"
      className="flex flex-col pl-t30 h-full"
    >
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

export default Connections
