import { createSelector } from 'reselect'
import { RootState } from 'app/store'

export const getClustersListState = (state: RootState) => state.clusters

export const getClustersState = createSelector(
  getClustersListState,
  (state) => state.clusters
)

export const getActiveViewState = createSelector(
  getClustersListState,
  (state) => state.activeView
)

export const getLoadingState = createSelector(
  getClustersListState,
  (state) => state.loading
)

export const getCheckedState = createSelector(
  getClustersListState,
  (state) => state.checked
)
