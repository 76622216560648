export enum MONACO_LANGUAGES_TYPES {
  JS = 'javascript',
  JSON = 'json',
  SQL = 'sql',
}

export interface MonacoEditorProps {
  value: string | undefined
  defaultValue?: string
  onChange?: (value: string | undefined, event: any) => void
  onValidate?: (markers: any[]) => void
  onMount?: (editor: any, monaco: any) => void
  language?: MONACO_LANGUAGES_TYPES
  classes?: {
    root: string
    editor: string
  }
  readOnly?: boolean
  minimap?: boolean
  lineNumbers?: boolean
  errorWords?: {
    word: string
    message: string
  }[]
}
