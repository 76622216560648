import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { cloneDeep } from 'lodash'
import { TreeNode } from './TreeNode'
import _ from 'lodash'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

export interface NodeProps {
  id: string | number
  name: string
  level: number
  type: string
  api_path: string
  query_collection_id?: string
  icon?: React.ReactNode
  label?: React.ReactNode
  className?: string
  selectedClassName?: string
  items?: NodeProps[]
  noSelect?: boolean
  menuItems?: {
    label: string
    onClick: () => void
  }[]
}

/// props for inside node
export interface _NodeProps extends NodeProps {
  toggled?: boolean
  disabled?: boolean
}

interface TreeViewProps {
  tree: NodeProps[]
  classes?: {
    root?: string
    rootUl?: string
  }
  selected?: any
  nodeCases?: any
  hoverFull?: boolean
  toggledNodes?: any[]
  disabledDrag?: string[]
  updateTreeDnd: (props: any) => void
  onSelect?: (node: _NodeProps) => void
  onToggle?: (node: _NodeProps) => void
}

export const TreeView: FunctionComponent<TreeViewProps> = ({
  tree,
  classes,
  selected,
  nodeCases,
  hoverFull = false,
  toggledNodes = [],
  disabledDrag,
  updateTreeDnd,
  onSelect = () => undefined,
  onToggle = () => undefined,
}) => {
  const [treeDnd, setTreeDnd] = useState<NodeProps[]>(tree)
  const [selectedNode, setSelectedNode] = useState<any>(
    selected?.query_id ? { ...selected, id: selected.query_id } : null
  )
  const [toggledData, setToggledData] = useState<any[]>([])

  useEffect(() => {
    if (toggledNodes.length)
      setToggledData((prev) => _.uniqBy([...toggledNodes, ...prev], 'id'))
  }, [toggledNodes])

  useEffect(() => {
    setSelectedNode(
      selected?.query_id ? { ...selected, id: selected.query_id } : null
    )
  }, [selected])

  useEffect(() => {
    setTreeDnd(tree)
  }, [tree])

  const _onToggle = (node: any) => {
    setToggledData((prev) => {
      const copyPrev = cloneDeep(prev)
      const idxNode = copyPrev.findIndex((d) => d.id === node.id)
      if (idxNode !== -1) {
        const newNode = copyPrev[idxNode]
        newNode.toggled = 'toggled' in newNode ? !newNode.toggled : true
        copyPrev.splice(idxNode, 1, newNode)
      } else {
        copyPrev.push({ ...node, toggled: true })
      }
      return copyPrev
    })
  }

  const _onSelect = (node: any) => {
    setSelectedNode(node)
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setTreeDnd((prev: NodeProps[]) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex] as NodeProps],
        ],
      })
    )
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes?.root || ''}>
        <ul className={classes?.rootUl || ''}>
          {treeDnd.map((node: any, index: number) => (
            <TreeNode
              key={node.id || node.query_collection_id}
              dragKey={node.type}
              toNodeKey={node.type + '_' + index}
              index={index}
              rootParentId={node.id || node.query_collection_id}
              node={
                'id' in node ? node : { ...node, id: node.query_collection_id }
              }
              memoryFields={{ apiPath: '', breadcrumbs: [] }}
              hoverFull={hoverFull}
              selectedNode={selectedNode}
              toggledData={toggledData}
              nodeCases={nodeCases}
              dndData={treeDnd}
              disabledDrag={disabledDrag}
              moveCard={moveCard}
              updateTreeDnd={updateTreeDnd}
              onToggle={(node: _NodeProps) => {
                _onToggle(node)
                onToggle(node)
              }}
              onSelect={(node: _NodeProps) => {
                _onSelect(node)
                onSelect(node)
              }}
            />
          ))}
        </ul>
      </div>
    </DndProvider>
  )
}
