import { FunctionComponent, useEffect, useState } from 'react'
import { Input, Label, SelectNew } from 'shared'
import { connect } from 'react-redux'
import { queriesActions } from 'pages/Queries/reducer'
import { QueriesItemType } from 'pages/Queries/types'
import cn from 'classnames'

const auths: any[] = [
  {
    id: 0,
    name: 'No Auth',
  },
  {
    id: 1,
    name: 'Bearer',
  },
]

interface AuthorizationViewProps {
  query: QueriesItemType
  className?: string
}

type DispatchProps = typeof mapDispatchToProps

const AuthorizationView: FunctionComponent<
  AuthorizationViewProps & DispatchProps
> = ({ query, className = '', checkChangedTab }) => {
  const [auth, setAuth] = useState<any>(() => {
    if (!query.auth?.type) return auths[0]
    return auths.filter((d) => d.name === query.auth?.type)[0]
  })
  const [token, setToken] = useState<string>(query.auth?.token || '')

  useEffect(() => {
    setAuth(() => {
      if (!query.auth?.type) return auths[0]
      return auths.filter((d) => d.name === query.auth?.type)[0]
    })
    setToken(query.auth?.token || '')
  }, [query.auth])

  const handleSetAuth = (value: any) => {
    setAuth(value)
    checkChangedTab({
      query_id: query.query_id,
      data: {
        name: 'auth',
        value:
          value.name === 'No Auth'
            ? { type: value.name }
            : { ...(query.auth || {}), type: value.name },
      },
    })
  }

  const handleTokenBlur = () => {
    checkChangedTab({
      query_id: query.query_id,
      data: {
        name: 'auth',
        value: { ...(query.auth || {}), token },
      },
    })
  }

  return (
    <div className={cn(className, 'flex items-center space-x-4')}>
      <Label width="fit" label="Auth:" size="sm" alignLabel="left">
        <SelectNew
          selected={auth}
          options={auths}
          width="w-[12rem]"
          isIcon={false}
          onChange={handleSetAuth}
        />
      </Label>
      {auth.name === 'Bearer' ? (
        <Label width="fit" alignLabel="left" label="Token:" size="sm">
          <Input
            type="text"
            name="token"
            width="[12rem]"
            placeholder="Token"
            value={token}
            onChange={(e: any) => setToken(e.target.value)}
            onBlur={handleTokenBlur}
          />
        </Label>
      ) : null}
      <p className="text-secondary">Need to save!</p>
    </div>
  )
}

// const mapStateToProps = createStructuredSelector<RootState, StateProps>({
//   resources: getResourcesState,
// })

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
}

export default connect(null, mapDispatchToProps)(AuthorizationView)
