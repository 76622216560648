import React, { FunctionComponent } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import PMainContainer from 'containers/Page/PMainContainer'
import DatasetItem from 'pages/Project/containers/DatasetItem'
import ManagePanel from 'pages/Project/containers/ManagePanel'
import { NoFound } from 'containers/NoFound'
import { DatasetsItemType } from 'pages/Project/types'
import { RootState } from 'app/store'
import { getLoadingState, getDatasetsState } from '../selectors'
import { LoaderWithOverlay } from 'shared'

interface MainContainerProps {}

type StateProps = {
  datasets: DatasetsItemType[]
  loading: boolean
}

const MainContainer: FunctionComponent<MainContainerProps & StateProps> = ({
  datasets,
  loading,
}) => {
  return (
    <div className="grid grid-cols-12 gap-6 h-full">
      <PMainContainer className="relative col-span-8 flex flex-col">
        <LoaderWithOverlay loading={loading} top="1/4" />
        <ManagePanel />
        {datasets && datasets.length ? (
          datasets.map((item, i) => (
            <DatasetItem
              key={i}
              idProject={item.project_id}
              item={item}
            />
          ))
        ) : (
          <NoFound name="No Datasets Found" />
        )}
      </PMainContainer>
      <PMainContainer className="col-span-4 relative">
        <LoaderWithOverlay loading={loading} top="1/4" />
      </PMainContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  datasets: getDatasetsState,
  loading: getLoadingState,
})

export default connect(mapStateToProps)(MainContainer)
