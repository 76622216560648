/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { IconButton, Menu, RTable, Typography } from 'shared'
import classnames from 'classnames'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSchemeDataState } from '../selectors'
import { sliderActions } from '../reducer'
import { cloneDeep } from 'lodash'
import { EyeOffIcon } from 'assets/general'
import { v4 as uuidv4 } from 'uuid'
import { DotsVerticalIcon } from '@heroicons/react/outline'

const schemeTypes = [
  {
    label: 'geo',
    value: 'geo',
  },
  {
    label: 'date',
    value: 'date',
  },
  {
    label: 'boolean',
    value: 'boolean',
  },
  {
    label: 'double',
    value: 'double',
  },
  {
    label: 'string',
    value: 'string',
  },
  {
    label: 'int',
    value: 'int',
  },
]

const getInputClassName = (rowData: any) => {
  return classnames(
    'w-full p-t6 group-hover:bg-default-50 hover:border-default-150 rounded outline-none border-2 border-transparent focus:border-primary'
  )
}

const TableInputComponent = React.memo(
  ({ rowData, onBlur }: any) => {
    const [value, setValue] = useState<string>(rowData.value)
    return (
      <input
        className={getInputClassName(rowData)}
        placeholder={rowData.value}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          onBlur({
            oldValue: rowData.value,
            newValue: value,
            columnName: rowData.cell.column.id,
            rowIndex: rowData.row.index,
          })
        }
      />
    )
  },
  (prev, next) => false
)

interface StateProps {
  schemeData: any[]
}

type DispatchProps = typeof mapDispatchToProps

type NewValueTypeT = any

interface BlurType {
  oldValue?: string
  newValue: NewValueTypeT
  columnName: string
  rowIndex: number
  newName?: string
}

const TableViewTab: FunctionComponent<StateProps & DispatchProps> = ({
  schemeData,
  setSchemeData,
}) => {
  const handleBlur = ({
    oldValue = '',
    newValue,
    columnName,
    rowIndex,
  }: BlurType) => {
    updateSchemeData(columnName, newValue, rowIndex)
  }

  const handleBlurDescription = ({
    columnName,
    newValue,
    rowIndex,
  }: BlurType) => {
    updateSchemeData(columnName, newValue, rowIndex)
  }

  const handleChangeType = ({ columnName, newValue, rowIndex }: BlurType) => {
    updateSchemeData(columnName, newValue, rowIndex)
  }

  const handleChangeCheck = ({
    columnName,
    newValue,
    rowIndex,
    newName,
  }: BlurType) => {
    updateSchemeData(columnName, newValue, rowIndex)
  }

  const updateSchemeData = (
    name: string,
    value: NewValueTypeT,
    index: number
  ) => {
    setSchemeData(
      cloneDeep(schemeData).map((d: any, i: number) => ({
        ...d,
        [name]: i === index ? value : d[name],
      }))
    )
  }

  const handleDuplicate = (rowData: any) => {
    const duplicateNumber = schemeData.filter(
      (filter: any) =>
        filter.new_name.indexOf(rowData.row.original.new_name) !== -1
    ).length
    let newName: string =
      rowData.row.original.new_name + ` (${duplicateNumber})`
    const idxScheme = schemeData.findIndex(
      (d: any) => d.id === rowData.row.original.id
    )
    const _schemeData = cloneDeep(schemeData)
    _schemeData.splice(idxScheme + 1, 0, {
      ...rowData.row.original,
      new_name: newName,
      id: uuidv4(),
    })
    setSchemeData(_schemeData)
  }

  const handleDelete = (rowData: any) => {
    const idxScheme = schemeData.findIndex(
      (d: any) => d.id === rowData.row.original.id
    )
    const _schemeData = cloneDeep(schemeData)
    _schemeData.splice(idxScheme, 1)
    setSchemeData(_schemeData)
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'show',
        Header: (rowData: any) => (
          <div>
            <EyeOffIcon />
          </div>
        ),
        Cell: (rowData: any) => {
          const isShow = rowData.value
          return (
            <div
              className="cursor-pointer"
              onClick={() =>
                handleChangeCheck({
                  columnName: rowData.cell.column.id,
                  newValue: !isShow,
                  rowIndex: rowData.row.index,
                  newName: rowData.row.original.new_name,
                })
              }
            >
              {<EyeOffIcon color={isShow ? 'default-300' : 'default-700'} />}
            </div>
          )
        },
        width: 50,
      },
      {
        Header: '#',
        accessor: (originalRow: any, rowIndex: number) => rowIndex,
        Cell: (rowData: any) => {
          return <Typography size="md">{rowData.value}</Typography>
        },
        width: 50,
      },
      {
        Header: 'Column Name',
        accessor: 'name',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
      },
      {
        Header: 'New Column Name',
        accessor: 'new_name',
        Cell: (rowData: any) => (
          <TableInputComponent rowData={rowData} onBlur={handleBlur} />
        ),
        width: 250,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (rowData: any) => (
          <Menu
            position="absolute"
            className="top-t3 cursor-pointer hover:bg-default-150 rounded px-t10 py-t7" // TODO add translate 50%
            items={schemeTypes
              .map((type) => ({
                label: type.label,
                onClick: () =>
                  handleChangeType({
                    newValue: type.value,
                    columnName: rowData.cell.column.id,
                    rowIndex: rowData.row.index,
                  }),
              }))
              .filter(Boolean)}
          >
            {(onClick: any) => (
              <div onClick={onClick}>
                <Typography size="md">{rowData.value}</Typography>
              </div>
            )}
          </Menu>
        ),
        width: 80,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (rowData: any) => (
          <TableInputComponent
            rowData={rowData}
            onBlur={handleBlurDescription}
          />
        ),
        width: 250,
      },
      {
        Header: '',
        id: 'action',
        Cell: (rowData: any) => (
          <Menu
            position="static"
            className="rounded" // TODO add translate 50%
            items={[
              {
                label: 'Duplicate',
                value: 'duplicate',
                onClick: () => handleDuplicate(rowData),
              },
              {
                label: 'Delete',
                value: 'delete',
                onClick: () => handleDelete(rowData),
              },
              {
                label: 'Access rights',
                value: 'access_rights',
                disabled: true,
                onClick: (e: any) => {
                  e.stopPropagation()
                  e.preventDefault()
                },
              },
            ].filter(Boolean)}
          >
            {(onClick: any) => (
              <IconButton
                className="hover:bg-default-150"
                onClick={(e) => {
                  e.stopPropagation()
                  onClick()
                }}
              >
                <DotsVerticalIcon />
              </IconButton>
            )}
          </Menu>
        ),
        width: 50,
      },
    ],
    [handleBlur, handleBlurDescription, handleChangeType, handleChangeCheck]
  )

  return (
    <div className="relative h-full">
      <div className="absolute h-full top-0 bottom-0 overflow-y-auto">
        <RTable
          columns={columns}
          data={schemeData}
          tableClassName="w-full border-t"
          tbodyClassName="h-full"
          getHeaderProps={(header, i) => ({
            className: classnames(
              'bg-default-100 text-left text-md p-t8 text-default-700 truncate p-t15'
            ),
            style: {
              width: header.width,
              minWidth: header.minWidth,
            },
          })}
          getRowProps={() => ({
            className: classnames(
              'group relative cursor-default hover:bg-default-50'
            ),
          })}
          getCellProps={(cell, i) => ({
            className: classnames(
              'text-md px-t15 text-left truncate  text-default-700'
              // { disabled: i === 1 }
            ),
            style: {
              width: cell.column.width,
              minWidth: cell.column.minWidth,
            },
          })}
        />
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  schemeData: getSchemeDataState,
})

const mapDispatchToProps = {
  setSchemeData: sliderActions.setSchemeData,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableViewTab)
