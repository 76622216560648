import React, { FunctionComponent, useState } from 'react'
import { ButtonSimple, Tabs } from 'shared'
import { QueriesItemType } from '../../types'
import QueryTabContainer from '../tabs/QueryTabContainer'
import ApiTabContainer from '../tabs/ApiTabContainer'
import RightPanel from '../right-panel/RightPanel'
import { queriesActions } from 'pages/Queries/reducer'
import { connect } from 'react-redux'
import TestsTabContainer from '../tabs/TestsTabContainer'
import SettingsTabContainer from '../tabs/SettingsTabContainer'

interface QueryTabContentProps {
  selectedTab: QueriesItemType
  className?: string
}

type DispatchProps = typeof mapDispatchToProps

const QueryTabContent: FunctionComponent<QueryTabContentProps & DispatchProps> =
  React.memo(
    ({
      selectedTab,
      showQueryParamsDrawer,
      // className,
    }) => {
      const [tab, setTab] = useState('query')
      return (
        <div className="flex flex-1 overflow-hidden">
          <div className="border-r flex-1 h-full overflow-hidden">
            <Tabs
              className="h-full flex flex-col"
              headerClassName="flex space-x-4 py-2.5 px-4 border-b"
              active={tab}
              onChange={(id) => setTab(id)}
              tabs={[
                {
                  id: 'query',
                  title: 'Query',
                  content: (
                    <QueryTabContainer className="" selectedTab={selectedTab} />
                  ),
                },
                {
                  id: 'api',
                  title: 'API',
                  content: <ApiTabContainer query={selectedTab} />,
                },
                {
                  id: 'tests',
                  title: 'Tests (7)',
                  content: <TestsTabContainer query={selectedTab} />,
                },
                {
                  id: 'settings',
                  title: 'Settings',
                  content: <SettingsTabContainer query={selectedTab} />,
                },
              ]}
              headerTab={() => (
                <div className="absolute right-4 top-2">
                  <ButtonSimple
                    className="bg-default-100 hover:bg-default-300 text-default-700 text-sm px-2.5 py-1 rounded-sm"
                    onClick={() => showQueryParamsDrawer()}
                  >
                    Params
                  </ButtonSimple>
                </div>
              )}
            />
          </div>
          <RightPanel />
        </div>
      )
    },
    (prev, next) =>
      JSON.parse(JSON.stringify(prev.selectedTab)) ===
      JSON.parse(JSON.stringify(next.selectedTab))
  )

const mapDispatchToProps = {
  showQueryParamsDrawer: queriesActions.showQueryParamsDrawer,
}

export default connect(null, mapDispatchToProps)(QueryTabContent)
