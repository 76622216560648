import { makeRequest } from './http'

const api = {
  // Token retrieval API
  // Authenticate user with email and password
  async userLogin(data) {
    const response = await makeRequest({
      path: '/auth/login',
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // User logout
  userLogout() {
    makeRequest({
      path: '/auth/logout',
      method: 'GET',
    })
  },
}

export default api
