import React, { FunctionComponent } from 'react'
import { MonitoringChartContainer } from './chart/MonitoringChartContainer'
import { MonitoringTable } from './table/MonitoringTable'

interface MainContainerProps {}

const MainContainer: FunctionComponent<MainContainerProps> = () => {
  return (
    <div className="flex-1 relative mt-t20">
      <MonitoringChartContainer />
      <MonitoringTable />
    </div>
  )
}

export default MainContainer
