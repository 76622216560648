import { FunctionComponent, useState } from 'react'
import VariantsDrawer from './drawer/VariantsDrawer'
import { PlusIcon } from 'assets/general'
import { ButtonNew } from 'shared'

const AddNewConnection: FunctionComponent = () => {
  const [isShow, setIsShow] = useState<boolean>(false)
  return (
    <div className="flex flex-col mb-t20 relative">
      <div className="flex">
        {/* <div
          className="bg-white p-t10 border-2 border-default rounded cursor-pointer border-dashed flex justify-center items-center"
          onClick={() => setIsShow((prev) => !prev)}
        >
          <PlusIcon size='lg' color='secondary' />
        </div> */}
        <ButtonNew
          onClick={() => setIsShow((prev) => !prev)}
          className="bg-green-200 hover:bg-green-300 text-green-900"
        >
          <PlusIcon className="mr-xs text-green-900" size={5} />
          Create Connection
        </ButtonNew>
      </div>
      <VariantsDrawer isShow={isShow} />
    </div>
  )
}

export default AddNewConnection
