import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RevisionContainer } from './RevisionContainer'
import SwitcherContainer from './SwitcherContainer'
import { getActiveViewState } from '../selectors'
import { datasetActions } from '../reducer'
import DatasetListView from './DatasetListView'
import { SwitchCase } from 'shared'
import DatasetTableView from './DatasetTableView'

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const DatasetColumns: FunctionComponent<SelectedProps & DispatchProps> = ({
  activeView,
  changeView,
}) => {
  return (
    <div className="mt-t20 p-t20 rounded shadow">
      <div className="flex justify-between">
        <RevisionContainer />
        <SwitcherContainer activeView={activeView} onClick={changeView} />
      </div>
      <SwitchCase value={activeView}>
        {{
          0: <DatasetListView />,
          1: <DatasetTableView />
        }}
      </SwitchCase>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<any, any>({
  activeView: getActiveViewState,
})

const mapDispatchToProps = {
  changeView: datasetActions.changeView,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetColumns)
