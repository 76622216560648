import { createSelector } from 'reselect'
import { RootState } from '../../app/store'

export const getProjectListState = (state: RootState) =>
  state.project.projectReducer

export const getDatasetsState = createSelector(
  getProjectListState,
  (state) => state.datasets
)

export const getDatasetByIdState = (dataset_id: string) => {
  return createSelector(
    getDatasetsState,
    (datasets) =>
      datasets.filter((dataset) => dataset.dataset_id === dataset_id)[0]
  )
}

export const getLoadingState = createSelector(
  getProjectListState,
  (state) => state.loading
)

export const getSelectedDatasetState = createSelector(
  getProjectListState,
  (state) => state.selectedDataset
)