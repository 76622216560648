import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import {
  ButtonSimple,
  MONACO_LANGUAGES_TYPES,
  SelectNew,
  SqlMonacoEditor,
} from 'shared'
import { variablesParser } from '../utils'
import { queriesActions } from '../reducer'
import { jsonTypeToVariablesType } from './right-panel/helpers'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSavedParamsItemState } from '../selectors'
import { SavedParamsTypes } from '../types'

interface ILanguageSelect {
  id: number | string
  name: MONACO_LANGUAGES_TYPES,
  label: string
}

const languages: ILanguageSelect[] = [
  {
    id: 0,
    name: MONACO_LANGUAGES_TYPES.SQL,
    label: 'SQL mode'
  },
  {
    id: 1,
    name: MONACO_LANGUAGES_TYPES.JS,
    label: 'JS mode'
  },
]

interface QueryEditorContainerProps {
  query: string | any
  className?: string
}

type StateProps = {
  savedParamsItem: SavedParamsTypes
}

type DispatchProps = typeof mapDispatchToProps

const QueryEditorContainer: FunctionComponent<
  QueryEditorContainerProps & StateProps & DispatchProps
> = ({ query, className, checkChangedTab, savedParamsItem }) => {
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [queryValue, setQueryValue] = useState<string>(query.query_string)
  const [language, setLanguage] = useState<ILanguageSelect>(() =>
    query.lang
      ? languages.filter((d) => d.name === query.lang)[0]
      : languages[0]
  )

  const transformQueryParams = useMemo(
    () =>
      jsonTypeToVariablesType(
        Object.assign(
          {},
          savedParamsItem?.params || {},
          savedParamsItem?.body || {}
        )
      ),
    [savedParamsItem]
  )

  useEffect(() => {
    setQueryValue(query.query_string)
  }, [query.query_string])

  useEffect(() => {
    if (query.lang)
      setLanguage(languages.filter((d) => d.name === query.lang)[0])
  }, [query?.lang])

  const generatePreview = () => {
    switch (language.name) {
      case MONACO_LANGUAGES_TYPES.JS: {
        try {
          return eval(variablesParser(queryValue, transformQueryParams || []))
        } catch (e: any) {
          return e?.message || 'Error'
        }
      }
      case MONACO_LANGUAGES_TYPES.SQL:
        return variablesParser(queryValue, transformQueryParams || [])
      default:
        return queryValue
    }
  }

  return (
    <div
      data-label="query-editor-container"
      className={classnames('border-t border-b-2 pt-1 overflow-hidden', className)}
    >
      <div className="h-full">
        <div className="absolute top-2 left-0 z-10">
          <SelectNew
            selected={language}
            options={languages}
            width="w-[8rem]"
            isIcon={false}
            className="ml-5 py-0"
            onChange={(value) => {
              setLanguage(value)
              checkChangedTab({
                query_id: query.query_id,
                data: { name: 'lang', value: value.name },
              })
            }}
          />
        </div>
        <div className="absolute top-14 right-3 z-10">
          <ButtonSimple
            className="bg-default-100 hover:bg-default-300 text-default-700 text-sm px-2.5 py-1 rounded-sm"
            disabled={query.deleted}
            onClick={() => setIsPreview(!isPreview)}
          >
            {isPreview ? 'Preview Off' : 'Preview On'}
          </ButtonSimple>
        </div>
        <SqlMonacoEditor
          language={!isPreview ? language.name : MONACO_LANGUAGES_TYPES.SQL}
          value={!isPreview ? queryValue : generatePreview()}
          defaultValue={queryValue}
          onChange={(value: any) => {
            setQueryValue(value)
            checkChangedTab({
              query_id: query.query_id,
              data: { name: 'query_string', value },
            })
          }}
          suggestions={transformQueryParams?.map((el) => ({
            label: el.name,
            insertText:
              language.name === MONACO_LANGUAGES_TYPES.JS
                ? `\`\${${el.name}}\``
                : `\${${el.name}}`,
          }))}
          readOnly={isPreview}
        />
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  savedParamsItem: getSavedParamsItemState,
})

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryEditorContainer)
