import React, { FunctionComponent } from 'react'
import { Button } from 'shared'
import { PlusIcon } from 'assets/general/PlusIcon'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'

const HeaderContainer: FunctionComponent = () => {
  return (
    <PHeaderContainer>
      <PageName name="Data Catalog" />
      <Button onClick={() => {}}>
        <PlusIcon className="mr-xs" />
        Add Dataset
      </Button>
    </PHeaderContainer>
  )
}

export default HeaderContainer
