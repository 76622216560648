import papaparser from 'papaparse'
import {
  PostgreSqlIcon,
  MySqlIcon,
  ClickhouseIcon,
  SolanaIcon,
} from 'assets/integrations'

// Index bug when call enum maps with constant. For example, SIZE_MAPS[any constant variable, not "some"].
export function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj
}

export function checkHasKey(maps: any, color: string) {
  if (hasKey(maps, color)) {
    return maps[color]
  }
  return undefined
}

export const tryStringToJSON = (str: any) => {
  try {
    const parse = JSON.parse(str)

    return typeof parse === 'string' ? str : parse
  } catch (err) {
    return str
  }
}

export const search = (data: string, searchValue: string) => {
  return data.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
}

export const fileReaderCSV = async (
  file: any,
  size?: number,
  not_preview?: boolean
) => {
  return await new Promise((resolve, reject) => {
    const previewSize = size || 5 * 1024
    const delimiter = ','

    const reader: any = new FileReader()
    let blob: any[] = []
    if (not_preview) {
      blob = file
    } else blob = file.slice(0, previewSize)

    reader.onload = function () {
      if (reader.result !== null) {
        const parsed: any = papaparser.parse(reader.result, {
          header: true,
          delimiter,
        })
        resolve(parsed)
      }
    }

    reader.onerror = function (e: any) {
      reject(e)
    }
    reader.readAsText(blob)
  })
}

export const getIcon = (type: string, size: number = 6, className: string = '') => {
  switch (type) {
    case 'postgres':
      return <PostgreSqlIcon size={size} className={className}/>
    case 'mysql':
      return <MySqlIcon size={size} className={className}/>
    case 'clickhouse':
      return <ClickhouseIcon size={size} className={className}/>
    case 'solana':
      return <SolanaIcon size={size} className={className}/>
    default:
      // add common template icon for DB
      return <PostgreSqlIcon size={size} className={className}/>
  }
}
