import { resourcesActions } from 'pages/Resources/reducer'
import { FC, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Loader, SlideOverHeader, Typography } from 'shared'
import { SlideOverState } from '../reducer'
import cn from 'classnames'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getCheckedState } from 'pages/Resources/selectors'
import { CheckIcon, WarningIcon } from 'assets/general'
import {
  _connectionsConfig,
  _getCredentialsInputs,
} from 'pages/Connections/containers/drawer/config'
import { _ConnectionsTypeTypeT } from 'pages/Connections/types'
import FileUploadComponent from 'pages/Connections/components/FileUploadComponent'
import { omit } from 'lodash'

const makeInitialValue = (type: _ConnectionsTypeTypeT, params?: any) => {
  let obj: any = {}
  if (type)
    _getCredentialsInputs(type).forEach((d) => {
      if (params) obj[d.name] = params[d.name] || params?.config[d.name] || ''
      else obj[d.name] = ''
    })
  return obj
}

interface ICreateResourceProps extends SlideOverState {
  hideSlideOver: () => void
}

interface StateProps {
  checked: boolean
}

type DispatchProps = typeof mapDispatchToProps

const CreateResource: FC<ICreateResourceProps & StateProps & DispatchProps> = ({
  loading,
  params,
  checked,
  hideSlideOver,
  createResource,
  updateResource,
  checkResource,
  didCheckResource,
}) => {
  const isUpdate = Boolean(params?.resource_id)
  const [dbType, setDbType] = useState<_ConnectionsTypeTypeT>(
    params?.config?.database || 'postgres'
  )
  const [values, setValues] = useState<any>(() =>
    makeInitialValue(dbType, params)
  )

  const handleChange = (e: any) => {
    setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const connectionInputs = useMemo(
    () => [..._getCredentialsInputs(dbType)],
    [dbType]
  )

  useEffect(() => {
    setValues(makeInitialValue(dbType, params))
    if (checked) didCheckResource(false)
  }, [dbType])

  useEffect(() => {
    if (checked) didCheckResource(false)
  }, [values])

  return (
    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
      <div className="flex-1">
        {/* Header */}
        <SlideOverHeader
          title={isUpdate ? 'Update resource' : 'Create new resource'}
          subtitle={
            isUpdate
              ? 'Get started by filling in the information below to update your resource.'
              : 'Get started by filling in the information below to update create new resource.'
          }
          close={hideSlideOver}
        />

        {/* Divider container */}
        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
          {/* Databases */}
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-8 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            {_connectionsConfig
              .filter((con) => con.enabled)
              .map((el, i) => (
                <div
                  key={i}
                  className={cn(
                    'w-full flex justify-center py-2 rounded bg-neutral-100 border-b-4 cursor-pointer hover:bg-neutral-200',
                    {
                      '!bg-green-50 border-green-400': dbType === el.name,
                      'pointer-events-none opacity-60':
                        isUpdate && dbType !== el.name,
                    }
                  )}
                  onClick={() => setDbType(el.name)}
                >
                  <el.Icon
                    width={'40px'}
                    height={'40px'}
                    size={null}
                    color="black"
                  />
                </div>
              ))}
          </div>

          {connectionInputs.map((el, i) => (
            <div
              key={el.name}
              className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
            >
              <div>
                <label
                  htmlFor={el.name}
                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                >
                  {el.label}
                </label>
              </div>
              <div className="sm:col-span-2">
                {el.type === 'file' ? (
                  // TODO width/height
                  <FileUploadComponent
                    accept={{ 'text/csv': ['.csv'] }}
                    type={el.type}
                    placeholder={el.placeholder}
                    setFile={(file: any) =>
                      handleChange({ target: { name: el.name, value: file } })
                    }
                  />
                ) : el.textarea ? (
                  <textarea
                    id={el.name}
                    name={el.name}
                    rows={3}
                    className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={values[el.name]}
                    onChange={handleChange}
                  />
                ) : (
                  <input
                    type={el.type}
                    name={el.name}
                    id={el.name} // TODO
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={values[el.name]}
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="space-y-6 py-6 sm:space-y-0 sm:py-0">
          <div className="flex justify-end space-x-1 px-4 sm:space-y-0 sm:px-6 sm:pb-5 sm:pt-1">
            {checked ? (
              <>
                <CheckIcon color="success" width="22px" size={null} />
                <Typography size="md" colorNew="text-success">
                  Connection is verified!
                </Typography>
              </>
            ) : (
              <>
                <WarningIcon color="secondary" width="22px" size={null} />
                <Typography size="md" colorNew="!text-secondary">
                  Check connection
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-between">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={hideSlideOver}
          >
            Cancel
          </button>
          <div className="space-x-3">
            <button
              type="button"
              className={cn(
                'relative inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0',
                { disabled: Boolean(loading?.is) }
              )}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                checkResource({
                  type: dbType,
                  config: omit(values, ['name', 'description']),
                })
              }}
            >
              <Loader loading={Boolean(loading?.is)} size="md" />
              Test Connection
            </button>
            <button
              type="button"
              className={cn(
                'inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                { disabled: Boolean(loading?.is) || !checked }
              )}
              disabled={Boolean(loading?.is) || !checked}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                const obj: any = {
                  type: dbType,
                  name: values.name,
                  description: values.description,
                  config: omit(values, ['name', 'description']),
                }
                if (isUpdate)
                  updateResource({ data: obj, resource_id: params.resource_id })
                else createResource(obj)
              }}
            >
              {/* <Loader loading={Boolean(loading?.is)} size="md" /> */}
              {isUpdate ? 'Save' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  checked: getCheckedState,
})

const mapDispatchToProps = {
  createResource: resourcesActions.createResource,
  updateResource: resourcesActions.updateResource,
  checkResource: resourcesActions.checkResource,
  didCheckResource: resourcesActions.didCheckResource,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateResource)
