import React from 'react'
import classnames from 'classnames'
import { COLOR_TYPES, SIZE_TYPES } from 'utils/theme'

export const chbgColor = (color: COLOR_TYPES) => {
  switch (color) {
    case 'secondary':
      return `border-secondary checked:bg-secondary checked:text-secondary hover:text-secondary focus:!border-secondary`
  }
}

const SIZE_MAPS: Record<SIZE_TYPES, string> = {
  xs: 'w-sm h-sm',
  sm: 'w-md h-md',
  md: 'w-t22 h-t22',
  md_plus: 'w-t22 h-t22',
  lg: 'w-xl h-xl',
  xl: 'w-2xl h-2xl',
  '2xl': 'py-2xl',
  '3xl': 'py-3xl',
  '4xl': 'py-4xl',
}

export interface CheckboxProps {
  checked: boolean
  name: string
  onChange: (e: any) => void

  size?: SIZE_TYPES
  color?: COLOR_TYPES
  className?: string | undefined
  [propName: string]: any
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  size = 'md',
  color = 'secondary',
  name = 'default-name',
  className,
  checked = false,
  onChange,
  ...other
}) => {
  return (
    <input
      type="checkbox"
      name={name}
      id={name}
      checked={checked}
      className={classnames(
        'block border-2 rounded-sm cursor-pointer bg-white  focus:outline-none ring-0',
        SIZE_MAPS[size],
        chbgColor(color),
        className
      )}
      onChange={onChange}
      {...other}
    />
  )
}
