import { PayloadAction } from '@reduxjs/toolkit'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { takeLatest, put, call, select, all } from 'redux-saga/effects'
import {
  dataCatalogService,
  queriesService,
  resourcesService,
} from 'utils/services'
import Queries from './storage'
import {
  addNewToTree,
  findTreeQueries,
  queriesActions,
  updateTree,
  updateTreeDnd,
  updateTreeMove,
} from './reducer'
import {
  CreateNewPayload,
  CreateQueryPayload,
  QueriesItemType,
  RunQueryPayload,
} from './types'
import { DatasetsItemType } from 'pages/Project/types'
import { checkChangedFields, transformArrToTree } from './utils'
import { group } from 'd3'
import {
  getQueryCollectionByIdState,
  getQueryCollectionsState,
} from './selectors'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { modalActions } from 'containers/Modal/reducer'
import { WorkspaceId } from 'containers/Sidebar/types'
import { getSelectedWorkspaceState } from 'containers/Sidebar/selectors'
import { sidebarActions } from 'containers/Sidebar/reducer'
import { resourcesActions } from 'pages/Resources/reducer'

function* handleFetchQueries({ payload }: PayloadAction<WorkspaceId>): any {
  try {
    const st_tabs = Queries.tabs
    const selectedTab = Queries.selectedTab
    yield put(sidebarActions.selectWorkspace(payload))
    const data = yield all([
      call(handleFetchQueryCollections),
      call(resourcesService.getResources, payload), // TODO move to Query tab
    ])
    if (st_tabs && st_tabs.length) {
      yield put(queriesActions.createTab(st_tabs))
      if (selectedTab !== null) {
        const tab = st_tabs.filter((st: any) => st.query_id === selectedTab)[0]
        yield put(queriesActions.updateSelectedParams(Queries.selectedParams))
        yield put(queriesActions.selectTab(tab))
      } else {
        yield put(queriesActions.selectTab(st_tabs[0]))
      }
    }
    yield put(queriesActions.stopLoading())
    // else {
    //   const arr = data.length ? data[0] : []
    //   Queries.addTab(data.length ? { query_id: data[0].query_id } : [])
    //   yield put(queriesActions.createTab(arr))
    //   yield put(queriesActions.selectTab(data.length ? data[0] : null))
    // }
    yield put(resourcesActions.didFetchResources(data[1]))
  } catch (error) {
    yield put(queriesActions.didFetchQueriesFail())
    yield put(queriesActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleCreateQuery({
  payload,
}: PayloadAction<CreateQueryPayload>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(queriesActions.startLoadingQueriesTree())
    const data: QueriesItemType = yield call(queriesService.createQuery, {
      workspace_id,
      data: {
        resource_id: payload.resource_id,
        name: payload.name,
        description: payload.description,
        query_string: payload.query_string,
        lang: payload.lang,
        api_url: payload.api_url,
      },
    })
    const newQuery = {
      id: data.query_id,
      name: 'New Query',
      type: 'query',
      api_url: payload.api_url,
      temp: true,
      saved_params: [{ params: {}, body: {} }],
    }
    yield put(
      queriesActions.createNewItemQueryCollection({
        collection_id: payload.collection_id,
        nodeId: payload.nodeId,
        data: newQuery,
      })
    )
    yield put(queriesActions.updateSelectedParams(null))
    yield put(queriesActions.stopLoadingQueriesTree())
    yield put(
      notificationActions.showNotification({
        message: `New Query created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(queriesActions.stopLoadingQueriesTree())
    yield put(
      notificationActions.showNotification({
        message: `Query not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleFetchQuery({ payload }: PayloadAction<QueriesItemType>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const data: QueriesItemType = yield call(queriesService.getQuery, {
      workspace_id,
      query_id: payload.query_id,
    })
    yield put(queriesActions.didFetchQuery(data))
  } catch (error) {
    yield put(queriesActions.stopLoadingQuery())
  }
}

function* handleUpdateQuery({ payload }: PayloadAction<any>) {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(queriesActions.startLoadingButtons('save'))
    const storageFields = checkChangedFields(payload.query_id)
    if (
      Object.keys(storageFields).length === 1 &&
      Object.keys(storageFields).includes('saved_params')
    ) {
    } else {
      yield call(queriesService.updateQuery, { ...payload, workspace_id }) // TODO payload with api_path(delete)
    }
    if (
      'name' in payload.data ||
      'api_type' in payload.data ||
      'api_path' in payload.data ||
      'saved_params' in payload.data
    ) {
      yield put(queriesActions.updateQueryCollection(payload))
    }
    yield put(queriesActions.stopLoadingButtons())
    yield put(
      notificationActions.showNotification({
        message: `${payload.data.name} updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
    Queries.cleanTab(payload.query_id, payload.data)
    if (payload.params_id)
      yield put(queriesActions.updateSelectedParams(payload.params_id))
    yield put(queriesActions.saveTab(payload))
  } catch (error) {
    yield put(queriesActions.stopLoadingButtons())
    yield put(
      notificationActions.showNotification({
        message: `${payload.data.name} not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteQuery({ payload }: PayloadAction<QueriesItemType>): any {
  try {
    const queriesIds: string[] = []
    const foldersIds: string[] = []
    const collectionId: string = payload.collection_id || Queries.collectionId
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(modalActions.startLoading('delete'))
    const collection: any = yield select(
      getQueryCollectionByIdState(collectionId)
    )
    const copyCollection = cloneDeep(collection)
    findTreeQueries(copyCollection, payload.query_id, queriesIds, foldersIds)
    if (queriesIds.length)
      yield call(queriesService.deleteMultipleQueries, {
        workspace_id,
        data: { ids: queriesIds },
      })
    if (copyCollection.query_collection_id !== payload.query_id) {
      yield call(queriesService.updateQueryCollection, {
        workspace_id,
        collection_id: collectionId,
        data: copyCollection,
      })
      yield put(
        queriesActions.didUpdateQueryCollection({
          collection_id: collectionId,
          data: copyCollection,
        })
      )
    } else {
      yield put(queriesActions.deleteQueryCollection(collectionId))
    }
    if ([...queriesIds, ...foldersIds].length)
      yield put(
        queriesActions.updateTabsDeleted([...queriesIds, ...foldersIds])
      )
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
    yield put(queriesActions.closeQuery(payload))
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleRunQuery({ payload }: PayloadAction<RunQueryPayload>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(queriesActions.startLoadingButtons('run'))
    const data = yield call(queriesService.runQuery, {
      data: payload,
      workspace_id,
    })
    Queries.updateTab(payload.query_id, { data })
    yield put(
      queriesActions.updateTab({
        query_id: payload.query_id,
        data: { data },
      })
    )
    yield put(queriesActions.stopLoadingButtons())
  } catch (error) {
    yield put(
      queriesActions.updateTab({
        query_id: payload.query_id,
        data: { data: [] },
      })
    )
    yield put(queriesActions.stopLoadingButtons())
  }
}

function* handleRunApi({ payload }: PayloadAction<any>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(queriesActions.startLoadingButtons('run'))
    const data = yield call(queriesService.runApi, {
      workspace_id,
      data: payload,
    })
    Queries.updateTab(payload.query_id, { data })
    yield put(
      queriesActions.updateTab({
        query_id: payload.query_id,
        data: { data },
      })
    )
    yield put(queriesActions.stopLoadingButtons())
  } catch (error) {
    yield put(
      queriesActions.updateTab({
        query_id: payload.query_id,
        data: { data: [] },
      })
    )
    yield put(queriesActions.stopLoadingButtons())
  }
}

function* handleFetchQueryCollections() {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(queriesActions.startLoading())
    const data: any[] = yield call(
      queriesService.getQueryCollections,
      workspace_id
    )
    yield put(queriesActions.didFetchQueryCollections(data))
    yield put(queriesActions.stopLoading())
  } catch (error) {
    yield put(queriesActions.stopLoading())
  }
}

function* handleCreateQueryCollection(): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(queriesActions.startLoadingQueriesTree())
    const data: any = yield call(queriesService.createQueryCollection, {
      workspace_id,
      data: {
        name: 'New Collection',
      },
    })
    const newObj = {
      query_id: data.query_collection_id,
      name: data.name,
      type: 'collection',
      temp: true,
    }
    yield put(queriesActions.didCreateQueryCollection(data))
    yield put(queriesActions.selectQuery(newObj))
    yield put(queriesActions.stopLoadingQueriesTree())
    yield put(
      notificationActions.showNotification({
        message: `New Collection created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(queriesActions.stopLoadingQueriesTree())
    yield put(
      notificationActions.showNotification({
        message: `Collection not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleCreateFolder({
  payload,
}: PayloadAction<CreateNewPayload>): any {
  try {
    yield put(queriesActions.startLoadingQueriesTree())
    const newFolder = {
      id: uuidv4(),
      name: 'New Folder',
      type: 'folder',
      temp: true,
    }
    yield put(
      queriesActions.createNewItemQueryCollection({
        collection_id: payload.collection_id,
        nodeId: payload.nodeId,
        data: newFolder,
      })
    )
    yield put(queriesActions.stopLoadingQueriesTree())
    yield put(
      notificationActions.showNotification({
        message: `New Folder created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(queriesActions.stopLoadingQueriesTree())
    yield put(
      notificationActions.showNotification({
        message: `Fodler not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleCreateNewItemQueryCollection({
  payload,
}: PayloadAction<any>): any {
  const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
  try {
    const collection: any = yield select(
      getQueryCollectionByIdState(payload.collection_id)
    )
    const copyCollection = cloneDeep(collection)
    addNewToTree(copyCollection, payload.nodeId, payload.data)
    yield call(queriesService.updateQueryCollection, {
      workspace_id,
      collection_id: payload.collection_id,
      data: copyCollection,
    })
    yield put(
      queriesActions.didUpdateQueryCollection({
        data: copyCollection,
        collection_id: payload.collection_id,
      })
    )
    const newObj = {
      query_id: payload.data.id,
      name: payload.data.name,
      type: payload.data.type,
      temp: payload.data.temp || false,
    }
    yield put(queriesActions.selectQuery(newObj))
  } catch (error) {
    // TODO if getQueryCollections !OK
    try {
      const data: any[] = yield call(
        queriesService.getQueryCollections,
        workspace_id
      )
      yield put(queriesActions.didFetchQueryCollections(data))
    } catch (e) {
      yield put(queriesActions.stopLoadingQueriesTree())
    }
    yield put(queriesActions.stopLoadingQueriesTree())
  }
}

function* handleUpdateQueryCollection({ payload }: PayloadAction<any>): any {
  const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
  try {
    const queriesList: any[] = []
    yield put(queriesActions.startLoadingButtons('save'))
    const collection: any = yield select(
      getQueryCollectionByIdState(Queries.collectionId)
    )
    const copyCollection = cloneDeep(collection)
    if ('api_path' in payload.data) {
      updateTree(
        copyCollection,
        payload.query_id,
        payload.data,
        queriesList,
        ''
      )
    } else {
      updateTree(copyCollection, payload.query_id, payload.data)
    }

    yield all(
      [
        queriesList.length > 1 // TODO revise all function
          ? call(queriesService.updateQueries, {
              workspace_id,
              data: queriesList,
            })
          : null,
        call(queriesService.updateQueryCollection, {
          workspace_id,
          collection_id: Queries.collectionId,
          data: copyCollection,
        }),
      ].filter(Boolean)
    )
    yield put(queriesActions.didUpdateQueryCollection({ data: copyCollection }))
    Queries.cleanTab(payload.query_id, payload.data)
    yield put(queriesActions.saveTab(payload))
    yield put(queriesActions.stopLoadingButtons())
  } catch (error) {
    // TODO if getQueryCollections !OK
    try {
      const data: any[] = yield call(
        queriesService.getQueryCollections,
        workspace_id
      )
      yield put(queriesActions.didFetchQueryCollections(data))
    } catch (e) {
      yield put(queriesActions.stopLoadingButtons())
    }
    yield put(queriesActions.stopLoadingButtons())
  }
}

function* handleDeleteQueryCollection({ payload }: PayloadAction<string>) {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield call(queriesService.deleteQueryCollection, {
      workspace_id,
      collection_id: payload,
    })
    yield put(queriesActions.didDeleteQueryCollection(payload))
  } catch (error) {
    yield put(queriesActions.stopLoading())
  }
}

// TODO similar with handleUpdateQueryCollection
function* handleSaveChangesTabAfterClose({ payload }: PayloadAction<any>): any {
  try {
    const queriesList: any[] = []
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(modalActions.startLoading('save'))
    if (payload.tab.type === 'query')
      yield call(queriesService.updateQuery, {
        workspace_id,
        query_id: payload.tab.query_id,
        data: payload.data,
      })
    if (
      'name' in payload.data ||
      'api_type' in payload.data ||
      'api_path' in payload.data ||
      'saved_params' in payload.data
    ) {
      const collection: any = yield select(
        getQueryCollectionByIdState(Queries.collectionId)
      )
      const copyCollection = cloneDeep(collection)
      if ('api_path' in payload.data) {
        updateTree(
          copyCollection,
          payload.tab.query_id,
          payload.data,
          queriesList,
          ''
        )
      } else {
        updateTree(copyCollection, payload.query_id, payload.data)
      }
      yield all(
        [
          queriesList.length
            ? call(queriesService.updateQueries, {
                workspace_id,
                data: queriesList,
              })
            : null,
          call(queriesService.updateQueryCollection, {
            workspace_id,
            collection_id: Queries.collectionId,
            data: copyCollection,
          }),
        ].filter(Boolean)
      )
      yield put(
        queriesActions.didUpdateQueryCollection({ data: copyCollection })
      )
    }
    yield put(queriesActions.updateSelectedParams(null))
    yield put(queriesActions.closeQuery(payload.tab))
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `Сhanges have been saved successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Сhanges have not been saved!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateSavedParams({ payload }: PayloadAction<any>): any {
  try {
    yield put(queriesActions.startLoadingButtons('save_params'))
    yield call(handleUpdateQueryCollectionTree, payload)
    const storageFields = checkChangedFields(payload.query_id)
    if (Object.keys(storageFields).length === 1) {
      Queries.cleanTab(payload.query_id, payload.data)
      yield put(queriesActions.saveTab(payload))
    } else {
      Queries.cleanTabByName(payload.query_id, 'saved_params')
      yield put(queriesActions.updateTab(payload))
    }
    if (payload.params_id)
      yield put(queriesActions.updateSelectedParams(payload.params_id))
    yield put(queriesActions.stopLoadingButtons())
  } catch (error) {
    yield put(queriesActions.stopLoadingButtons())
  }
}

function* handleAddNewSavedParams({ payload }: PayloadAction<any>): any {
  try {
    yield put(queriesActions.startLoadingButtons('add_new_params'))
    yield call(handleUpdateQueryCollectionTree, {
      query_id: payload.query_id,
      data: {
        saved_params: [...payload.savedParams, payload.newParams],
      },
    })
    // Add first element
    if (!payload.savedParams.length) {
      const storageFields = checkChangedFields(payload.query_id)
      const _payload = {
        query_id: payload.query_id,
        data: {
          saved_params: [payload.newParams],
        },
      }
      if (Object.keys(storageFields).length === 1) {
        Queries.cleanTab(payload.query_id, _payload)
        yield put(queriesActions.saveTab(_payload))
      } else {
        Queries.cleanTabByName(payload.query_id, 'saved_params')
        yield put(queriesActions.updateTab(_payload))
      }
    } else {
      yield put(
        queriesActions.updateTabs({
          query_id: payload.query_id,
          data: {
            saved_params: [...payload.savedParams, payload.newParams],
          },
        })
      )
      yield put(
        queriesActions.updateSelectedTab({
          data: {
            saved_params: [...payload.changedSavedParams, payload.newParams],
          },
        })
      )
      Queries.updateTab(payload.query_id, {
        ...{ saved_params: [...payload.changedSavedParams, payload.newParams] },
        // temp: false,
      })
    }
    if (payload.params_id)
      yield put(queriesActions.updateSelectedParams(payload.params_id))
    yield put(queriesActions.stopLoadingButtons())
  } catch (error) {
    yield put(queriesActions.stopLoadingButtons())
  }
}

function* handleDuplicateSavedParams({ payload }: PayloadAction<any>): any {
  try {
    yield put(queriesActions.startLoadingButtons('add_new_params'))
    yield call(handleUpdateQueryCollectionTree, {
      query_id: payload.query_id,
      data: {
        saved_params: [...payload.savedParams, payload.newParams],
      },
    })
    yield put(
      queriesActions.updateTabs({
        query_id: payload.query_id,
        data: {
          saved_params: [...payload.savedParams, payload.newParams],
        },
      })
    )
    yield put(
      queriesActions.updateSelectedTab({
        data: {
          saved_params: [...payload.changedSavedParams, payload.newParams],
        },
      })
    )
    Queries.updateTab(payload.query_id, {
      ...{ saved_params: [...payload.changedSavedParams, payload.newParams] },
      // temp: false,
    })
    if (payload.params_id)
      yield put(queriesActions.updateSelectedParams(payload.params_id))
    yield put(queriesActions.stopLoadingButtons())
  } catch (error) {
    yield put(queriesActions.stopLoadingButtons())
  }
}

function* handleDeleteSavedParams({ payload }: PayloadAction<any>): any {
  try {
    yield put(modalActions.startLoading('delete'))
    yield call(handleUpdateQueryCollectionTree, payload)
    yield put(queriesActions.updateTab(payload))
    if (payload.params_id === null) {
      Queries.cleanTabByName(payload.query_id, 'saved_params')
    } else {
      Queries.updateTab(payload.query_id, {
        ...payload.data,
        // temp: false,
      })
    }
    if (Object.keys(payload).includes('params_id')) {
      yield put(queriesActions.updateSelectedParams(payload.params_id))
    }
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
  } catch (error) {
    yield put(modalActions.stopLoading())
  }
}

function* handleUpdateQueryCollectionTree(payload: any): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const collection: any = yield select(
      getQueryCollectionByIdState(Queries.collectionId)
    )
    const copyCollection = cloneDeep(collection)
    updateTree(copyCollection, payload.query_id, payload.data)
    yield call(queriesService.updateQueryCollection, {
      workspace_id,
      collection_id: Queries.collectionId,
      data: copyCollection,
    })
    yield put(queriesActions.didUpdateQueryCollection({ data: copyCollection }))
  } catch (error) {}
}

function* handleUpdateQueryCollectionTreeDnd({
  payload,
}: PayloadAction<any>): any {
  try {
    if (payload.indexes.length) {
      const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
      const collections: any = yield select(getQueryCollectionsState)
      const copyCollection = cloneDeep(collections[payload.indexes[0]])
      updateTreeDnd(copyCollection, payload.indexes.slice(1), payload.data)
      yield call(queriesService.updateQueryCollection, {
        workspace_id,
        collection_id: copyCollection.query_collection_id,
        data: copyCollection,
      })
      yield put(
        queriesActions.didUpdateQueryCollection({ data: copyCollection })
      )
    } else {
      // collection drag
      // TODO add update collections PUT request
      // yield put(queriesActions.didFetchQueryCollections(data))
    }
  } catch (error) {}
}

function* handleUpdateQueryCollectionTreeMove({
  payload,
}: PayloadAction<any>): any {
  try {
    yield put(modalActions.startLoading('move'))
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const collections: any = yield select(getQueryCollectionsState)
    const copyCollection = cloneDeep(collections[payload.old[0]])
    updateTreeMove(copyCollection, payload.old.slice(1), 'delete', payload.node)
    updateTreeMove(copyCollection, payload.new.slice(1), 'add', {
      ...payload.node,
      api_url: payload.apiPath,
    })
    yield call(queriesService.updateQueryCollection, {
      workspace_id,
      collection_id: copyCollection.query_collection_id,
      data: copyCollection,
    })
    yield put(queriesActions.didUpdateQueryCollection({ data: copyCollection }))
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not moved!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

// RIGHT PANEL

function* handleFetchDatasetsTreeData() {
  try {
    const data: DatasetsItemType[] = yield call(dataCatalogService.getDatasets)
    yield put(
      queriesActions.didFetchDatasetsTreeData(
        transformArrToTree(
          group(
            data,
            (d) => d.project_id,
            (d) => d.dataset_id
            // (d) => d.name
          ),
          0
        )
      )
    )
  } catch (error) {
    yield put(queriesActions.stopLoading())
  }
}

export default function* watchQueriesSaga() {
  yield takeLatest(queriesActions.fetchQueries.type, handleFetchQueries)
  yield takeLatest(queriesActions.createQuery.type, handleCreateQuery)
  yield takeLatest(queriesActions.fetchQuery.type, handleFetchQuery)
  yield takeLatest(queriesActions.updateQuery.type, handleUpdateQuery)
  yield takeLatest(queriesActions.deleteQuery.type, handleDeleteQuery)
  yield takeLatest(queriesActions.runQuery.type, handleRunQuery)
  yield takeLatest(queriesActions.runApi.type, handleRunApi)
  yield takeLatest(
    queriesActions.fetchDatasetsTreeData.type,
    handleFetchDatasetsTreeData
  )
  yield takeLatest(
    queriesActions.fetchQueryCollections.type,
    handleFetchQueryCollections
  )
  yield takeLatest(
    queriesActions.createQueryCollection.type,
    handleCreateQueryCollection
  )
  yield takeLatest(queriesActions.createFolder.type, handleCreateFolder)
  yield takeLatest(
    queriesActions.createNewItemQueryCollection.type,
    handleCreateNewItemQueryCollection
  )
  yield takeLatest(
    queriesActions.updateQueryCollection.type,
    handleUpdateQueryCollection
  )
  yield takeLatest(
    queriesActions.deleteQueryCollection.type,
    handleDeleteQueryCollection
  )
  yield takeLatest(
    queriesActions.updateQueryCollectionTreeDnd.type,
    handleUpdateQueryCollectionTreeDnd
  )
  yield takeLatest(
    queriesActions.updateQueryCollectionTreeMove.type,
    handleUpdateQueryCollectionTreeMove
  )
  yield takeLatest(
    queriesActions.saveChangesTabAfterClose.type,
    handleSaveChangesTabAfterClose
  )
  yield takeLatest(
    queriesActions.updateSavedParams.type,
    handleUpdateSavedParams
  )
  yield takeLatest(
    queriesActions.addNewSavedParams.type,
    handleAddNewSavedParams
  )
  yield takeLatest(
    queriesActions.duplicateSavedParams.type,
    handleDuplicateSavedParams
  )
  yield takeLatest(
    queriesActions.deleteSavedParams.type,
    handleDeleteSavedParams
  )
}
