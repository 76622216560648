/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react'
import PRootContainer from 'containers/Page/PRootContainer'
import { HeaderContainer } from './containers/HeaderContainer'

export const Home: FC = () => {
  return (
    <PRootContainer
      data-label="project-container"
      className="flex flex-col h-full px-20 py-5"
    >
      <HeaderContainer />
      {/* <MainContainer /> */}
    </PRootContainer>
  )
}
