import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

// import { MenuIcon } from 'assets/general'

// import { tabsConfig } from './config'
import { Routes } from 'utils/router'
import { LogoIcon } from 'assets/logos'
import IconContainer from 'containers/IconContainer'
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSelectedWorkspaceState, getWorkspacesState } from './selectors'
import { sidebarActions } from './reducer'
import { connect, ConnectedProps } from 'react-redux'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'
import { Avatar } from 'shared'

type PropsFromRedux = ConnectedProps<typeof connector>

const Sidebar: FC<any & PropsFromRedux> = ({
  workspaces,
  selectedWorkspace,
  fetchWorkspaces,
  selectWorkspace,
  deleteWorkspace,
  showModal,
}) => {
  // const tabs = useMemo(() => tabsConfig.filter((tab) => tab.enabled), [])

  useEffect(() => {
    fetchWorkspaces()
  }, [])

  return (
    <div
      data-label="sidebar-container"
      className="sidebar bg-black-light text-blue-100 pt-2 px-1 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out"
    >
      <Link to={Routes.url.Root()} className="w-max flex m-auto pt-3">
        <LogoIcon size={null} width={45} />
      </Link>

      <nav data-label="sidebar-nav" className="mt-h10 w-16">
        {workspaces.map((w: any) => (
          <div key={w.workspace_id} className={cn('block mt-5 first:mt-0')}>
            <div className="flex flex-col justify-center items-center">
              <Link
                to={Routes.url.Workspace(w.workspace_id)}
                // title={w.name}
                // className={cn(
                //   'relative group p-2.5 rounded-full transition duration-200',
                //   selectedWorkspace === w.workspace_id
                //     ? 'bg-white-dark text-primary-500/75 hover:text-primary-500/100'
                //     : 'text-white-dark bg-opacity-75 hover:bg-opacity-100 bg-black-lighter'
                // )}
                className={cn(
                  'relative group p-1 rounded-full transition duration-200',
                  selectedWorkspace === w.workspace_id
                    ? 'outline outline-4 outline-offset-2 outline-white text-primary-500/75 hover:text-primary-500/100'
                    : 'text-white-dark bg-opacity-75 hover:bg-opacity-100 bg-black-lighter hover:outline hover:outline-4 hover:outline-offset-2 hover:outline-default-500'
                )}
                onClick={() => selectWorkspace(w.workspace_id)}
              >
                <PencilIcon
                  className={cn(
                    'absolute z-10 -top-2 -left-2 w-5 h-5 rounded-full p-1 text-secondary/75 opacity-0 group-hover:opacity-100 hover:text-secondary/100',
                    selectedWorkspace === w.workspace_id
                      ? 'bg-white-dark'
                      : 'bg-black-lighter'
                  )}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    showModal({
                      type: ModalTypes.CreateWorkspace,
                      config: {
                        ...ModalOptions.CreateWorkspace,
                        headerName: 'Update workspace',
                      },
                      params: {
                        workspace: w,
                      },
                    })
                  }}
                />
                {/* <TrashIcon
                  className={cn(
                    'absolute z-10 -bottom-2 -left-2 w-5 h-5 rounded-full p-1 text-secondary/75 opacity-0 group-hover:opacity-100 hover:text-secondary/100',
                    selectedWorkspace === w.workspace_id
                      ? 'bg-white-dark'
                      : 'bg-black-lighter'
                  )}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    deleteWorkspace(w.workspace_id)
                  }}
                /> */}
                
                {/* <LogoIcon size={6} width={null} /> */}
                <Avatar
                  src={w.icon_url}
                  size="2xl"
                  // className="rounded-tl-none rounded-full mr-sm"
                />
                
                {/* <workspace.IconComponent size="6" color={null} /> */}
              </Link>
              {/* <Typography
                className={classnames(
                  'mt-1.5 group-hover:opacity-100 duration-200',
                  selectedWorkspace === w.workspace_id
                    ? 'opacity-100'
                    : 'opacity-75'
                )}
                color="white-dark"
              >
                {w.name}
              </Typography> */}
            </div>
          </div>
        ))}
        <div className="block mt-5 first:mt-0">
          <div className="flex flex-col justify-center items-center">
            <IconContainer
              className={cn(
                'p-2.5 rounded-full cursor-pointer transition duration-200',
                'text-white-dark opacity-75 hover:opacity-100 bg-black-lighter'
              )}
              onClick={() =>
                showModal({
                  type: ModalTypes.CreateWorkspace,
                  config: {
                    ...ModalOptions.CreateWorkspace,
                  },
                })
              }
            >
              <PlusIcon className="w-6 h-6 text-secondary" />
            </IconContainer>
          </div>
        </div>
        {/* {tabs.map((tab) => (
            <Link
              to={tab.path}
              key={tab.path}
              className={classnames('block mt-5 first:mt-0')}
            >
              <div className="group flex flex-col justify-center items-center">
                <IconContainer
                  className={classnames(
                    'p-2.5 rounded-full transition duration-200',
                    pathname.includes(tab.path)
                      ? 'bg-white-dark text-primary-500'
                      : 'text-white-dark opacity-75 group-hover:opacity-100 bg-black-lighter'
                  )}
                >
                  <tab.IconComponent size="6" color={null} />
                </IconContainer>
                <Typography
                  className={classnames(
                    'mt-1.5 group-hover:opacity-100 duration-200',
                    pathname.includes(tab.path) ? 'opacity-100' : 'opacity-75'
                  )}
                  color="white-dark"
                >
                  {tab.label}
                </Typography>
              </div>
            </Link>
          ))} */}
      </nav>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  workspaces: getWorkspacesState,
  selectedWorkspace: getSelectedWorkspaceState,
})

const mapDispatchToProps = {
  fetchWorkspaces: sidebarActions.fetchWorkspaces,
  selectWorkspace: sidebarActions.selectWorkspace,
  deleteWorkspace: sidebarActions.deleteWorkspace,
  showModal: modalActions.showModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Sidebar)

// TODO add mobile version

// <div className="relative min-h-screen md:flex">
//{
/* <!-- mobile menu bar --> */
//}
// <div className="bg-gray-800 text-gray-100 flex justify-between md:hidden">
//   <Link
//     to={Routes.Root()}
//     className="w-max text-white flex items-center space-x-2 px-4"
//   >
//     <LogoIcon />
//   </Link>

//{
/* <!-- mobile menu button --> */
//}
//     <button className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700">
//       <MenuIcon />
//     </button>
//   </div>
// </div>
