import { FunctionComponent, useMemo } from 'react'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getActiveStepState } from './selectors'
import { sliderActions } from './reducer'
import { connect } from 'react-redux'
import Stepper from './stepper'
import DBSelection from './stepper/DBSelection'
import ManageDatasetInfo from './ManageDatasetInfo'
import DBPrepare from './stepper/DBPrepare'
import DBSchema from './stepper/DBSchema'
import { ConnectionsTypeTypeT } from 'pages/Connections/types'
import { getConnectionTypeState } from 'pages/Connections/selectors'

interface StateProps {
  activeStep: any
  connectionType: ConnectionsTypeTypeT
}

type DispatchProps = typeof mapDispatchToProps

const CreateDatasetStepper: FunctionComponent<StateProps & DispatchProps> = ({
  activeStep,
  connectionType,
  setActiveStep,
}) => {
  const steps = useMemo(() => {
    return [
      {
        id: 'first',
        header: 'First Step',
        content: () => <DBSelection />,
      },
      connectionType === 'blank'
        ? null
        : {
            id: 'second',
            header: 'Second Step',
            content: () => <DBPrepare />,
          },
      {
        id: 'third',
        header: 'Third Step',
        content: () => <DBSchema />,
      },
      {
        id: 'finish',
        header: 'Finish Step',
        content: (prevStep: () => void) => (
          <ManageDatasetInfo prevStep={prevStep} />
        ),
        buttons: null,
      },
    ].filter(Boolean)
  }, [connectionType])

  return (
    <>
      <div className="flex-1 m-t20 p-t20 shadow overflow-y-auto">
        <Stepper steps={steps} active={activeStep} setActive={setActiveStep} />
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  activeStep: getActiveStepState,
  connectionType: getConnectionTypeState,
})

const mapDispatchToProps = {
  setActiveStep: sliderActions.setActiveStep,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDatasetStepper)
