import 'whatwg-fetch'

import _ from 'app/auth/constants'
import User from 'app/auth/User'

export const makeRequest = async ({
  path,
  method = 'GET',
  params = {},
  headers,
  contentType = 'application/json',
  formData,
  // success,
  // error,
  // successMessage,
  // errorMessage,
}) => {
  const options = {
    method: method,
    body: params,
    headers: {
      ...headers,
      'Content-Type': contentType,
      'Cache-Control': 'no-cache, must-revalidate',
    },
    mode: 'cors',
    timeout: 5,
  }
  const isParams = Object.keys(params).length
  if (method === 'GET' || (!isParams && method === 'DELETE'))
    delete options.body
  if (method === 'GET' || (!isParams && method === 'DELETE'))
    delete options.headers['Content-Type']
  if (formData) delete options.headers['Content-Type']

  if (!headers) {
    const token = User.isAuth()
    if (token) options.headers['Authorization'] = `Bearer ${token}`
  }

  let response = await fetch(
    path.indexOf('http') === 0 ? path : _.SERVER_API + path,
    options
  )
    .then((response) => {
      if (response.status === 500) {
        return Promise.reject({
          status: 500,
          message: 'Something went wrong. Contact us for more information.',
        })
      }

      if (!response.ok) {
        // throw Error(response.statusText);
        return Promise.reject({ status: response.status })
        // return response.json().then((res) => {
        //   console.log('response !ok', res)
        //   // return Promise.reject({ ...res, status: response.status })
        // })
        // return response.json().then((res) => {
        //   console.log('response !ok', res)
        //   // throw { ...res, status: response.status }
        // })
      }

      // check if content type non json object => return plain text
      if (
        response.headers.get('content-type') &&
        response.headers.get('content-type').indexOf('application/json') === -1
      ) {
        return response.text()
      }

      return response.json()
    })
    .then((data) => {
      // if (success) return success(data, successMessage)
      // if (successMessage) return { data, success: successMessage }
      return data
    })
    .catch((error) => {
      if (error && error.status === 500) {
        throw new Error(
          'Something went wrong. Contact us for more information.'
        )
      }
      if (error && error.type === 'Unauthorized') return

      if (error && error.status === 404) {
        throw error
      }

      if (error && error.status === 403) {
        if (!error.message) {
          error.message = 'Sorry, you have insufficient permissions'
        }
        throw error
      }

      // throw new Error(
      //   error.message ||
      //     (error.error && error.error.message) ||
      //     'Server error occurred'
      // )

      // if (err && err.type === 'Unauthorized') return
      // if (error)
      //   error(
      //     err,
      //     err.status && err.status === 500 ? err.message : errorMessage
      //   )
      // return {
      //   error: err.status && err.status === 500 ? err.message : errorMessage,
      // }
    })

  return response
}
