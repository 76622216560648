import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { FC } from 'react'

interface ISlideOverHeaderProps {
  title: string
  subtitle: string
  close: () => void
}

export const SlideOverHeader: FC<ISlideOverHeaderProps> = ({
  title = 'Unknown title',
  subtitle = 'Unknown subtitle',
  close = () => {},
}) => {
  return (
    <div className="bg-gray-100 px-4 py-6 sm:px-6">
      <div className="flex items-start justify-between space-x-3">
        <div className="space-y-1">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            {title}
          </Dialog.Title>
          <p className="text-sm text-gray-500">{subtitle}</p>
        </div>
        <div className="flex h-7 items-center">
          <button
            type="button"
            className="text-gray-400 hover:text-gray-500"
            onClick={close}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  )
}
