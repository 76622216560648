import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Typography } from 'shared'
import { QueriesItemType } from '../types'
import { queriesActions } from 'pages/Queries/reducer'
import cn from 'classnames'

interface ApiInputContainerProps {
  selectedTab: QueriesItemType
  api?: string
  handleChange?: (value: string) => void
  className?: string
}

type DispatchProps = typeof mapDispatchToProps

const ApiInputContainer: FunctionComponent<
  ApiInputContainerProps & DispatchProps
> = React.memo(
  ({
    selectedTab,
    api,
    checkChangedTab,
    handleChange,
    // className,
  }) => {
    const [api_path, setApiPath] = useState<string>(
      api || selectedTab.api_path || ''
    )

    useEffect(
      () => setApiPath(api || selectedTab.api_path || ''),
      [api, selectedTab.api_path]
    )

    return (
      <div
        className={`flex items-center bg-default-100 rounded cursor-default w-full h-H pl-2.5`}
      >
        <Typography size="sm">{selectedTab.path_api || null}</Typography>
        <Input
          width="full"
          type="text"
          name="api_path"
          value={api_path}
          className={cn(
            '!z-10 px-0 py-0 bg-default-100 !border-transparent focus:px-2 text-sm',
            {
              '!px-2': !api_path,
            }
          )}
          placeholder="Enter api PATH"
          onChange={(e: any) => {
            if (handleChange) {
              handleChange(e.target.value)
            } else {
              setApiPath(e.target.value)
              checkChangedTab({
                query_id: selectedTab.query_id,
                data: { name: 'api_path', value: e.target.value },
              })
            }
          }}
        />
      </div>
    )
  },
  (prev, next) =>
    JSON.parse(JSON.stringify(prev.selectedTab)) ===
    JSON.parse(JSON.stringify(next.selectedTab))
)

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
}

export default connect(null, mapDispatchToProps)(ApiInputContainer)
