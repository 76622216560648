/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/general'
import classnames from 'classnames'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import { Button, SwitchCase, Typography } from 'shared'
import { defineNextStep, definePrevStep } from '../utils'

interface StepperButtonsProps {
  children: ReactNode
  className?: string
}

export const StepperButtons: FunctionComponent<StepperButtonsProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classnames(
        'flex flex-row justify-between items-center mt-t20',
        className
      )}
    >
      {children}
    </div>
  )
}

interface StepperProps {
  steps: any[]
  active: any
  setActive: (step: any) => void
}

const Stepper: FunctionComponent<StepperProps> = ({
  steps = [],
  active,
  setActive,
}) => {
  const stepsOrder: string[] = useMemo(() => steps.map((d) => d.id), [steps])

  const prevStep = () => {
    const stepOrder = definePrevStep(stepsOrder, active)
    setActive(stepsOrder[stepOrder])
  }

  const nextStep = () => {
    const stepOrder = defineNextStep(stepsOrder, active)
    setActive(stepsOrder[stepOrder])
  }

  const cases = useMemo(() => {
    const obj: any = {}
    steps.forEach((step, i) => {
      const isLast = i === stepsOrder.length - 1
      obj[step.id] = (
        <div className="flex flex-col h-full">
          <Typography size="xl" weight="bold">
            {step.header}
          </Typography>
          {step.content(prevStep, nextStep)}
          {step.buttons === null ? null : (
            <StepperButtons>
              <Button onClick={prevStep} color="default-500" variant="OUTLINED">
                <ChevronLeftIcon color="secondary" />
                <Typography>Back</Typography>
              </Button>

              <Button className="flex flex-row items-center" onClick={nextStep}>
                <Typography>{isLast ? 'Finish' : 'Next'}</Typography>
                {!isLast ? <ChevronRightIcon color="white" /> : null}
              </Button>
            </StepperButtons>
          )}
        </div>
      )
    })
    return obj
  }, [steps, active])

  return (
    <div className="h-full">
      <SwitchCase value={active}>{cases}</SwitchCase>
    </div>
  )
}

export default Stepper
