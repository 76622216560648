import { FC, FunctionComponent, useMemo, useRef } from 'react'
import {
  Typography,
  TreeView,
  IconButton,
  MenuItemsType,
  MenuNew,
  MONACO_LANGUAGES_TYPES,
} from 'shared'
import { MenuVerticalDotIcon } from 'assets/general'
import cn from 'classnames'
import { cloneDeep, isArray } from 'lodash'
import { ToggleIcon } from './right-panel/FieldsTab'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { QueriesItemType } from '../types'
import { getQueryCollectionsState, getSelectedTabState } from '../selectors'
import { findToggledNodes, queriesActions } from '../reducer'
import Queries from '../storage'
import { apiMethodOptions } from './ApiSettings'
import NodeCasesContainer from './NodeCasesContainer'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'
import { getResourcesState } from 'pages/Resources/selectors'
import { IResources } from 'pages/Resources/types'

const nodeCasesTypes: any = {
  0: 'collection',
  1: 'folder',
  2: 'query',
}

interface ApiTypeStringProps {
  type: string
  className?: string
}

const ApiTypeString: FC<ApiTypeStringProps> = ({
  type = '',
  className = '',
}) => (
  <Typography
    className={cn('!mr-1.5 !text-xxs', className)}
    weight="medium"
    colorNew={`${
      apiMethodOptions.find((m) => m.name === type)?.color || 'text-default-500'
    }`}
  >
    {type.length > 4 ? type.substring(0, 3) : type}
  </Typography>
)

interface ApiStringProps {
  api: string
  className?: string
}

const ApiString: FC<ApiStringProps> = ({ api = '', className = '' }) => (
  <div className="flex">
    <Typography
      className={cn(
        'truncate',
        { 'px-1 rounded-sm bg-yellow-50 border !text-xxs': api },
        className
      )}
      colorNew="text-yellow-900 opacity-50"
    >
      {api}
    </Typography>
  </div>
)

interface MenuOptionProps {
  items: MenuItemsType[]
  className?: string
  isSelected?: boolean
}

const MenuOption: FC<MenuOptionProps> = ({
  items,
  className = '',
  isSelected,
}) => (
  <MenuNew
    className={cn('!absolute right-1.5 top-[5px]', className)}
    items={items.filter(Boolean)}
  >
    <IconButton
      className={cn(
        'opacity-0 group-hover:opacity-100 hover:bg-default-300',
        isSelected ? 'hover:bg-default-400' : 'hover:bg-default-300'
      )}
    >
      <MenuVerticalDotIcon size="4" />
    </IconButton>
  </MenuNew>
)

const commonClasses = (props?: any) => ({
  nodeCaseContainer: `relative flex group items-center cursor-pointer w-full hover:bg-default-100 py-1 ${
    props?.border || ''
  }`,
  selectedNode: 'bg-default-200 hover:!bg-default-200',
})

interface StateProps {
  selectedTab: QueriesItemType | null
  queryCollections: any[]
  resources: IResources[]
}

type DispatchProps = typeof mapDispatchToProps

const QueriesCatalogTreeContainer: FunctionComponent<
  StateProps & DispatchProps
> = ({
  selectedTab,
  queryCollections,
  resources,
  createQuery,
  selectQuery,
  deleteQuery,
  createFolder,
  updateTab,
  resetTempTab,
  updateTreeDnd,
  updateTreeMove,
  showModal,
}) => {
  const clickTimeout = useRef<any>(null)
  const resource: IResources | null = useMemo(
    () => (resources.length ? resources[0] : null),
    [resources]
  )

  const defaultToggledNodes = useMemo(() => {
    let toggledData: any[] = []
    if (selectedTab !== null) {
      cloneDeep(queryCollections).some((d: any) =>
        findToggledNodes(d, selectedTab?.query_id, toggledData)
      )
    }
    return toggledData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  const handleSelectQuery = (
    e: any,
    node: any,
    obj: any,
    onClick: (e: any) => void
  ) => {
    if (selectedTab?.query_id === node.id && selectedTab?.temp) {
      if (clickTimeout.current === null) {
        if (isArray(node.items)) onClick(e)
        clickTimeout.current = setTimeout(() => {
          selectQuery(obj)
          clickTimeout.current = null
        }, 200)
      } else {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
        resetTempTab(obj)
      }
    } else {
      if (isArray(node.items)) onClick(e)
      selectQuery(obj)
    }
  }

  if (!queryCollections.length) return null
  return (
    <TreeView
      hoverFull
      tree={queryCollections}
      selected={selectedTab}
      toggledNodes={defaultToggledNodes}
      updateTreeDnd={updateTreeDnd}
      disabledDrag={['collection']}
      nodeCases={({
        node,
        toggled,
        selectedNode,
        rootParentId,
        memoryFields,
        toNodeKey,
        onClick,
        onSelect,
      }: any) => {
        return {
          [nodeCasesTypes[0]]: (
            <NodeCasesContainer
              style={{ paddingLeft: `${16 * node.level}px` }}
              hoverColor="default-100"
              isHoverPath
              node={node}
              selectedNode={selectedNode}
              apiPath={memoryFields.apiPath}
              commonClasses={commonClasses()}
              saveRootParentId={() => {
                Queries.setCollectionId(rootParentId)
                updateTab({
                  query_id: node.id,
                  data: {
                    path_api: memoryFields.apiPath,
                    breadcrumbs: memoryFields.breadcrumbs,
                  },
                })
              }}
              onClick={(e: any) => {
                onSelect()
                handleSelectQuery(
                  e,
                  node,
                  {
                    query_id: node.id,
                    name: node.name,
                    type: node.type,
                    path_api: memoryFields.apiPath,
                    api_path: node?.api_path || '',
                  },
                  onClick
                )
              }}
            >
              <div className="flex px-2.5">
                <ToggleIcon node={node} toggled={toggled} />
                <div>
                  <Typography className="font-bold" color="default-500" size="xs">
                    {node.name}
                  </Typography>
                  <ApiString api={node.api_path || '/'} />
                </div>
              </div>
              <MenuOption
                isSelected={
                  selectedNode !== null && selectedNode.id === node.id
                }
                items={[
                  {
                    label: 'Add Query',
                    disabled: !resource,
                    onClick: (e: any) => {
                      e.stopPropagation()
                      createQuery({
                        resource_id: resource?.resource_id || '',
                        name: `New Query`,
                        description: 'New Query description',
                        query_string: 'SELECT * FROM table_name',
                        lang: MONACO_LANGUAGES_TYPES.SQL,
                        api_url: memoryFields.apiPath + (node?.api_path || ''),
                        collection_id: rootParentId,
                        nodeId: node.id,
                      })
                    },
                  },
                  {
                    label: 'Add Folder',
                    onClick: (e: any) => {
                      e.stopPropagation()
                      createFolder({
                        collection_id: rootParentId,
                        nodeId: node.id,
                      })
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: (e: any) => {
                      e.stopPropagation()
                      showModal({
                        type: ModalTypes.ConfirmationDelete,
                        config: {
                          ...ModalOptions.ConfirmationDelete,
                          headerName: 'Delete',
                        },
                        params: {
                          tab: {
                            ...node,
                            query_id: node.id,
                            collection_id: rootParentId,
                          },
                          handleDelete: deleteQuery,
                        },
                      })
                    },
                  },
                ]}
              />
            </NodeCasesContainer>
          ),
          [nodeCasesTypes[1]]: (
            <NodeCasesContainer
              style={{ paddingLeft: `${16 * node.level}px` }}
              hoverColor="default-100"
              isHoverPath
              node={node}
              selectedNode={selectedNode}
              apiPath={memoryFields.apiPath}
              commonClasses={commonClasses()}
              saveRootParentId={() => {
                Queries.setCollectionId(rootParentId)
                updateTab({
                  query_id: node.id,
                  data: {
                    path_api: memoryFields.apiPath,
                    breadcrumbs: memoryFields.breadcrumbs,
                  },
                })
              }}
              onClick={(e: any) => {
                onSelect()
                handleSelectQuery(
                  e,
                  node,
                  {
                    query_id: node.id,
                    name: node.name,
                    type: node.type,
                    path_api: memoryFields.apiPath,
                    api_path: node?.api_path || '',
                  },
                  onClick
                )
              }}
            >
              <div className="flex px-2.5">
                <ToggleIcon node={node} toggled={toggled} />
                {/* <FolderIcon className="mr-1.5" /> */}

                <div>
                  <Typography className="font-bold" color="default-500" size="xs">
                    {node.name}
                  </Typography>
                  <ApiString api={node.api_path || '/'} />
                </div>
              </div>
              <MenuOption
                isSelected={
                  selectedNode !== null && selectedNode.id === node.id
                }
                items={[
                  {
                    label: 'Add Query',
                    disabled: !resource,
                    onClick: (e: any) => {
                      e.stopPropagation()
                      createQuery({
                        resource_id: resource?.resource_id || '',
                        name: `New Query`,
                        description: 'New Query description',
                        query_string: 'SELECT * FROM table_name',
                        lang: MONACO_LANGUAGES_TYPES.SQL,
                        api_url: memoryFields.apiPath + (node?.api_path || ''),
                        collection_id: rootParentId,
                        nodeId: node.id,
                      })
                    },
                  },
                  {
                    label: 'Add Folder',
                    onClick: (e: any) => {
                      e.stopPropagation()
                      createFolder({
                        collection_id: rootParentId,
                        nodeId: node.id,
                      })
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: (e: any) => {
                      e.stopPropagation()
                      showModal({
                        type: ModalTypes.ConfirmationDelete,
                        config: {
                          ...ModalOptions.ConfirmationDelete,
                          headerName: 'Delete',
                        },
                        params: {
                          tab: {
                            ...node,
                            query_id: node.id,
                            collection_id: rootParentId,
                          },
                          handleDelete: deleteQuery,
                        },
                      })
                    },
                  },
                ]}
              />
            </NodeCasesContainer>
          ),
          [nodeCasesTypes[2]]: (
            <NodeCasesContainer
              style={{ paddingLeft: `${16 * node.level}px` }}
              hoverColor="default-100"
              isHoverPath
              node={node}
              selectedNode={selectedNode}
              apiPath={memoryFields.apiPath}
              commonClasses={commonClasses({
                border: `border-r-8 ${
                  apiMethodOptions.find((m) => m.name === node.api_type)
                    ?.border || 'border-green-200'
                }`,
              })}
              saveRootParentId={() => {
                Queries.setCollectionId(rootParentId)
                updateTab({
                  query_id: node.id,
                  data: {
                    path_api: memoryFields.apiPath,
                    breadcrumbs: memoryFields.breadcrumbs,
                  },
                })
              }}
              onClick={(e: any) => {
                onSelect()
                handleSelectQuery(
                  e,
                  node,
                  {
                    query_id: node.id,
                    name: node.name,
                    type: node.type,
                    path_api: memoryFields.apiPath,
                    api_path: node.api_path || '',
                    saved_params: node.saved_params,
                  },
                  onClick
                )
              }}
            >
              <div className="flex px-4">
                <div className="pl-2 border-l-4">
                  <Typography className="truncate font-bold" color="default-700" size="xs">
                    {node.name}
                  </Typography>
                  <div className="flex flex-row items-center">
                    <div className="flex w-9">
                      <ApiTypeString
                        type={node.api_type || 'GET'}
                        className="w-full text-left"
                      />
                    </div>
                    <ApiString api={node.api_path || '/'} />
                  </div>
                </div>
              </div>
              <MenuOption
                isSelected={
                  selectedNode !== null && selectedNode.id === node.id
                }
                items={[
                  {
                    label: 'Move',
                    onClick: (e: any) => {
                      e.stopPropagation()
                      showModal({
                        type: ModalTypes.TreeMove,
                        config: {
                          ...ModalOptions.TreeMove,
                        },
                        params: {
                          tab: {
                            ...node,
                            query_id: node.id,
                            collection_id: rootParentId,
                          },
                          node,
                          moveNode: toNodeKey,
                          handleMove: (idxs: any) => {
                            updateTreeMove(idxs)
                          },
                        },
                      })
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: (e: any) => {
                      e.stopPropagation()
                      showModal({
                        type: ModalTypes.ConfirmationDelete,
                        config: {
                          ...ModalOptions.ConfirmationDelete,
                          headerName: 'Delete',
                        },
                        params: {
                          tab: {
                            ...node,
                            query_id: node.id,
                            collection_id: rootParentId,
                          },
                          handleDelete: deleteQuery,
                        },
                      })
                    },
                  },
                ]}
              />
            </NodeCasesContainer>
          ),
        }
      }}
    />
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedTab: getSelectedTabState,
  queryCollections: getQueryCollectionsState,
  resources: getResourcesState,
})

const mapDispatchToProps = {
  createQuery: queriesActions.createQuery,
  selectQuery: queriesActions.selectQuery,
  deleteQuery: queriesActions.deleteQuery,
  createFolder: queriesActions.createFolder,
  updateTab: queriesActions.updateTab,
  resetTempTab: queriesActions.resetTempTab,
  updateTreeDnd: queriesActions.updateQueryCollectionTreeDnd,
  updateTreeMove: queriesActions.updateQueryCollectionTreeMove,
  showModal: modalActions.showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueriesCatalogTreeContainer)
