import { FC, useMemo, useState } from 'react'
import PMainContainer from 'containers/Page/PMainContainer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getResourceState } from '../selectors'
import { connect } from 'react-redux'
import { IResources } from 'pages/Resources/types'
import { Button, Input, Label, PopoverNew, Typography } from 'shared'
import ResourceEditTable from './ResourceEditTable'
import TableList from './TableList'
import { resourceActions } from '../reducer'

interface StateProps {
  resource: IResources | null
}

type DispatchProps = typeof mapDispatchToProps

const Main: FC<StateProps & DispatchProps> = ({ resource, createTable }) => {
  const [input, setInput] = useState<string>('')

  if (!resource) return null
  return (
    <PMainContainer className="flex-1 overflow-hidden">
      <div className="grid grid-cols-7 gap-10 h-full overflow-hidden">
        <div className="col-span-2 space-y-6 flex flex-col overflow-hidden">
          <div className="flex items-center justify-between">
            <div className="font-medium">TABLES</div>
            <PopoverNew
              button={
                <Button onClick={() => {}}>
                  <Typography size="sm">Create Table</Typography>
                </Button>
              }
            >
              {(close: () => void) => (
                <div className="relative space-y-4 h-full flex flex-col p-2.5 bg-white shadow">
                  <div className="">
                    <Label label={'Table name'} size="md" weight="medium" />
                    <Input
                      type="text"
                      name="name"
                      width="full"
                      value={input}
                      placeholder={'Table name'}
                      onChange={(e: any) => setInput(e.target.value)}
                    />
                  </div>
                  <div className="flex justify-between">
                    <Button color="default-500" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      disabled={!input}
                      onClick={() => {
                        createTable(input)
                        close()
                      }}
                    >
                      Create
                    </Button>
                  </div>
                </div>
              )}
            </PopoverNew>
          </div>
          <TableList />
        </div>
        <div className="col-span-5 h-full overflow-hidden">
          <ResourceEditTable />
        </div>
      </div>
    </PMainContainer>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  resource: getResourceState,
})

const mapDispatchToProps = {
  createTable: resourceActions.createTable,
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
