import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import classnames from 'classnames'
import {
  LoadingButtonsTypes,
  QueriesItemType,
  SavedParamsTypes,
  TableInputProps,
} from '../types'
import JsonView from './right-panel/JsonView'
import TableView from './right-panel/TableView'
import { SwitchCase, Typography } from 'shared'
import { connect } from 'react-redux'
import { queriesActions } from 'pages/Queries/reducer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getLoadingButtonsState,
  getQueryIdState,
  getSavedParamsItemState,
  getSavedParamsState,
  getSelectedParamsState,
} from 'pages/Queries/selectors'
import { jsonTypeToVariablesType } from './right-panel/helpers'
import { updateParamsLogic } from '../utils'
import AuthorizationView from './right-panel/AuthorizationView'

enum VIEW_TYPES {
  PARAMS = 'params',
  BODY = 'body',
  AUTHORIZATION = 'authorization',
}

interface ApiParamsProps {
  query: QueriesItemType
  className?: string
}

type StateProps = {
  queryId: string
  savedParams: SavedParamsTypes[]
  selectedParams: string | null
  savedParamsItem: SavedParamsTypes
  loadingButtons: LoadingButtonsTypes
}

type DispatchProps = typeof mapDispatchToProps

const ApiParams: FunctionComponent<
  ApiParamsProps & StateProps & DispatchProps
> = ({
  className,
  query,
  queryId,
  savedParams,
  selectedParams,
  savedParamsItem,
  checkChangedTab,
  loadingButtons,
}) => {
  const [paramsView, setParamsView] = useState<VIEW_TYPES>(VIEW_TYPES.PARAMS)
  const [inputValue, setInputValue] = useState<TableInputProps>({
    value: '',
    name: '',
  })

  const handleChange = (id: VIEW_TYPES) => {
    setParamsView(id)
  }

  const handleUpdateParams = useCallback(
    ({ value, name }: TableInputProps) => {
      checkChangedTab({
        query_id: queryId,
        data: {
          name: 'saved_params',
          value: updateParamsLogic(savedParams, selectedParams, name, value),
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryId, savedParams, selectedParams]
  )

  const handleChangeTableInput = useCallback(
    ({ value, name }: TableInputProps) => {
      setInputValue({ value, name })
    },
    []
  )

  const transformQueryParams = useMemo(
    () => jsonTypeToVariablesType(savedParamsItem?.params || {}),
    [savedParamsItem]
  )

  const transformBodyParams = useMemo(
    () => jsonTypeToVariablesType(savedParamsItem?.body || {}),
    [savedParamsItem]
  )

  useEffect(() => {
    if (inputValue.name) handleUpdateParams(inputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <div className={classnames('flex flex-col grow mt-5 pb-2.5', className)}>
      <div className="flex align-center space-x-0.5">
        {[
          {
            name: 'params',
            label: 'Params',
            onClick: () => handleChange(VIEW_TYPES.PARAMS),
          },
          {
            name: 'body',
            label: 'Body',
            onClick: () => handleChange(VIEW_TYPES.BODY),
          },
          {
            name: 'authorization',
            label: 'Authorization',
            onClick: () => handleChange(VIEW_TYPES.AUTHORIZATION),
          },
        ].map((el, i) => (
          <Typography
            key={i}
            className={classnames(
              'cursor-pointer rounded-sm px-2.5 py-1 duration-300',
              el.name === paramsView ? 'bg-default-100' : ''
            )}
            size="sm"
            color={el.name === paramsView ? 'default-900' : 'default-600'}
            onClick={el.onClick}
          >
            {el.label}
          </Typography>
        ))}
      </div>
      <div className="relative grow mt-1.5">
        <SwitchCase value={paramsView}>
          {{
            params: (
              <TableView
                variables={transformQueryParams}
                setVariables={(value) =>
                  handleChangeTableInput({ value, name: 'params' })
                }
                className={
                  'absolute top-0 left-0 max-h-full h-full w-full overflow-auto'
                }
              />
            ),
            body: (
              <JsonView
                variables={transformBodyParams}
                setVariables={(value) =>
                  handleChangeTableInput({ value, name: 'body' })
                }
                className={'absolute top-0 left-0 max-h-full h-full w-full'}
              />
            ),
            authorization: <AuthorizationView query={query} />,
          }}
        </SwitchCase>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  queryId: getQueryIdState,
  savedParams: getSavedParamsState,
  selectedParams: getSelectedParamsState,
  savedParamsItem: getSavedParamsItemState,
  loadingButtons: getLoadingButtonsState,
})

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiParams)
