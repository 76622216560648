import { FunctionComponent, useState } from 'react'
import { Button, ButtonSimple, Input, Label, Select } from 'shared'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import ModalContentContainer from 'containers/Modal/ModalContentContainer'
import ModalFooterContainer from 'containers/Modal/ModalFooterContainer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getModalLoadingState,
  getModalParamsState,
} from 'containers/Modal/selectors'
import { CLUSTERS_TYPE_TYPES, ClustersTypeTypeT } from '../types'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  EyeOffIcon,
} from 'assets/general'
import classnames from 'classnames'
import { clustersActions } from '../reducer'
import { getCheckedState } from '../selectors'

interface InputsType {
  type: string
  name: string
  label: string
  placeholder: string
  variant?: string
  options?: any
  disabled?: boolean
}

const inputs: InputsType[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Name:',
    placeholder: 'Name',
  },
  {
    type: 'text',
    name: 'description',
    label: 'Description:',
    placeholder: 'Description',
  },
  {
    type: 'text',
    name: 'cluster_id',
    label: 'Cluster:',
    placeholder: 'Cluster',
    variant: 'select',
    disabled: true,
    options: [
      { id: 0, name: '1', title: '1' },
      { id: 1, name: '2', title: '2' },
      { id: 2, name: '3', title: '3' },
    ],
  },
  {
    type: 'text',
    name: 'provider',
    label: 'Provider:',
    placeholder: 'Provider',
    variant: 'select',
    options: [
      { id: 0, name: 'AWS', title: 'AWS' },
      { id: 1, name: 'Azure', title: 'Azure' },
      { id: 2, name: 'GCP', title: 'GCP' },
    ],
  },
  {
    type: 'text',
    name: 'type',
    label: 'Type:',
    placeholder: 'Type',
    variant: 'select',
    options: [
      { id: 0, name: 'clickhouse', title: 'Clickhouse' },
      { id: 1, name: 'postgresql', title: 'Postgresql' },
    ],
  },
]

const clickhouseInputs = [
  {
    type: 'text',
    name: 'url',
    label: 'Url:',
    placeholder: 'Url',
  },
  {
    type: 'text',
    name: 'user',
    label: 'User:',
    placeholder: 'User',
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password:',
    placeholder: 'Password',
  },
]

const postgresqlInputs = [
  {
    type: 'text',
    name: 'host',
    label: 'Host:',
    placeholder: 'Host',
  },
  {
    type: 'text',
    name: 'port',
    label: 'Port:',
    placeholder: 'Port',
  },
  {
    type: 'text',
    name: 'user',
    label: 'User:',
    placeholder: 'User',
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password:',
    placeholder: 'Password',
  },
]

// const advancedInputs = [
//   {
//     type: 'text',
//     name: 'advanced_1',
//     label: 'Advanced 1:',
//     placeholder: 'Advanced 1',
//   },
//   {
//     type: 'text',
//     name: 'advanced_2',
//     label: 'Advanced 2:',
//     placeholder: 'Advanced 2',
//   },
// ]

const InputComponent = ({
  data,
  inputValue,
  params,
  handleChange,
}: {
  data: InputsType
  inputValue: any
  params: any
  handleChange: (value: any, name: string) => void
}) => {
  const [isShowPwd, setIsShowPwd] = useState<boolean>(false)
  return (
    <Label
      key={data.name}
      className="justify-end mt-t15 first:mt-0"
      label={data.label}
      alignLabel="left"
      size="lg"
      weight="bold"
    >
      {data.variant === 'select' ? (
        <Select
          options={data.options}
          size={250}
          value={
            data.options.filter(
              (opt: any) => opt.name === inputValue[data.name]
            )[0]
          }
          disabled={params?.cluster ? data.disabled : false}
          onChange={(value: any) => handleChange(value.name, data.name)}
        />
      ) : (
        <div className="relative">
          <Input
            type={
              data.type === 'password'
                ? isShowPwd
                  ? 'text'
                  : 'password'
                : data.type
            }
            name={data.name}
            value={inputValue[data.name]}
            placeholder={data.placeholder}
            disabled={params?.cluster ? data.disabled : false}
            color="default-200"
            onChange={(e: any) => handleChange(e.target.value, data.name)}
          />
          {data.type === 'password' ? (
            isShowPwd ? (
              <EyeOffIcon
                className="absolute right-t16 top-t10 cursor-pointer"
                onClick={() => {
                  setIsShowPwd(false)
                }}
              />
            ) : (
              <EyeIcon
                className="absolute right-t16 top-t10 cursor-pointer"
                onClick={() => {
                  setIsShowPwd(true)
                }}
              />
            )
          ) : null}
        </div>
      )}
    </Label>
  )
}

const getConfigInputs = (type: string) => {
  switch (type) {
    case CLUSTERS_TYPE_TYPES.CLICKHOUSE:
      return clickhouseInputs
    case CLUSTERS_TYPE_TYPES.POSTGRESQL:
      return postgresqlInputs
    default:
      return []
  }
}

const getCredentials = (type: string | ClustersTypeTypeT, params?: any) => {
  switch (type) {
    case CLUSTERS_TYPE_TYPES.CLICKHOUSE:
      return {
        url: params?.cluster?.credentials?.url
          ? params.cluster.credentials.url
          : '',
        user: params?.cluster?.credentials?.user
          ? params.cluster.credentials.user
          : '',
        password: params?.cluster?.credentials?.password
          ? params.cluster.credentials.password
          : '',
      }
    case CLUSTERS_TYPE_TYPES.POSTGRESQL:
      return {
        host: params?.cluster?.credentials?.host
          ? params.cluster.credentials.host
          : '',
        port: params?.cluster?.credentials?.port
          ? params.cluster.credentials.port
          : '',
        user: params?.cluster?.credentials?.user
          ? params.cluster.credentials.user
          : '',
        password: params?.cluster?.credentials?.password
          ? params.cluster.credentials.password
          : '',
      }
    default:
      return {}
  }
}

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const CreateNewCluster: FunctionComponent<SelectedProps & DispatchProps> = ({
  loading,
  params,
  checked,
  hideModal,
  createCluster,
  updateCluster,
  deleteCluster,
  checkCluster,
  didCheckCluster,
}) => {
  // TODO make better!
  const [inputValue, setInputValue] = useState<{ [name: string]: string }>({
    cluster_id: params?.cluster?.cluster_id ? params.cluster.cluster_id : '',
    provider: params?.cluster?.provider ? params.cluster.provider : '',
    type: params?.cluster?.type ? params.cluster.type : '',
    name: params?.cluster?.name ? params.cluster.name : '',
    description: params?.cluster?.description ? params.cluster.description : '',
  })
  const [credentials, setCredentials] = useState<any>(
    getCredentials(params?.cluster?.type, params)
  )
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false)

  const handleChange = (value: any, name: string) => {
    setInputValue((prev: any) => {
      return { ...prev, [name]: value }
    })
    if (name === 'type') setCredentials(getCredentials(value))
    if (checked) didCheckCluster(false)
  }

  const handleChangeCredentials = (value: any, name: string) => {
    setCredentials((prev: any) => {
      return { ...prev, [name]: value }
    })

    if (checked) didCheckCluster(false)
  }

  return (
    <div data-label="modal-new-cluster">
      <ModalContentContainer className={classnames('pb-0')}>
        <div className="grid grid-cols-12">
          <div className="flex flex-col items-start col-span-10">
            {inputs.map((d: InputsType) => (
              <InputComponent
                key={d.name}
                data={d}
                inputValue={inputValue}
                params={params}
                handleChange={handleChange}
              />
            ))}
            {inputValue.type
              ? getConfigInputs(inputValue.type).map((d: InputsType) => (
                  <InputComponent
                    key={d.name}
                    data={d}
                    inputValue={credentials}
                    params={params}
                    handleChange={handleChangeCredentials}
                  />
                ))
              : null}
          </div>
          <div className="col-span-2"></div>
        </div>
        <div className="relative mt-t25 mb-t3">
          <hr className="bg-default-200"></hr>
          <ButtonSimple
            className="bg-default-100 hover:bg-default-150 text-default-700 text-md px-t10 py-t5 rounded-full absolute left-t50% right-t50% transform -translate-x-t50% -translate-y-t50%"
            onClick={() => setIsAdvanced(!isAdvanced)}
          >
            Advanced
            {isAdvanced ? (
              <ChevronUpIcon className="ml-t5" size="sm" />
            ) : (
              <ChevronDownIcon className="ml-t5" size="sm" />
            )}
          </ButtonSimple>
        </div>
        {/* {isAdvanced ? (
          <div className="grid grid-cols-12">
            <div className="flex flex-col items-start col-span-10">
              {advancedInputs.map((d: InputsType) => (
                <InputComponent
                  key={d.name}
                  data={d}
                  inputValue={inputValue}
                  params={params}
                  handleChange={handleChange}
                />
              ))}
            </div>
            <div className="col-span-2"></div>
          </div>
        ) : null} */}
      </ModalContentContainer>
      <ModalFooterContainer>
        <Button className="mr-t20" variant="OUTLINED" onClick={hideModal}>
          Cancel
        </Button>
        <div className="flex space-x-4">
          {params?.cluster ? (
            <Button
              loading={loading.name === 'delete' && loading.is}
              disabled={loading.is}
              onClick={() =>
                deleteCluster({ cluster_id: params.cluster.cluster_id })
              }
            >
              Delete
            </Button>
          ) : null}
          <Button
            loading={loading.name === 'check' && loading.is}
            disabled={loading.is}
            color="success"
            onClick={() =>
              checkCluster({
                credentials,
                ...inputValue,
              })
            }
          >
            Check
          </Button>
          <Button
            loading={
              (loading.name === 'save' || loading.name === 'create') &&
              loading.is
            }
            disabled={loading.is || !checked}
            color="primary"
            onClick={() =>
              params?.cluster
                ? updateCluster({
                    credentials,
                    ...inputValue,
                  })
                : createCluster({
                    credentials,
                    ...inputValue,
                  })
            }
          >
            {params?.cluster ? 'Save' : 'Create'}
          </Button>
        </div>
      </ModalFooterContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  loading: getModalLoadingState,
  params: getModalParamsState,
  checked: getCheckedState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
  createCluster: clustersActions.createCluster,
  updateCluster: clustersActions.updateCluster,
  deleteCluster: clustersActions.deleteCluster,
  checkCluster: clustersActions.checkCluster,
  didCheckCluster: clustersActions.didCheckCluster,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCluster)
