import { ReactNode, FunctionComponent } from 'react'
import classnames from 'classnames'

interface IconButtonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick?: (e: any) => void
}

export const IconButton: FunctionComponent<IconButtonProps> = ({
  children,
  className,
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <button
      type="button"
      className={classnames(
        { disabled },
        'inline-flex items-center p-1.5 rounded-full focus:outline-none focus:ring-0',
        className
      )}
      onClick={onClick}
    >
      {/* <span className="sr-only">View notifications</span> */}
      {children}
    </button>
  )
}
