import { FunctionComponent } from 'react'
import { InputSearch, Pagination, Select } from 'shared'

interface ManagePanelProps {}

const ManagePanel: FunctionComponent<ManagePanelProps> = () => {
  return (
    <div className="flex justify-between mb-t5">
      <InputSearch className="shadow" />
      <div className="flex items-center">
        <Select
          color="white"
          classes={{ root: 'mr-t20 shadow' }}
          options={[{ id: 0, name: 'First', title: 'First' }]}
        />
        <Select
          color="white"
          classes={{ root: 'mr-t20 shadow' }}
          options={[{ id: 0, name: 'Second', title: 'Second' }]}
        />
        <Pagination />
      </div>
    </div>
  )
}

export default ManagePanel
