import { FunctionComponent } from 'react'
import { RootState } from 'app/store'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Button } from 'shared'

import { mainActions } from '../../reducer'
import { getValueState, getTextState } from '../../selectors'
import styles from './Counter.module.css'

interface ICounterProps {
  value: number
  text: string
}

type DispatchProps = typeof mapDispatchToProps

const Counter: FunctionComponent<ICounterProps & DispatchProps> = ({
  value,
  text,
  decrement,
  increment,
  reset,
}) => {
  return (
    <div>
      <div className={styles.row}>
        <Button onClick={() => decrement('decr')}>Decrement</Button>
        <span className={styles.value}>
          {value}
          {text && `- ${text}`}
        </span>
        <Button color="primary" onClick={() => increment('decr')}>
          Increment
        </Button>
      </div>
      <div className="mt-md text-center">
        <Button variant="CONTAINED" onClick={() => reset()}>
          Reset
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, ICounterProps>({
  value: getValueState,
  text: getTextState,
})

const mapDispatchToProps = {
  increment: mainActions.increment,
  decrement: mainActions.decrement,
  reset: mainActions.reset,
}

export default connect(mapStateToProps, mapDispatchToProps)(Counter)
