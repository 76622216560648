import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import HeaderContainer from './containers/HeaderContainer'
import { ParamsType } from 'utils/router'
import MainContainer from './containers/MainContainer'
import PRootContainer from 'containers/Page/PRootContainer'
import { projectActions } from './reducer'

type DispatchProps = typeof mapDispatchToProps

const Project: FunctionComponent<DispatchProps> = ({ fetchDatasets }) => {
  const { id } = useParams<ParamsType>()

  useEffect(() => {
    fetchDatasets(id)
  }, [id, fetchDatasets]) // TODO eslint

  return (
    <PRootContainer data-label="project-container" className="flex flex-col h-full px-t80 py-t20">
      <HeaderContainer idProject={id} />
      <MainContainer idProject={id} />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchDatasets: projectActions.fetchDatasets,
}

export default connect(null, mapDispatchToProps)(Project)
