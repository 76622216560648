import { createSelector } from 'reselect'
import { RootState } from 'app/store'

export const getModalListState = (state: RootState) => state.modal

export const getIsOpenState = createSelector(
  getModalListState,
  (state) => state.isOpen
)

export const getTypeState = createSelector(
  getModalListState,
  (state) => state.type
)

export const getConfigState = createSelector(
  getModalListState,
  (state) => state.config
)

export const getModalLoadingState = createSelector(
  getModalListState,
  (state) => state.loading
)

export const getModalParamsState = createSelector(
  getModalListState,
  (state) => state.params
)
