import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
// import SwitcherContainer from 'pages/Dataset/containers/SwitcherContainer'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'
import { Button } from 'shared'
import { PlusIcon } from 'assets/general/PlusIcon'
import { modalActions } from 'containers/Modal/reducer'
import { getActiveViewState } from '../selectors'
import { clustersActions } from '../reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'

interface HeaderContainerProps {}

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const HeaderContainer: FunctionComponent<
  HeaderContainerProps & SelectedProps & DispatchProps
> = ({ activeView, changeView, showModal }) => {
  return (
    <PHeaderContainer>
      <div className="flex space-x-t50 items-center">
        <PageName name="Clusters" />
        <Button
          onClick={() =>
            showModal({
              type: ModalTypes.CreateNewCluster,
              config: { ...ModalOptions.CreateNewCluster },
            })
          }
        >
          <PlusIcon className="mr-xs" />
          Add Cluster
        </Button>
      </div>
      {/* <SwitcherContainer activeView={activeView} onClick={changeView} /> */}
    </PHeaderContainer>
  )
}

const mapStateToProps = createStructuredSelector<any, any>({
  activeView: getActiveViewState,
})

const mapDispatchToProps = {
  changeView: clustersActions.changeView,
  showModal: modalActions.showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
