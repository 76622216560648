import { makeRequest } from './http'

const api = {
  async getResources(workspace_id) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async getResource({ workspace_id, resource_id }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async createResource({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources`,
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async updateResource({ workspace_id, resource_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Resource
  async deleteResource({ workspace_id, resource_id }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Check Resource
  async checkResource({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/check`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  // TABLES

  // Create Resource Table
  async createTable({ workspace_id, resource_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  // Update Resource Table
  async updateTable({ workspace_id, resource_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables`,
      method: 'PUT',
      params: JSON.stringify(data),
    })
    return response
  },

  // Delete Resource Table
  async deleteTable({ workspace_id, resource_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables`,
      method: 'DELETE',
      params: JSON.stringify(data),
    })
    return response
  },

  //  DATA

  async getTableData({ workspace_id, resource_id, table_name }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/data`,
      method: 'GET',
    })
    return response
  },

  async createTableData({ workspace_id, resource_id, table_name, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/data`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  async updateTableData({ workspace_id, resource_id, table_name, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/data`,
      method: 'PUT',
      params: JSON.stringify(data),
    })
    return response
  },

  async deleteTableData({ workspace_id, resource_id, table_name, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/data`,
      method: 'DELETE',
      params: JSON.stringify(data),
    })
    return response
  },

  // COLUMNS

  async createTableColumn({ workspace_id, resource_id, table_name, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/columns`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  async updateTableColumn({ workspace_id, resource_id, table_name, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/columns`,
      method: 'PUT',
      params: JSON.stringify(data),
    })
    return response
  },

  async deleteTableColumn({ workspace_id, resource_id, table_name, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/${table_name}/columns`,
      method: 'DELETE',
      params: JSON.stringify(data),
    })
    return response
  },

  // OTHERS

  async generateSalt({ workspace_id, resource_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/resources/${resource_id}/tables/generate_salt`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },
}

export default api
