import { FunctionComponent } from 'react'
import PreviewContainer from '../PreviewContainer'
import SourceTab from '../sourceTab/SourceTab'

const ConnectionsPrepare: FunctionComponent = () => {
  return (
    <div className="flex flex-col h-full w-full">
      <SourceTab />
      <PreviewContainer />
    </div>
  )
}

export default ConnectionsPrepare
