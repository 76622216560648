import { FunctionComponent } from 'react'
import { Button, MONACO_LANGUAGES_TYPES, Typography } from 'shared'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { v4 as uuidv4 } from 'uuid'
import ModalContentContainer from 'containers/Modal/ModalContentContainer'
import ModalFooterContainer from 'containers/Modal/ModalFooterContainer'
import { RootState } from 'app/store'
import {
  getModalLoadingState,
  getModalParamsState,
} from 'containers/Modal/selectors'
import Queries from '../storage'
import { SavedParamsTypes } from '../types'

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const ConfirmationCloseTab: FunctionComponent<
  SelectedProps & DispatchProps
> = ({ loading, params, hideModal }) => {
  const { tab } = params
  return (
    <div data-label="modal-confirmation-close-tab">
      <ModalContentContainer className="grid grid-cols-12">
        <div className="flex flex-col items-start col-span-10">
          <Typography className="leading-4" size="sm">
            This tab <span className="font-medium">{tab.name}</span> has unsaved
            changes which will be lost if you choose to close it. Save these
            changes to avoid losing your work.
          </Typography>
        </div>
        <div className="col-span-2"></div>
      </ModalContentContainer>
      <ModalFooterContainer>
        <Button
          className="mr-5"
          variant="OUTLINED"
          onClick={(e: any) => {
            params.handleNotSave(e, tab)
            hideModal()
          }}
        >
          Don't save
        </Button>
        <div className="flex space-x-4">
          <Button
            variant="OUTLINED"
            color="primary"
            onClick={hideModal}
            // ref={cancelButtonRef}
          >
            Cancel
          </Button>
          <Button
            loading={loading.name === 'save' && loading.is}
            disabled={loading.is}
            color="primary"
            onClick={() => {
              const findTab = Queries.getTabById(tab.query_id)
              const keys = Object.keys(findTab)
              const apiPath = keys.includes('api_path')
                ? findTab.api_path
                : tab.api_path || ''
              const data =
                findTab.type === 'query'
                  ? {
                      name: tab.name,
                      query_params: keys.includes('query_params')
                        ? findTab.query_params
                        : tab.query_params,
                      query_string: keys.includes('query_string')
                        ? findTab.query_string
                        : tab.query_string,
                      lang: keys.includes('lang')
                        ? findTab.lang
                        : tab.lang || MONACO_LANGUAGES_TYPES.SQL,
                      api_path: apiPath,
                      api_type: keys.includes('api_type')
                        ? findTab.api_type
                        : tab.api_type || 'GET',
                      api_url: tab.path_api + apiPath,
                      saved_params: keys.includes('saved_params')
                        ? findTab.saved_params.map((d: SavedParamsTypes) =>
                            !d.id ? { ...d, id: uuidv4() } : d
                          )
                        : tab.saved_params,
                      auth: keys.includes('auth') ? findTab.auth : tab.auth,
                    }
                  : {
                      api_path: apiPath,
                    }
              params.handleSave({
                tab,
                data,
              })
            }}
          >
            Save changes
          </Button>
        </div>
      </ModalFooterContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  loading: getModalLoadingState,
  params: getModalParamsState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationCloseTab)
