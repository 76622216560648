import { FunctionComponent, useEffect, useState } from 'react'
import { Button, Input, Label, SelectNew, TextArea } from 'shared'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import ModalContentContainer from 'containers/Modal/ModalContentContainer'
import ModalFooterContainer from 'containers/Modal/ModalFooterContainer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getModalLoadingState,
  getModalParamsState,
} from 'containers/Modal/selectors'
import { sidebarActions } from 'containers/Sidebar/reducer'
import { resourcesService } from 'utils/services'
import { IResources } from 'pages/Resources/types'

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const CreateWorkspace: FunctionComponent<SelectedProps & DispatchProps> = ({
  loading,
  params,
  createWorkspace,
  updateWorkspace,
  hideModal,
}) => {
  const [inputValue, setInputValue] = useState<any>({
    name: params?.workspace?.name || '',
    description: params?.workspace?.description || '',
    icon_url: params?.workspace?.icon_url || '',
    auth_resource_id: null,
  })
  const [resources, setResources] = useState<IResources[]>([])

  useEffect(() => {
    async function fetchResources() {
      const result = await resourcesService.getResources(
        params.workspace.workspace_id
      )
      if (result) {
        setResources(result)
        if (params.workspace.auth_resource_id) {
          const idx = result.findIndex(
            (d: IResources) =>
              d.resource_id === params.workspace.auth_resource_id
          )
          if (idx !== -1)
            setInputValue((prev: any) => ({
              ...prev,
              auth_resource_id: {
                id: result[idx].resource_id,
                name: result[idx].name,
              },
            }))
        }
      }
    }
    if (params?.workspace?.workspace_id) fetchResources()
  }, [params?.workspace?.workspace_id])

  const handleChange = (target: any) => {
    setInputValue((prev: any) => {
      return { ...prev, [target.name]: target.value }
    })
  }

  return (
    <div data-label="modal-create-workspace">
      <ModalContentContainer className="grid grid-cols-12">
        <div className="flex flex-col items-start col-span-12 m-auto">
          <Label
            className="justify-end mt-4 first:mt-0"
            classNameLabel="w-24"
            label="Name"
            alignLabel="left"
            size="sm"
            weight="medium"
          >
            <Input
              type="text"
              name="name"
              value={inputValue.name}
              placeholder="Workspace name"
              onChange={(e: any) => handleChange(e.target)}
            />
          </Label>
          <Label
            className="justify-end mt-4 first:mt-0"
            classNameLabel="w-24"
            label="Description"
            alignLabel="left"
            size="sm"
            weight="medium"
          >
            <TextArea
              type="text"
              name="description"
              value={inputValue.description}
              placeholder="Workspace description"
              onChange={(e: any) => handleChange(e.target)}
            />
          </Label>
          <Label
            className="justify-end mt-4 first:mt-0"
            classNameLabel="w-24"
            label="Icon URL"
            alignLabel="left"
            size="sm"
            weight="medium"
          >
            <Input
              type="text"
              name="icon_url"
              value={inputValue.icon_url}
              placeholder="Icon URL"
              onChange={(e: any) => handleChange(e.target)}
            />
          </Label>
          <Label
            className="justify-end mt-4 first:mt-0"
            classNameLabel="w-24"
            label="Authorization"
            alignLabel="left"
            size="sm"
            weight="medium"
          >
            <SelectNew
              selected={inputValue.auth_resource_id}
              options={
                resources.map((res) => ({
                  id: res.resource_id,
                  name: res.name,
                })) || []
              }
              width="w-64"
              isIcon={false}
              onChange={(value: any) =>
                handleChange({ name: 'auth_resource_id', value })
              }
            />
          </Label>
        </div>
        <div className="col-span-2"></div>
      </ModalContentContainer>
      <ModalFooterContainer>
        <Button
          className="mr-5"
          color="default-500"
          variant="OUTLINED"
          onClick={hideModal}
        >
          Cancel
        </Button>
        <Button
          loading={loading.is}
          disabled={loading.is}
          color="primary"
          onClick={() => {
            if (params?.workspace) {
              const obj = { ...inputValue }
              if (obj.auth_resource_id === null) delete obj.auth_resource_id
              else obj.auth_resource_id = obj.auth_resource_id.id
              updateWorkspace({
                workspace_id: params.workspace.workspace_id,
                data: obj,
              })
            } else createWorkspace(inputValue)
          }}
        >
          {params?.workspace ? 'Save' : 'Create'}
        </Button>
      </ModalFooterContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  loading: getModalLoadingState,
  params: getModalParamsState,
})

const mapDispatchToProps = {
  createWorkspace: sidebarActions.createWorkspace,
  updateWorkspace: sidebarActions.updateWorkspace,
  hideModal: modalActions.hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkspace)
