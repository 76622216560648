import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectsItemType, CreateProjectModalType } from './types'

const prefix = 'projects'

export interface ProjectsState {
  activeView: number
  loading: boolean
  projects: ProjectsItemType[]
}

const initialState: ProjectsState = {
  activeView: 0,
  loading: false,
  projects: [],
}

const projectsReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchProjects: (state) => {
      state.loading = true
    },
    didFetchProjects: (
      state,
      { payload }: PayloadAction<ProjectsItemType[]>
    ) => {
      state.projects = payload
      state.loading = false
    },
    didFetchProjectsFail: (state) => {
      state.loading = false
    },
    createProject: (state, action: PayloadAction<CreateProjectModalType>) => {},
    updateProject: (state, action: PayloadAction<any>) => {},
    deleteProject: (state, action: PayloadAction<any>) => {},
    changeView: (state, { payload }: PayloadAction<any>) => {
      state.activeView = payload
    },
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
  },
})

export const projectsActions = projectsReducer.actions

export default projectsReducer.reducer
