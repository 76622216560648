import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { FileUpload } from 'shared'
import { fileReaderCSV } from 'utils/helpers'
import PreviewContainer from '../PreviewContainer'
import { v4 as uuidv4 } from 'uuid'
import { sliderActions } from '../reducer'

type DispatchProps = typeof mapDispatchToProps

const CSVPrepare: FunctionComponent<DispatchProps> = ({
  setSchemeData,
  setPreviewData,
  setPreviewDataMemory,
}) => {
  const handleChangeFile = (value: any) => {
    fileReaderCSV(value)
      .then((result: any) => {
        if (result.data) {
          const schema = Object.keys(result.data[0]).map((d) => ({
            id: uuidv4(),
            name: d,
            new_name: d,
            type: typeof d,
            description: `${d} default description`,
            show: true,
          }))
          setPreviewDataMemory({ data: result.data })
          setSchemeData(schema)
        }
      })
      .catch((e) => {
        setPreviewDataMemory(null)
        setPreviewData(null)
        setSchemeData([])
      })
  }
  return (
    <div className="flex flex-col h-full w-full">
      <FileUpload
        className="h-full mb-t20"
        inputClass="justify-center"
        accept={{ 'text/csv': ['.csv'] }}
        type="file"
        width="full"
        height="full"
        setFile={handleChangeFile}
      />
      <PreviewContainer />
    </div>
  )
}

const mapDispatchToProps = {
  setSchemeData: sliderActions.setSchemeData,
  setPreviewData: sliderActions.setPreviewData,
  setPreviewDataMemory: sliderActions.setPreviewDataMemory,
}

export default connect(null, mapDispatchToProps)(CSVPrepare)
