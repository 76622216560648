import React from 'react'
import classNames from 'classnames'

import { IconProps, Icon } from '../Icon'

export const ClickhouseIcon: React.FunctionComponent<IconProps> = (props) => {
  return (
    <Icon {...props} className={classNames('fill-current', props.className)}>
      <svg viewBox="0 0 9 8" width="50px" xmlns="http://www.w3.org/2000/svg">
        <path d="m0 7h1v1h-1z" fill="#f00" />
        <path
          d="m0 0h1v7h-1zm2 0h1v8h-1zm2 0h1v8h-1zm2 0h1v8h-1zm2 3.25h1v1.5h-1z"
          fill="#fc0"
        />
      </svg>
    </Icon>
  )
}
