import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { queriesActions } from '../../reducer'
import ApiSettings from '../ApiSettings'
import { QueriesItemType, SavedParamsTypes } from '../../types'
import ApiParams from '../ApiParams'
import QueryResultsContainer from '../QueryResultsContainer'
import QueryParamsDrawer from '../drawer/QueryParamsDrawer'
import _ from 'app/auth/constants'
import { createStructuredSelector } from 'reselect'
import { getSavedParamsItemState } from 'pages/Queries/selectors'
import { RootState } from 'app/store'

interface ApiTabContainerProps {
  query: QueriesItemType
  className?: string
}

interface StateProps {
  savedParamsItem: SavedParamsTypes
}

type DispatchProps = typeof mapDispatchToProps

const ApiTabContainer: FunctionComponent<
  ApiTabContainerProps & StateProps & DispatchProps
> = ({ query, savedParamsItem, checkChangedTab }) => {
  const [apiUrl, setApiUrl] = useState<string>(
    query.api_path ? query.api_path : ''
  )
  const [apiType, setApiType] = useState<string>(
    query.api_type ? query.api_type : 'GET'
  )

  useEffect(() => setApiUrl(query.api_path || ''), [query.api_path])

  useEffect(() => setApiType(query.api_type || 'GET'), [query.api_type])

  const parsedApiPath = useMemo(
    () =>
      `${query.path_api}${query.api_path}`
        .split('/')
        .map((d) =>
          d.includes(':') ? savedParamsItem?.params[d.substring(1)] : d
        )
        .join('/'),
    [query.path_api, query.api_path, savedParamsItem?.params]
  )

  return (
    <div
      data-label="api-tab-container"
      className={`relative flex flex-col grow overflow-auto ${
        query.deleted ? 'pointer-events-none' : ''
      }`}
    >
      <QueryParamsDrawer />
      <div className="flex flex-col h-60% p-2.5">
        <ApiSettings
          apiUrl={apiUrl}
          apiType={apiType}
          selectedTab={query}
          setApiUrl={(value: string) => {
            setApiUrl(value)
            checkChangedTab({
              query_id: query.query_id,
              data: { name: 'api_path', value },
            })
          }}
          setApiType={(value: string) => {
            setApiType(value)
            checkChangedTab({
              query_id: query.query_id,
              data: { name: 'api_type', value },
            })
          }}
        />
        <div className="flex text-xs m-2 justify-end ">
          <div className="mr-2">API URL: </div>
          <a
            className="text-blue-600"
            href={`${_.SERVER_API}/run/${query.workspace_id}${parsedApiPath}`}
            target="_blank"
          >
            {_.SERVER_API}/run/{query.workspace_id}
            {parsedApiPath}
          </a>
        </div>

        <ApiParams query={query} />
      </div>
      <QueryResultsContainer
        tab="api"
        selectedTab={query}
        className="w-full h-55% border-t"
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  savedParamsItem: getSavedParamsItemState,
})

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiTabContainer)
