import { makeRequest } from './http'

const api = {
  async getClusters() {
    const response = await makeRequest({
      path: '/clusters',
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Create Cluster
  async createCluster(data) {
    const response = await makeRequest({
      path: '/clusters',
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Update Cluster
  async updateCluster({ cluster_id, data }) {
    const response = await makeRequest({
      path: `/clusters/${cluster_id}`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Cluster
  async deleteCluster({ cluster_id }) {
    const response = await makeRequest({
      path: `/clusters/${cluster_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Check Cluster
  async checkCluster(data) {
    const response = await makeRequest({
      path: '/clusters/check',
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },
}

export default api
