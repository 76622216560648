import { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import cn from 'classnames'
import { getIcon } from 'utils/helpers'

interface ISelectNewProps {
  options: any[]
  selected: any
  onChange: (value: any) => void

  width?: string
  isIcon?: boolean
  className?: string
}

export const SelectNew: FC<ISelectNewProps> = ({
  options = [],
  selected,
  width = 'w-56',
  isIcon = true,
  className = '',
  onChange,
}) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">
            Assigned to
          </Listbox.Label> */}
          <div className="relative">
            <Listbox.Button
              className={cn(
                'relative bg-white border border-gray-300 rounded-sm shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-0 sm:text-sm',
                width,
                className
              )}
            >
              <span className="flex items-center">
                {isIcon ? getIcon(selected?.type, 4, 'mr-3') : null}
                <span className="block truncate">
                  {selected?.label || selected?.name || 'Select...'}
                </span>
              </span>
              <span className="absolute inset-y-0 right-10 flex items-center pointer-events-none capitalize text-default-500">
                {selected?.type}
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-20 w-full bg-white shadow-lg max-h-56 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-default-100' : '',
                        'text-default-700 cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {() => {
                      const isSelected = option?.id === selected?.id // Default listbox {selected} not working
                      return (
                        <>
                          <div className="flex items-center">
                            {isIcon ? getIcon(option.type, 4, 'mr-3') : null}
                            <span
                              className={cn(
                                isSelected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {option?.label || option.name}
                            </span>
                          </div>
                          
                          <span className="absolute inset-y-0 right-10 flex items-center pointer-events-none capitalize text-default-500">
                            {option?.type}
                          </span>
                          
                          {isSelected ? (
                            <span
                              className={cn(
                                'absolute inset-y-0 right-0 flex items-center pr-2 text-indigo-600'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
