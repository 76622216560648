import { FunctionComponent, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { CloseIcon, PlusIcon, DotsHorizontalIcon } from 'assets/general'
import { IconButton, Indicator, Typography } from 'shared'
import TabContent from './TabContent'
import { queriesActions } from '../reducer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getLoadingQueryState,
  getLoadingState,
  getSelectedTabState,
  getTabsState,
} from '../selectors'
import { QueriesItemType } from '../types'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'
import TabContentSkeleton from './skeletons/TabContentSkeleton'

interface QueryTabsProps {
  className?: string
}

type StateProps = {
  tabs: QueriesItemType[]
  selectedTab: QueriesItemType | null
  loadingQuery: boolean
  loading: boolean
}

type DispatchProps = typeof mapDispatchToProps

const QueryTabs: FunctionComponent<
  QueryTabsProps & StateProps & DispatchProps
> = ({
  tabs,
  selectedTab,
  createQuery,
  fetchQuery,
  closeQuery,
  saveChangesTabAfterClose,
  selectTab,
  className,
  loadingQuery,
  loading,
  showModal,
  resetTempTab,
}) => {
  const clickTimeout = useRef<any>(null)

  const handleAddTab = () => {
    // TODO need to add resource_id
    // createQuery({
    //   name: `New Query random_id_${Math.floor(Math.random() * 1000)}`,
    //   description: 'Some description',
    //   query_string: 'SELECT * FROM table_name',
    // })
  }

  const handleCloseTab = (e: Event, el: QueriesItemType) => {
    e.stopPropagation()
    closeQuery(el)
  }

  const handleClickTab = (e: any, el: any) => {
    if (selectedTab?.query_id === el.query_id) {
      if (clickTimeout.current === null) {
        clickTimeout.current = setTimeout(() => {
          selectTab(el)
          clickTimeout.current = null
        }, 200)
      } else {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
        if (el.temp) resetTempTab(el)
      }
    } else {
      selectTab(el)
    }
  }

  useEffect(() => {
    if (
      selectedTab !== null &&
      selectedTab.type === 'query' &&
      !selectedTab?.created_at
    ) {
      fetchQuery({ query_id: selectedTab?.query_id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  return (
    <div
      data-label="query-tabs-container"
      className={classnames('relative overflow-hidden w-full pt-9', className)}
    >
      <div className="w-full absolute top-0 pt-[1px] overflow-y-hidden overflow-x-hidden hover:overflow-x-auto">
        <div className="flex items-center">
          {tabs.length && selectedTab
            ? tabs.map((el, i) => (
                <div
                  key={i}
                  className="h-9 group relative flex items-center border-b cursor-pointer"
                  style={{
                    width: 95 / tabs.length + '%',
                    minWidth: 70,
                    maxWidth: 170,
                  }}
                  onClick={(e) => handleClickTab(e, el)}
                >
                  <div
                    className={classnames('flex items-center px-2.5 truncate')}
                  >
                    <Typography
                      className={classnames('truncate pr-5', {
                        italic: el.temp,
                      })}
                      color="default-700"
                      weight="medium"
                      size="xs"
                    >
                      {el.name}
                      {el.deleted ? ' (deleted)' : ''}
                    </Typography>
                    {el.query_id === selectedTab.query_id ? (
                      <>
                        <div className="absolute h-1 -top-px -left-[1px] right-0 border-t border-l border-r rounded-t-md bg-red-100 border-red-100 z-10"></div>
                        <div className="absolute top-[3px] -bottom-[1px] -right-[9.5px] w-2.5 border-l border-b rounded-bl-md z-10"></div>
                        <div className="absolute top-[3px] -bottom-[1px] -left-2.5 w-2.5 border-r border-b rounded-br-md z-10"></div>
                        <div className="absolute -left-[3px] -right-[3px] -bottom-0.5 h-0.5 bg-white z-10"></div>
                      </>
                    ) : (
                      <>
                        <div className="absolute top-1.5 bottom-1.5 -right-0 w-0.5 border-r"></div>
                      </>
                    )}
                  </div>
                  {el.changed ? (
                    <Indicator
                      className="absolute right-[14px] w-[8px] h-[8px] bg-success"
                      variant={Indicator.variant.CIRCLE}
                    />
                  ) : null}
                  <IconButton
                    onClick={(e) => {
                      if (el.changed) {
                        showModal({
                          type: ModalTypes.ConfirmationCloseTab,
                          config: {
                            ...ModalOptions.ConfirmationCloseTab,
                          },
                          params: {
                            tab: el,
                            handleNotSave: handleCloseTab,
                            handleSave: saveChangesTabAfterClose,
                          },
                        })
                      } else {
                        handleCloseTab(e, el)
                      }
                    }}
                    className="absolute right-0 px-2.5 hidden group-hover:block z-10"
                  >
                    <CloseIcon
                      className="rounded-2 bg-white hover:bg-red-100"
                      color="secondary"
                    />
                  </IconButton>
                </div>
              ))
            : null}

          <div className="px-1.5 z-10">
            <IconButton disabled onClick={handleAddTab}>
              <PlusIcon
                className="rounded-2 hover:bg-default-100 mr-1.5"
                size="5"
                color="default-700"
              />
            </IconButton>
            <IconButton disabled className="p-0.5 hover:bg-default-200">
              <DotsHorizontalIcon
                // className="rounded-2 hover:bg-default-100"
                size="5"
                color="default-700"
              />
            </IconButton>
          </div>
        </div>
      </div>
      {loadingQuery ||
      loading ||
      (selectedTab?.type === 'query' && !selectedTab?.created_at) ? (
        <TabContentSkeleton />
      ) : tabs.length && selectedTab ? (
        <TabContent selectedTab={selectedTab} />
      ) : null}
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  tabs: getTabsState,
  selectedTab: getSelectedTabState,
  loadingQuery: getLoadingQueryState,
  loading: getLoadingState,
})

const mapDispatchToProps = {
  createQuery: queriesActions.createQuery,
  fetchQuery: queriesActions.fetchQuery,
  closeQuery: queriesActions.closeQuery,
  saveChangesTabAfterClose: queriesActions.saveChangesTabAfterClose,
  selectTab: queriesActions.selectTab,
  resetTempTab: queriesActions.resetTempTab,
  showModal: modalActions.showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryTabs)
