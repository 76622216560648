import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MainState } from './types'

const prefix = 'main'

const initialState: MainState = {
  value: 0,
  text: '',
}

const mainReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<string>) => {},
    didIncrement: (state, { payload }: PayloadAction<string>) => {
      state.value += 1
      state.text = payload
    },
    decrement: (state, action: PayloadAction<string>) => {},
    didDecrement: (state, { payload }: PayloadAction<string>) => {
      state.value -= 1
      state.text = payload
    },
    reset: (state) => initialState,
  },
})

export const mainActions = mainReducer.actions

export default mainReducer.reducer
