import { createSelector } from 'reselect'
import { RootState } from '../../app/store'

export const getMainListState = (state: RootState) => state.main

export const getValueState = createSelector(
  getMainListState,
  (state) => state.value
)

export const getTextState = createSelector(
  getMainListState,
  (state) => state.text
)
