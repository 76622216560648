import React, { FunctionComponent } from 'react'
import { ButtonsContainer } from './ButtonsContainer'
import DatasetDescription from './DatasetDescription'
import DatasetColumns from './DatasetColumns'

interface MainContainerProps {}

const MainContainer: FunctionComponent<MainContainerProps> = () => {
  return (
    <div>
      <div className="mt-t20">
        <DatasetDescription />
      </div>
      <ButtonsContainer />
      <DatasetColumns />
    </div>
  )
}

export default MainContainer
