import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { PlusIcon } from 'assets/general'
import { IconButton, InputSearch, LoaderWithOverlay, Typography } from 'shared'
import {
  getHoveredPathActiveState,
  getLoadingQueriesTreeState,
  getLoadingState,
} from '../selectors'
import { RootState } from 'app/store'
import { createStructuredSelector } from 'reselect'
import { queriesActions } from '../reducer'
import QueriesCatalogTreeContainer from './QueriesCatalogTreeContainer'
import QueriesTreeSkeleton from './skeletons/QueriesTreeSkeleton'

interface QueriesCatalogProps {
  className?: string
}

interface StateProps {
  hoveredPathActive: string | undefined
  loading: boolean
  loadingQueriesTree: boolean
}

type DispatchProps = typeof mapDispatchToProps

const QueriesCatalog: FunctionComponent<
  QueriesCatalogProps & StateProps & DispatchProps
> = ({
  createQueryCollection,
  className,
  hoveredPathActive,
  loading,
  loadingQueriesTree,
}) => {
  return (
    <div
      data-label="queries-catalog-container"
      className={classnames(
        'flex flex-col bg-default-50 rounded-5 pt-2.5',
        className
      )}
    >
      <div className="flex items-center overflow-x-auto mb-2.5 px-2.5">
        <div className="mr-2.5">
          <IconButton onClick={() => createQueryCollection()} className="hover:bg-default-200">
            <PlusIcon size="6" />
          </IconButton>
        </div>
        <InputSearch
          width="full"
          placeholder=""
          iconClass="top-1/2 -mt-2 w-4 h-4"
          className="grow shadow"
        />
      </div>
      <div className="flex items-center justify-end overflow-x-auto mb-2.5 px-2.5">
        {hoveredPathActive ? (
          <div className="px-1 rounded-sm bg-default-500">
            <Typography color="white-dark" size='xs'>{hoveredPathActive}</Typography>
          </div>
        ) : null}
      </div>
      <div className="grow relative">
        <div className="absolute top-0 left-0 overflow-y-auto h-full w-full">
          {loading ? (
            <QueriesTreeSkeleton />
          ) : (
            <>
              <LoaderWithOverlay loading={loadingQueriesTree} top="1/4" />
              <QueriesCatalogTreeContainer />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  hoveredPathActive: getHoveredPathActiveState,
  loading: getLoadingState,
  loadingQueriesTree: getLoadingQueriesTreeState,
})

const mapDispatchToProps = {
  createQueryCollection: queriesActions.createQueryCollection,
}

export default connect(mapStateToProps, mapDispatchToProps)(QueriesCatalog)
