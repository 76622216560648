import { makeRequest } from './http'

const api = {
  // Get Connections
  async getConnections() {
    const response = await makeRequest({
      path: '/connections',
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Create Connection
  async createConnection(data) {
    const response = await makeRequest({
      path: '/connections',
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Update Connection
  async updateConnection({ connection_id, data }) {
    const response = await makeRequest({
      path: `/connection/${connection_id}`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Connection
  async deleteConnection({ connection_id }) {
    const response = await makeRequest({
      path: `/connections/${connection_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Check Connection
  async checkConnection(data) {
    const response = await makeRequest({
      path: '/connections/check',
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },
}

export default api
