import React, { FunctionComponent } from 'react'
import PRootContainer from 'containers/Page/PRootContainer'
import HeaderContainer from './containers/HeaderContainer'
import MainContainer from './containers/MainContainer'

interface DatasetProps {}

const Dataset: FunctionComponent<DatasetProps> = () => {
  return (
    <PRootContainer data-label="dataset-container" className="px-t80 py-t20">
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

export default Dataset
