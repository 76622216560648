import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put } from 'redux-saga/effects'

import { mainActions } from './reducer'

function* handleCounterIncrement({ payload }: PayloadAction<any>) {
  try {
    yield put(mainActions.didIncrement(payload))
  } catch (error) {}
}

function* handleCounterDecrement({ payload }: PayloadAction<any>) {
  try {
    yield put(mainActions.didDecrement(payload))
  } catch (error) {}
}

export default function* watchMainSaga() {
  yield takeLatest(mainActions.increment.type, handleCounterIncrement)
  yield takeLatest(mainActions.decrement.type, handleCounterDecrement)
}
