import { FunctionComponent, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Typography } from 'shared'
import classnames from 'classnames'
import TableView from '../right-panel/TableView'
import { SavedParamsTypes, TableInputProps } from '../../types'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { jsonTypeToVariablesType } from '../right-panel/helpers'
import {
  getIsShowQueryParamsDrawerState,
  getQueryIdState,
  getSavedParamsItemState,
  getSavedParamsState,
  getSelectedParamsState,
} from '../../selectors'
import { queriesActions } from '../../reducer'
import { updateParamsLogic } from '../../utils'
import { useCallback } from 'react'
import { useEffect } from 'react'

interface QueryParamsDrawerProps {
  className?: string
}

type StateProps = {
  queryId: string
  savedParams: SavedParamsTypes[]
  selectedParams: string | null
  savedParamsItem: SavedParamsTypes
  isShowQueryParamsDrawer: boolean
}

type DispatchProps = typeof mapDispatchToProps

const QueryParamsDrawer: FunctionComponent<
  QueryParamsDrawerProps & StateProps & DispatchProps
> = ({
  className,
  queryId,
  isShowQueryParamsDrawer,
  checkChangedTab,
  savedParams,
  selectedParams,
  savedParamsItem,
}) => {
  const [inputValue, setInputValue] = useState<TableInputProps>({
    value: '',
    name: '',
  })
  const handleUpdateParams = useCallback(
    ({ value, name }: TableInputProps) => {
      checkChangedTab({
        query_id: queryId,
        data: {
          name: 'saved_params',
          value: updateParamsLogic(savedParams, selectedParams, name, value),
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryId, savedParams, selectedParams]
  )

  const handleChange = useCallback(({ value, name }: TableInputProps) => {
    setInputValue({ value, name })
  }, [])

  const transformQueryParams = useMemo(
    () => jsonTypeToVariablesType(savedParamsItem?.params || {}),
    [savedParamsItem]
  )

  const transformBodyParams = useMemo(
    () => jsonTypeToVariablesType(savedParamsItem?.body || {}),
    [savedParamsItem]
  )

  useEffect(() => {
    if (inputValue.name) handleUpdateParams(inputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <div
      className={classnames(
        'absolute z-40 top-0 shadow-l right-0 flex flex-col bg-white h-full overflow-auto', // ease-in-out transition-all duration-200
        `${isShowQueryParamsDrawer ? 'w-64 p-2.5' : 'w-0 p-0'}`,
        className
      )}
    >
      <div className="flex flex-col h-full">
        <div
          className={classnames('flex flex-col pb-2.5 h-50% w-full', {
            hidden: !isShowQueryParamsDrawer,
          })}
        >
          <div className="flex">
            <Typography
              className="mb-2 font-bold"
              size="sm"
              weight="medium"
              colorNew="text-default-400"
            >
              Query Params
            </Typography>
          </div>
          <TableView
            variables={transformQueryParams}
            setVariables={(value) => handleChange({ value, name: 'params' })}
            className={'h-full w-full overflow-auto'}
          />
        </div>
        <div className="flex flex-col h-50% w-full">
          <div className="flex">
            <Typography
              className="mb-2 font-bold"
              size="sm"
              weight="medium"
              colorNew="text-default-400"
            >
              Body Params
            </Typography>
          </div>
          <TableView
            variables={transformBodyParams}
            setVariables={(value) => handleChange({ value, name: 'body' })}
            className={'h-full w-full overflow-auto'}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  queryId: getQueryIdState,
  savedParams: getSavedParamsState,
  selectedParams: getSelectedParamsState,
  savedParamsItem: getSavedParamsItemState,
  isShowQueryParamsDrawer: getIsShowQueryParamsDrawerState,
})

const mapDispatchToProps = {
  checkChangedTab: queriesActions.checkChangedTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryParamsDrawer)
