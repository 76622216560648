import React from 'react'

import classnames from 'classnames'
import {
  SIZE_TYPES,
  WEIGHT_TYPES,
  COLOR_TYPES,
  textSize,
  fontWeight,
  textColor,
} from 'utils/theme'

type AlignLabel = 'top' | 'left' | 'right'

interface LabelProps {
  label: string | React.ReactNode
  children?: React.ReactNode
  alignLabel?: AlignLabel
  className?: string
  classNameLabel?: string
  style?: any
  size?: SIZE_TYPES
  weight?: WEIGHT_TYPES
  color?: COLOR_TYPES
  width?: string
}

const alignLabelClasses = (align: AlignLabel) => {
  switch (align) {
    case 'left':
      return { root: 'flex flex-row items-center space-x-2', label: '' }

    case 'right':
      return { root: 'flex flex-row-reverse items-center', label: 'ml-2.5' }

    default:
      return { root: '', label: 'mb-1.5' }
  }
}

export const Label: React.FunctionComponent<LabelProps> = ({
  children,
  className = '',
  classNameLabel = 'flex-none',
  label,
  size = 'md',
  weight = 'normal',
  color = 'default-900',
  alignLabel = 'top',
  width = 'full',
}) => {
  const classes = alignLabelClasses(alignLabel)

  return (
    <div
      data-label="label"
      className={classnames(`w-${width}`, classes.root, className)}
    >
      {typeof label === 'string' ? (
        <div
          className={classnames(
            'whitespace-nowrap',
            classes.label,
            textSize(size),
            fontWeight(weight),
            textColor(color),
            classNameLabel
          )}
        >
          {label}
        </div>
      ) : (
        <div className={classnames(classNameLabel)}>{label}</div>
      )}
      {children ? <div className="grow">{children}</div> : null}
    </div>
  )
}
