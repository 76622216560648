/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect } from 'react'
import { sliderActions } from './reducer'
import { connect } from 'react-redux'
import CreateDatasetStepper from './CreateDatasetStepper'

type DispatchProps = typeof mapDispatchToProps

const ManageDataset: FunctionComponent<DispatchProps> = ({ resetState }) => {
  useEffect(() => {
    return () => {
      resetState()
    }
  }, [])

  return <CreateDatasetStepper />
}

const mapDispatchToProps = {
  resetState: sliderActions.resetState,
}

export default connect(null, mapDispatchToProps)(ManageDataset)
