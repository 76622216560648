import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import PMainContainer from 'containers/Page/PMainContainer'
import DatasetItem from './DatasetItem'
import ManagePanel from './ManagePanel'
import { getDatasetsState, getLoadingState } from '../selectors'
import { RootState } from 'app/store'
import { createStructuredSelector } from 'reselect'
import { DatasetsItemType, LoadingType } from '../types'
import { NoFound } from 'containers/NoFound'
import { LoaderWithOverlay } from 'shared'
import DatasetInfo from './DatasetInfo'

interface MainContainerProps {
  idProject: string
}

type StateProps = {
  datasets: DatasetsItemType[]
  loading: LoadingType
}

const MainContainer: FunctionComponent<MainContainerProps & StateProps> = ({
  idProject,
  datasets,
  loading,
}) => {
  return (
    <div className="grid grid-cols-12 gap-6 h-full">
      <PMainContainer className="relative col-span-8 flex flex-col">
        <LoaderWithOverlay loading={loading.is} top="1/4" />
        <ManagePanel />
        {datasets && datasets.length ? (
          datasets.map((item) => (
            <DatasetItem
              key={item.dataset_id}
              idProject={idProject}
              item={item}
            />
          ))
        ) : (
          <NoFound name="No Datasets Found" />
        )}
      </PMainContainer>
      <PMainContainer className="col-span-4 relative">
        <LoaderWithOverlay loading={loading.is} top="1/4" />
        <DatasetInfo />
      </PMainContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  datasets: getDatasetsState,
  loading: getLoadingState,
})

export default connect(mapStateToProps)(MainContainer)
