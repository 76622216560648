/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Label, Input, Typography, Button, ButtonSimple } from 'shared'
import { connect } from 'react-redux'
import { CheckIcon, WarningIcon } from 'assets/general'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { connectionsActions } from 'pages/Connections/reducer'
import {
  getCheckedState,
  getConnectionTypeState,
  getLoadingState,
  getSelectedConnectionState,
} from 'pages/Connections/selectors'
import {
  getCredentialsObject,
  getCredentialsInputs,
  inputs,
  typeIcons,
} from 'pages/Connections/containers/drawer/config'
import {
  ConnectionsCredentialsTypeT,
  ConnectionsItemType,
  ConnectionsTypeTypeT,
  LoadingType,
} from 'pages/Connections/types'
import FileUploadComponent from '../components/FileUploadComponent'
import classnames from 'classnames'

const makeInitialValue = (
  type: ConnectionsTypeTypeT,
  params?: ConnectionsItemType
) => {
  let obj: any = {
    file: params?.file || null,
    name: params?.name || '',
    description: params?.description || '',
  }
  if (type) {
    obj = { ...obj, ...getCredentialsObject(type, params) }
  }
  return obj
}

interface CreateNewConnectionProps {
  className?: string
  cancel?: () => void
  create?: () => void
}

interface StateProps {
  connectionType: ConnectionsTypeTypeT
  checked: boolean
  loading: LoadingType
  selectedConnection: ConnectionsItemType | undefined
}

type DispatchProps = typeof mapDispathToProps

const CreateNewConnection: FC<
  CreateNewConnectionProps & StateProps & DispatchProps
> = ({
  className = '',
  connectionType,
  checked,
  loading,
  selectedConnection,
  selectConnectionType,
  createConnection,
  updateConnection,
  deleteConnection,
  checkConnection,
  didCheckConnection,
  selectConnection,
  cancel,
  create,
}) => {
  const [inputsValue, setInputsValue] = useState<{ [name: string]: string }>(
    () => makeInitialValue(connectionType, selectedConnection)
  )
  const connectionInputs = useMemo(
    () => [
      ...inputs,
      ...getCredentialsInputs(connectionType || selectedConnection?.type || ''),
    ],
    [connectionType, selectedConnection]
  )

  const handleChange = (value: any, name: string) => {
    changeInputsLogic(value, name)
  }

  const handleChangeFile = useCallback(
    (value: any) => changeInputsLogic(value, 'file'),
    []
  )

  const changeInputsLogic = (value: any, name: string) => {
    setInputsValue((prev: any) => ({ ...prev, [name]: value }))
    didCheckConnection(false)
  }

  useEffect(() => {
    setInputsValue(makeInitialValue(connectionType))
    if (checked) didCheckConnection(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionType])

  useEffect(() => {
    setInputsValue(
      makeInitialValue(selectedConnection?.type || '', selectedConnection)
    )
    if (checked) didCheckConnection(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection])

  const TypeIcon = typeIcons[connectionType]

  return (
    <div
      data-label="create-new-connection"
      className={classnames(
        'relative bg-white rounded shadow w-full p-t20',
        className
      )}
    >
      <div className="absolute right-t20 top-t20">
        {TypeIcon ? <TypeIcon width="50px" size={null} /> : null}
      </div>
      <Typography className="mb-t15" size="xl" weight="bold">
        {`${selectedConnection ? 'Update' : 'Create new'} connection`}
      </Typography>
      {connectionType === 'csv' ? (
        <div className="mb-t20">
          <Label className="mb-t20" label="CSV file" size="lg" weight="bold">
            <FileUploadComponent
              accept={{ 'text/csv': ['.csv'] }}
              type="file"
              setFile={handleChangeFile}
            />
          </Label>
        </div>
      ) : null}
      <div className="mb-t20 overflow-y-auto" style={{ maxHeight: '250px' }}>
        {connectionInputs.map((el, i) => (
          <Label
            key={i}
            className="mb-t20"
            label={el.label}
            size="lg"
            weight="bold"
          >
            <Input
              type={el.type}
              name={el.name}
              value={inputsValue[el.name]}
              placeholder={el.placeholder}
              error={false}
              onChange={(e: any) => handleChange(e.target.value, el.name)}
            />
          </Label>
        ))}
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-t10">
          {checked ? (
            <>
              <CheckIcon color="success" width="22px" size={null} />
              <Typography size="lg" color="success">
                Connection is verified!
              </Typography>
            </>
          ) : (
            <>
              <WarningIcon color="secondary" width="22px" size={null} />
              <Typography size="lg" color="secondary">
                Check connection
              </Typography>
            </>
          )}
        </div>
        <ButtonSimple
          className="flex h-H bg-secondary hover:bg-secondary-dark ml-t10 px-t10 rounded-3"
          loading={loading.name === 'check' && loading.is}
          disabled={loading.is}
          onClick={() => {
            let credentials: ConnectionsCredentialsTypeT = {}
            Object.keys(getCredentialsObject(connectionType)).forEach((cr) => {
              credentials[cr] = inputsValue[cr]
            })
            checkConnection({
              name: inputsValue.name,
              description: inputsValue.description,
              credentials,
              type: connectionType,
            })
          }}
        >
          <Typography size="md" color="white">
            Test Connection
          </Typography>
        </ButtonSimple>
      </div>
      <div className="flex flex-row items-center justify-between mt-t20">
        <Button
          color="default-500"
          onClick={() => {
            if (selectedConnection) selectConnection(undefined)
            if (connectionType) selectConnectionType('')
            if (cancel) cancel()
          }}
        >
          Cancel
        </Button>
        <div className="flex space-x-t10">
          {selectedConnection ? (
            <Button
              onClick={() => deleteConnection(selectedConnection.connection_id)}
            >
              Delete
            </Button>
          ) : null}
          <Button
            color="primary"
            disabled={!checked}
            onClick={() => {
              let credentials: ConnectionsCredentialsTypeT = {}
              Object.keys(getCredentialsObject(connectionType)).forEach(
                (cr) => {
                  credentials[cr] = inputsValue[cr]
                }
              )
              const obj = {
                name: inputsValue.name,
                description: inputsValue.description,
                credentials,
                type: connectionType || selectedConnection?.type || '',
                file: inputsValue.file,
              }
              if (selectedConnection) {
                updateConnection({
                  ...obj,
                  connection_id: selectedConnection.connection_id,
                })
              } else {
                createConnection(obj)
              }
              if (create) create()
            }}
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
  checked: getCheckedState,
  loading: getLoadingState,
  selectedConnection: getSelectedConnectionState,
})

const mapDispathToProps = {
  fetchConnections: connectionsActions.fetchConnections,
  createConnection: connectionsActions.createConnection,
  updateConnection: connectionsActions.updateConnection,
  deleteConnection: connectionsActions.deleteConnection,
  selectConnectionType: connectionsActions.selectConnectionType,
  checkConnection: connectionsActions.checkConnection,
  didCheckConnection: connectionsActions.didCheckConnection,
  selectConnection: connectionsActions.selectConnection,
}

export default connect(mapStateToProps, mapDispathToProps)(CreateNewConnection)
