import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'modal'

export interface ModalState {
  type: string | undefined
  isOpen?: boolean
  config?: any
  loading?: { is: boolean; name: string }
  params?: any
}

const initialState: ModalState = {
  isOpen: false,
  type: undefined,
  config: {},
  loading: { is: false, name: '' },
  params: {},
}

const modalReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    showModal: (state: ModalState, { payload }: PayloadAction<ModalState>) => {
      state.isOpen = true
      state.type = payload.type
      state.config = payload.config
      state.params = payload.params
    },
    hideModal: (state: ModalState) => {
      return { ...initialState }
    },
    startLoading: (state: ModalState, { payload }: PayloadAction<string>) => {
      state.loading = { is: true, name: payload }
    },
    stopLoading: (state: ModalState) => {
      state.loading = { is: false, name: '' }
    },
  },
})

export const modalActions = modalReducer.actions

export default modalReducer.reducer
