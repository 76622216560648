import {
  FC,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { isArray } from 'lodash'
import update from 'immutability-helper'

import { _NodeProps } from './TreeView'
import { SwitchCase } from 'shared/switch-case'
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import cn from 'classnames'

interface TreeNodeProps {
  index: number
  node: _NodeProps
  selectedNode: _NodeProps
  toggledData: _NodeProps[]
  dndData: _NodeProps[]
  memoryFields: any
  dragKey: string
  toNodeKey: string
  rootParentId?: string
  nodeCases?: any
  hoverFull?: boolean
  disabledDrag?: string[]
  moveCard?: (dragIndex: number, hoverIndex: number) => void
  updateTreeDnd: (props: any) => void
  onToggle: (node: _NodeProps) => void
  onSelect: (node: _NodeProps) => void
}

export const TreeNode: FunctionComponent<TreeNodeProps> = ({
  index,
  dragKey,
  toNodeKey,
  node,
  rootParentId,
  selectedNode,
  toggledData,
  memoryFields,
  nodeCases,
  hoverFull,
  dndData,
  disabledDrag = [],
  moveCard = () => {},
  updateTreeDnd,
  onToggle,
  onSelect,
}) => {
  const ref = useRef<any>(null)
  const [{ handlerId }, drop] = useDrop<
    any,
    void,
    { handlerId: Identifier | null }
  >({
    accept: dragKey,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: dragKey,
    item: () => ({ id: node.id, type: node.type, level: node.level, index }),
    end: (item, monitor) => {
      const indexes: number[] = []
      dragKey.split('/').forEach((d: any) => {
        const idx = d.split('_')[1]
        if (idx) indexes.push(Number(idx))
      })
      updateTreeDnd({ indexes, data: dndData })
    },
    canDrag: () => !disabledDrag.includes(node.type),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const handleToggle = (e: any) => {
    e.stopPropagation()
    onToggle(node)
  }

  const handleSelectNode = () => {
    onSelect(node)
  }

  // const handleClick = (e: any) => {
  //   if (!node.noSelect) {
  //     return handleSelectNode()
  //   } else {
  //     handleToggle(e)
  //   }
  // }

  const toggledNode = toggledData.find((d) => d.id === node.id)
  const toggled = toggledNode && toggledNode.toggled

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      className={cn('w-full relative', {
        'pointer-events-none opacity-75': node.disabled,
      })}
      style={{
        paddingLeft: `${!hoverFull && node.level ? 20 : 0}px`,
      }}
    >
      {isDragging ? (
        <div className="absolute z-10 inset-0 bg-yellow-50 border-2 border-dashed border-yellow-300"></div>
      ) : null}
      <SwitchCase value={node.type}>
        {nodeCases({
          node,
          toggled,
          selectedNode,
          rootParentId,
          memoryFields,
          dragKey,
          toNodeKey,
          onClick: handleToggle,
          onSelect: handleSelectNode,
        })}
      </SwitchCase>
      {toggled && isArray(node.items) ? (
        <Items
          dragKey={dragKey}
          toNodeKey={toNodeKey}
          index={index}
          node={node}
          memoryFields={memoryFields}
          hoverFull={hoverFull}
          selectedNode={selectedNode}
          toggledData={toggledData}
          dndData={dndData}
          nodeCases={nodeCases}
          rootParentId={rootParentId}
          disabledDrag={disabledDrag}
          updateTreeDnd={updateTreeDnd}
          onToggle={onToggle}
          onSelect={onSelect}
        />
      ) : null}
    </li>
  )
}

export const Items: FC<TreeNodeProps> = ({
  dragKey,
  toNodeKey,
  index,
  node: currentNode,
  memoryFields,
  hoverFull,
  selectedNode,
  toggledData,
  nodeCases,
  rootParentId,
  disabledDrag,
  updateTreeDnd,
  onToggle,
  onSelect,
}) => {
  const [itemsDnd, setItemsDnd] = useState<_NodeProps[]>(
    currentNode.items || []
  )

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setItemsDnd((prev: _NodeProps[]) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex] as _NodeProps],
        ],
      })
    )
  }, [])

  useEffect(() => {
    setItemsDnd(currentNode.items || [])
  }, [currentNode.items])

  if (!isArray(currentNode.items) || !currentNode) return null
  return (
    <ul>
      {itemsDnd.map((node: any, idx: number) => {
        const api = [memoryFields.apiPath, currentNode.api_path || ''].join('')
        return (
          <TreeNode
            key={node.id}
            dragKey={dragKey + '_' + index + '/' + node.type}
            toNodeKey={toNodeKey + '/' + node.type + '_' + idx}
            index={idx}
            rootParentId={rootParentId}
            node={node}
            disabledDrag={disabledDrag}
            moveCard={moveCard}
            memoryFields={{
              apiPath: api,
              breadcrumbs: [
                ...memoryFields.breadcrumbs,
                {
                  query_id: currentNode.id || currentNode.query_collection_id,
                  name: currentNode.name,
                  type: currentNode.type,
                  path_api: api,
                  api_path: currentNode.api_path,
                },
              ],
            }}
            hoverFull={hoverFull}
            nodeCases={nodeCases}
            selectedNode={selectedNode}
            toggledData={toggledData}
            dndData={itemsDnd}
            updateTreeDnd={updateTreeDnd}
            onToggle={onToggle}
            onSelect={onSelect}
          />
        )
      })}
    </ul>
  )
}
