import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { QueryParamsType, VariableProps } from '../../types'
import {
  checkСompliance,
  getUniqueName,
  isUniqueName,
  jsonTypeToVariablesType,
  uniqueName,
  variablesTypeToJsonType,
} from './helpers'

import { MonacoEditor, MONACO_LANGUAGES_TYPES } from 'shared'
import { tryStringToJSON } from 'utils/helpers'

interface JsonViewProps {
  variables: VariableProps[]
  setVariables: (variables: QueryParamsType) => void
  className: string
}

const JsonView: FunctionComponent<JsonViewProps> = ({
  variables = [],
  setVariables = () => null,
  className = '',
}) => {
  const [errorWords, setErrorWords] =
    useState<{ word: string; message: string }[]>()

  const value = useMemo(
    () => JSON.stringify(variablesTypeToJsonType(variables), null, 2),
    [variables]
  )

  const handleChange = useCallback(
    async (value: string) => {
      const json = tryStringToJSON(value)

      if (typeof json !== 'string') {
        const result = jsonTypeToVariablesType(json)

        const errWords: any[] = []

        const newVariables = result.map((el, i) => {
          const is = checkСompliance(el.name)

          if (!is) {
            errWords.push({
              word: el.name,
              message:
                'The variable name should only consist of letters, symbols "-" and "_"!',
            })
          }

          return {
            ...el,
            name: getUniqueName(
              is
                ? el.name
                : variables[i] && result.length === variables.length
                ? variables[i].name
                : uniqueName('field', 0),
              result,
              isUniqueName
            ),
          }
        })

        setVariables(variablesTypeToJsonType(newVariables))

        setErrorWords(errWords)
      }
    },
    [variables, setVariables]
  )

  return (
    <div className={className}>
      <MonacoEditor
        language={MONACO_LANGUAGES_TYPES.JSON}
        value={value}
        defaultValue={value}
        onChange={(value) => handleChange(value || '')}
        minimap={false}
        lineNumbers={false}
        errorWords={errorWords}
      />
    </div>
  )
}

export default JsonView
