import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Breadcrumb } from 'shared'
import { ParamsType, Routes } from 'utils/router'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'

interface HeaderContainerProps {}

const HeaderContainer: FunctionComponent<HeaderContainerProps> = () => {
  const { id, id_dataset } = useParams<ParamsType>()
  return (
    <>
      <PHeaderContainer>
        <PageName name="Projects" />
        <Button onClick={() => console.log('DOWNLOAD DICTIONARY')}>
          Download Dictionary
        </Button>
      </PHeaderContainer>
      <Breadcrumb
        paths={[
          { path: Routes.url.Projects(), name: 'Projects' },
          { path: `/projects/${id}`, name: `${id}` },
          {
            path: `/projects/${id}/datasets/${id_dataset}`,
            name: `${id_dataset}`,
          },
        ]}
      />
    </>
  )
}

export default HeaderContainer
