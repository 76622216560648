export const ModalTypes = {
  CreateNewProject: 'CreateNewProject',
  CreateNewDataset: 'CreateNewDataset',
  CreateNewCluster: 'CreateNewCluster',
  ConfirmationCloseTab: 'ConfirmationCloseTab',
  ConfirmationDelete: 'ConfirmationDelete',
  CreateWorkspace: 'CreateWorkspace',
  TreeMove: 'TreeMove',
}

export const ModalOptions = {
  [ModalTypes.CreateNewProject]: {
    headerName: 'Create new project',
  },
  [ModalTypes.CreateNewDataset]: {
    headerName: 'Create new dataset',
  },
  [ModalTypes.CreateNewCluster]: {
    headerName: 'Create new cluster',
  },
  [ModalTypes.ConfirmationCloseTab]: {
    headerName: 'Do you want to save?',
  },
  [ModalTypes.CreateWorkspace]: {
    headerName: 'Create new workspace',
  },
  [ModalTypes.TreeMove]: {
    headerName: 'Move item to nested tree',
  },
}
