import {
  ConnectionsItemType,
  CreateConnectionPayload,
  DeleteConnectionPayloadT,
  UpdateConnectionPayload,
} from './types'
import { connectionsService } from 'utils/services'
import { connectionsActions } from './reducer'
import { call, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { notificationActions } from 'containers/Notification/reducer'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { v4 as uuidv4 } from 'uuid'

export const connections: ConnectionsItemType[] = [
  {
    connection_id: '0',
    owner_id: 'owner_id',
    name: 'Connection  clickhouse',
    description: 'Connection clickhouse description',
    status: 'active',
    type: 'clickhouse',
    credentials: {
      url: 'http://qwerty.clickhouse.com',
      user: 'Clickhouse user',
      password: 'clickhouse_pass',
    },
    config: {},
    props: {},
    tables: [
      {
        id: '0',
        name: 'first_table',
        data: [
          {
            MonthlyIncome: 100000,
            NumberOfDependents: 4,
            NumberOfOpenCreditLinesAndLoans: 130,
            'NumberOfTime30-59DaysPastDueNotWorse': 11,
            'NumberOfTime60-89DaysPastDueNotWorse': 10,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 46,
            RevolvingUtilizationOfUnsecuredLines: 0.7440000001,
            SeriousDlqin2yrs: 16,
            age: 58,
            effort: '(0.05, 0.043544]',
          },
          {
            MonthlyIncome: 2.0,
            NumberOfDependents: 155.0,
            NumberOfOpenCreditLinesAndLoans: 73,
            'NumberOfTime30-59DaysPastDueNotWorse': 10,
            'NumberOfTime60-89DaysPastDueNotWorse': 10,
            NumberOfTimes90DaysLate: 5,
            NumberRealEstateLoansOrLines: 0,
            RevolvingUtilizationOfUnsecuredLines: 0.577790000001,
            SeriousDlqin2yrs: 555,
            age: 406,
            effort: '(0.9, 0.4444434]',
          },
        ],
      },
      {
        id: '1',
        name: 'second_table',
        data: [
          {
            MonthlyIncome: 9120.0,
            NumberOfDependents: 2.0,
            NumberOfOpenCreditLinesAndLoans: 13,
            'NumberOfTime30-59DaysPastDueNotWorse': 2,
            'NumberOfTime60-89DaysPastDueNotWorse': 0,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 6,
            RevolvingUtilizationOfUnsecuredLines: 0.7661266090000001,
            SeriousDlqin2yrs: 1,
            age: 45,
            effort: '(0.00649, 0.04]',
          },
          {
            MonthlyIncome: 2600.0,
            NumberOfDependents: 1.0,
            NumberOfOpenCreditLinesAndLoans: 4,
            'NumberOfTime30-59DaysPastDueNotWorse': 0,
            'NumberOfTime60-89DaysPastDueNotWorse': 0,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 0,
            RevolvingUtilizationOfUnsecuredLines: 0.9571510190000001,
            SeriousDlqin2yrs: 0,
            age: 40,
            effort: '(0.000309, 0.00134]',
          },
        ],
      },
    ],
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
  {
    connection_id: '11',
    owner_id: 'owner_id',
    name: 'Connection  clickhouse 11',
    description: 'Connection clickhouse 11 description',
    status: 'active',
    type: 'clickhouse',
    credentials: {
      url: 'http://qwerty.clickhouse.com',
      user: 'Clickhouse user',
      password: 'clickhouse_pass',
    },
    config: {},
    props: {},
    tables: [
      {
        id: '0',
        name: 'first_table_11',
        data: [
          {
            MonthlyIncome: 100000,
            NumberOfDependents: 2.0,
            NumberOfOpenCreditLinesAndLoans: 13,
            'NumberOfTime30-59DaysPastDueNotWorse': 2,
            'NumberOfTime60-89DaysPastDueNotWorse': 0,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 6,
            RevolvingUtilizationOfUnsecuredLines: 0.7661266090000001,
            SeriousDlqin2yrs: 1,
            age: 45,
            effort: '(0.00649, 0.04]',
          },
          {
            MonthlyIncome: 2600.0,
            NumberOfDependents: 1.0,
            NumberOfOpenCreditLinesAndLoans: 4,
            'NumberOfTime30-59DaysPastDueNotWorse': 0,
            'NumberOfTime60-89DaysPastDueNotWorse': 0,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 0,
            RevolvingUtilizationOfUnsecuredLines: 0.9571510190000001,
            SeriousDlqin2yrs: 0,
            age: 40,
            effort: '(0.000309, 0.00134]',
          },
        ],
      },
      {
        id: '1',
        name: 'second_table_11',
        data: [
          {
            MonthlyIncome: 9120.0,
            NumberOfDependents: 2.0,
            NumberOfOpenCreditLinesAndLoans: 13,
            'NumberOfTime30-59DaysPastDueNotWorse': 2,
            'NumberOfTime60-89DaysPastDueNotWorse': 0,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 6,
            RevolvingUtilizationOfUnsecuredLines: 0.7661266090000001,
            SeriousDlqin2yrs: 1,
            age: 45,
            effort: '(0.00649, 0.04]',
          },
          {
            MonthlyIncome: 2600.0,
            NumberOfDependents: 1.0,
            NumberOfOpenCreditLinesAndLoans: 4,
            'NumberOfTime30-59DaysPastDueNotWorse': 0,
            'NumberOfTime60-89DaysPastDueNotWorse': 0,
            NumberOfTimes90DaysLate: 0,
            NumberRealEstateLoansOrLines: 0,
            RevolvingUtilizationOfUnsecuredLines: 0.9571510190000001,
            SeriousDlqin2yrs: 0,
            age: 40,
            effort: '(0.000309, 0.00134]',
          },
        ],
      },
    ],
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
  {
    connection_id: '1',
    owner_id: 'owner_id',
    name: 'Connection postgresql',
    description: 'Connection postgresql description',
    status: 'inactive',
    type: 'postgresql',
    credentials: {
      host: 'something.host',
      port: '4321',
      user: 'GCP user',
      password: 'GCP_pass',
    },
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
  {
    connection_id: '2',
    owner_id: 'owner_id',
    name: 'Connection mysql',
    description: 'Connection mysql description',
    status: 'active',
    type: 'mysql',
    credentials: {},
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
  {
    connection_id: '3',
    owner_id: 'owner_id',
    name: 'Connection google_sheets',
    description: 'Connection google_sheets description',
    status: 'active',
    type: 'google_sheets',
    credentials: {},
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
  {
    connection_id: '4',
    owner_id: 'owner_id',
    name: 'Connection json',
    description: 'Connection json description',
    status: 'active',
    type: 'json',
    credentials: {},
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
  {
    connection_id: '5',
    owner_id: 'owner_id',
    name: 'Connection csv',
    description: 'Connection csv description',
    status: 'inactive',
    type: 'csv',
    credentials: {},
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
  },
]

function* handleFetchConnections() {
  try {
    yield call(connectionsService.getConnections)
    yield put(connectionsActions.didFetchConnections(connections))
  } catch (error) {
    yield put(connectionsActions.didFetchConnections(connections))
    // yield put(connectionsActions.didFetchClustersFail())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleCreateConnection({
  payload,
}: PayloadAction<CreateConnectionPayload>) {
  try {
    yield put(connectionsActions.startLoading('create'))
    // yield call(connectionsService.createConnection, payload)
    const obj = {
      ...connections[0],
      ...payload,
      connection_id: uuidv4(),
    }
    yield put(connectionsActions.didCreateConnection(obj))
    yield put(connectionsActions.selectConnectionType(''))
    yield put(connectionsActions.selectConnection(obj))
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Connection created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Connection not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateConnection({
  payload,
}: PayloadAction<UpdateConnectionPayload>) {
  try {
    yield put(connectionsActions.startLoading('update'))
    // yield call(connectionsService.updateConnection, payload)
    yield put(connectionsActions.didUpdateConnection(payload))
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Connection updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Connection not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteConnection({
  payload,
}: PayloadAction<DeleteConnectionPayloadT>) {
  try {
    yield put(connectionsActions.startLoading('delete'))
    // yield call(connectionsService.deleteConnection, payload)
    yield put(connectionsActions.selectConnection(undefined))
    yield put(connectionsActions.didDeleteConnection(payload))
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Connection deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Connection not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleCheckConnection({
  payload,
}: PayloadAction<CreateConnectionPayload>) {
  try {
    yield put(connectionsActions.startLoading('check'))
    yield call(connectionsService.checkConnection, payload)
    yield put(connectionsActions.didCheckConnection(true))
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Verification passed successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(connectionsActions.didCheckConnection(true)) // TODO false
    yield put(connectionsActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Verification failed!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

export default function* watchConnectionsSaga() {
  yield takeLatest(
    connectionsActions.fetchConnections.type,
    handleFetchConnections
  )
  yield takeLatest(
    connectionsActions.createConnection.type,
    handleCreateConnection
  )
  yield takeLatest(
    connectionsActions.updateConnection.type,
    handleUpdateConnection
  )
  yield takeLatest(
    connectionsActions.deleteConnection.type,
    handleDeleteConnection
  )
  yield takeLatest(
    connectionsActions.checkConnection.type,
    handleCheckConnection
  )
}
