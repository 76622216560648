import PRootContainer from 'containers/Page/PRootContainer'
import { FC } from 'react'

export const DefaultResources: FC = () => {
  return (
    <PRootContainer
      data-label="default-resources-container"
      className="flex flex-col h-full px-20 py-5"
    >
      <div className="flex flex-col items-center justify-center">
        <p>You have not selected a workspace.</p>{' '}
        <p>Please, select or create a workspace for further work.</p>
      </div>
    </PRootContainer>
  )
}
