import _ from './constants'

class User {
  isAuth() {
    return _.STORAGE.getItem(_.SESSION_TOKEN)
  }

  login(data: any) {
    if (data) {
      _.STORAGE.setItem(_.SESSION_TOKEN, data.token)
      _.STORAGE.setItem(_.USER_INFO, JSON.stringify(data))
    }
  }

  logout() {
    _.STORAGE.removeItem(_.SESSION_TOKEN)
    _.STORAGE.removeItem(_.USER_INFO)

    // storage.clean()
  }

  get info() {
    return _.STORAGE.getItem(_.USER_INFO)
  }
}

export default new User()
