import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkspaceId } from 'containers/Sidebar/types'
import { cloneDeep } from 'lodash'
import { IResources, ResourceId } from 'pages/Resources/types'
import {
  ICreateTableData,
  IResourceState,
  ITableColumn,
  IResourceTableData,
  ITableData,
  IUpdateTableColumn,
  IUpdateTablePayload,
  IUpdateTableData,
} from './types'

const prefix = 'resource'

const initialState: IResourceState = {
  resource: null,
  selectedTable: null,
  tableData: {
    data: [],
    columns: [],
  },
}

const resourceReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchResource: (
      state,
      action: PayloadAction<{
        workspace_id?: WorkspaceId
        resource_id: ResourceId
      }>
    ) => {},
    didFetchResource: (state, { payload }: PayloadAction<IResources>) => {
      state.resource = payload || null
    },
    createTable: (state, action: PayloadAction<string>) => {},
    didCreateTable: (
      state,
      { payload }: PayloadAction<{ data: string[]; table_name: string }>
    ) => {
      if (state.resource) state.resource.tables = payload.data
      state.selectedTable = payload.table_name
    },
    updateTable: (state, action: PayloadAction<IUpdateTablePayload>) => {},
    didUpdateTable: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: string[]
        table_name: string
        new_table_name: string
      }>
    ) => {
      if (state.resource) state.resource.tables = payload.data
      if (state.selectedTable === payload.table_name)
        state.selectedTable = payload.new_table_name
    },
    deleteTable: (state, action: PayloadAction<string>) => {},
    didDeleteTable: (
      state,
      { payload }: PayloadAction<{ data: string[]; table_name: string }>
    ) => {
      if (state.resource) state.resource.tables = payload.data
      if (state.selectedTable === payload.table_name) {
        state.selectedTable = initialState.selectedTable
        state.tableData = initialState.tableData
      }
    },
    setSelectedTable: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedTable = payload
    },

    // COLUMNS

    createTableColumn: (state, action: PayloadAction<ITableColumn>) => {},
    updateTableColumn: (state, action: PayloadAction<IUpdateTableColumn>) => {},
    deleteTableColumn: (state, action: PayloadAction<string>) => {},
    didUpdateTableColumn: (
      state,
      { payload }: PayloadAction<ITableColumn[]>
    ) => {
      state.tableData.columns = payload
    },

    // DATA

    fetchTableData: (state) => {},
    didFetchTableData: (
      state,
      { payload }: PayloadAction<IResourceTableData>
    ) => {
      state.tableData = {
        ...payload,
        data: payload.data.sort((a: ITableData, b: ITableData) => a.id - b.id),
      }
    },

    createTableData: (state, action: PayloadAction<ICreateTableData>) => {},
    didCreateTableData: (state, { payload }: PayloadAction<ITableData[]>) => {
      state.tableData.data = [...state.tableData.data, ...payload].sort(
        (a, b) => a.id - b.id
      )
    },
    updateTableData: (state, action: PayloadAction<IUpdateTableData>) => {},
    didUpdateTableData: (state, { payload }: PayloadAction<ITableData[]>) => {
      if (payload.length) {
        const idx = state.tableData.data.findIndex(
          (d: ITableData) => d.id === payload[0].id
        )
        if (idx !== -1) state.tableData.data.splice(idx, 1, payload[0])
      }
    },
    deleteTableData: (state, action: PayloadAction<string[] | number[]>) => {},
    didDeleteTableData: (
      state,
      { payload }: PayloadAction<string[] | number[]>
    ) => {
      const data = cloneDeep(state.tableData.data) // TODO may be update state.tableData without cloneDeep
      payload.forEach((id) => {
        const idx = data.findIndex((d: ITableData) => d.id === id)
        if (idx !== -1) data.splice(idx, 1)
      })
      state.tableData.data = data
    },
    resetResource: () => {
      return initialState
    },
  },
})

export const resourceActions = resourceReducer.actions

export default resourceReducer.reducer
