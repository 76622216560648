import { makeRequest } from './http'

const api = {
  // Get Queries
  async getQueries(workspace_id) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Create New Query
  async createQuery({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries`,
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Get Query
  async getQuery({ workspace_id, query_id }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries/${query_id}`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Update Query
  async updateQuery({ workspace_id, query_id, data = {} }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries/${query_id}`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  //  Update multiple queries
  async updateQueries({ workspace_id, data = [] }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Query
  async deleteQuery({ workspace_id, query_id }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries/${query_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Multiple Queries
  async deleteMultipleQueries({ workspace_id, data = {} }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries/delete_multiple`,
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Run Query
  async runQuery({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/queries/run`,
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Run Api
  async runApi({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/run/${workspace_id}${data.url}`,
      method: data.method,
      params: JSON.stringify(data.params),
      headers: data.headers || {},
    })
    return response
  },

  // Get all query collections
  async getQueryCollections(workspace_id) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/query_collections`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Create new query collection
  async createQueryCollection({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/query_collections`,
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Update query collection
  async updateQueryCollection({ workspace_id, collection_id, data }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/query_collections/${collection_id}`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete query collection
  async deleteQueryCollection({ workspace_id, collection_id }) {
    const response = await makeRequest({
      path: `/workspace/${workspace_id}/query_collections/${collection_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },
}

export default api
