import { FunctionComponent } from 'react'
import { Button, Typography } from 'shared'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ModalContentContainer from 'containers/Modal/ModalContentContainer'
import ModalFooterContainer from 'containers/Modal/ModalFooterContainer'
import { RootState } from 'app/store'
import {
  getModalLoadingState,
  getModalParamsState,
} from 'containers/Modal/selectors'

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const ConfirmationDelete: FunctionComponent<SelectedProps & DispatchProps> = ({
  loading,
  params,
  hideModal,
}) => {
  const { tab } = params
  return (
    <div data-label="modal-confirmation-close-tab">
      <ModalContentContainer className="grid grid-cols-12">
        <div className="flex flex-col items-start col-span-10">
          <Typography className="leading-4" size="md">
            Are you sure you want to delete{' '}
            <span className="font-bold">{tab.name}</span>?
          </Typography>
        </div>
        <div className="col-span-2"></div>
      </ModalContentContainer>
      <ModalFooterContainer>
        <div></div>
        <div className="flex space-x-4">
          <Button variant="OUTLINED" color="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            loading={loading.name === 'delete' && loading.is}
            disabled={loading.is}
            onClick={() => {
              params.handleDelete(tab)
            }}
          >
            Delete
          </Button>
        </div>
      </ModalFooterContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  loading: getModalLoadingState,
  params: getModalParamsState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDelete)
