import { FunctionComponent } from 'react'
import { Typography } from 'shared'

interface PageNameProps {
  name: string
}

const PageName: FunctionComponent<PageNameProps> = ({ name }) => {
  return (
    <Typography size="xl" weight="medium">
      {name}
    </Typography>
  )
}

export default PageName
