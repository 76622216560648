import { FC } from 'react'
import PMainContainer from 'containers/Page/PMainContainer'
import { IWorkspace } from 'containers/Sidebar/types'

interface MainContainerProps {
  workspace: IWorkspace | undefined
}

export const MainContainer: FC<MainContainerProps> = ({ workspace }) => {
  return (
    <PMainContainer className="h-full">{workspace?.description}</PMainContainer>
  )
}
