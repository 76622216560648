import React, { FunctionComponent } from 'react'
import { PageTypes } from './types'
import classnames from 'classnames'

const PageLayout: FunctionComponent<PageTypes> = ({
  children,
  className = '',
}) => {
  return (
    <div
      data-label="root-page-container"
      className={classnames('overflow-hidden flex flex-1', className)}
    >
      {children}
    </div>
  )
}

export default PageLayout
