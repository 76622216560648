/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect } from 'react'
import { connectionsConfig } from 'pages/Connections/containers/drawer/config'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { connectionsActions } from 'pages/Connections/reducer'
import { getConnectionTypeState } from 'pages/Connections/selectors'
import { RootState } from 'app/store'
import { createStructuredSelector } from 'reselect'
import { ConnectionsTypeTypeT } from 'pages/Connections/types'
import { sliderActions } from '../reducer'

interface StateProps {
  connectionType: ConnectionsTypeTypeT
}

type DispatchProps = typeof mapDispathToProps

const DBSelection: FunctionComponent<StateProps & DispatchProps> = ({
  connectionType,
  selectConnectionType,
  resetState,
}) => {
  useEffect(() => {
    resetState()
  }, [])

  return (
    <div className="grow mt-t20">
      <div className="grid grid-cols-5 gap-t20">
        {connectionsConfig
          .filter((d) => d.enabled)
          .map((el, i) => (
            <div
              key={i}
              className={classnames(
                'flex justify-center rounded bg-white border-2 cursor-pointer py-t20',
                { 'border-secondary': connectionType === el.name }
              )}
              onClick={() => selectConnectionType(el.name)}
            >
              <el.Icon width="70px" height="70px" size={null} color="black" />
            </div>
          ))}
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
})

const mapDispathToProps = {
  selectConnectionType: connectionsActions.selectConnectionType,
  resetState: sliderActions.resetState,
}

export default connect(mapStateToProps, mapDispathToProps)(DBSelection)
