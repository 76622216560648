import React, { Fragment, FC } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import cn from 'classnames'
import { ChevronDownIcon, CloseIcon } from 'assets/general'
import { bgColor, COLOR_TYPES, SIZE_TYPES } from 'utils/theme'

export interface SelectValueType {
  id: number | string
  name: string
  title: string
  component?: (
    value: SelectValueType,
    i: number,
    options: Array<SelectValueType>
  ) => React.ReactNode
}

const SIZE_MAPS: Record<SIZE_TYPES, string> = {
  xs: 'w-24',
  sm: 'w-32',
  md: 'w-40',
  md_plus: 'w-44',
  lg: 'w-48',
  xl: 'w-56',
  '2xl': 'w-64',
  '3xl': 'w-64',
  '4xl': 'w-64',
}

interface SelectProps {
  options: Array<SelectValueType>
  value?: SelectValueType | undefined
  onChange?: (e: any) => void
  classes?: {
    root?: string
    label?: string
    selected?: string
    buttonTitle?: string
    options?: string
    option?: string
  }
  label?: string
  placeholder?: string
  labelComponent?: React.ReactNode
  selectedComponent?: (
    value: SelectValueType,
    options?: Array<SelectValueType>
  ) => React.ReactNode
  size?: number | SIZE_TYPES | 'full-width'
  color?: COLOR_TYPES
  disabled?: boolean
}

export const Select: FC<SelectProps> = ({
  classes = {},
  label,
  value,
  labelComponent,
  selectedComponent,
  options,
  size = 'md',
  color = 'default-200',
  disabled = false,
  placeholder = '',
  onChange = () => {},
}) => {
  const defaultOption = {
    id: -1,
    name: '',
    title: 'Select',
  }

  const handleChange = (value: SelectValueType | undefined) => {
    onChange(value)
  }

  if (placeholder) defaultOption.title = placeholder

  return (
    <Listbox value={value || defaultOption} onChange={handleChange}>
      {({ open }) => (
        <div
          className={cn(
            classes.root,
            typeof size === 'string'
              ? size === 'full-width'
                ? 'w-full'
                : SIZE_MAPS[size]
              : ''
          )}
          {...(typeof size === 'number'
            ? {
                style: {
                  width: size,
                },
              }
            : {})}
        >
          <Listbox.Label
            className={cn('block text-sm', classes.label)}
          >
            {labelComponent || label}
          </Listbox.Label>

          <div className="relative">
            <Listbox.Button
              tabIndex={-1}
              className={cn(
                'relative w-full h-H sm:text-sm border-0 border-default-300 rounded px-4 text-left focus:border-primary',
                { disabled: disabled },
                bgColor(color),
                classes.selected
              )}
            >
              {selectedComponent ? (
                selectedComponent(value || defaultOption)
              ) : (
                <span
                  className={cn(
                    'block cursor-text',
                    value ? 'text-default-900' : 'text-default-600',
                    classes.buttonTitle
                  )}
                >
                  {value ? value.title : defaultOption.title}
                </span>
              )}
              {value ? (
                <span className="absolute inset-y-0 right-4 flex items-center pr-2.5 cursor-pointer">
                  <CloseIcon
                    color="secondary"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      handleChange(undefined)
                    }}
                  />
                </span>
              ) : null}
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2.5 cursor-pointer">
                <ChevronDownIcon size="sm"/>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className={cn(
                  'absolute z-40 mt-1 w-full bg-white shadow-lg max-h-36 rounded py-1 text-lg ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                  classes.options
                )}
              >
                {options.map((op, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active, selected }) =>
                      cn(
                        active || selected
                          ? 'text-default-900 bg-default-100'
                          : '',
                        'cursor-pointer text-left select-none relative py-2 px-3',
                        classes.option
                      )
                    }
                    value={op}
                  >
                    {op.component ? (
                      op.component(op, i, options)
                    ) : (
                      <span className={cn('block truncate')} title={op.title}>
                        {op.title}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
