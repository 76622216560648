import { FC } from 'react'
import { connect } from 'react-redux'
import { Avatar, IconButton, InputSearch, MenuNew, Typography } from 'shared'
import { signInActions } from 'pages/SignIn/reducer'
import {
  MessageIcon,
  BellIcon,
  LogoutIcon,
  /*ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClockIcon,
  DataCatalogIcon,
  GridIcon,
  LinkIcon,
  MailIcon,
  MenuIcon,
  MenuVerticalDotIcon,
  PaperClipIcon,
  PlusIcon,
  QueriesIcon,
  SearchIcon,
  SelectorIcon,
  UserIcon,
  ViewDocsIcon,
  ViewListIcon,
  WarningIcon,*/
} from 'assets/general'
import { Link, useLocation } from 'react-router-dom'
import { Routes } from 'utils/router'
import cn from 'classnames'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSelectedWorkspaceState } from 'containers/Sidebar/selectors'

type SelectProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const Header: FC<SelectProps & DispatchProps> = ({
  selectedWorkspace,
  signOut,
}) => {
  const { pathname } = useLocation()
  return (
    <div
      data-label="header-container"
      className="header flex justify-between px-20 py-2 text-right shadow"
    >
      <nav data-label="header-nav" className="space-x-4">
        <Link
          to={
            selectedWorkspace
              ? Routes.url.Workspace(selectedWorkspace)
              : Routes.url.DefaultWorkspaces()
          }
          className={cn(
            'inline-flex items-center px-1 pt-1 border-b-2',
            [
              Routes.url.DefaultWorkspaces(),
              Routes.url.Workspace(selectedWorkspace),
            ].includes(pathname)
              ? 'border-secondary'
              : 'border-white'
          )}
        >
          <Typography
            className={cn('group-hover:opacity-100 duration-200')}
            size="md"
          >
            Overview
          </Typography>
        </Link>
        <Link
          to={
            selectedWorkspace
              ? Routes.url.Resources(selectedWorkspace)
              : Routes.url.DefaultResources()
          }
          className={cn(
            'inline-flex items-center px-1 pt-1 border-b-2',
            pathname.includes(Routes.url.DefaultResources())
              ? 'border-secondary'
              : 'border-white'
          )}
        >
          <Typography
            className={cn('group-hover:opacity-100 duration-200')}
            size="md"
          >
            Resources
          </Typography>
        </Link>
        <Link
          to={
            selectedWorkspace
              ? Routes.url.Queries(selectedWorkspace)
              : Routes.url.DefaultQueries()
          }
          className={cn(
            'inline-flex items-center px-1 pt-1 border-b-2',
            pathname.includes(Routes.url.DefaultQueries())
              ? 'border-secondary'
              : 'border-white'
          )}
        >
          <Typography
            className={cn('group-hover:opacity-100 duration-200')}
            size="md"
          >
            Queries
          </Typography>
        </Link>
      </nav>

      <InputSearch width="t300" color="default-100" />

      <div className="flex items-center">
        {/* <IconButton className="mr-4">
          <BellIcon size={null} width={18} />
        </IconButton>
        <IconButton className="mr-8">
          <MessageIcon size={null} width={18} />
        </IconButton> */}
        <MenuNew
          items={[
            {
              label: 'Logout',
              iconComponent: LogoutIcon,
              onClick: () => signOut(),
            },
          ].filter(Boolean)}
        >
          <div className="flex items-center cursor-pointer">
            <Typography className="mr-md" size="md">
              Queryable Demo
            </Typography>
            <Avatar size="H" />
          </div>
        </MenuNew>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  selectedWorkspace: getSelectedWorkspaceState,
})

const mapDispatchToProps = {
  signOut: signInActions.signOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
