import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import PRootContainer from 'containers/Page/PRootContainer'
import HeaderContainer from './containers/HeaderContainer'
import MainContainer from './containers/MainContainer'
import { dataCatalogActions } from './reducer'

type DispatchProps = typeof mapDispatchToProps

const DataCatalog: FunctionComponent<DispatchProps> = ({ fetchDatasets }) => {
  useEffect(() => {
    fetchDatasets()
  }, [fetchDatasets])

  return (
    <PRootContainer
      data-label="project-container"
      className="flex flex-col h-full px-t80 py-t20"
    >
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchDatasets: dataCatalogActions.fetchDatasets,
}

export default connect(null, mapDispatchToProps)(DataCatalog)
