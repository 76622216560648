import { createSelector } from 'reselect'
import { RootState } from 'app/store'

export const getSliderListState = (state: RootState) =>
  state.project.sliderReducer

export const getSelectedConnectionsState = createSelector(
  getSliderListState,
  (state) => state.selectedConnections
)

export const getSelectedConnectionIdState = createSelector(
  getSliderListState,
  (state) => state.selectedConnectionId
)

export const getSelectedTablesState = createSelector(
  getSliderListState,
  (state) => state.selectedTables
)

export const getSelectedConnectionState = (connection_id?: string | number) => {
  return createSelector(
    getSelectedConnectionsState,
    getSelectedConnectionIdState,
    (connections, id) =>
      connections.filter(
        (connection) => connection.connection_id === (id || connection_id)
      )[0] || {}
  )
}

export const getPreviewDataState = createSelector(
  getSliderListState,
  (state) => state.previewData
)

export const getPreviewDataMemoryState = createSelector(
  getSliderListState,
  (state) => state.previewDataMemory
)

export const getSchemeDataState = createSelector(
  getSliderListState,
  (state) => state.schemeData
)

export const getIsShowPreviewDataState = createSelector(
  getSliderListState,
  (state) => state.isShowPreviewData
)

export const getIsShowConnectionPopoverState = createSelector(
  getSliderListState,
  (state) => state.isShowConnectionPopover
)

export const getActiveStepState = createSelector(
  getSliderListState,
  (state) => state.activeStep
)
