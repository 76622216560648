import { FC, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { getNotificationState } from './selectors'
import { notificationActions, NotificationState } from './reducer'
import { createStructuredSelector } from 'reselect'
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { RootState } from 'app/store'

type PropsFromRedux = ConnectedProps<typeof connector>

interface NotificationProps {}

const Notification: FC<NotificationProps & PropsFromRedux> = ({
  notification,
  hideNotification,
}) => {
  useEffect(() => {
    if (notification)
      toast(notification.message, {
        ...notification.options,
        onClose: hideNotification,
      })
  }, [notification, hideNotification])

  return (
    <ToastContainer
      autoClose={2000}
      hideProgressBar={true}
      position="top-center"
    />
  )
}

const mapStateToProps = createStructuredSelector<RootState, NotificationState>({
  notification: getNotificationState,
})

const mapDispatchToProps = {
  hideNotification: notificationActions.hideNotification,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Notification)
