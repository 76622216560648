/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { IconButton, Menu, RTable, Typography } from 'shared'
import classnames from 'classnames'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getResourcesState } from '../selectors'
import { IResources } from '../types'
import { resourcesActions } from '../reducer'
import { slideOverActions } from 'containers/SlideOver/reducer'
import {
  SlideOverOptions,
  SlideOverTypes,
} from 'containers/SlideOver/constants'
import { pushRoute, Routes } from 'utils/router'
import { getIcon } from 'utils/helpers'
import { getSelectedWorkspaceState } from 'containers/Sidebar/selectors'
import { SelectedWorkspace } from 'containers/Sidebar/types'
import { DotsVerticalIcon } from '@heroicons/react/outline'

interface StateProps {
  resources: IResources[]
  selectedWorkspace: SelectedWorkspace
}

type DispatchProps = typeof mapDispatchToProps

const ResourcesTable: FC<StateProps & DispatchProps> = ({
  resources,
  selectedWorkspace,
  deleteResource,
  showSlideOver,
}) => {
  const handleDelete = (rowData: any) =>
    deleteResource({
      resource_id: rowData.row.original.resource_id,
      name: rowData.row.original.name,
    })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (rowData: any) => (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              {getIcon(rowData.row.values.type)}
            </div>
            <div className="ml-4">
              <Typography size="md">{rowData.value}</Typography>
            </div>
          </div>
        ),
        width: 250,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
        width: 80,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
        // width: 200,
      },
      {
        Header: 'Host',
        accessor: 'config.host',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
      },
      {
        Header: 'Port',
        accessor: 'config.port',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
      },
      {
        Header: 'Database',
        accessor: 'config.database',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: (rowData: any) => (
          <Typography size="md">{rowData.value}</Typography>
        ),
        width: 80,
      },
      {
        Header: '',
        id: 'action',
        Cell: (rowData: any) => (
          <Menu
            position="static"
            className="rounded" // TODO add translate 50%
            items={[
              {
                label: 'Edit',
                value: 'edit',
                onClick: () =>
                  showSlideOver({
                    type: SlideOverTypes.CreateResource,
                    config: {
                      ...SlideOverOptions.CreateResource,
                    },
                    params: {
                      ...rowData.row.original,
                    },
                  }),
              },
              {
                label: 'Delete',
                value: 'delete',
                onClick: () => handleDelete(rowData),
              },
              {
                label: 'Access rights',
                value: 'access_rights',
                disabled: true,
                onClick: (e: any) => {
                  e.stopPropagation()
                  e.preventDefault()
                },
              },
            ].filter(Boolean)}
          >
            {(onClick: any) => (
              <IconButton
                className="hover:bg-default-200"
                onClick={(e) => {
                  e.stopPropagation()
                  onClick()
                }}
              >
                <DotsVerticalIcon className="h-4 w-4 text-gray-700" />
              </IconButton>
            )}
          </Menu>
        ),
        width: 20,
      },
    ],
    []
  )

  return (
    <div className="max-w-full">
      <div className="shadow-md overflow-auto">
        <RTable
          columns={columns}
          data={resources}
          tableClassName="min-w-full"
          theadClassName="bg-default-100"
          tbodyClassName="bg-white"
          getHeaderProps={(header, i) => ({
            className: classnames(
              'text-left text-md font-medium text-default-700 truncate p-1.5 sm:p-2 lg:p-4'
            ),
            style: {
              width: header.width,
              minWidth: header.minWidth,
            },
          })}
          getRowProps={(row) => ({
            className: classnames('group cursor-pointer hover:bg-default-50'),
            onClick: () => {
              if (selectedWorkspace && row.original.resource_id)
                pushRoute(
                  Routes.url.Resource(
                    selectedWorkspace,
                    row.original.resource_id
                  )
                )
            },
          })}
          getCellProps={(cell, i) => ({
            className: classnames(
              'whitespace-nowrap truncate p-1.5 sm:p-2 lg:p-4'
              // 'text-md px-4 text-left truncate  text-default-700'
              // { disabled: i === 1 }
            ),
            style: {
              width: cell.column.width,
              minWidth: cell.column.minWidth,
            },
          })}
        />
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  resources: getResourcesState,
  selectedWorkspace: getSelectedWorkspaceState,
})

const mapDispatchToProps = {
  deleteResource: resourcesActions.deleteResource,
  showSlideOver: slideOverActions.showSlideOver,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesTable)
