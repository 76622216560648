import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { debounce } from 'lodash'
import { IconButton } from 'shared'
import { CloseIcon } from 'assets/general'
import { RTable } from './RTable'
import {
  RowDataType,
  DataElementType,
  EditTableInputComponentProps,
  EditTableColumnType,
  EditTableProps,
} from './types'

const timeout = 200

const getMoreEl = (columns: EditTableColumnType[]) => {
  const moreEl: { [key: string]: string } = {}

  columns.forEach((el: EditTableColumnType) => {
    moreEl[el.accessor] = ''
  })

  return moreEl
}

const TableInputComponent = React.memo(
  ({
    rowData,
    inputClassName = '',
    handleChange,
    onBlur,
  }: EditTableInputComponentProps) => {
    return (
      <input
        className={
          typeof inputClassName === 'string'
            ? inputClassName
            : inputClassName
            ? inputClassName(rowData)
            : ''
        }
        placeholder={
          rowData.row.index === rowData.data.length - 1 ? 'Add New' : undefined
        }
        value={rowData.value}
        onChange={(e) => handleChange(e.target.value, rowData)}
        onBlur={(e) => (onBlur ? onBlur(e, rowData) : null)}
      />
    )
  },
  (prev, next) => false
)

export const EditTable: FunctionComponent<EditTableProps> = ({
  columns,
  data,
  setData,
  onBlur,
  inputClassName,
  tableClassName,
  theadClassName,
  tbodyClassName,
  getHeaderGroupProps,
  getHeaderProps,
  getRowProps,
  getCellProps,
}) => {
  const [state, setState] = useState<DataElementType[]>([
    ...data,
    getMoreEl(columns),
  ])

  const debouncedUpdate = useMemo(
    () =>
      debounce((newData) => {
        if (setData)
          setData(
            newData.filter(
              (el: DataElementType, i: number) => i < newData.length - 1
            )
          )
      }, timeout),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setData]
  )

  useEffect(() => {
    if (data) setState([...data, getMoreEl(columns)])
  }, [data, columns])

  const handleChange = useCallback(
    (value: string, rowData: RowDataType) => {
      const newData = [...rowData.data]

      if (rowData.row.index === newData.length - 1)
        newData.push(getMoreEl(columns))

      newData[rowData.row.index] = {
        ...newData[rowData.row.index],
        [rowData.column.id]: value,
      }

      setState(newData)
      debouncedUpdate(newData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, debouncedUpdate]
  )

  const handleDelete = (rowData: RowDataType) => {
    const newData = [...rowData.data]
    const filteredData = newData.filter(
      (el: DataElementType, i: number) => rowData.row.index !== i
    )
    setState(filteredData)
    if (setData)
      setData(
        filteredData.filter(
          (el: DataElementType, i: number) => i < filteredData.length - 1
        )
      )
  }

  const _columns = React.useMemo(
    () =>
      columns.map((el: EditTableColumnType, i: number) => ({
        ...el,
        Cell: (rowData: RowDataType) => (
          <>
            <TableInputComponent
              rowData={rowData}
              inputClassName={inputClassName}
              handleChange={handleChange}
              onBlur={onBlur || (() => {})}
            />
            {i === columns.length - 1 &&
            rowData.row.index !== rowData.data.length - 1 ? (
              <IconButton
                onClick={() => handleDelete(rowData)}
                className="absolute top-1 right-1 transform hidden group-hover:block"
              >
                <CloseIcon
                  className="rounded-sm bg-white hover:bg-red-100"
                  color="secondary"
                  size="sm"
                />
              </IconButton>
            ) : (
              ''
            )}
          </>
        ),
      })), // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns]
  )

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RTable
      columns={_columns}
      data={state}
      tableClassName={tableClassName}
      theadClassName={theadClassName}
      tbodyClassName={tbodyClassName}
      getHeaderGroupProps={getHeaderGroupProps}
      getHeaderProps={getHeaderProps}
      getRowProps={getRowProps}
      getCellProps={getCellProps}
    />
  )
}
