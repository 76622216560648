import { TextPararamsType } from './types'

export const getChartWidth = (element: any, minWidth: number): number => {
  const width = element.clientWidth

  return width > minWidth ? width : minWidth
}

// Calculating height and width of text
// The width argument is only needed to calculate  height of multiline text
export const getTextParams = (
  txt: string,
  fontFamily: string = 'PtRootUI',
  fontSize: number = 10,
  width?: number
): TextPararamsType => {
  let el = document.createElement('span')
  el.style.fontSize = fontSize + 'px'
  el.style.fontFamily = fontFamily
  el.style.position = 'fixed'
  el.style.top = (-1000).toString()
  el.style.left = (-1000).toString()

  if (width) el.style.width = width + 'px'

  el.innerHTML = txt
  document.body.appendChild(el)

  width = el.offsetWidth
  const height = el.offsetHeight

  document.body.removeChild(el)

  return { width, height }
}

export const dateFormater = (date: Date): string => {
  const arr = new Date(date)
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .split('/')

  const el = arr[0].length === 1 ? 0 + arr[0] : arr[0]
  arr[0] = arr[1].length === 1 ? 0 + arr[1] : arr[1]
  arr[1] = el

  return arr.join('/')
}

export const getChartData = (
  dates: { start: Date; end: Date },
  count: number,
  field: string
) => {
  const delta: number = (dates.end.getTime() - dates.start.getTime()) / count

  let arr = []

  for (let i = 0; i < count; i++) {
    let date = new Date(dates.start)
    date.setTime(date.getTime() + delta * i)

    const a: number = 10 + Math.random() * 100,
      b: number = 10 + Math.random() * 100,
      c: number = 10 + Math.random() * 100,
      d: number = 10 + Math.random() * 100

    arr.push({
      date: date,
      [`${field}-1`]: a,
      [`${field}-2`]: b,
      [`${field}-3`]: c,
      [`${field}-5`]: d,
      total: a + b + c + d,
    })
  }

  return arr //.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
}
