import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'notification'

export interface NotificationState {
  notification: any
}

const initialState: NotificationState = {
  notification: {},
}

const notificationReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    showNotification: (
      state: NotificationState,
      { payload }: PayloadAction<any>
    ) => {
      state.notification = {
        ...payload,
      }
    },
    hideNotification: (state: NotificationState) => {
      state.notification = {}
    },
  },
})

export const notificationActions = notificationReducer.actions

export default notificationReducer.reducer
