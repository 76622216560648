import { createSelector } from 'reselect'
import { RootState } from 'app/store'

export const getSlideOverListState = (state: RootState) => state.slideOver

export const getIsOpenState = createSelector(
  getSlideOverListState,
  (state) => state.isOpen
)

export const getTypeState = createSelector(
  getSlideOverListState,
  (state) => state.type
)

export const getConfigState = createSelector(
  getSlideOverListState,
  (state) => state.config
)

export const getSlideOverLoadingState = createSelector(
  getSlideOverListState,
  (state) => state.loading
)

export const getSlideOverParamsState = createSelector(
  getSlideOverListState,
  (state) => state.params
)
