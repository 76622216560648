import React, { ReactNode } from 'react'
import classNames from 'classnames'
import {
  COLOR_TYPES,
  SIZE_TYPES,
  WEIGHT_TYPES,
  textColor,
  textSize,
  fontWeight,
} from 'utils/theme'

interface TypographyProps {
  children: ReactNode
  title?: string
  color?: COLOR_TYPES
  colorNew?: string
  size?: SIZE_TYPES
  weight?: WEIGHT_TYPES
  className?: string
  style?: any
  onClick?: () => void
}

export const Typography: React.FunctionComponent<TypographyProps> = ({
  children,
  title = '',
  color = 'default-900',
  colorNew = '',
  size = 'sm',
  weight = 'normal',
  className = '',
  style = {},
  onClick = () => {},
}) => {
  return (
    <div
      className={classNames(
        colorNew || textColor(color),
        textSize(size),
        fontWeight(weight),
        className
      )}
      style={style}
      title={title}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
