import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DatasetsItemType } from 'pages/Project/types'

const prefix = 'datacatalog'

export interface DataCatalogState {
  datasets: DatasetsItemType[]
  loading: boolean
}

const initialState: DataCatalogState = {
  datasets: [],
  loading: false,
}

const dataCatalogReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchDatasets: (state) => {
      state.loading = true
    },
    didFetchDatasets: (
      state,
      { payload }: PayloadAction<DatasetsItemType[]>
    ) => {
      state.datasets = payload
      state.loading = false
    },
    didFetchDatasetsFail: (state) => {
      state.loading = false
    },
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
  },
})

export const dataCatalogActions = dataCatalogReducer.actions

export default dataCatalogReducer.reducer
