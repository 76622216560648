import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from 'shared'
import { Routes } from 'utils/router'
import { DatasetsItemType } from '../types'
import { connect } from 'react-redux'
import { slideOverActions } from 'containers/SlideOver/reducer'
import { typeIcons } from 'pages/Connections/containers/drawer/config'
import { projectActions } from '../reducer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSelectedDatasetState } from '../selectors'
import classnames from 'classnames'

interface DatasetItemProps {
  item: DatasetsItemType
  idProject: string
}

interface StateProps {
  selectedDataset: DatasetsItemType | null
}

type DispatchProps = typeof mapDispatchToProps

const DatasetItem: FunctionComponent<
  DatasetItemProps & StateProps & DispatchProps
> = ({
  item,
  idProject,
  showSlideOver,
  selectedDataset,
  setSelectedDataset,
}) => {
  const history = useHistory()
  const TypeIcon = item.type ? typeIcons[item.type] : null
  return (
    <div
      className={classnames(
        'mt-t20 first:mt-0 px-t15 py-t20 rounded shadow-sm bg-white hover:shadow-md',
        {
          'shadow-md':
            selectedDataset && selectedDataset.dataset_id === item.dataset_id,
        }
      )}
      onClick={() => setSelectedDataset(item)}
    >
      <div className="flex justify-between">
        <div className="flex">
          <div className="pr-t30">
            <div className="flex">
              <Typography
                className="cursor-pointer"
                color="primary-500"
                size="xl"
                weight="bold"
                onClick={() =>
                  history.push(
                    Routes.url.Dataset({
                      project_id: idProject,
                      dataset_id: item.dataset_id,
                    })
                  )
                }
              >
                {item.name}
              </Typography>
            </div>
            <Typography className="mt-t5" size="md" color="default-600">
              {item.description}
            </Typography>
            <div className="flex mt-t15 first:mt-0">
              <Typography weight="bold" size="md">
                Table owner:
              </Typography>
              <Typography className="ml-t15" size="md">
                {item.owner_id}
              </Typography>
            </div>
          </div>
          <div className="border-l-2 px-t30 flex-none border-default-300">
            <div className="flex">
              <Typography size="lg" weight="bold">
                ---
              </Typography>
              <Typography className="ml-sm" size="lg">
                rows
              </Typography>
            </div>
            <div className="flex mt-t10">
              <Typography size="lg" weight="bold">
                ---
              </Typography>
              <Typography className="ml-sm" size="lg">
                columns
              </Typography>
            </div>
            <div className="flex mt-t10">
              <Typography size="lg" weight="bold">
                ---
              </Typography>
              <Typography className="ml-sm" size="lg">
                rows 3 months ago
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex items-start">
          {TypeIcon ? <TypeIcon width="20px" size={null} /> : null}
          {/* <IconButton className="mr-t20">
            <LinkIcon
              width={'16px'}
              height={'16px'}
              color='secondary'
              size={null}
              // className="transform rotate-90"
            />
          </IconButton> */}
          {/* <Menu
            className="right-t15"
            position="absolute"
            items={[
              {
                label: 'Edit',
                onClick: () =>
                  showSlideOver({
                    type: SlideOverTypes.ManageDataset,
                    config: {
                      ...SlideOverOptions.ManageDataset,
                      headerName: 'Update dataset',
                    },
                    params: {
                      idProject,
                      dataset: item,
                    },
                  }),
              },
            ].filter(Boolean)}
          >
            {(onClick: any) => (
              <IconButton
                className="absolute right-t15"
                onClick={(e: any) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onClick()
                }}
              >
                <MenuVerticalDotIcon
                  width={'16px'}
                  height={'16px'}
                  size={null}
                />
              </IconButton>
            )}
          </Menu> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedDataset: getSelectedDatasetState,
})

const mapDispatchToProps = {
  showSlideOver: slideOverActions.showSlideOver,
  setSelectedDataset: projectActions.setSelectedDataset,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetItem)
