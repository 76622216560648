import React, { FunctionComponent } from 'react'
import { PageTypes } from './types'
import classnames from 'classnames'

const PHeaderContainer: FunctionComponent<PageTypes> = ({
  children,
  className = '',
}) => {
  return (
    <div
      data-label="page-header-container"
      className={classnames('flex justify-between items-center min-h-H', className)}
    >
      {children}
    </div>
  )
}

export default PHeaderContainer
