import { FC } from 'react'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'

export const HeaderContainer: FC = () => {
  return (
    <PHeaderContainer>
      <PageName name="Welcome, dear friend!" />
    </PHeaderContainer>
  )
}
