import { Fragment, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getConnectionsState,
  getSearchValueState,
  getTypeFilterValueState,
} from 'pages/Connections/selectors'
import { ConnectionsItemType, TypeFilterT } from 'pages/Connections/types'
import { search } from 'utils/helpers'
import ConnectionItem from './ConnectionItem'

interface ConnectionsListProps {}

interface StateProps {
  connections: ConnectionsItemType[]
  searchValue: string
  typeFilterValue: TypeFilterT
}

const ConnectionsList: FunctionComponent<ConnectionsListProps & StateProps> = ({
  searchValue,
  typeFilterValue,
  connections,
}) => {
  return (
    <Fragment>
      {connections
        .filter((item: ConnectionsItemType) =>
          typeFilterValue?.name && searchValue
            ? item.type === typeFilterValue.name &&
              search(item.name, searchValue)
            : typeFilterValue?.name && !searchValue
            ? item.type === typeFilterValue.name
            : !typeFilterValue?.name && searchValue
            ? search(item.name, searchValue)
            : true
        )
        .map((item: ConnectionsItemType) => (
          <ConnectionItem key={item.connection_id} item={item} />
        ))}
    </Fragment>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connections: getConnectionsState,
  searchValue: getSearchValueState,
  typeFilterValue: getTypeFilterValueState,
})

export default connect(mapStateToProps)(ConnectionsList)
