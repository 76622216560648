import { makeRequest } from './http'

const api = {
  async getProjects() {
    const response = await makeRequest({
      path: '/projects',
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async getProjectsByFilter({ project_id, cluster_id }) {
    const response = await makeRequest({
      path: `/projects/filter?project_id=${project_id}&cluster_id=${cluster_id}`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Create Project
  async createProject(data) {
    const response = await makeRequest({
      path: '/projects',
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Update Project
  async updateProject({ project_id, name, description }) {
    const response = await makeRequest({
      path: `/projects/${project_id}`,
      method: 'PUT',
      params: JSON.stringify({ name, description }),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Project
  async deleteProject(project_id) {
    const response = await makeRequest({
      path: `/projects/${project_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },
}

export default api
