import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { PageTypes } from '../Page/types'

const ModalHeaderContainer: FunctionComponent<PageTypes> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classnames('bg-gray-50 px-5 py-4 text-left', className)}
    >
      {children}
    </div>
  )
}

export default ModalHeaderContainer
