export interface ClustersItemType {
  provider: ClustersProviderTypeT
  cluster_id: string
  owner_id: string
  name: string
  description: string
  status: 'active' | 'inactive'
  type: ClustersTypeTypeT
  credentials: ClustersCredentialsTypeT
  config: ClustersConfigType
  props: ClustersPropsType
  created_at: string
  updated_at: string
  number_of_processors?: string | number
  number_of_projects?: string | number
  number_of_datasets?: string | number
  memory_size?: string
  price?: string
}

export const CLUSTERS_PROVIDER_TYPES = {
  AWS: 'AWS',
  AZURE: 'Azure',
  GCP: 'GCP',
} as const

export type ClustersProviderTypeT =
  typeof CLUSTERS_PROVIDER_TYPES[keyof typeof CLUSTERS_PROVIDER_TYPES]

export const CLUSTERS_TYPE_TYPES = {
  CLICKHOUSE: 'clickhouse',
  POSTGRESQL: 'postgresql',
} as const

export type ClustersTypeTypeT =
  typeof CLUSTERS_TYPE_TYPES[keyof typeof CLUSTERS_TYPE_TYPES]

export interface ClustersClickhouseCredentialsType {
  url: string
  user: string
  password: string
  [name: string]: string
}

export interface ClustersPostgresqlCredentialsType {
  host: string
  port: string
  user: string
  password: string
  [name: string]: string
}

export type ClustersCredentialsTypeT =
  | ClustersClickhouseCredentialsType
  | ClustersPostgresqlCredentialsType

export interface ClustersConfigType {}

export interface ClustersPropsType {}

export interface CreateClusterPayload {
  cluster_id: string
  provider: ClustersProviderTypeT
  type: ClustersTypeTypeT
  credentials: ClustersCredentialsTypeT
  name?: string
  description?: string
  config?: ClustersConfigType
  props?: ClustersPropsType
}

export interface UpdateClusterPayload {
  credentials?: ClustersCredentialsTypeT
  name?: string
  description?: string
  config?: ClustersConfigType
  props?: ClustersPropsType
}

export interface DeleteClusterPayload {
  cluster_id: string
}

// export type CLOUD_TYPES = {
//   [key in CLOUD_ENUM]: any
// }
