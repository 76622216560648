import { makeRequest } from './http'

const api = {
  async getWorkspaces() {
    const response = await makeRequest({
      path: `/workspaces`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async getWorkspace(workspace_id) {
    const response = await makeRequest({
      path: `/workspaces/${workspace_id}`,
      method: 'GET',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async createWorkspace(data) {
    const response = await makeRequest({
      path: `/workspaces`,
      method: 'POST',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  async updateWorkspace({ workspace_id, data }) {
    const response = await makeRequest({
      path: `/workspaces/${workspace_id}`,
      method: 'PUT',
      params: JSON.stringify(data),
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },

  // Delete Workspace
  async deleteWorkspace(workspace_id) {
    const response = await makeRequest({
      path: `/workspaces/${workspace_id}`,
      method: 'DELETE',
      // errorMessage: 'Invalid email or password!',
    })
    return response
  },
}

export default api
