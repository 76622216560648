import { FunctionComponent } from 'react'
import { MonacoEditor, MONACO_LANGUAGES_TYPES } from 'shared'
import TabContentContainer from './TabContentContainer'

interface FormulaTabProps {
  formulaValue: string
  setFormulaValue: (value: string | ((prevState: string) => string)) => void
}

const FormulaTab: FunctionComponent<FormulaTabProps> = ({
  formulaValue,
  setFormulaValue,
}) => {
  return (
    <TabContentContainer>
      <MonacoEditor
        language={MONACO_LANGUAGES_TYPES.JSON}
        value={formulaValue}
        defaultValue={formulaValue}
        onChange={(value) => setFormulaValue(value || '')}
        minimap={false}
        lineNumbers={true}
      />
      {/* <SqlMonacoEditor
        value={'{}'}
        onChange={(value: any) => {}}
        readOnly={false}
      /> */}
    </TabContentContainer>
  )
}

export default FormulaTab
