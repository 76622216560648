import { COLOR_TYPES } from 'utils/theme'

export enum MONTHS_TYPES {
  JAN = 0,
  FEB = 1,
  MAR = 2,
  APR = 3,
  MAY = 4,
  JUN = 5,
  JUL = 6,
  AUG = 7,
  SEP = 8,
  OCT = 9,
  NOV = 10,
  DEC = 11,
}

export enum VALUE_TYPES {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  FIXED = 'fixed',
  UNIX = 'unix',
}

export enum ACTIVE_TYPES {
  CALENDER = 'calender',
  DEFAULT = 'default',
  NONE = '',
}

export enum TITLE_TYPES {
  YEAR = 'year',
  DEFAULT = 'default',
}

export interface DatesType {
  start: Date
  end?: Date
}

// field END is required
export interface DatesIntervalType {
  start: Date
  end: Date
}

export interface DetailedDateType {
  year?: number
  month?: number
  day?: number
  hour?: number
  minute?: number
  second?: number
}

export interface DetailedDatesType {
  start: DetailedDateType
  end?: DetailedDateType
}

export interface DeltaType {
  year?: number
  month?: number
  day?: number
  hour?: number
  minute?: number
  second?: number
}

export interface UnixType {
  start: number
  end?: number
}

export interface TimesType {
  dates?: DetailedDatesType
  delta?: DeltaType
  unix?: UnixType
}

export interface ValueType {
  title: string
  times: TimesType
  type: VALUE_TYPES
  view?: string
  range?: string
  interval?: string
}

export interface LeftBlockChildType {
  title: string
  times: TimesType
}

export interface LeftBlockType {
  title: string
  type: VALUE_TYPES
  children: LeftBlockChildType[]
}

export interface RightElementType {
  title: string
  type?: VALUE_TYPES | undefined
  times?: TimesType | undefined
  name?: string
  icon?: React.ReactNode
  backgroundColor?: COLOR_TYPES
  onClick?: (setActiveType: (type: ACTIVE_TYPES) => void) => void
}

export interface DatapickerProps {
  value: ValueType
  setValue: (value: ValueType) => void
  preValue: ValueType | null
  setPreValue: (preValue: ValueType | null) => void
}

export interface DatePickerInputProps {
  value: ValueType
  preValue: ValueType | null
  hoverValue: ValueType | null
  viewDate: string
  activeType: ACTIVE_TYPES
  dates: DatesIntervalType
  error: string
  setValue: (value: ValueType) => void
  setPreValue: (preValue: ValueType | null) => void
  setError: (error: string) => void
  setActiveType: (type: ACTIVE_TYPES) => void
}

export interface DatePickerInputProps {
  value: ValueType
  preValue: ValueType | null
  hoverValue: ValueType | null
  viewDate: string
  activeType: ACTIVE_TYPES
  dates: DatesIntervalType
  error: string
  setValue: (value: ValueType) => void
  setPreValue: (preValue: ValueType | null) => void
  setError: (error: string) => void
  setActiveType: (type: ACTIVE_TYPES) => void
}
