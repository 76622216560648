import React, { FunctionComponent, ReactNode } from 'react'

interface SwitchCaseProps {
  children: { [name: string]: ReactNode }
  value: string | number
}

export const SwitchCase: FunctionComponent<SwitchCaseProps> = ({
  children,
  value,
}) => <>{children[value]}</>
