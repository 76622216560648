import React from 'react'
import { Loader } from './Loader'
import { COLOR_TYPES, SIZE_TYPES } from 'utils/theme'
import classnames from 'classnames'

interface LoaderWithOverlayProps {
  loading: boolean
  size?: SIZE_TYPES
  color?: COLOR_TYPES
  className?: string
  top?: string
}

export const LoaderWithOverlay: React.FunctionComponent<
  LoaderWithOverlayProps
> = ({
  loading = false,
  size = 'lg',
  color = 'default-600',
  className = '',
  top = '',
}) => {
  return loading ? (
    <div
      className={classnames(
        'flex justify-center h-full w-full absolute top-0 left-0',
        className
      )}
    >
      <span className="h-full w-full absolute z-40 bg-white opacity-60"></span>
      <Loader
        loading={loading}
        className={`${top ? 'top-' + top : ''}`}
        size={size}
        color={color}
      />
    </div>
  ) : null
}
