import { FunctionComponent } from 'react'
import classnames from 'classnames'
import { connectionsConfig } from '../containers/drawer/config'
import { connect } from 'react-redux'
import { connectionsActions } from '../reducer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getConnectionTypeState } from '../selectors'
import { ConnectionsTypeTypeT } from '../types'

interface ClassesType {
  root?: string
  iconContainer?: string
}

interface ConnectionVariantsProps {
  className?: ClassesType
  iconSize?: string
}

interface StateProps {
  connectionType: ConnectionsTypeTypeT
}

type DispatchProps = typeof mapDispathToProps

const ConnectionVariants: FunctionComponent<
  ConnectionVariantsProps & StateProps & DispatchProps
> = ({
  className = { root: 'p-t20 space-x-t30', iconContainer: ' p-t20' },
  iconSize = '90px',
  connectionType,
  selectConnectionType,
}) => {
  return (
    <div className={classnames('flex', className.root)}>
      {connectionsConfig
        .filter((d) => d.enabled)
        .map((el, i) => (
          <div
            key={i}
            className={classnames(
              'w-min rounded bg-white shadow cursor-pointer',
              { 'shadow-primary': connectionType === el.name },
              className.iconContainer
            )}
            onClick={() => selectConnectionType(el.name)}
          >
            <el.Icon
              width={iconSize}
              height={iconSize}
              size={null}
              color="black"
            />
          </div>
        ))}
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
})

const mapDispathToProps = {
  selectConnectionType: connectionsActions.selectConnectionType,
}

export default connect(mapStateToProps, mapDispathToProps)(ConnectionVariants)
