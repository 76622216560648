import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from 'assets/general'
import { Typography } from 'shared/typography'
import { SIZE_TYPES } from 'utils/theme'

type PathType = {
  path: string
  name: string
}

interface BreadcrumbProps {
  paths: PathType[]
  size?: SIZE_TYPES
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  paths = [],
  size = 'md',
}) => {
  return (
    <ul className="flex">
      {paths.map((d, i) => {
        const isLast = i === paths.length - 1
        return (
          <li key={d.path} className="inline-flex items-center">
            <Link to={d.path}>
              <Typography
                weight={isLast ? 'bold' : 'normal'}
                color={isLast ? 'primary-500' : 'default-600'}
                size="lg"
              >
                {d.name}
              </Typography>
            </Link>
            {!isLast ? (
              <ChevronRightIcon color="default-600" className="mx-xs" />
            ) : null}
          </li>
        )
      })}
    </ul>
  )
}
