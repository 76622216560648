import { FC, useEffect } from 'react'
import { Header } from './containers/Header'
import Main from './containers/Main'
import PRootContainer from 'containers/Page/PRootContainer'
import { resourceActions } from './reducer'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'

type PropsFromRedux = ConnectedProps<typeof connector>

const Resource: FC<PropsFromRedux> = ({ fetchResource, resetResource }) => {
  const { workspace_id, resource_id } = useParams<any>()
  useEffect(() => {
    if (workspace_id && resource_id)
      fetchResource({ workspace_id, resource_id })
  }, [workspace_id, resource_id])

  useEffect(() => {
    return () => {
      resetResource()
    }
  }, [])

  return (
    <PRootContainer
      data-label="resources-container"
      className="flex flex-1 flex-col px-20 py-5"
    >
      <Header />
      <Main />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchResource: resourceActions.fetchResource,
  resetResource: resourceActions.resetResource,
}

const connector = connect(null, mapDispatchToProps)

export default connector(Resource)
