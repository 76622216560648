import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Typography } from 'shared'
import { QueriesItemType } from '../types'
import { queriesActions } from '../reducer'
import ActionContainer from './ActionContainer'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'

interface TabContentHeaderProps {
  selectedTab: QueriesItemType
  handleUpdate: (data: any) => void
  className?: string
}

type DispatchProps = typeof mapDispatchToProps

const TabContentHeader: FunctionComponent<
  TabContentHeaderProps & DispatchProps
> = React.memo(
  ({
    selectedTab,
    handleUpdate,
    deleteQuery,
    selectQuery,
    showModal,
    // className,
  }) => {
    const [queryName, setQueryName] = useState(selectedTab.name)

    useEffect(() => {
      setQueryName(selectedTab.name || '')
    }, [selectedTab.name])

    return (
      <div
        data-label="tab-content-header-container"
        className="border-b flex items-center justify-between space-x-1.5 py-2 px-5"
      >
        <div
          className={`flex items-center ${
            selectedTab.deleted ? 'pointer-events-none' : ''
          }`}
        >
          {selectedTab.breadcrumbs && selectedTab.breadcrumbs.length
            ? selectedTab.breadcrumbs.map((breadcrumb, i) => (
                <div key={i} className="flex">
                  <Typography
                    className="whitespace-nowrap hover:underline hover:cursor-pointer"
                    weight="medium"
                    color="default-600"
                    onClick={() => selectQuery(breadcrumb)}
                  >
                    {breadcrumb.name}
                  </Typography>
                  <Typography
                    className="px-1.5"
                    weight="medium"
                    color="default-600"
                  >
                    /
                  </Typography>
                </div>
              ))
            : null}
          <Input
            type="text"
            name="query_name"
            placeholder="Query name"
            height="6"
            width="36"
            className="px-0 !text-sm !border-transparent !bg-white !text-primary-500 focus:px-2"
            value={queryName}
            onChange={(e: any) => {
              setQueryName(e.target.value)
            }}
            onBlur={() =>
              handleUpdate({
                query_id: selectedTab.query_id,
                data: {
                  name: queryName,
                },
              })
            }
          />
        </div>
        <ActionContainer
          query={selectedTab}
          items={[
            {
              label: 'delete',
              onClick: () => {
                showModal({
                  type: ModalTypes.ConfirmationDelete,
                  config: {
                    ...ModalOptions.ConfirmationDelete,
                    headerName: 'Delete',
                  },
                  params: {
                    tab: selectedTab,
                    handleDelete: deleteQuery,
                  },
                })
              },
            },
          ]}
          handleUpdate={handleUpdate}
        />
      </div>
    )
  },
  (prev, next) =>
    JSON.parse(JSON.stringify(prev.selectedTab)) ===
    JSON.parse(JSON.stringify(next.selectedTab))
)

const mapDispatchToProps = {
  deleteQuery: queriesActions.deleteQuery,
  selectQuery: queriesActions.selectQuery,
  showModal: modalActions.showModal,
}

export default connect(null, mapDispatchToProps)(TabContentHeader)
