import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react'
import cn from 'classnames'
import { Typography, SwitchCase } from 'shared'
import { COLOR_TYPES, borderColor, textColor, SIZE_TYPES } from 'utils/theme'

interface TabProps {
  id: string
  title: ReactNode | string
  content: ReactNode
}

interface TabsProps {
  tabs: TabProps[]
  onChange?: (id: string) => void
  active?: string
  color?: COLOR_TYPES
  size?: SIZE_TYPES
  className?: string
  headerClassName?: string
  disabled?: boolean
  headerTab?: ((activeTab: string) => void) | undefined
  tabComponent?: any | null
}

export const Tabs: FunctionComponent<TabsProps> = ({
  tabs,
  onChange = () => '',
  active,
  color = 'primary-light',
  size = 'sm',
  className,
  headerClassName,
  disabled = false,
  headerTab = undefined,
  tabComponent = null,
}) => {
  const [activeTab, setActiveTab] = useState<string>(active || tabs[0].id)

  useEffect(() => {
    if (active) setActiveTab(active)
  }, [active])

  const handleChange = (id: string) => {
    if (id !== activeTab) {
      setActiveTab(id)
      onChange(id)
    }
  }

  const getTabContent = () => {
    const content: any = {}

    tabs.forEach((el: TabProps) => {
      content[el.id] = el.content
    })

    return content
  }

  return (
    <div data-label="tabs-container" className={className}>
      <div className={cn('relative', headerClassName)}>
        <>
          {tabs.map((tab, i) =>
            tabComponent !== null ? (
              <React.Fragment key={i}>
                {tabComponent({
                  index: i,
                  tab,
                  activeTab,
                  disabled,
                  color,
                  handleChange,
                })}
              </React.Fragment>
            ) : (
              <div key={i} className="flex items-center">
                <div
                  className={cn(
                    'group cursor-pointer px-0.5 py-0.5 border-b-2 border-white duration-300',
                    activeTab === tab.id
                      ? cn(borderColor(color), textColor(color))
                      : '',
                    { disabled }
                  )}
                  onClick={() => handleChange(tab.id)}
                >
                  <Typography
                    className={cn(
                      'flex items-center truncate',
                      activeTab === tab.id
                        ? 'text-primary-light hover:text-primary-light'
                        : 'text-default-400 hover:text-default-500'
                    )}
                    size={size}
                  >
                    {tab.title}
                  </Typography>
                </div>
              </div>
            )
          )}
          {headerTab !== undefined ? headerTab(activeTab) : null}
        </>
      </div>
      <SwitchCase value={activeTab}>{getTabContent()}</SwitchCase>
    </div>
  )
}
