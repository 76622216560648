// import { FC, Fragment } from 'react'
// import { Dialog, Transition } from '@headlessui/react'
// import { XIcon } from '@heroicons/react/outline'
// import { Typography } from 'shared'
// import { SLIDE_OVERS } from 'containers/SlideOver/SlideOverContainer'

// interface SlideOverProps {
//   type: string | undefined
//   show: boolean
//   headerName: string // TODO add ReactNode
//   hideSlideOver: () => void
// }

// export const SlideOver: FC<SlideOverProps> = ({
//   type,
//   show,
//   headerName,
//   hideSlideOver,
// }) => {
//   return (
//     <Transition.Root show={show} as={Fragment}>
//       <Dialog
//         as="div"
//         className="fixed inset-0 overflow-hidden"
//         onClose={hideSlideOver}
//       >
//         <div className="absolute inset-0 overflow-hidden">
//           <Transition.Child
//             as={Fragment}
//             enter="ease-in-out duration-500"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in-out duration-500"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <Dialog.Overlay className="absolute inset-0 bg-default-500/75 transition-opacity" />
//           </Transition.Child>
//           <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
//             <Transition.Child
//               as={Fragment}
//               enter="transform transition ease-in-out duration-500 sm:duration-700"
//               enterFrom="translate-x-full"
//               enterTo="translate-x-0"
//               leave="transform transition ease-in-out duration-500 sm:duration-700"
//               leaveFrom="translate-x-0"
//               leaveTo="translate-x-full"
//             >
//               <div className="relative w-screen max-w-3xl">
//                 <Transition.Child
//                   as={Fragment}
//                   enter="ease-in-out duration-500"
//                   enterFrom="opacity-0"
//                   enterTo="opacity-100"
//                   leave="ease-in-out duration-500"
//                   leaveFrom="opacity-100"
//                   leaveTo="opacity-0"
//                 >
//                   <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
//                     <button
//                       type="button"
//                       className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
//                       onClick={hideSlideOver}
//                     >
//                       <span className="sr-only">Close panel</span>
//                       <XIcon className="h-6 w-6" aria-hidden="true" />
//                     </button>
//                   </div>
//                 </Transition.Child>

//                 <div className="relative h-full flex flex-col pt-6 bg-white shadow-xl">
//                   {headerName ? (
//                     <div className="px-t20 pb-t10 border-b">
//                       <Typography size="xl" weight="bold">
//                         {headerName}
//                       </Typography>
//                     </div>
//                   ) : null}
//                   {type ? SLIDE_OVERS[type] : null}
//                 </div>
//               </div>
//             </Transition.Child>
//           </div>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   )
// }

import { FC, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { SLIDE_OVERS } from 'containers/SlideOver/SlideOverContainer'
import { SlideOverState } from 'containers/SlideOver/reducer'

interface ISlideOverProps extends SlideOverState {
  hideSlideOver: () => void
}

export const SlideOver: FC<ISlideOverProps> = (props) => {
  const { type, isOpen, hideSlideOver } = props
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={hideSlideOver}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-default-400/50  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  {type ? SLIDE_OVERS[type](props) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
