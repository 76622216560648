import classnames from 'classnames'
import { FunctionComponent, ReactNode } from 'react'

interface TabContentContainerProps {
  children: ReactNode
  className?: string
}

const TabContentContainer: FunctionComponent<TabContentContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={classnames('w-t350 h-t250 pt-t20', className)}>{children}</div>
  )
}

export default TabContentContainer
