import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
// import { ISignFormFail } from '../typings'

const prefix = 'signIn'

interface SignInFail {
  message: string
}

export interface SignInPayload {
  email: string
  password: string
  rememberMe: boolean
}

export interface SignInState {
  loading: boolean
  error: null | SignInFail
}

const initialState: SignInState = {
  loading: false,
  error: null,
}

const signInReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<SignInPayload>) => {
      state.loading = true
      state.error = null
    },
    didSignIn: (state) => {
      state.loading = false
      state.error = null
    },
    didSignInFail: (state, { payload }: PayloadAction<SignInFail>) => {
      state.loading = false
      state.error = payload
    },
  },
})

const signOut = createAction(`${prefix}/signOut`)

export const signInActions = { ...signInReducer.actions, signOut }

export default signInReducer.reducer
