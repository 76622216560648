import React, { FC, useState } from 'react'
import { DatePicker, DatePickerTypes, datePickerHelpers, Select } from 'shared'
import { StackedBarChart } from './StackedBarChart'
import { FieldType, MonitoringChartContainerProps } from '../../types'
import { getChartData } from './helpers'

const fields: FieldType[] = [
  {
    id: 0,
    name: 'user',
    title: 'User',
  },
  {
    id: 1,
    name: 'datasource',
    title: 'Datasource',
  },
  {
    id: 2,
    name: 'action_type',
    title: 'Action Type',
  },
]

export const MonitoringChartContainer: FC<MonitoringChartContainerProps> =
  () => {
    const [differentField, setDifferentField] = useState<FieldType>(fields[0])
    const [preValueDate, setPreValueDate] =
      useState<DatePickerTypes.ValueType | null>(null)

    const [valueDate, setValueDate] = useState<DatePickerTypes.ValueType>({
      //start: new Date("December 17, 1995 03:24:50"),
      //end: new Date("December 17, 1995 04:24:50"),
      title: '1 hour',
      range: '1h',
      times: {
        delta: {
          hour: 1,
        },
      },
      type: DatePickerTypes.VALUE_TYPES.RELATIVE,
    })

    return (
      <div data-label="monitoring-chart-container" className="p-t25 pb-0 rounded shadow">
        <div className="flex justify-between mb-t10">
          <DatePicker
            value={valueDate}
            setValue={setValueDate}
            preValue={preValueDate}
            setPreValue={setPreValueDate}
          />
          <Select
            options={fields}
            size={250}
            value={differentField}
            // disabled={params?.project ? d.disabled : false}
            onChange={setDifferentField}
          />
        </div>
        <StackedBarChart
          data={getChartData(
            datePickerHelpers.getDatesAndRange(valueDate.times).dates,
            100,
            differentField.name
          )}
          minHeight={200}
          minWidth={460}
          //transitionDuration={300}
          margin={{ top: 10, right: 5, bottom: 70, left: 40 }}
          colors={['#579dff', '#1e4376', '#00BBA2', '#db2777']}
        />
      </div>
    )
  }
