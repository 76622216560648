import { FunctionComponent } from 'react'
import classnames from 'classnames'
import ConnectionVariants from 'pages/Connections/components/ConnectionVariants'

interface VariantsDrawerProps {
  isShow: boolean
}

const VariantsDrawer: FunctionComponent<VariantsDrawerProps> = ({ isShow }) => {
  return (
    <div
      className={classnames(
        ' z-30 top-t60 left-0 shadow rounded flex flex-col bg-white w-full overflow-auto', // ease-in-out transition-all duration-200
        `${isShow ? 'min-h-t10 mt-t20' : 'p-0 h-0'}`
      )}
    >
      <ConnectionVariants />
    </div>
  )
}

export default VariantsDrawer
