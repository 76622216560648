import React, { FunctionComponent } from 'react'
import { QueriesItemType } from '../../types'
import QueryParamsDrawer from '../drawer/QueryParamsDrawer'

interface ApiTabContainerProps {
  query: QueriesItemType
  className?: string
}

const SettingsTabContainer: FunctionComponent<ApiTabContainerProps> = ({
  query,
}) => {
  return (
    <div
      data-label="settings-tab-container"
      className={`relative flex flex-col grow overflow-auto ${
        query.deleted ? 'pointer-events-none' : ''
      }`}
    >
      Settings
      <QueryParamsDrawer />
    </div>
  )
}

export default SettingsTabContainer
