import { PayloadAction } from '@reduxjs/toolkit'
import { modalActions } from 'containers/Modal/reducer'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import { pushRoute, Routes } from 'utils/router'
import { workspacesService } from 'utils/services'
import { sidebarActions } from './reducer'
import { getSelectedWorkspaceState, getWorkspaceByIdState } from './selectors'
import {
  ICreateWorkspace,
  IUpdateWorkspace,
  IWorkspace,
  SelectedWorkspace,
  WorkspaceId,
} from './types'

function* handleFetchWorkspaces() {
  try {
    const data: any[] = yield call(workspacesService.getWorkspaces)
    yield put(sidebarActions.didFetchWorkspaces(data))
  } catch (error) {}
}

function* handleCreateWorkspace({ payload }: PayloadAction<ICreateWorkspace>) {
  try {
    yield put(modalActions.startLoading(''))
    const data: IWorkspace = yield call(
      workspacesService.createWorkspace,
      payload
    )
    if (data.workspace_id) {
      yield put(sidebarActions.didCreateWorkspace(data))
      pushRoute(Routes.url.Resources(data.workspace_id))
    }

    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `Workspace not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateWorkspace({ payload }: PayloadAction<IUpdateWorkspace>) {
  const workspace: IWorkspace = yield select(
    getWorkspaceByIdState(payload.workspace_id)
  )
  try {
    yield put(modalActions.startLoading(''))
    yield call(workspacesService.updateWorkspace, payload)
    yield put(sidebarActions.didUpdateWorkspace(payload))

    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${workspace.name} updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${workspace.name} not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteWorkspace({ payload }: PayloadAction<WorkspaceId>) {
  const workspace: IWorkspace = yield select(getWorkspaceByIdState(payload))
  try {
    const selectedWorkspace: SelectedWorkspace = yield select(
      getSelectedWorkspaceState
    )
    yield call(workspacesService.deleteWorkspace, payload)
    yield put(sidebarActions.didDeleteWorkspace(payload))
    if (selectedWorkspace === payload) pushRoute(Routes.url.Root())

    yield put(
      notificationActions.showNotification({
        message: `${workspace.name} deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `${workspace.name} not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

export default function* watchSidebarSaga() {
  yield takeLatest(sidebarActions.fetchWorkspaces.type, handleFetchWorkspaces)
  yield takeLatest(sidebarActions.createWorkspace.type, handleCreateWorkspace)
  yield takeLatest(sidebarActions.updateWorkspace.type, handleUpdateWorkspace)
  yield takeLatest(sidebarActions.deleteWorkspace.type, handleDeleteWorkspace)
}
