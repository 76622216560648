import React from 'react'
import classnames from 'classnames'
import { LoaderIcon } from 'assets/general'
import { COLOR_TYPES, SIZE_TYPES } from 'utils/theme'

interface LoaderProps {
  loading: boolean
  size?: SIZE_TYPES
  color?: COLOR_TYPES
  className?: string
  top?: string
}

export const Loader: React.FunctionComponent<LoaderProps> = ({
  loading = false,
  size = 'lg',
  color = 'default-300',
  className = '',
}) => {
  return loading ? (
    <LoaderIcon
      isChildrenClass={true}
      className={classnames('absolute z-50', className)}
      size={size}
      color={color}
    />
  ) : null
}
