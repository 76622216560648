import _ from 'app/auth/constants'
import { cloneDeep } from 'lodash'

const __ = {
  TABS: 'qb_queries_tabs',
  SELECTED_TAB: 'qb_queries_selected_tab',
  COLLECTION_ID: 'qb_queries_collection_id',
  SELECTED_PARAMS: 'qb_queries_selected_params',
}

class Queries {
  addTab(data: any) {
    const TABS = this.tabs
    this.setStorage(__.TABS, TABS !== null ? [...TABS, data] : [data])
  }

  updateTab(query_id: string, data: any) {
    const copyTabs = cloneDeep(this.tabs)
    const idxTab = copyTabs.findIndex((d: any) => d.query_id === query_id)
    const updatedTab = { ...copyTabs[idxTab], ...data }
    copyTabs.splice(idxTab, 1, updatedTab)

    this.setStorage(__.TABS, [...copyTabs])
  }

  saveTab(query_id: string, data: any, changed: boolean) {
    const copyTabs = cloneDeep(this.tabs)
    const idxTab = copyTabs.findIndex((d: any) => d.query_id === query_id)
    const updatedTab = { ...copyTabs[idxTab], ...data, changed }
    copyTabs.splice(idxTab, 1, updatedTab)

    this.setStorage(__.TABS, [...copyTabs])
  }

  replaceTab(query_id: string, data: any) {
    const copyTabs = cloneDeep(this.tabs)
    const idxTab = copyTabs.findIndex((d: any) => d.query_id === query_id)
    copyTabs.splice(idxTab, 1, data)

    this.setStorage(__.TABS, [...copyTabs])
  }

  cleanTab(query_id: string, data: any) {
    const copyTabs = cloneDeep(this.tabs)
    const idxTab = copyTabs.findIndex((d: any) => d.query_id === query_id)
    const obj: any = {
      query_id,
      name: 'name' in data ? data.name : copyTabs[idxTab].name,
      type: copyTabs[idxTab].type,
    }
    if ('temp' in copyTabs[idxTab]) obj.temp = copyTabs[idxTab].temp
    if (copyTabs[idxTab].data) obj.data = copyTabs[idxTab].data
    copyTabs.splice(idxTab, 1, obj)

    this.setStorage(__.TABS, [...copyTabs])
  }

  cleanTabByName(query_id: string, name: string) {
    const copyTabs = cloneDeep(this.tabs)
    const idxTab = copyTabs.findIndex((d: any) => d.query_id === query_id)
    const obj: any = copyTabs[idxTab]
    delete obj[name]
    copyTabs.splice(idxTab, 1, obj)

    this.setStorage(__.TABS, [...copyTabs])
  }

  removeTab(query_id: string) {
    const copyTabs = cloneDeep(this.tabs)
    const newTabs = copyTabs.filter((d: any) => d.query_id !== query_id)

    this.setStorage(__.TABS, [...newTabs])
  }

  removeTabs(ids: string[]) {
    const copyTabs = cloneDeep(this.tabs)
    const newTabs = copyTabs.filter((d: any) => !ids.includes(d.query_id))

    this.setStorage(__.TABS, [...newTabs])
  }

  removeQueries() {
    _.STORAGE.removeItem(__.TABS)

    // storage.clean()
  }

  getTabById(query_id: string) {
    return this.tabs.filter((d: any) => d.query_id === query_id)[0]
  }

  setStorage(name: string, data: any) {
    _.STORAGE.setItem(name, JSON.stringify(data))
  }

  selectTab(query_id: string | null) {
    this.setStorage(__.SELECTED_TAB, query_id)
  }

  setCollectionId(collection_id: string | null) {
    this.setStorage(__.COLLECTION_ID, collection_id)
  }

  setSelectedParams(selectedParams: string | null) {
    this.setStorage(__.SELECTED_PARAMS, selectedParams)
  }

  get tabs() {
    return JSON.parse(`${_.STORAGE.getItem(__.TABS)}` || '')
  }

  get selectedTab() {
    return JSON.parse(`${_.STORAGE.getItem(__.SELECTED_TAB)}` || '')
  }

  get collectionId() {
    return JSON.parse(`${_.STORAGE.getItem(__.COLLECTION_ID)}` || '')
  }

  get selectedParams() {
    return JSON.parse(`${_.STORAGE.getItem(__.SELECTED_PARAMS)}` || '')
  }
}

export default new Queries()
