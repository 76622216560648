import React, { FunctionComponent } from 'react'
import { Indicator, RTable, Typography } from 'shared'

const DatasetTableView: FunctionComponent = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (
          <Indicator
            variant={Indicator.variant.SQUARE}
            size="lg"
            color="default-300"
          />
        ),
      },
      {
        Header: 'Column',
        accessor: 'column',
        Cell: (rowData: any) => {
          return (
            <>
              <Typography size="md" color="black" weight="bold">
                {rowData.value.name}
              </Typography>
              <Typography className="mt-t5" size="md">
                {rowData.value.label}
              </Typography>
            </>
          )
        },
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: (rowData) => <Typography size="md">{rowData.value}</Typography>,
      },
      {
        Header: 'Unique/%',
        accessor: 'unique',
        Cell: (rowData) => <Typography size="md">{rowData.value}</Typography>,
      },
      {
        Header: 'Minimum',
        accessor: 'min',
        Cell: (rowData) => <Typography size="md">{rowData.value}</Typography>,
      },
      {
        Header: 'Maximum',
        accessor: 'max',
        Cell: (rowData) => <Typography size="md">{rowData.value}</Typography>,
      },
      {
        Header: 'Mean',
        accessor: 'mean',
        Cell: (rowData) => <Typography size="md">{rowData.value}</Typography>,
      },
      {
        Header: 'Median',
        accessor: 'median',
        Cell: (rowData) => <Typography size="md">{rowData.value}</Typography>,
      },
    ],
    []
  )

  const data = React.useMemo(
    () => [
      {
        type: 'type',
        column: { label: 'Policy number', name: 'policy_number' },
        frequency: '199/-',
        unique: '0/0%',
        min: 18,
        max: 96,
        mean: '104,234',
        median: 108,
      },
      {
        type: 'type',
        column: { label: 'Policy number', name: 'policy_number' },
        frequency: '199/-',
        unique: '0/0%',
        min: 18,
        max: 96,
        mean: '104,234',
        median: 108,
      },
    ],
    []
  )

  return (
    <div className="mt-t20">
      <RTable
        tableClassName="w-full border-collapse"
        columns={columns}
        data={data}
        getHeaderGroupProps={() => ({
          className: 'bg-primary-light rounded text-md text-white',
        })}
        getHeaderProps={() => ({
          className:
            'py-t20 px-t15 text-left first:rounded-tl first:rounded-bl last:rounded-tr last:rounded-br',
        })}
        getRowProps={() => ({
          className: 'border-b-2 border-default-300',
        })}
        getCellProps={() => ({
          className: 'py-t20 px-t15 whitespace-nowrap',
        })}
      />
    </div>
  )
}

export default DatasetTableView
