export const buttonsGroup = [
  {
    id: 0,
    name: 'Overview',
  },
  {
    id: 1,
    name: 'Query',
  },
  {
    id: 2,
    name: 'Dictionary',
  },
  {
    id: 3,
    name: 'Discussion',
  },
  {
    id: 4,
    name: 'Lineage',
  },
  {
    id: 5,
    name: 'Adoption',
  },
  {
    id: 6,
    name: 'Settings',
  },
]

export const stats = {
  maxmin: [
    {
      label: 'Maximum',
      value: '999,123',
    },
    {
      label: 'Minimum',
      value: '123,345',
    },
    {
      label: 'Mean',
      value: '34,346.234',
    },
    {
      label: 'Medium',
      value: '235,234',
    },
  ],
  unique: [
    {
      label: 'Unique Values',
      value: '1,000',
    },
    {
      label: 'Unique Percentage',
      value: '0.6%',
    },
    {
      label: 'Missing Values',
      value: '0(0%)',
    },
    {
      label: 'Standart Deviation',
      value: '235,435.234',
    },
  ],
}