import React, { FunctionComponent } from 'react'

const QueriesTreeSkeleton: FunctionComponent = () => {
  return (
    <div data-label="queries-tree-skeleton" className="h-full w-full px-2.5">
      <div className="animate-pulse flex flex-col flex-1 space-y-2">
        <div className="h-6 bg-default-300 rounded w-3/6"></div>
        <div
          style={{ marginLeft: '17%' }}
          className="h-6 bg-default-300 rounded w-3/6"
        ></div>
        <div
          style={{ marginLeft: '34%' }}
          className="h-6 bg-default-300 rounded w-3/6"
        ></div>
        <div
          style={{ marginLeft: '34%' }}
          className="h-6 bg-default-300 rounded w-4/6"
        ></div>
        <div
          style={{ marginLeft: '34%' }}
          className="h-6 bg-default-300 rounded w-3/6"
        ></div>
        <div className="h-6 bg-default-300 rounded w-3/6"></div>
        <div
          style={{ marginLeft: '17%' }}
          className="h-6 bg-default-300 rounded w-3/6"
        ></div>
        <div
          style={{ marginLeft: '34%' }}
          className="h-6 bg-default-300 rounded w-3/6"
        ></div>
        <div
          style={{ marginLeft: '34%' }}
          className="h-6 bg-default-300 rounded w-4/6"
        ></div>
        <div
          style={{ marginLeft: '34%' }}
          className="h-6 bg-default-300 rounded w-3/6"
        ></div>
      </div>
    </div>
  )
}

export default QueriesTreeSkeleton
