import { PayloadAction } from '@reduxjs/toolkit'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { sidebarActions } from 'containers/Sidebar/reducer'
import { getSelectedWorkspaceState } from 'containers/Sidebar/selectors'
import { WorkspaceId } from 'containers/Sidebar/types'
import { IResources, ResourceId } from 'pages/Resources/types'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import { resourcesService } from 'utils/services'
import { resourceActions } from './reducer'
import { getResourceIdState, getSelectedTableState } from './selectors'
import {
  ICreateTableData,
  ITableColumn,
  IResourceTableData,
  ITableData,
  IUpdateTableColumn,
  IUpdateTablePayload,
  IUpdateTableData,
} from './types'

function* handleFetchResource({
  payload,
}: PayloadAction<{
  workspace_id: WorkspaceId
  resource_id: ResourceId
}>): any {
  try {
    let data: IResources
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    if (!workspace_id) {
      yield put(sidebarActions.selectWorkspace(payload.workspace_id))
      data = yield call(resourcesService.getResource, payload)
    } else
      data = yield call(resourcesService.getResource, {
        workspace_id,
        resource_id: payload.resource_id,
      })
    yield put(resourceActions.didFetchResource(data))
  } catch (error) {}
}

function* handleCreateTable({ payload }: PayloadAction<string>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)

    const data: string[] = yield call(resourcesService.createTable, {
      workspace_id,
      resource_id,
      data: { table_name: payload },
    })
    yield put(resourceActions.didCreateTable({ data, table_name: payload }))
    yield put(
      notificationActions.showNotification({
        message: `Table created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Table not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateTable({
  payload,
}: PayloadAction<IUpdateTablePayload>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)

    const data: string[] = yield call(resourcesService.updateTable, {
      workspace_id,
      resource_id,
      data: payload,
    })
    yield put(resourceActions.didUpdateTable({ data, ...payload }))
    yield put(
      notificationActions.showNotification({
        message: `Table updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Table not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteTable({ payload }: PayloadAction<string>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)

    const data: string[] = yield call(resourcesService.deleteTable, {
      workspace_id,
      resource_id,
      data: { table_name: payload },
    })
    yield put(resourceActions.didDeleteTable({ data, table_name: payload }))
    yield put(
      notificationActions.showNotification({
        message: `Table deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Table not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleFetchTableData(): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)
    if (table_name) {
      const data: IResourceTableData = yield call(
        resourcesService.getTableData,
        {
          workspace_id,
          resource_id,
          table_name,
        }
      )
      yield put(resourceActions.didFetchTableData(data))
    }
  } catch (error) {}
}

function* handleCreateTableColumn({
  payload,
}: PayloadAction<ITableColumn>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)

    const columns: ITableColumn[] = yield call(
      resourcesService.createTableColumn,
      {
        workspace_id,
        resource_id,
        table_name,
        data: payload,
      }
    )
    const data: IResourceTableData = yield call(resourcesService.getTableData, {
      workspace_id,
      resource_id,
      table_name,
    })
    // yield put(resourceActions.didUpdateTableColumn(columns))
    yield put(resourceActions.didFetchTableData(data)) // TODO may be update data via loop
    yield put(
      notificationActions.showNotification({
        message: `Column created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Column not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateTableColumn({
  payload,
}: PayloadAction<IUpdateTableColumn>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)

    const columns: ITableColumn[] = yield call(
      resourcesService.updateTableColumn,
      {
        workspace_id,
        resource_id,
        table_name,
        data: payload,
      }
    )
    const data: IResourceTableData = yield call(resourcesService.getTableData, {
      workspace_id,
      resource_id,
      table_name,
    })
    // yield put(resourceActions.didUpdateTableColumn(columns)) // TODO update data/ delete field
    yield put(resourceActions.didFetchTableData(data)) // TODO may be update data via loop
    yield put(
      notificationActions.showNotification({
        message: `Column updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Column not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteTableColumn({ payload }: PayloadAction<string>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)

    const columns: ITableColumn[] = yield call(
      resourcesService.deleteTableColumn,
      {
        workspace_id,
        resource_id,
        table_name,
        data: { column_name: payload },
      }
    )
    const data: IResourceTableData = yield call(resourcesService.getTableData, {
      workspace_id,
      resource_id,
      table_name,
    })
    // yield put(resourceActions.didUpdateTableColumn(columns)) // TODO update data/ delete field
    yield put(resourceActions.didFetchTableData(data)) // TODO may be update data via loop
    yield put(
      notificationActions.showNotification({
        message: `Column deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Column not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleCreateTableData({
  payload,
}: PayloadAction<ICreateTableData>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)

    const data: ITableData[] = yield call(resourcesService.createTableData, {
      workspace_id,
      resource_id,
      table_name,
      data: payload,
    })
    yield put(resourceActions.didCreateTableData(data || []))
    yield put(
      notificationActions.showNotification({
        message: `Row(s) created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Row(s) not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateTableData({
  payload,
}: PayloadAction<IUpdateTableData>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)

    const data: ITableData[] = yield call(resourcesService.updateTableData, {
      workspace_id,
      resource_id,
      table_name,
      data: { id: payload.id, value: payload.value },
    })

    yield put(
      resourceActions.didUpdateTableData(
        data || [
          {
            ...payload.old_value,
            // error: payload.column_name // If need error for input
          },
        ]
      )
    )
    if (data)
      yield put(
        notificationActions.showNotification({
          message: `Row(s) updated successfully!`,
          options: {
            type: TOAST_TYPES.SUCCESS,
          },
        })
      )
    else
      yield put(
        notificationActions.showNotification({
          message: `Row(s) not updated!`,
          options: {
            type: TOAST_TYPES.ERROR,
          },
        })
      )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Row(s) not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteTableData({
  payload,
}: PayloadAction<string[] | number[]>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const resource_id: WorkspaceId = yield select(getResourceIdState)
    const table_name: string | null = yield select(getSelectedTableState)

    yield call(resourcesService.deleteTableData, {
      workspace_id,
      resource_id,
      table_name,
      data: { ids: payload },
    })

    yield put(resourceActions.didDeleteTableData(payload))
    yield put(
      notificationActions.showNotification({
        message: `Row(s) deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Row(s) not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

export default function* watchResourcesSaga() {
  yield takeLatest(resourceActions.fetchResource.type, handleFetchResource)
  yield takeLatest(resourceActions.createTable.type, handleCreateTable)
  yield takeLatest(resourceActions.updateTable.type, handleUpdateTable)
  yield takeLatest(resourceActions.deleteTable.type, handleDeleteTable)

  yield takeLatest(resourceActions.fetchTableData.type, handleFetchTableData)
  yield takeLatest(
    resourceActions.createTableColumn.type,
    handleCreateTableColumn
  )
  yield takeLatest(
    resourceActions.updateTableColumn.type,
    handleUpdateTableColumn
  )
  yield takeLatest(
    resourceActions.deleteTableColumn.type,
    handleDeleteTableColumn
  )
  yield takeLatest(resourceActions.createTableData.type, handleCreateTableData)
  yield takeLatest(resourceActions.updateTableData.type, handleUpdateTableData)
  yield takeLatest(resourceActions.deleteTableData.type, handleDeleteTableData)
}
