import { createSelector } from 'reselect'
import { RootState } from '../../app/store'

export const getConnectionsListState = (state: RootState) => state.connections

export const getLoadingState = createSelector(
  getConnectionsListState,
  (state) => state.loading
)

export const getConnectionsState = createSelector(
  getConnectionsListState,
  (state) => state.connections
)

export const getConnectionTypeState = createSelector(
  getConnectionsListState,
  (state) => state.connectionType
)

export const getCheckedState = createSelector(
  getConnectionsListState,
  (state) => state.checked
)

export const getSelectedConnectionState = createSelector(
  getConnectionsListState,
  (state) => state.selectedConnection
)

export const getSearchValueState = createSelector(
  getConnectionsListState,
  (state) => state.searchValue
)

export const getTypeFilterValueState = createSelector(
  getConnectionsListState,
  (state) => state.typeFilterValue
)
