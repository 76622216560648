/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react'
import {
  Button,
  ButtonSimple,
  Checkbox,
  FileUpload,
  IconButton,
  Input,
  Label,
  Menu,
  Popover,
  RTable,
  Select,
} from 'shared'
import classnames from 'classnames'
import { isEqual } from 'lodash'
import { PlusIcon } from 'assets/general'
import moment, { Moment } from 'moment'
import cn from 'classnames'
// import { fileReaderCSV } from 'utils/helpers'
import { DotsVerticalIcon, DuplicateIcon } from '@heroicons/react/outline'
import { connect } from 'react-redux'
import { resourceActions } from '../reducer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getSelectedTableState, getTableDataState } from '../selectors'
import { ITableColumn, ITableData, IUpdateTableColumn } from '../types'
import { ShieldCheckIcon } from '@heroicons/react/solid'
import useCountDown from 'react-countdown-hook'
import { toast } from 'react-toastify'
import { resourcesService } from 'utils/services'
import { useParams } from 'react-router-dom'

// const defaultHeaders: any[] = [
//   { name: 'id', type: 'integer' },
//   { name: 'name', type: 'string' },
// ]

const _types: any[] = [
  { id: 0, name: 'integer', title: 'Integer' },
  { id: 1, name: 'double precision', title: 'Float' },
  { id: 2, name: 'boolean', title: 'Boolean' },
  { id: 3, name: 'text', title: 'Text' },
  { id: 4, name: 'character varying', title: 'String' },
  { id: 5, name: 'json', title: 'JSON' },
  { id: 6, name: 'date', title: 'Date' },
  { id: 7, name: 'timestamp without time zone', title: 'Timestamp' },
]

const getInputClassName = (rowData: any) => {
  return classnames(
    'w-full h-10 p-1.5 sm:p-2 lg:p-4 hover:bg-default-50 outline-none border border-transparent focus:border-primary focus:bg-default-50'
  )
}

const validate = (type: string, value: any) => {
  switch (type) {
    case 'integer':
      return Number.isInteger(Number(value))
    case 'float':
      return Boolean(
        Number.parseFloat(value) && !Number.isInteger(Number.parseFloat(value))
      )
    case 'boolean':
      return value === 'true' || value === 'false'
    case 'date':
      return moment(value, 'YYYY-MM-DD', true).isValid()
    case 'timestamp': {
      var formats = [
        'YYYY-MM-DD LT',
        'YYYY-MM-DD h:mm:ss A',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DD HH:mm',
      ]
      return moment(value, formats, true).isValid()
    }
    default:
      return typeof value === 'string'
  }
}

const getColumnType = (value: string) => {
  let type = 'string'
  _types.some((d) => {
    const valid = validate(d.name, value)
    if (valid) type = d.name
    return valid
  })
  return type
}

const TableInputComponent = React.memo(
  ({ type, rowData, onBlur }: any) => {
    const [value, setValue] = useState<string>(
      rowData.value === null ? JSON.stringify(rowData.value) : rowData.value
    )
    // const [error, setError] = useState<boolean>(false)

    return (
      <input
        type="text"
        className={cn(
          getInputClassName(rowData)
          // rowData.row.original.error === rowData.column.id &&
          //   '!border-secondary focus:!border-secondary'
        )}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          // if (validate(type, value)) {
          // setError(true)
          if (
            !isEqual(
              rowData.value === null
                ? JSON.stringify(rowData.value)
                : rowData.value,
              value
            )
          )
            onBlur({
              id: rowData.row.original.id,
              value: { [rowData.column.id]: value },
              old_value: { ...rowData.row.original },
              // column_name: rowData.column.id,// TODO if need error and comment columns dependencies data
            })
          // } else {
          //   setError(true)
          // }
        }}
      />
    )
  },
  (prev, next) => false
)

function momentRandom(start: Moment, end: Moment) {
  const endTime = +moment(end)
  const randomNumber = (to: number, from = 0) =>
    Math.floor(Math.random() * (to - from) + from)

  if (start) {
    const startTime = +moment(start)
    if (startTime > endTime) {
      throw new Error('End date is before start date!')
    }
    return moment(randomNumber(endTime, startTime))
  }
  return moment(randomNumber(endTime))
}

const generateValue = (type: string) => {
  switch (type) {
    case 'integer':
      return Math.floor(Math.random() * 1000)
    case 'float':
      return (Math.random() * 1000).toFixed(2)
    case 'boolean':
      return Math.random() < 0.5
    case 'string':
      return (Math.random() + 1).toString(36).substring(7)
    case 'date':
      return momentRandom(moment('2012-01-01'), moment()).format('YYYY-MM-DD')
    case 'timestamp':
      return momentRandom(moment('2012-01-01'), moment()).format(
        'YYYY-MM-DD LT'
      )
  }
}

// const getDefaultHeaders = (data: any[]) => {
//   return data.length
//     ? Object.keys(data[0]).map((d) => ({
//         name: d,
//         type: getColumnType(data[0][d]),
//       }))
//     : defaultHeaders
// }

const blurSalt = '$2b$10$uCCtcJ6jiHAhld2MW5OyVu' // Salt to blur style

interface IResourceEditTableProps {}

interface StateProps {
  selectedTable: string | null
  tableData: ITableData
}

type DispatchProps = typeof mapDispatchToProps

const ResourceEditTable: FC<
  IResourceEditTableProps & StateProps & DispatchProps
> = ({
  selectedTable,
  tableData,
  fetchTableData,
  createTableColumn,
  updateTableColumn,
  deleteTableColumn,
  createTableData,
  updateTableData,
  deleteTableData,
}) => {
  const { workspace_id, resource_id } = useParams<any>()
  const [salt, setSalt] = useState<string>('')

  // const resetDropzone = useRef<any>(null)
  const isUsersTable = selectedTable === 'users' // Add generate salt for 'users' table only
  const [timeLeft, { start }] = useCountDown(10000, 1000) // TODO find better

  const copyAddress = useCallback(async () => {
    if (salt) await navigator.clipboard.writeText(salt)
    toast('Salt copied!', { type: 'success' })
  }, [salt])

  useEffect(() => {
    if (selectedTable) fetchTableData()
  }, [selectedTable])

  // const handleGenerateData = () => {
  //   const arr = Array.from(Array(rows)).map((d) => {
  //     const obj = headers.reduce(
  //       (a, v) => ({ ...a, [v.name]: generateValue(v.type) }),
  //       {}
  //     )
  //     return obj
  //   })

  //   setData(arr)
  //   handleResetDropzone()
  // }

  // const handleChangeFile = (value: any) => {
  //   fileReaderCSV(value)
  //     .then((result: any) => {
  //       if (result.data && result.data.length) {
  //         setHeaders(getDefaultHeaders(result.data))
  //         setData(result.data)
  //       }
  //     })
  //     .catch((e) => {})
  // }

  // const handleReset = () => {
  //   setHeaders(defaultHeaders)
  //   setData([])
  //   handleResetDropzone()
  // }

  // const handleResetDropzone = () => {
  //   if (resetDropzone && resetDropzone.current !== null)
  //     resetDropzone.current.reset()
  // }

  const handleGenerateSalt = async () => {
    const result = await resourcesService.generateSalt({
      workspace_id,
      resource_id,
      data: { table_name: selectedTable },
    })
    setSalt(result.auth_salt)
    start()
  }

  const columns = React.useMemo(
    () => [
      ...tableData.columns.map((d: ITableColumn) => ({
        Header: (
          <div className="flex items-center space-x-2">
            {d.secured ? (
              <ShieldCheckIcon className="w-4 h-4 text-success-dark" />
            ) : null}
            <EditColumnButton
              data={d}
              disabled={d.column_name === 'id'}
              save={updateTableColumn}
              _delete={deleteTableColumn}
            >
              {d.column_name + ` (${d.data_type})`}
            </EditColumnButton>
          </div>
        ),
        accessor: d.column_name,
        Cell: (rowData: any) => (
          <TableInputComponent
            type={d.data_type}
            rowData={rowData}
            onBlur={updateTableData}
          />
        ),
      })),
      {
        Header: <AddColumnButton addColumn={createTableColumn} />,
        id: 'action',
        Cell: (rowData: any) => (
          <Menu
            position="static"
            className="rounded text-center" // TODO add translate 50%
            items={[
              {
                label: 'Delete',
                value: 'delete',
                onClick: () => deleteTableData([rowData.row.original.id]),
              },
              {
                label: 'Access rights',
                value: 'access_rights',
                disabled: true,
                onClick: (e: any) => {
                  e.stopPropagation()
                  e.preventDefault()
                },
              },
            ].filter(Boolean)}
          >
            {(onClick: any) => (
              <IconButton
                className="hover:bg-default-200"
                onClick={(e) => {
                  e.stopPropagation()
                  onClick()
                }}
              >
                <DotsVerticalIcon className="h-4 w-4 text-gray-700" />
              </IconButton>
            )}
          </Menu>
        ),
        width: 20,
      },
    ],
    [
      tableData.columns,
      tableData.data, // TODO WHY NEED THIS FIELD. DELETE, UPDATE(input)
      createTableColumn,
      updateTableColumn,
      deleteTableColumn,
    ]
  )

  const saltRender = useMemo(
    () =>
      salt ? (
        <div className="flex items-center px-2 py-0.5 space-x-2 rounded-sm bg-default-100">
          <p
            className={cn(
              'text-default-600',
              timeLeft === 0 ? 'select-none blur-sm pointer-events-none' : ''
            )}
          >
            {timeLeft !== 0 ? salt : blurSalt}
          </p>
          {timeLeft !== 0 ? (
            <div className="flex items-center space-x-1 whitespace-nowrap text-xs">
              <DuplicateIcon
                className="w-4 h-4 cursor-pointer text-default-600 hover:text-secondary"
                onClick={copyAddress}
              />
              <p className="text-primary">
                <strong>{timeLeft / 1000}</strong> sec left
              </p>
            </div>
          ) : null}
        </div>
      ) : null,
    [salt, timeLeft]
  )

  if (!selectedTable)
    return (
      <div className="flex justify-center">
        <p className="text-xl text-secondary">
          Please, select or create table!
        </p>
      </div>
    )

  return (
    <div className="relative h-full flex flex-col space-y-6">
      <div className="relative flex justify-between items-start">
        {isUsersTable ? (
          <div className="flex items-center space-x-2">
            <ButtonSimple
              className="bg-green-100 hover:bg-green-300 text-default-700 self-start px-2.5 py-1 text-sm rounded"
              onClick={handleGenerateSalt}
            >
              Generate Salt
            </ButtonSimple>
            {saltRender}
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex space-x-4">
          <div className="flex items-center text-md mr-2 space-x-2">
            <div className="flex space-x-1">
              <p>R:</p>{' '}
              <strong className="text-pink-500">{tableData.data.length}</strong>
            </div>
            <div className="flex space-x-1">
              <p>C:</p>{' '}
              <strong className="text-pink-500">
                {tableData.columns.length}
              </strong>
            </div>
          </div>
          <ButtonSimple
            className="bg-green-100 hover:bg-green-300 text-default-700 self-start h-H px-2.5 py-1 text-sm rounded"
            disabled={tableData.columns.length < 2}
            onClick={() =>
              createTableData({
                values: [
                  tableData.columns
                    .filter((col: ITableColumn) => col.column_name !== 'id')
                    .reduce(
                      (a: any, v: ITableColumn) => ({
                        ...a,
                        [v.column_name]: null,
                      }),
                      {}
                    ),
                ],
              })
            }
          >
            Add Row
          </ButtonSimple>
          {/* <ButtonSimple
            className="bg-pink-100 hover:bg-pink-300 text-default-700 self-start px-2.5 py-1 text-sm rounded"
            disabled
            onClick={() => {}}
          >
            Reset
          </ButtonSimple> */}
          {/* <div className="flex items-center space-x-4">
            <Label
              alignLabel="left"
              label="Number of rows"
              size="md"
              weight="medium"
            >
              <Input
                type="number"
                name="rows"
                width="28"
                disabled
                value={rows}
                onChange={(e: any) => setRows(+e.target.value)}
              />
            </Label>
            <Button
              className="whitespace-nowrap self-center"
              disabled // </div>={tableData.columns.length < 2}
              onClick={() => {
                // const values: any[] = []
                // const cols = tableData.columns.filter(
                //   (col: ITableColumn) => col.column_name !== 'id'
                // )
                // for (let i = 0; i < rows; i++) {
                //   values.push(
                //     cols.reduce(
                //       (a: any, v: ITableColumn) => ({
                //         ...a,
                //         [v.column_name]: null,
                //       }),
                //       {}
                //     )
                //   )
                // }
                // createTableData({ values })
              }}
            >
              Generate Data
            </Button>
          </div>
          <div className="flex items-center space-x-4">
            <Label
              width="auto whitespace-nowrap"
              label="Upload CSV"
              size="md"
              weight="medium"
            />
            <div className="flex flex-col h-full w-full disabled">
              <FileUpload
                ref={resetDropzone}
                className="h-H"
                inputClass="justify-center"
                accept={{ 'text/csv': ['.csv'] }}
                type="file"
                width="full"
                height="full"
                setFile={handleChangeFile}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="inline-block flex-1 overflow-hidden min-w-full align-middle">
        <div className="h-full overflow-auto shadow-md border border-gray-200 rounded-md ring-1 ring-black ring-opacity-5">
          <RTable
            columns={columns}
            data={tableData.data}
            tableClassName="min-w-full relative"
            theadClassName="bg-default-100 sticky top-0 z-10"
            tbodyClassName="bg-white"
            getHeaderProps={(header, i) => ({
              className: classnames(
                'text-left text-md font-medium text-default-700 truncate p-1.5 sm:p-2 lg:p-4'
              ),
              style: {
                width: header.width,
                minWidth: header.minWidth,
              },
            })}
            getRowProps={() => ({
              className: classnames('group relative'),
            })}
            getCellProps={(cell, i) => ({
              className: classnames(
                'whitespace-nowrap truncate hover:bg-default-50',
                { 'hover:bg-white': cell.column.id === 'action' },
                cell.column.id === 'id' && 'pointer-events-none'
              ),
              style: {
                width: cell.column.width,
                minWidth: cell.column.minWidth,
              },
            })}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedTable: getSelectedTableState,
  tableData: getTableDataState,
})

const mapDispatchToProps = {
  fetchTableData: resourceActions.fetchTableData,
  createTableColumn: resourceActions.createTableColumn,
  updateTableColumn: resourceActions.updateTableColumn,
  deleteTableColumn: resourceActions.deleteTableColumn,
  createTableData: resourceActions.createTableData,
  updateTableData: resourceActions.updateTableData,
  deleteTableData: resourceActions.deleteTableData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceEditTable)

export const EditColumnButton: FC<any> = ({
  children,
  data,
  disabled,
  save,
  _delete,
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const handleShowPopover = (value: boolean) => {
    setShowPopover(value)
  }
  return (
    <>
      <button disabled={disabled} onClick={() => handleShowPopover(true)}>
        {children}
      </button>
      <Popover
        className="mt-80"
        show={showPopover}
        handleShow={handleShowPopover}
      >
        <EditColumn
          data={data}
          save={save}
          _delete={_delete}
          close={() => handleShowPopover(false)}
        />
      </Popover>
    </>
  )
}

export const AddColumnButton: FC<any> = ({ addColumn }) => {
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const handleShowPopover = (value: boolean) => {
    setShowPopover(value)
  }
  return (
    <div className="flex justify-center">
      <button onClick={() => handleShowPopover(true)}>
        <PlusIcon />
      </button>
      <Popover
        className="mr-52"
        show={showPopover}
        handleShow={handleShowPopover}
        // button={
        // <button className="" onClick={() => handleShowPopover(true)}>
        // <PlusIcon />
        // </button>
        // }
      >
        <AddColumn
          addColumn={addColumn}
          close={() => handleShowPopover(false)}
        />
        {/* <AddNewColumnPopoverContainer handleClose={handleShowPopover} /> */}
      </Popover>
    </div>
  )
}

const EditColumn: FC<any> = ({ data, save, _delete, close }) => {
  const [inputValue, setInputValue] = useState<string>(data.column_name || '')
  const [type, setType] = useState<any>(
    () => _types.filter((d) => d.name === data.data_type)[0]
  )
  const [secured, setSecured] = useState<boolean>(false)

  const handleChangeInput = (value: string) => {
    setInputValue(value)
  }

  const handleChangeSelect = (value: any) => {
    setType(value)
  }

  return (
    <div className="relative space-y-6 h-full flex flex-col p-2.5 bg-white shadow">
      <div className="">
        <Label label={'Column name'} size="md" weight="medium" />
        <Input
          type="text"
          name="name"
          width="full"
          value={inputValue}
          placeholder={'Column name'}
          onChange={(e: any) => handleChangeInput(e.target.value)}
        />
      </div>
      <div className="">
        <Label label={'Column type'} size="md" weight="medium" />
        <Select
          classes={{ buttonTitle: 'pr-8 truncate' }}
          options={_types}
          size={220}
          placeholder={type?.title || 'Select...'}
          value={type}
          onChange={(value: any) => handleChangeSelect(value)}
        />
      </div>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <Checkbox
            name="column-security"
            checked={secured}
            onChange={(e: any) => setSecured(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="column-security" className=" text-gray-700">
            Safe column
          </label>
        </div>
      </div>
      <div className="flex justify-between">
        <Button color="default-500" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          // disabled={isSaveDisabled}
          onClick={() => {
            _delete(data.column_name)
            close()
          }}
        >
          Delete
        </Button>
        <Button
          color="primary"
          disabled={!data.column_name && !inputValue}
          onClick={() => {
            const obj: IUpdateTableColumn = {
              column_name: data.column_name,
            }
            if (data.column_name !== inputValue)
              obj.new_column_name = inputValue
            if (data.data_type !== type.name) obj.new_data_type = type.name
            if (data.secured !== secured) obj.secured = secured

            save(obj)
            close()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const AddColumn: FC<any> = ({ addColumn, close }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [type, setType] = useState<any>(_types[0])
  const [secured, setSecured] = useState<boolean>(false)

  const handleChangeInput = (value: string) => {
    setInputValue(value)
  }

  const handleChangeSelect = (value: any) => {
    setType(value)
  }

  return (
    <div className="relative space-y-6 h-full flex flex-col p-2.5 bg-white shadow">
      <div className="">
        <Label label={'Column name'} size="md" weight="medium" />
        <Input
          type="text"
          name="name"
          width="full"
          value={inputValue}
          placeholder={'Column name'}
          onChange={(e: any) => handleChangeInput(e.target.value)}
        />
      </div>
      <div className="">
        <Label label={'Data type'} size="md" weight="medium" />
        <Select
          classes={{ buttonTitle: 'pr-8 truncate' }}
          options={_types}
          size={220}
          placeholder={type?.title || 'Select...'}
          value={type}
          onChange={(value: any) => handleChangeSelect(value)}
        />
      </div>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <Checkbox
            name="column-security"
            checked={secured}
            onChange={(e: any) => setSecured(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="column-security" className=" text-gray-700">
            Safe column
          </label>
        </div>
      </div>
      <div className="flex justify-between">
        <Button color="default-500" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!inputValue}
          onClick={() => {
            addColumn({
              column_name: inputValue,
              data_type: type.name,
              secured,
            })
            close()
          }}
        >
          Create
        </Button>
      </div>
    </div>
  )
}
