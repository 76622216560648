import { timeFormat } from 'd3'

import {
  DatesType,
  DetailedDatesType,
  DetailedDateType,
  DatesIntervalType,
  TimesType,
  TITLE_TYPES,
  MONTHS_TYPES,
} from './types'

export const convertDates = ({ start, end }: DatesType): DetailedDatesType => {
  return {
    start: {
      year: start.getFullYear(),
      month: start.getMonth() + 1,
      day: start.getDate(),
      hour: start.getHours(),
      minute: start.getMinutes(),
      second: start.getSeconds(),
    },
    end: end
      ? {
          year: end.getFullYear(),
          month: end.getMonth() + 1,
          day: end.getDate(),
          hour: end.getHours(),
          minute: end.getMinutes(),
          second: end.getSeconds(),
        }
      : undefined,
  }
}

export const getRange = ({ start, end }: DatesIntervalType): string => {
  let delta: number = (end.getTime() - start.getTime()) / 1000

  if (delta % 10 === 9) delta += 1

  let prefix = ''
  if (delta < 0) {
    delta = -delta
    prefix = '-'
  }

  const year: number = Math.floor(delta / 3.154e7)
  delta -= year * 3.154e7
  const month: number = Math.floor(delta / 2.628e6)
  delta -= month * 2.628e6
  const day: number = Math.floor(delta / 86400)
  delta -= day * 86400
  const hour: number = Math.floor(delta / 3600)
  delta -= hour * 3600
  const minute: number = Math.floor(delta / 60)
  delta -= minute * 60
  const second: number = Math.floor(delta)

  return (
    prefix +
    (year ? year + 'y' : '') +
    (month ? month + 'mo' : '') +
    (day && !year ? day + 'd' : '') +
    (hour && !month && !year ? hour + 'h' : '') +
    (minute && !day && !month && !year ? minute + 'm' : '') +
    (second && !hour && !day && !month && !year ? second + 's' : '')
  )
}

export const getDatesAndRange = ({
  dates,
  delta,
  unix,
}: TimesType): {
  dates: DatesIntervalType
  range: string
} => {
  const start: DetailedDateType | undefined = dates?.start
  const end: DetailedDateType | undefined = dates?.end

  let endDate: Date, startDate: Date

  endDate = new Date()
  startDate = new Date()
  if (delta) {
    startDate.setFullYear(
      endDate.getFullYear() - (delta.year || 0),
      endDate.getMonth() - (delta.month || 0),
      endDate.getDate() - (delta.day || 0)
    )
    startDate.setHours(
      endDate.getHours() - (delta.hour || 0),
      endDate.getMinutes() - (delta.minute || 0),
      endDate.getSeconds() - (delta.second || 0)
    )
  } else if (start) {
    const today: Date = new Date()
    startDate.setFullYear(
      start.year || today.getFullYear(),
      start.month ? start.month - 1 : start.year ? 0 : today.getMonth(),
      start.day || (start.month || start.year ? 1 : today.getDate())
    )
    startDate.setHours(
      start.hour ||
        (start.day || start.month || start.year ? 0 : today.getHours()),
      start.minute ||
        (start.hour || start.day || start.month || start.year
          ? 0
          : today.getMinutes()),
      start.second ||
        (start.minute || start.hour || start.day || start.month || start.year
          ? 0
          : today.getSeconds())
    )

    if (!end) {
      endDate.setFullYear(
        start.year ? start.year + (!start.month ? 1 : 0) : today.getFullYear(),
        start.month
          ? start.month - (!start.day ? 0 : 1)
          : start.year
          ? 0
          : today.getMonth(),
        start.day
          ? start.day + (!start.hour ? 1 : 0)
          : start.month || start.year
          ? 1
          : today.getDate()
      )
      endDate.setHours(
        start.hour
          ? start.hour + (!start.minute ? 1 : 0)
          : start.day || start.month || start.year
          ? 0
          : today.getHours(),
        start.minute
          ? start.minute + (!start.second ? 1 : 0)
          : start.hour || start.day || start.month || start.year
          ? 0
          : today.getMinutes(),
        start.second
          ? start.second + 1
          : start.minute || start.hour || start.day || start.month || start.year
          ? -1
          : today.getSeconds()
      )
    } else {
      endDate.setFullYear(
        end.year ? end.year : today.getFullYear(),
        end.month ? end.month - 1 : end.year ? 0 : today.getMonth(),
        end.day ? end.day : end.month || end.year ? 1 : today.getDate()
      )
      endDate.setHours(
        end.hour
          ? end.hour
          : end.day || end.month || end.year
          ? 0
          : today.getHours(),
        end.minute
          ? end.minute
          : end.hour || end.day || end.month || end.year
          ? 0
          : today.getMinutes(),
        end.second
          ? end.second
          : end.minute || end.hour || end.day || end.month || end.year
          ? 0
          : today.getSeconds()
      )
    }
  } else if (unix) {
    startDate.setTime(unix.start)
    if (unix.end) endDate.setTime(unix.end)
  }

  return {
    dates: { start: startDate, end: endDate },
    range: getRange({ start: startDate, end: endDate }),
  }
}

export const getTitleTypeTimeFormat = (
  dates: DatesIntervalType
): {
  type: string
  title: string
} => {
  const today: Date = new Date()

  const type: TITLE_TYPES =
    today.getFullYear() !== dates.start.getFullYear() ||
    today.getFullYear() !== dates.end.getFullYear()
      ? TITLE_TYPES.YEAR
      : TITLE_TYPES.DEFAULT

  const getTimeFormat: (date: Date) => string = timeFormat(
    type !== TITLE_TYPES.YEAR ? '%b %d, %I:%M %p' : '%b %d, %Y, %I:%M %p'
  )

  return {
    type,
    title: getTimeFormat(dates.start) + ' - ' + getTimeFormat(dates.end),
  }
}

export const parseTitle = (
  title: string
): {
  dates: DatesIntervalType
  error: string
} => {
  let dates: [Date, Date] = [new Date(), new Date()]

  let error: string = ''

  if (title) {
    title.split(' - ').forEach((date: string, index) => {
      const dateElements: string[] = date.split(' ')

      if (!dateElements[0]) return

      let ind = 0
      const month: any = MONTHS_TYPES[dateElements[ind].toUpperCase() as any] // number

      if (month >= 0) {
        dates[index].setMonth(month)
      } else {
        //dates[index].setMonth(0)
        error = 'Wrong month'
      }

      ind++

      const day: number = +(dateElements[ind][0] + dateElements[ind][1])

      if (day > 0 && day < 32) {
        dates[index].setDate(day)
      } else {
        //dates[index].setDate(1)
        error = 'Wrong day'
      }

      ind++

      if (dateElements.length === 5) {
        const year = +dateElements[ind]

        dates[index].setFullYear(year)

        ind++
      }

      const [_hour, _minute]: string[] = dateElements[ind].split(':')

      const hour: number = Number.parseInt(_hour)
      const minute: number = Number.parseInt(_minute)

      if (hour >= 0 && hour < 13) {
        dates[index].setHours(hour)
      } else {
        //dates[index].setHours(0)
        error = 'Wrong hours'
      }

      if (minute >= 0 && minute < 60) {
        dates[index].setMinutes(minute)
      } else {
        //dates[index].setMinutes(0)
        error = 'Wrong minutes'
      }

      ind++

      const m: string = dateElements[ind]?.toLowerCase()

      if (m === 'pm' || m === 'am') {
        dates[index].setHours(
          dates[index].getHours() +
            (m === 'pm' && hour < 12
              ? 12
              : +hour === 12 && +minute === 0
              ? -12
              : 0)
        )
      } else {
        error = 'Wrong time of day'
      }
    })

    if (dates[0] > dates[1]) error = 'Wrong time of day'
  }

  return {
    dates: { start: dates[0], end: dates[1] },
    error,
  }
}

export const getMask = (name: string | undefined): (string | RegExp)[] => {
  switch (name) {
    case 'datepicker':
      return [
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        ',',
        ' ',
        /[0-9]/,
        /[0-9]/,
        ':',
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[A-Za-z]/,
        /[A-Za-z]/,
        ' ',
        '-',
        ' ',
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        ',',
        ' ',
        /[0-9]/,
        /[0-9]/,
        ':',
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[A-Za-z]/,
        /[A-Za-z]/,
      ]

    default:
      return [
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        ',',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ',',
        ' ',
        /[0-9]/,
        /[0-9]/,
        ':',
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[A-Za-z]/,
        /[A-Za-z]/,
        ' ',
        '-',
        ' ',
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        ',',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ',',
        ' ',
        /[0-9]/,
        /[0-9]/,
        ':',
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[A-Za-z]/,
        /[A-Za-z]/,
      ]
  }
}
