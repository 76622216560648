import React, { Suspense, ComponentType } from 'react'
import { Provider, ConnectedComponent } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'

import SignIn from 'pages/SignIn'

import { history, Routes } from 'utils/router'

import PrivateRoute from './PrivateRoute'
import App from './App'
import store from './store'

const publicRoutes: [string, ComponentType | ConnectedComponent<any, any>][] = [
  // ['/register', SignUpPage],
  [Routes.path.SignIn, SignIn],
  // ['/signup', SignUpPage],
  // ['/forgot-password', ForgotPasswordPage],
  // ['/reset-password', ChangePasswordPage],
]

const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          {publicRoutes.map(([route, Comp]: [string, any]) => (
            <Route path={route} component={Comp} key={route} />
          ))}

          <PrivateRoute
            path={[
              Routes.path.Root,
              Routes.path.Resources,
              Routes.path.DefaultResources,
              Routes.path.Queries,
              Routes.path.DefaultQueries,

              Routes.path.Main,
              // Routes.Projects(),
              Routes.path.Project,
              Routes.path.Dataset,
              Routes.path.DataCatalog,
              Routes.path.Clusters,
              Routes.path.Monitoring,
              Routes.path.Admin,
            ]}
            component={
              <Suspense fallback={<div>Loading...</div>}>
                <App />
              </Suspense>
            }
          />

          {/* <Redirect from={Routes.Root()} to={Routes.Projects()} exact /> */}
          <Route path="*">
            <div>Not found page</div>
          </Route>
        </Switch>
      </Router>
    </Provider>
  )
}

export default Root
