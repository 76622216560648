// MAIN VARIANT TYPES

export type VARIANT_TYPES = 'OUTLINED' | 'CONTAINED'

// MAIN COLOR TYPES

export type COLOR_TYPES =
  | 'default-50'
  | 'default-100'
  | 'default-150'
  | 'default-200'
  | 'default-300'
  | 'default-400'
  | 'default-500'
  | 'default-600'
  | 'default-700'
  | 'default-800'
  | 'default-900'
  | 'primary'
  | 'primary-light'
  | 'primary-dark'
  | 'primary-500'
  | 'secondary'
  | 'success'
  | 'black'
  | 'black-light'
  | 'black-lighter'
  | 'white'
  | 'white-dark'
  | 'green-500'
  | 'blue-500'
  | 'yellow-500'
  | 'red-500'

// MAIN SIZE TYPES

export type SIZE_TYPES =
  | 'xs'
  | 'sm'
  | 'md'
  | 'md_plus'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'

// MAIN WEIGHT TYPES

export type WEIGHT_TYPES =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black'

// GENERAL UTILITIES

export const wSize = (size: SIZE_TYPES | string | number) => `w-${size}`

export const hSize = (size: SIZE_TYPES | string | number) => `h-${size}`

export const bgColor = (color: COLOR_TYPES | string) => `bg-${color}`

export const borderColor = (color: COLOR_TYPES | string) => `border-${color}`

export const textColor = (color: COLOR_TYPES) => `text-${color}`

export const textSize = (size: SIZE_TYPES | string) => `text-${size}`

export const fontWeight = (weight: WEIGHT_TYPES) => `font-${weight}`

export const pSize = (size: SIZE_TYPES) => `p-${size}`

export const pxSize = (size: SIZE_TYPES) => `px-${size}`

export const pySize = (size: SIZE_TYPES) => `py-${size}`

// BUTTON, BADGE UTILITIES

export const buttonColor = (
  variant: VARIANT_TYPES,
  color: COLOR_TYPES,
  isShadow: boolean
) => {
  const defaultColor = color === 'default-300'
  return variant === 'OUTLINED'
    ? `
      border
      ${borderColor(color)}
      ${bgColor('white')}
      ${textColor(color)}
      hover:${bgColor(color)}
      hover:${textColor('white')}
      `
    : `
      ${bgColor(color)}
      ${textColor(defaultColor ? 'black' : 'white')}
      ${isShadow && `hover:shadow-${color}`}
    `
}

export const buttonSize = (size: SIZE_TYPES) => `
  px-md ${pySize(size)} ${textSize(size)}
`

export const getStatusColor = (
  status: string | 'active' | 'inactive'
): COLOR_TYPES => {
  switch (status) {
    case 'active':
      return 'success'
    case 'inactive':
      return 'default-600'
    default:
      return 'default-600'
  }
}
