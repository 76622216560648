import { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Input, Label, SelectNew } from 'shared'
import { queriesActions } from '../reducer'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { getResourcesState } from 'pages/Resources/selectors'
import { IResources } from 'pages/Resources/types'
import { QueriesItemType } from '../types'

interface QueryResourceContainerProps {
  query: QueriesItemType
  className?: string
}

type StateProps = {
  resources: IResources[]
}

type DispatchProps = typeof mapDispatchToProps

const QueryResourceContainer: FC<
  QueryResourceContainerProps & StateProps & DispatchProps
> = ({ query, resources, className, updateQuery }) => {
  const [postQuery, setPostQuery] = useState<string>(
    query?.post_query?.join(',') || ''
  )
  const [selected, setSelected] = useState<any>(() => {
    if (resources.length && query.resource_id) {
      const resource = resources.find(
        (d) => d.resource_id === query.resource_id
      )

      return resource
        ? { id: resource.resource_id, name: resource.name, type: resource.type }
        : null
    }
    return null
  })

  useEffect(() => {
    if (resources.length)
      setSelected(() => {
        if (query.resource_id) {
          const resource = resources.find(
            (d) => d.resource_id === query.resource_id
          )

          return resource
            ? { id: resource.resource_id, name: resource.name }
            : null
        }
        return null
      })
  }, [resources])

  const handleSetSelected = (value: any) => {
    setSelected(value)
    updateQuery({
      query_id: query.query_id,
      data: {
        resource_id: value.id,
      },
    })
  }

  // const handleSetPostQuery = (value: any) => {
  //   // TODO better with checkChangedTab
  //   // checkChangedTab({
  //   //   query_id: query.query_id,
  //   //   data: {
  //   //     name: 'auth',
  //   //     value: { ...(query.auth || {}), token },
  //   //   },
  //   // })
  //   const post_query = postQuery.trim()
  //     ? postQuery.split(',').map((d) => d.trim())
  //     : []
  //   if (postQuery !== query.query_id)
  //     updateQuery({
  //       query_id: query.query_id,
  //       data: {
  //         post_query,
  //       },
  //     })
  // }

  return (
    <div
      data-label="query-resource-container"
      className={classnames('flex px-4 py-2 ml-40', className)}
    >
      <Label width="full" label="Resource" size="sm" alignLabel="left">
        <SelectNew
          selected={selected}
          options={resources.map((d: IResources) => ({
            id: d.resource_id,
            name: d.name,
            type: d.type
          }))}
          width="w-full"
          onChange={handleSetSelected}
        />
      </Label>
      {/* <Label width="fit" label="Post query:" size="sm" alignLabel="left">
        <Input
          type="text"
          name="post_query"
          // className='!w-[20rem]'
          placeholder="Query id(s)"
          value={postQuery}
          onChange={(e: any) => setPostQuery(e.target.value)}
          onBlur={handleSetPostQuery}
        />
      </Label> */}
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  resources: getResourcesState,
})

const mapDispatchToProps = {
  updateQuery: queriesActions.updateQuery,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryResourceContainer)
