import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { Avatar, IconButton, Menu, Typography } from 'shared'
import { ClockIcon, MenuVerticalDotIcon } from 'assets/general'
import { UserIcon } from 'assets/general/UserIcon'
import { PaperClipIcon } from 'assets/general/PaperClipIcon'
import { PlusIcon } from 'assets/general/PlusIcon'
import { useHistory } from 'react-router-dom'
import { Routes } from 'utils/router'
import { ProjectsItemType } from '../types'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'

interface PItemProps {
  item: ProjectsItemType
}

type DispatchProps = typeof mapDispatchToProps

const OptionItem: FunctionComponent<{
  item: {
    name: string
    value: string | number
  }
}> = ({ item }) => {
  return (
    <div className="relative mt-t15 first:mt-0">
      <Typography weight="bold" size="md">
        {item.name}:
      </Typography>
      <Typography
        className="absolute top-0 left-16"
        color="default-600"
        size="md"
      >
        {item.value}
      </Typography>
    </div>
  )
}

const FooterItem: FunctionComponent = () => {
  return (
    <div className="flex justify-between mt-t15">
      <div className="flex">
        <div className="flex mr-md items-center">
          <UserIcon className="mr-xs" color="default-700" />
          <Typography size="md">5</Typography>
        </div>
        <div className="flex items-center">
          <PaperClipIcon className="mr-xs" color="default-700" />
          <Typography size="md">3</Typography>
        </div>
      </div>
      <div className="flex">
        <Avatar
          src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          size="md"
          className="rounded-tl-none rounded-full mr-sm"
          onClick={(e) => {
            e.stopPropagation()
            console.log('avatar image')
          }}
        />
        <Avatar
          src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
          size="md"
          className="rounded-tl-none rounded-full mr-sm"
          onClick={(e) => {
            e.stopPropagation()
            console.log('avatar image')
          }}
        />
        <Avatar
          size="md"
          className="rounded-tl-none rounded-full mr-sm"
          onClick={(e) => {
            e.stopPropagation()
            console.log('avatar image')
          }}
        />
        <div
          className="flex items-center justify-center cursor-pointer border border-default-500 border-dashed rounded-tl-none rounded-full w-md h-md"
          onClick={(e) => {
            e.stopPropagation()
            console.log('add avatar')
          }}
        >
          <PlusIcon color="secondary" size="sm" />
        </div>
      </div>
    </div>
  )
}

const ProjectsItem: FunctionComponent<PItemProps & DispatchProps> = ({
  item,
  showModal,
}) => {
  const history = useHistory()
  return (
    <div
      className="relative p-t20 rounded shadow bg-white cursor-pointer"
      onClick={() => history.push(Routes.url.Project(item.project_id))}
    >
      <Menu
        position="static"
        items={[
          {
            label: 'Edit',
            onClick: () =>
              showModal({
                type: ModalTypes.CreateNewProject,
                config: {
                  ...ModalOptions.CreateNewProject,
                },
                params: {
                  project: item,
                },
              }),
          },
        ].filter(Boolean)}
      >
        {(onClick: any) => (
          <IconButton
            className="absolute right-t15"
            onClick={(e: any) => {
              e.stopPropagation()
              onClick()
            }}
          >
            <MenuVerticalDotIcon width={'16px'} height={'16px'} size={null} />
          </IconButton>
        )}
      </Menu>
      <Typography className="flex items-center" color="default-500" size="md">
        <ClockIcon className="mr-sm" color="default-400" />
        {item.created_at}
      </Typography>
      <Typography className="mt-t5" size="xl" color="primary-500" weight="bold">
        {item.name}
      </Typography>
      <Typography className="mt-t5" color="default-600" size="md">
        {item.description}
      </Typography>
      <div className="py-t20 border-b">
        <OptionItem item={{ name: 'Tables', value: 'Some value' }} />
        <OptionItem item={{ name: 'Size', value: 'Some value' }} />
        <OptionItem item={{ name: 'Cluster', value: item.cluster_id }} />
      </div>
      <FooterItem />
    </div>
  )
}

const mapDispatchToProps = {
  showModal: modalActions.showModal,
}

export default connect(null, mapDispatchToProps)(ProjectsItem)
