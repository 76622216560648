import { VariableProps } from '../../types'
import { VariableNameSymbols } from '../../utils'
import { tryStringToJSON } from 'utils/helpers'

export const validateValue = (value: string) => {
  const _try = tryStringToJSON(value)

  return typeof _try !== 'string' || (_try[0] !== '[' && _try[0] !== '{')
    ? _try
    : undefined
}

export const uniqueName = (name: string, i: any) =>
  name + '_' + (new Date().getTime() % 1e10) + i

export const checkСompliance = (str: string) => {
  return !str || str.split('').find((el) => !VariableNameSymbols.test(el))
    ? false
    : true
}

export const getUniqueName = (
  name: string,
  arr: { name: string }[],
  _is: (name: string, arr: any) => boolean,
  i = 0
): string => {
  const is = _is(name, arr)

  return is ? name : getUniqueName(uniqueName(name, i), arr, _is)
}

export const isUniqueName = (name: string, arr: { name: string }[]) => {
  return arr.filter((el: any) => el.name === name).length < 2
}
export const getUniqueNameAll = (
  name: string,
  arr: { name: string }[],
  i: number
) =>
  getUniqueName(
    name,
    arr,
    (_name: any, _arr: any) => isUniqueNameAll(_name, _arr, i),
    i
  )

export const isUniqueNameAll = (
  name: string,
  arr: { name: string }[],
  i: number
) => {
  if (isUniqueName(name, arr)) {
    return true
  } else {
    const ind = arr.findIndex((el: any) => el.name === name)
    return ind >= 0 && ind === i
  }
}

export const variablesTypeToJsonType = (variables: VariableProps[]) => {
  const json: any = {}

  variables.forEach((el) => {
    json[el.name] = el.value
  })

  return json
}

export const jsonTypeToVariablesType = (json: any) => {
  const variables: any[] = []
  Object.keys(json).forEach((key) => {
    variables.push({
      name: key,
      value: json[key],
    })
  })

  return variables
}
