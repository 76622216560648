import { FunctionComponent, useEffect } from 'react'
import PRootContainer from 'containers/Page/PRootContainer'
import QueriesCatalog from './containers/QueriesCatalog'
import QueryTabs from './containers/QueryTabs'
import { queriesActions } from './reducer'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

export const someData = [
  {
    name: 'Test Name',
    value: 'test value',
    test: 'test',
    test1: '1421',
    'test-test': 'aaaa',
    'value-test': 'afsdf',
  },
  {
    name: 'Test Name',
    value: 'test value',
    test: 'test',
    test1: '1421',
    'test-test': 'aaaa',
    'value-test': 'afsdf',
  },
  {
    name: 'Test Name',
    value: 'test value',
    test: 'test',
    test1: '1421',
    'test-test': 'aaaa',
    'value-test': 'afsdf',
  },
  {
    name: 'Test Name',
    value: 'test value',
    test: 'test',
    test1: '1421',
    'test-test': 'aaaa',
    'value-test': 'afsdf',
  },
  {
    name: 'Test Name',
    value: 'test value',
    test: 'test',
    test1: '1421',
    'test-test': 'aaaa',
    'value-test': 'afsdf',
  },
]

type DispatchProps = typeof mapDispatchToProps

const Queries: FunctionComponent<DispatchProps> = ({ fetchQueries }) => {
  const { workspace_id } = useParams<any>()
  useEffect(() => {
    if (workspace_id) fetchQueries(workspace_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_id])

  return (
    <PRootContainer
      data-label="queries-page-container"
      className="relative overflow-hidden grid grid-cols-5"
    >
      <QueriesCatalog className="w-full z-10" />

      <QueryTabs className="col-span-4 h-full" />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchQueries: queriesActions.fetchQueries,
}

export default connect(null, mapDispatchToProps)(Queries)
