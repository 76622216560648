import React from 'react'

import classNames from 'classnames'
import { IconProps, Icon } from '../Icon'

export const UserIcon: React.FunctionComponent<IconProps> = (props) => {
  return (
    <Icon {...props} className={classNames('fill-current', props.className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clipRule="evenodd"
        />
      </svg>
    </Icon>
  )
}
