/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { useWindowSize } from 'utils/hooks'
import { getChartWidth, getTextParams, dateFormater } from './helpers'
import {
  LegendDataTypeT,
  LegendParamsTypeT,
  LegendPointType,
  ChartStoreType,
  SeriesPointTypeT,
  StackedBarChartProps,
  DataTypeT,
  STypeT,
  СurentValueTypeT,
  СurrentValueElementTypeT,
  SelectionTypeT,
  TextPararamsType,
} from './types'

const getLegendDxDy = (
  chartStore: any,
  legendDx: number,
  legendData: LegendDataTypeT,
  maxLegendWidth: number
): LegendParamsTypeT =>
  legendData.map((d, i) => {
    let h: number = 0,
      w: number = 0,
      fullW: number = 0

    legendData.slice(0, i).forEach((el, ind) => {
      w += legendDx + chartStore.getTextParams(el.name).width
      fullW = w + chartStore.getTextParams(legendData[ind + 1].name).width

      if (fullW > maxLegendWidth) {
        fullW = 0
        w = 0
        h += 25 + Math.floor(fullW / maxLegendWidth) * chartStore.fontSize + 3
      }
    })

    const fullH =
      h +
      25 +
      Math.floor(
        (legendDx + chartStore.getTextParams(d.name).width) / maxLegendWidth
      ) *
        chartStore.fontSize +
      3

    return {
      name: d.name,
      w,
      h,
      fullH,
      fullW,
    }
  })

// calculation of width and coordinates for legend elements
const getlegendParams = (
  chartStore: any,
  legendData: LegendDataTypeT
): LegendParamsTypeT => {
  const maxLegendDx = 80
  const maxLegendWidth: number = chartStore.width

  let legendDx: number = 50

  const legendDxDy = getLegendDxDy(
    chartStore,
    legendDx,
    legendData,
    maxLegendWidth
  )

  legendDx +=
    (maxLegendWidth - d3.max(legendDxDy, (d: any) => d.fullW)) /
    legendData.length

  if (legendDx > maxLegendDx) legendDx = maxLegendDx

  return getLegendDxDy(chartStore, legendDx, legendData, maxLegendWidth)
}

const prepareStore = async (
  chartStore: ChartStoreType,
  {
    data,
    minWidth,
    minHeight,
    margin,
    colors,
  }: //transitionDuration,
  StackedBarChartProps,
  element: HTMLDivElement | null
) => {
  chartStore.data = data
  chartStore.root = d3.select(element)

  chartStore.fullWidth = getChartWidth(element, minWidth)
  chartStore.fullHeight = minHeight
  chartStore.margin = margin
  chartStore.width =
    chartStore.fullWidth - chartStore.margin.left - chartStore.margin.right
  chartStore.height =
    chartStore.fullHeight - chartStore.margin.top - chartStore.margin.bottom

  chartStore.getColor = (key: string) =>
    colors[valueKeys.findIndex((el): boolean => el === key)]

  // chartStore.trans = d3.transition().duration(transitionDuration).ease(d3.easeQuadOut)
  // chartStore.transDur = transitionDuration

  chartStore.xBandwidth = (data: DataTypeT | null, x: any) => {
    if (data) {
      const preWidth: number = d3
        .scaleBand()
        .padding(0.1)
        .domain(d3.range(0, data.length - 1).map((el) => el.toString()))
        .range([x(data[0].date), x(data[data.length - 1].date)])
        .bandwidth()

      return preWidth > chartStore.width / 10 ? chartStore.width / 10 : preWidth
    } else return 0
  }

  const valueKeys: string[] = Object.keys(data[0]).filter(
    (key) => key !== 'total' && key !== 'date'
  )

  chartStore.series = d3.stack().keys(valueKeys)(data)

  chartStore.xDefaultDomain = () => {
    const startData: Date = new Date((chartStore.data || data)[0].date)
    const endData: Date = new Date(
      (chartStore.data || data)[data.length - 1].date
    )

    startData.setTime(
      startData.getTime() - (endData.getTime() - startData.getTime()) / 20
    )

    endData.setTime(
      endData.getTime() + (endData.getTime() - startData.getTime()) / 20
    )

    return [startData, endData]
  }

  chartStore.x.domain(chartStore.xDefaultDomain()).range([0, chartStore.width])
  chartStore.xAxis = d3.axisBottom(chartStore.x).tickSize(10)

  chartStore.y
    .domain([
      0,
      (d3.max(chartStore.series, (s: STypeT) =>
        d3.max(s, (point: SeriesPointTypeT) => point[1])
      ) || 0) * 1.1,
    ])
    .range([chartStore.height, 0])

  chartStore.yAxis = d3
    .axisLeft(chartStore.y)
    .ticks(5)
    .tickSize(chartStore.width + 10)

  chartStore.brush
    .extent([
      [0, 0],
      [chartStore.width, chartStore.height],
    ])
    .on('start', () => removeTooltip(chartStore))
    .on('end', (e: any) => updateWithZoom(e, chartStore))

  chartStore.xAxisDrag.on('drag', (e: any) => updateWithDrag(e, chartStore))
}

const updateWithZoomOrDrag = (chartStore: ChartStoreType) => {
  const { root, brush, xAxis }: ChartStoreType = chartStore

  root.select('.brush').call(brush.move, null)

  root.select('.x-axis').call(xAxis)
  root.select('.x-axis path').style('opacity', 0)
  root.selectAll('.x-axis .tick line').style('opacity', 0)

  updateBars(chartStore)
}

const updateWithZoom = (event: any, chartStore: ChartStoreType) => {
  const extent: [Number, Number] = event.selection

  if (extent) {
    const { x }: ChartStoreType = chartStore

    x.domain([x.invert(extent[0]), x.invert(extent[1])])

    updateWithZoomOrDrag(chartStore)
    //updateLegend(chartStore, data);
  }
}

const updateWithDrag = (event: any, chartStore: ChartStoreType) => {
  const { x, width }: ChartStoreType = chartStore
  const dx: number = event.dx
  const startValue: Date = x.invert(0 - dx)
  const endValue: Date = x.invert(width - dx)

  x.domain([startValue, endValue])

  updateWithZoomOrDrag(chartStore)
}

const updateBars = (chartStore: ChartStoreType) => {
  const { root, x, y, series, getColor, data, xBandwidth }: ChartStoreType =
    chartStore

  if (data && getColor) {
    const updateBarRect = (el: any) => {
      el.attr(
        'x',
        (d: SeriesPointTypeT) =>
          x(d.data.date) - (xBandwidth ? xBandwidth(data, x) / 2 : 0)
      )
        .attr('y', (d: SeriesPointTypeT) => y(d[1]))
        .attr('height', (d: SeriesPointTypeT) =>
          Math.abs((y(d[0]) || 0) - (y(d[1]) || 0))
        )
        .attr('width', xBandwidth ? xBandwidth(data, x) : 0)
    }

    const updateSeries = (el: any) => {
      el.attr('fill', (d: STypeT) => getColor(d.key))
        .selectAll('rect')
        .data((d: SeriesPointTypeT) => d)
        .join(
          (enter: SelectionTypeT) => {
            enter.append('rect').call(updateBarRect)
          },
          (update: SelectionTypeT) => {
            update.call(updateBarRect)
          },
          (exit: SelectionTypeT) => exit.remove()
        )
    }

    root
      .select('.bars')
      .selectAll('.bar')
      .data(series)
      .join(
        (enter: SelectionTypeT) => {
          enter.append('g').attr('class', 'bar').call(updateSeries)
        },
        (update: SelectionTypeT) => {
          update.call(updateSeries)
        },
        (exit: SelectionTypeT) => {
          exit.remove()
        }
      )
  }
}

const updateLegend = (chartStore: ChartStoreType) => {
  const {
    height,
    fullWidth,
    margin,
    root,
    data,
    getTextParams,
    fontSize,
    getColor,
  }: ChartStoreType = chartStore

  if (data && getColor) {
    const legendData: LegendDataTypeT = Object.keys(data[0])
      .filter((key) => key !== 'total' && key !== 'date')
      .map((el, i) => ({ name: el, color: getColor(el) }))

    const legendParams: LegendParamsTypeT = getlegendParams(
      chartStore,
      legendData
    )

    const getTranslateX = (d: LegendPointType, i: number): number => {
      const ml: number = margin?.left || 0
      const max: number =
        d3.max(
          legendParams.filter((el) => el.h === legendParams[i].h),
          (param) => param.fullW
        ) || 1

      return (
        fullWidth / 2 -
        ml -
        max / 2 +
        legendParams[i].w +
        getTextParams(d.name).width / 2
      )
    }

    const updateLegendEl = (el: any) =>
      el.attr('transform', (d: LegendPointType, i: number): string => {
        return `translate(
          ${getTranslateX(d, i)},
          ${legendParams[i].h}
        )`
      })

    const updateRect = (el: any) => {
      el.attr(
        'x',
        (d: LegendPointType): number => -getTextParams(d.name).width / 2 - 25
      ).attr('fill', (d: LegendPointType): string => d.color)
    }

    root
      .select('.legend')
      .attr('transform', `translate(0, ${height + 50})`)
      .selectAll('g')
      .data(legendData)
      .join(
        (enter: SelectionTypeT) => {
          const en = enter.append('g').call(updateLegendEl)

          en.append('text')
            .attr('text-anchor', 'middle')
            .attr('font-size', fontSize)
            .text((d: LegendPointType): string => d.name)

          en.append('rect')
            .attr('y', -(fontSize - 1))
            .attr('width', 16)
            .attr('height', 16)
            .call(updateRect)
        },
        (update: SelectionTypeT) => {
          update.call(updateLegendEl)

          update.select('text').text((d: LegendPointType): string => d.name)

          update.select('rect').call(updateRect)
        },
        (exit: SelectionTypeT) => {
          exit.remove()
        }
      )
  }
}

function drawTooltip(event: any, chartStore: ChartStoreType) {
  const { root, data, getColor, x, xBandwidth }: ChartStoreType = chartStore

  if (data && getColor) {
    const overlay: any = root.select('.brush')
    const currentPosition: number = d3.pointer(event, overlay.node())[0]
    const currentValue: СurentValueTypeT = Object.entries(
      data.find(
        (el) =>
          x(el.date) + (xBandwidth ? xBandwidth(data, x) / 2 : 0) >=
            currentPosition &&
          x(el.date) - (xBandwidth ? xBandwidth(data, x) / 2 : 0) <=
            currentPosition
      ) || {}
    )

    if (currentValue.length) {
      root
        .select('.tooltip')
        .html('')
        .style('opacity', 1)
        .style('left', event.pageX + 10 + 'px')
        .style('top', event.pageY + 10 + 'px')
        .style('background-color', `#fff`)
        .style('box-shadow', `0 0 10px rgba(0,0,0,0.5)`)
        .selectAll()
        .data(currentValue)
        .enter()
        .append('div')
        .style('color', `#000`)
        .style('padding', (d: СurrentValueElementTypeT): string =>
          d[0] === 'date' || d[0] === 'total' ? `8px 2px` : `2px`
        )
        .style('font-weight', (d: СurrentValueElementTypeT): string =>
          d[0] === 'date' || d[0] === 'total' ? `500` : `400`
        )
        .style('font-size', `13px`)
        .html((d: СurrentValueElementTypeT): string =>
          d[0] === 'date' || d[0] === 'total'
            ? `<span>${d[0]}: ${
                d[1] instanceof Date ? dateFormater(d[1]) : d[1]
              }</span>`
            : `<span style="color: ${getColor(d[0])}">
                ■
              </span> 
              <span>${d[1]}</span>`
        )
    } else removeTooltip(chartStore)
  }
}

function removeTooltip(chartStore: ChartStoreType) {
  const { root }: ChartStoreType = chartStore
  root.select('.tooltip').style('opacity', 0)
}

// Create chart function
const drawChart = (chartStore: ChartStoreType) => {
  const {
    id,
    root,
    width,
    height,
    xAxis,
    yAxis,
    brush,
    xAxisDrag,
    xDefaultDomain,
  }: ChartStoreType = chartStore

  // main SVG
  const svg = root.select('.main-svg')

  // main G
  const g = svg.append('g').attr('class', 'main-g')

  // Add X axis
  g.append('g')
    .attr('class', 'x-axis')
    .attr('pointer-events', 'none')
    .call(xAxis)

  g.select('.x-axis path').style('opacity', 0)

  // Add Y axis
  g.append('g').attr('class', 'y-axis').call(yAxis)

  g.select('.y-axis path').style('opacity', 0)

  // defs
  g.append('defs')
    .append('clipPath')
    .attr('id', `clip-${id}`)
    .append('rect')
    .attr('class', 'clip-rect')
    .attr('width', width)
    .attr('height', height)

  //x axis drag
  g.append('rect')
    .attr('class', 'x-drag')
    .attr('clip-path', `url(#clip-${id}-x-drag)`)
    .attr('transform', `translate(0, ${height})`)
    .attr('fill', 'none')
    .style('cursor', 'ew-resize')
    .attr('pointer-events', 'all')
    .attr('height', 20)
    .call(xAxisDrag)
    .on('dblclick.zoom', () => {
      if (xDefaultDomain) {
        chartStore.x.domain(xDefaultDomain())
        chartStore.root.select('.x-axis').call(xAxis)

        chartStore.root.select('.x-axis path').style('opacity', 0)
        chartStore.root.selectAll('.x-axis .tick line').style('opacity', 0)

        updateBars(chartStore)
      }
    })

  // tooltip
  root
    .select('.tooltip')
    .style('opacity', 0)
    .style('font-size', '10px')
    .style('pointer-events', 'none')
    .style('font-weight', 'bold')
    .style('position', 'fixed')
    .style('z-index', 999999)
    .style('background-color', 'black')
    .style('border-radius', '3px')
    .style('padding', '5px')

  // bars
  g.append('g').attr('class', 'bars').attr('clip-path', `url(#clip-${id})`)

  // brush
  g.append('g')
    .attr('class', 'brush')
    .call(brush)
    .on('mousemove', (e: any) => {
      drawTooltip(e, chartStore)
    })
    .on('mouseout', () => {
      removeTooltip(chartStore)
    })
    .on('dblclick', () => {
      if (xDefaultDomain) {
        chartStore.x.domain(xDefaultDomain())
        chartStore.root.select('.x-axis').call(xAxis)

        chartStore.root.select('.x-axis path').style('opacity', 0)
        chartStore.root.selectAll('.x-axis .tick line').style('opacity', 0)

        updateBars(chartStore)
      }
    })

  // legend
  g.append('g').attr('class', 'legend')
}

// Update chart function
const updateChart = (chartStore: ChartStoreType) => {
  const {
    root,
    fullWidth,
    fullHeight,
    width,
    height,
    margin,
    xAxis,
    yAxis,
    brush,
  }: ChartStoreType = chartStore

  const svg = root
    .select('.main-svg')
    .attr('width', fullWidth)
    .attr('height', fullHeight)

  const g = svg
    .select('.main-g')
    .attr('transform', `translate(${margin?.left}, ${margin?.top})`)

  g.select('.x-axis').attr('transform', `translate(0, ${height})`).call(xAxis)
  g.selectAll('.x-axis .tick line').style('opacity', 0)

  g.select('.y-axis').call(yAxis).attr('transform', `translate(${width}, 0)`)
  g.selectAll('.y-axis line').attr('stroke', '#e0e0e0')

  root.select('.x-drag').attr('width', width)

  root.select('.brush').call(brush)

  updateBars(chartStore)
  updateLegend(chartStore)
}

// Chart Component
export const StackedBarChart: FC<StackedBarChartProps> = (props) => {
  const { data, minWidth, minHeight, margin }: StackedBarChartProps = props
  const windowsize = useWindowSize()

  const rootEl = useRef<HTMLDivElement>(null)
  const chartStoreRef = useRef<ChartStoreType>({
    id: 'monitoring-stackedbar-chart',
    data: null,
    root: null,
    width: 0,
    fullWidth: 0,
    height: 0,
    fullHeight: 0,
    margin: null,
    series: null,
    x: d3.scaleTime(),
    xDefaultDomain: null,
    y: d3.scaleLinear(),
    xBandwidth: null,
    center: d3.scaleLinear(),
    getColor: null,
    line: d3.line(),
    brush: d3.brushX(),
    xAxis: null,
    yAxis: null,
    //trans: null,
    //transDur: 0,
    xAxisDrag: d3.drag(),
    fontSize: 14,
    getTextParams: (text): TextPararamsType =>
      getTextParams(text, `PtRootUI`, 14),
  })

  const chartStore: ChartStoreType = chartStoreRef.current

  useEffect(() => {
    prepareStore(chartStore, props, rootEl.current)
    drawChart(chartStore)
  }, [])

  useEffect(() => {
    prepareStore(chartStore, props, rootEl.current)
    updateChart(chartStore)
  }, [data, minWidth, minHeight, margin, windowsize.width])

  return (
    <div
      className={`relative w-full overflow-hidden`}
      style={{ height: minHeight }}
    >
      <div ref={rootEl} className={`absolute w-full`}>
        <svg className="main-svg"></svg>
        <div className="tooltip"></div>
      </div>
    </div>
  )
}
