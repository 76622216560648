import { PayloadAction } from '@reduxjs/toolkit'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { slideOverActions } from 'containers/SlideOver/reducer'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import { projectService } from 'utils/services'
import { projectActions } from './reducer'
import { getDatasetByIdState } from './selectors'
import { CreateDatasetPayload, DatasetsItemType, DeleteDatasetPayload } from './types'

function* handleFetchDatasets({ payload }: PayloadAction<string>) {
  try {
    const data: DatasetsItemType[] = yield call(
      projectService.getDatasets,
      payload
    )
    yield put(projectActions.didFetchDatasets(data))
  } catch (error) {
    yield put(projectActions.didFetchDatasetsFail())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleCreateDataset({
  payload,
}: PayloadAction<CreateDatasetPayload>) {
  try {
    yield put(slideOverActions.startLoading('create-dataset'))
    // yield call(projectService.createDataset, payload)
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )

    // TODO remove. need return obj on projectService.createDataset and send to action
    const data: DatasetsItemType[] = yield call(
      projectService.getDatasets,
      payload.project_id
    )
    yield put(projectActions.didCreateDataset({ ...data[0], ...payload }))
    yield put(slideOverActions.hideSlideOver())
  } catch (error) {
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
    // yield put(projectsActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleUpdateDataset({
  payload,
}: PayloadAction<CreateDatasetPayload>): any {
  try {
    yield put(slideOverActions.startLoading('save'))
    // yield call(projectService.updateDataset, payload)
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
    yield put(projectActions.didUpdateDataset(payload))
    yield put(slideOverActions.hideSlideOver())
  } catch (error) {
    const dataset: any = yield select(getDatasetByIdState(payload.dataset_id))
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${dataset.name} not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
    // yield put(projectsActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleDeleteDataset({ payload }: PayloadAction<DeleteDatasetPayload>) {
  try {
    yield put(slideOverActions.startLoading('delete'))
    // yield call(projectService.deleteDataset, {
    //   project_id: payload.project_id,
    //   dataset_id: payload.dataset_id,
    // })
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
    yield put(projectActions.didDeleteDataset(payload))
    yield put(slideOverActions.hideSlideOver())
  } catch (error) {
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
    // yield put(projectsActions.stopLoading())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

export default function* watchProjectSaga() {
  yield takeLatest(projectActions.fetchDatasets.type, handleFetchDatasets)
  yield takeLatest(projectActions.createDataset.type, handleCreateDataset)
  yield takeLatest(projectActions.updateDataset.type, handleUpdateDataset)
  yield takeLatest(projectActions.deleteDataset.type, handleDeleteDataset)
}
