import { RootState } from 'app/store'
import { connectionsConfig } from 'pages/Connections/containers/drawer/config'
import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { InputSearch, Select } from 'shared'
import { connectionsActions } from '../reducer'
import { getSearchValueState, getTypeFilterValueState } from '../selectors'
import { TypeFilterT } from '../types'

interface ManagePanelProps {}

interface StateProps {
  searchValue: string
  typeFilterValue: TypeFilterT
}

type DispatchProps = typeof mapDispathToProps

const ManagePanel: FunctionComponent<
  ManagePanelProps & StateProps & DispatchProps
> = ({ searchValue, typeFilterValue, setSearchValue, setTypeFilterValue }) => {
  return (
    <div className="flex justify-between mb-t30">
      <InputSearch
        placeholder="Search connections"
        // width="full"
        color="default-100"
        iconClass="top-1/2 -mt-t15/2 w-t15 h-t15"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Select
        color="default-100"
        classes={{ root: 'mr-t20' }}
        options={connectionsConfig.map((d, i) => ({
          id: i,
          name: d.name,
          title: d.label,
        }))}
        placeholder="Type"
        value={typeFilterValue}
        onChange={(value) => setTypeFilterValue(value)}
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  searchValue: getSearchValueState,
  typeFilterValue: getTypeFilterValueState,
})

const mapDispathToProps = {
  setSearchValue: connectionsActions.setSearchValue,
  setTypeFilterValue: connectionsActions.setTypeFilterValue,
}

export default connect(mapStateToProps, mapDispathToProps)(ManagePanel)
