import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateNewPayload,
  CreateQueryPayload,
  HoveredPathTypes,
  LoadingButtonsTypes,
  QueriesItemType,
  RunQueryPayload,
} from './types'
import Queries from './storage'
import { isEqual, cloneDeep, isArray } from 'lodash'
import { checkChangedFields } from './utils'
import { WorkspaceId } from 'containers/Sidebar/types'
import { NodeProps } from 'shared'

const prefix = 'queries'

export interface QueriesState {
  queries: QueriesItemType[]
  loading: boolean
  loadingQuery: boolean
  loadingQueriesTree: boolean
  loadingButtons: LoadingButtonsTypes
  tabs: QueriesItemType[]
  selectedTab: QueriesItemType | null
  datasetsTreeData: any[]
  queryCollections: any[]
  hoveredPath: HoveredPathTypes
  isShowQueryParamsDrawer: boolean
  selectedParams: string | null
}

const initialState: QueriesState = {
  loading: false,
  loadingQuery: false,
  loadingQueriesTree: false,
  loadingButtons: { is: false, name: '' },
  queries: [],
  tabs: [],
  selectedTab: null,
  datasetsTreeData: [],
  queryCollections: [],
  hoveredPath: { active: '', selected: '' },
  isShowQueryParamsDrawer: false,
  selectedParams: null,
}

const queriesReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchQueries: (state, action: PayloadAction<WorkspaceId>) => {
      state.loading = true
    },
    didFetchQueries: (state, { payload }: PayloadAction<QueriesItemType[]>) => {
      state.queries = payload
      state.loading = false
    },
    didFetchQueriesFail: (state) => {
      state.loading = false
    },
    createQuery: (state, action: PayloadAction<CreateQueryPayload>) => {},
    fetchQuery: (state, action: PayloadAction<any>) => {
      state.loadingQuery = true
    },
    didFetchQuery: (state, { payload }: PayloadAction<QueriesItemType>) => {
      const idxTab = cloneDeep(
        state.tabs.findIndex((tab) => tab.query_id === payload.query_id)
      )
      const idxCol = state.queryCollections.findIndex(
        (d) => d.query_collection_id === Queries.collectionId
      )
      let treeItem = {}
      findTreeItem(
        cloneDeep(state.queryCollections[idxCol]),
        payload.query_id,
        treeItem
      )
      state.tabs.splice(idxTab, 1, {
        ...state.tabs[idxTab],
        ...treeItem,
        ...payload,
      })
      state.selectedTab = {
        ...state.selectedTab,
        ...treeItem,
        ...payload,
        ...Queries.tabs.filter((d: any) => d.query_id === payload.query_id)[0],
      }
      state.loadingQuery = false
    },
    updateQuery: (state, action: PayloadAction<any>) => {},
    closeQuery: (state, { payload }: PayloadAction<QueriesItemType>) => {
      const idxTab = state.tabs.findIndex(
        (tab) => tab.query_id === payload.query_id
      )
      if (idxTab !== -1) {
        Queries.removeTab(payload.query_id)
        state.tabs.splice(idxTab, 1)
        if (
          state.tabs.length &&
          state.selectedTab?.query_id === payload.query_id
        ) {
          const newIdx = idxTab - 1 >= 0 ? idxTab - 1 : 0
          Queries.selectTab(state.tabs[newIdx].query_id)
          state.selectedTab = {
            ...state.tabs[newIdx],
            ...Queries.tabs[newIdx],
          }
          if (
            state.selectedTab?.saved_params?.length &&
            state.selectedTab.saved_params[0].id
          ) {
            Queries.setSelectedParams(state.selectedTab.saved_params[0].id)
            state.selectedParams = state.selectedTab.saved_params[0].id
          }
        } else if (!state.tabs.length) {
          Queries.setCollectionId(null)
          Queries.selectTab(null)
          state.selectedTab = null
          Queries.setSelectedParams(null)
          state.selectedParams = null
        }
      }
    },
    selectQuery: (state, { payload }: PayloadAction<QueriesItemType>) => {
      const query = cloneDeep(
        state.tabs.find((tab) => tab.query_id === payload.query_id)
      )
      const isNewTab = payload.query_id === state.selectedTab?.query_id
      if (!query) {
        const idxTempTab = state.tabs.findIndex((tab) => tab.temp)
        const obj = {
          query_id: payload.query_id,
          name: payload.name,
          type: payload.type,
        }
        if (idxTempTab !== -1) {
          state.tabs.splice(idxTempTab, 1, { ...payload, temp: true })
          Queries.replaceTab(state.tabs[idxTempTab].query_id, {
            ...obj,
            temp: true,
          })
          Queries.selectTab(payload.query_id)
          state.selectedTab = { ...payload, temp: true }
          if (
            (state.selectedParams === null || !isNewTab) &&
            state.selectedTab?.saved_params?.length &&
            state.selectedTab.saved_params[0].id
          ) {
            Queries.setSelectedParams(state.selectedTab.saved_params[0].id)
            state.selectedParams = state.selectedTab.saved_params[0].id
          }
        } else {
          state.tabs.push({ ...payload, temp: true })
          Queries.addTab({ ...obj, temp: true })
          Queries.selectTab(payload.query_id)
          state.selectedTab = { ...payload, temp: true }
          if (
            (state.selectedParams === null || !isNewTab) &&
            state.selectedTab?.saved_params?.length &&
            state.selectedTab.saved_params[0].id
          ) {
            Queries.setSelectedParams(state.selectedTab.saved_params[0].id)
            state.selectedParams = state.selectedTab.saved_params[0].id
          }
        }
      } else {
        Queries.selectTab(query.query_id)
        state.selectedTab = {
          ...payload,
          ...query,
          ...Queries.tabs.filter(
            (d: any) => d.query_id === payload.query_id
          )[0],
        }
        if (
          (state.selectedParams === null || !isNewTab) &&
          state.selectedTab?.saved_params?.length &&
          state.selectedTab.saved_params[0].id
        ) {
          Queries.setSelectedParams(state.selectedTab.saved_params[0].id)
          state.selectedParams = state.selectedTab.saved_params[0].id
        }
      }
    },
    deleteQuery: (state, action: PayloadAction<QueriesItemType>) => {},
    runQuery: (state, action: PayloadAction<RunQueryPayload>) => {},
    runApi: (state, action: PayloadAction<any>) => {},
    createTab: (state, { payload }: PayloadAction<QueriesItemType[] | any>) => {
      if (!isArray(payload)) {
        state.tabs.push(payload)
      } else {
        state.tabs = payload
      }
    },
    selectTab: (state, { payload }: PayloadAction<QueriesItemType | any>) => {
      Queries.selectTab(payload !== null ? payload.query_id : null)
      if (payload === null) {
        state.selectedTab = null
      } else if (payload.type === 'query') {
        const isNewTab = payload.query_id === state.selectedTab?.query_id
        state.selectedTab = {
          ...payload,
          ...Queries.tabs.filter(
            (d: any) => d.query_id === payload.query_id
          )[0],
        }
        if (
          (state.selectedParams === null || !isNewTab) &&
          state.selectedTab?.saved_params?.length &&
          state.selectedTab.saved_params[0].id
        ) {
          Queries.setSelectedParams(state.selectedTab.saved_params[0].id)
          state.selectedParams = state.selectedTab.saved_params[0].id
        }
      } else {
        const idxCol = state.queryCollections.findIndex(
          (d) =>
            d.query_collection_id ===
            (payload.type === 'collection'
              ? payload.query_id
              : Queries.collectionId)
        )
        const treeItem = {}
        findTreeItem(
          cloneDeep(state.queryCollections[idxCol]),
          payload.query_id,
          treeItem
        )
        const idxTab = state.tabs.findIndex(
          (d) => d.query_id === payload.query_id
        )
        state.tabs[idxTab] = { ...state.tabs[idxTab], ...treeItem }
        state.selectedTab = {
          ...payload,
          ...treeItem,
          ...Queries.tabs.filter(
            (d: any) => d.query_id === payload.query_id
          )[0],
        }
        Queries.setSelectedParams(null)
        state.selectedParams = null
      }
    },
    updateTab: (state, { payload }: PayloadAction<any>) => {
      const idx = state.tabs.findIndex((d) => d.query_id === payload.query_id)
      state.tabs[idx] = { ...state.tabs[idx], ...payload.data }
      state.selectedTab = {
        ...state.selectedTab,
        ...payload.data,
      }
    },
    saveTab: (state, { payload }: PayloadAction<any>) => {
      const idx = state.tabs.findIndex((d) => d.query_id === payload.query_id)
      state.tabs[idx] = { ...state.tabs[idx], ...payload.data, changed: false }
      state.selectedTab = {
        ...state.selectedTab,
        ...payload.data,
        changed: false,
      }
    },
    saveChangesTabAfterClose: (state, { payload }: PayloadAction<any>) => {},
    updateFolderTab: (state, { payload }: PayloadAction<any>) => {},
    updateTabsDeleted: (state, { payload }: PayloadAction<string[]>) => {
      state.tabs.map((tab: any) =>
        payload.includes(tab.query_id) ? (tab.deleted = true) : tab
      )
      if (
        state.selectedTab !== null &&
        payload.includes(state.selectedTab?.query_id || '')
      )
        state.selectedTab.deleted = true
      Queries.removeTabs(payload)
    },
    checkChangedTab: (state, { payload }: PayloadAction<any>) => {
      const idx = state.tabs.findIndex((d) => d.query_id === payload.query_id)

      const changedFields = checkChangedFields(payload.query_id)
      if (changedFields[payload.data.name])
        delete changedFields[payload.data.name]

      const q_keys = Object.keys(changedFields)

      if (idx !== -1) {
        if (!isEqual(state.tabs[idx][payload.data.name], payload.data.value)) {
          state.tabs[idx] = { ...state.tabs[idx], temp: false, changed: true }
          state.selectedTab =
            state.selectedTab !== null
              ? {
                  ...state.selectedTab,
                  [payload.data.name]: payload.data.value,
                  temp: false,
                  changed: true,
                }
              : null
          Queries.saveTab(
            payload.query_id,
            {
              [payload.data.name]: payload.data.value,
              temp: false,
            },
            true
          )
        } else {
          if (q_keys.length) {
            Queries.cleanTabByName(payload.query_id, payload.data.name)
          } else {
            Queries.cleanTab(payload.query_id, {})
            state.tabs[idx] = { ...state.tabs[idx], changed: false }
            state.selectedTab =
              state.selectedTab !== null
                ? { ...state.selectedTab, ...state.tabs[idx], changed: false }
                : null
          }
        }
      }
    },
    resetTempTab: (state, { payload }: PayloadAction<any>) => {
      const idx = state.tabs.findIndex(
        (d) => d.query_id === (payload.query_id || payload.id)
      )
      state.tabs[idx] = { ...state.tabs[idx], temp: false }
      Queries.updateTab(payload.query_id, { temp: false })
      state.selectedTab =
        state.selectedTab !== null
          ? { ...state.selectedTab, temp: false }
          : null
    },
    fetchQueryCollections: (state) => {},
    didFetchQueryCollections: (state, { payload }: PayloadAction<any>) => {
      state.queryCollections = payload
    },
    createQueryCollection: (state) => {},
    didCreateQueryCollection: (state, { payload }: PayloadAction<any>) => {
      state.queryCollections.push(payload)
    },
    updateQueryCollection: (state, { payload }: PayloadAction<any>) => {},
    didUpdateQueryCollection: (state, { payload }: PayloadAction<any>) => {
      const collection_id = payload.collection_id || Queries.collectionId
      const idxCollection = state.queryCollections.findIndex(
        (d) => d.query_collection_id === collection_id
      )
      state.queryCollections[idxCollection] = payload.data
    },
    deleteQueryCollection: (state, { payload }: PayloadAction<any>) => {},
    didDeleteQueryCollection: (state, { payload }: PayloadAction<any>) => {
      const idxCollection = state.queryCollections.findIndex(
        (d) => d.query_collection_id === payload
      )
      state.queryCollections.splice(idxCollection, 1)
    },
    createFolder: (state, action: PayloadAction<CreateNewPayload>) => {},
    createNewItemQueryCollection: (
      state,
      action: PayloadAction<{
        collection_id: string
        nodeId: string
        data: {
          id: string
          name: string
          type: string
          api_path?: string
          temp?: boolean
        }
      }>
    ) => {},
    updateQueryCollectionTreeDnd: (
      state,
      { payload }: PayloadAction<any>
    ) => {},
    updateQueryCollectionTreeMove: (
      state,
      { payload }: PayloadAction<any>
    ) => {},
    setHoveredPath: (state, { payload }: PayloadAction<HoveredPathTypes>) => {
      state.hoveredPath = { ...state.hoveredPath, ...payload }
    },
    fetchDatasetsTreeData: (state) => {},
    didFetchDatasetsTreeData: (state, { payload }: PayloadAction<any>) => {
      state.datasetsTreeData = payload
    },
    showQueryParamsDrawer: (state) => {
      state.isShowQueryParamsDrawer = !state.isShowQueryParamsDrawer
    },
    updateSelectedParams: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.selectedParams = payload
      Queries.setSelectedParams(payload)
    },
    updateSavedParams: (state, action: PayloadAction<any>) => {},
    addNewSavedParams: (state, action: PayloadAction<any>) => {},
    duplicateSavedParams: (state, action: PayloadAction<any>) => {},
    updateTabs: (state, { payload }: PayloadAction<any>) => {
      const idx = state.tabs.findIndex((d) => d.query_id === payload.query_id)
      state.tabs[idx] = { ...state.tabs[idx], ...payload.data }
    },
    updateSelectedTab: (state, { payload }: PayloadAction<any>) => {
      state.selectedTab = {
        ...state.selectedTab,
        ...payload.data,
      }
    },
    deleteSavedParams: (state, action: PayloadAction<any>) => {},
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    stopLoadingQuery: (state) => {
      state.loadingQuery = false
    },
    startLoadingQueriesTree: (state) => {
      state.loadingQueriesTree = true
    },
    stopLoadingQueriesTree: (state) => {
      state.loadingQueriesTree = false
    },
    startLoadingButtons: (state, { payload }: PayloadAction<string>) => {
      state.loadingButtons = { is: true, name: payload }
    },
    stopLoadingButtons: (state) => {
      state.loadingButtons = { is: false, name: '' }
    },
  },
})

export const addNewToTree = (tree: any, nodeId: string, data?: any) => {
  if ((tree.id || tree.query_collection_id) !== nodeId && tree.items) {
    return tree.items.some((d: any) => addNewToTree(d, nodeId, data))
  } else if ((tree.id || tree.query_collection_id) === nodeId) {
    let obj = {}
    if (data.type === 'folder') {
      obj = {
        ...data,
        level: tree.level + 1,
        items: [],
      }
    } else if (data.type === 'query') {
      obj = {
        ...data,
        level: tree.level + 1,
      }
    }
    tree.items.push(obj)
    return tree.items.sort((a: any, b: any) => {
      if (a.type > b.type) {
        return 1
      }
      if (a.type < b.type) {
        return -1
      }
      return 0
    })
  }
}

export const updateTree = (
  tree: any,
  nodeId: string,
  data?: any,
  queriesList?: any[],
  newPath?: string
) => {
  if ((tree.id || tree.query_collection_id) !== nodeId && tree.items) {
    return tree.items.some((d: any) =>
      updateTree(d, nodeId, data, queriesList, newPath + (tree?.api_path || ''))
    )
  } else if ((tree.id || tree.query_collection_id) === nodeId) {
    Object.keys(data).forEach((field) => {
      tree[field] = data[field]
    })
    if (queriesList) updateTreeQueries(tree, queriesList, newPath)
    return tree
  }
}

export const updateTreeDnd = (
  tree: any,
  indexes: number[],
  data: NodeProps[]
): any => {
  if (indexes.length) {
    return indexes.forEach((idx: number) =>
      updateTreeDnd(tree.items[idx], indexes.slice(1), data)
    )
  } else {
    tree.items = data
    return tree
  }
}

export const updateTreeMove = (
  tree: any,
  indexes: number[],
  action: string,
  node: NodeProps,
  index: number = 1
): any => {
  if (indexes.length) {
    return indexes.forEach((idx: number) =>
      updateTreeMove(tree.items[idx], indexes.slice(1), action, node, index + 1)
    )
  } else {
    switch (action) {
      case 'delete': {
        const idx = tree.items.findIndex((d: NodeProps) => d.id === node.id)
        if (idx !== -1) tree.items.splice(idx, 1)
        return tree
      }
      case 'add': {
        tree.items.push({ ...node, level: index })
        return tree
      }
      default:
        return tree
    }
  }
}

export const findToggledNodes = (
  tree: any,
  nodeId: string,
  toggledData: any[]
) => {
  if ((tree.id || tree.query_collection_id) !== nodeId && tree.items) {
    const isSome = tree.items.some((d: any) =>
      findToggledNodes(d, nodeId, toggledData)
    )
    const id = 'id' in tree ? tree.id : tree.query_collection_id
    if (isSome) toggledData.push({ ...tree, id, toggled: true })
    return isSome
  } else if ((tree.id || tree.query_collection_id) === nodeId) {
    return tree
  }
}

export const findTreeItem = (tree: any, nodeId: string, treeItem: any) => {
  if ((tree.id || tree.query_collection_id) !== nodeId && tree.items) {
    return tree.items.some((d: any) => findTreeItem(d, nodeId, treeItem))
  } else if ((tree.id || tree.query_collection_id) === nodeId) {
    const query_id = 'id' in tree ? tree.id : tree.query_collection_id
    Object.keys(tree).forEach((d) => {
      treeItem[d] = tree[d]
    })
    treeItem.query_id = query_id
    return tree
  }
}

export const findTreeQueries = (
  tree: any,
  nodeId: string,
  queries: string[],
  folders: string[]
) => {
  if ((tree.id || tree.query_collection_id) !== nodeId) {
    const idx =
      tree.items && tree.items.length
        ? tree.items.findIndex((d: any) =>
            findTreeQueries(d, nodeId, queries, folders)
          )
        : -1
    if (idx !== -1 && tree.items[idx].id === nodeId) tree.items.splice(idx, 1)
    return idx !== -1
  } else if ((tree.id || tree.query_collection_id) === nodeId) {
    collectTreeQueries(tree, queries, folders)
    return tree
  }
}

export const collectTreeQueries = (
  tree: any,
  queries: string[],
  folders: string[]
) => {
  if (tree.items) {
    folders.push(tree.id)
    return tree.items.forEach((d: any) =>
      collectTreeQueries(d, queries, folders)
    )
  } else if (tree.type === 'query') {
    queries.push(tree.id)
    return tree
  }
}

export const updateTreeQueries = (
  tree: any,
  queries: any[],
  newPath: string = ''
) => {
  if (tree.items) {
    return tree.items.forEach((d: any) =>
      updateTreeQueries(d, queries, newPath + (tree?.api_path || ''))
    )
  } else if (tree.type === 'query') {
    tree.api_url = newPath + (tree?.api_path || '')
    queries.push({
      query_id: tree.id,
      api_url: newPath + (tree?.api_path || ''),
    })
    return tree
  }
}

export const queriesActions = queriesReducer.actions

export default queriesReducer.reducer
