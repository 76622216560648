import { FunctionComponent } from 'react'
import { SqlMonacoEditor } from 'shared'

interface SqlViewTabProps {
  sqlValue: string
  setSqlValue: (value: string | ((prevState: string) => string)) => void
}

const SqlViewTab: FunctionComponent<SqlViewTabProps> = ({
  sqlValue,
  setSqlValue,
}) => {
  return (
    <div className="h-full">
      <SqlMonacoEditor
        value={sqlValue}
        onChange={(value: any) => {
          setSqlValue(value)
        }}
        // readOnly={isPreview}
      />
    </div>
  )
}

export default SqlViewTab
