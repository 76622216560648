import { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { connectionsActions } from 'pages/Connections/reducer'
import {
  getConnectionTypeState,
  getSelectedConnectionState,
} from 'pages/Connections/selectors'
import {
  ConnectionsItemType,
  ConnectionsTypeTypeT,
} from 'pages/Connections/types'
import CreateNewConnection from './CreateNewConnection'
import ManagePanel from './ManagePanel'
import ConnectionsList from './ConnectionsList'

interface ConnectionsContainerProps {}

interface StateProps {
  connectionType: ConnectionsTypeTypeT
  selectedConnection: ConnectionsItemType | undefined
}

type DispatchProps = typeof mapDispathToProps

const ConnectionsContainer: FC<
  ConnectionsContainerProps & StateProps & DispatchProps
> = ({ connectionType, selectedConnection, fetchConnections }) => {
  useEffect(() => {
    fetchConnections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-label="setup-integration-credentials-container">
      <div className="bg-white rounded shadow p-t20 flex flex-col">
        <ManagePanel />
        <div className="relative w-full grid grid-cols-12 gap-t20">
          <div className="col-span-6 space-y-t20">
            <ConnectionsList />
          </div>
          <div className="col-span-6">
            {connectionType || selectedConnection ? (
              <CreateNewConnection />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connectionType: getConnectionTypeState,
  selectedConnection: getSelectedConnectionState,
})

const mapDispathToProps = {
  fetchConnections: connectionsActions.fetchConnections,
}

export default connect(mapStateToProps, mapDispathToProps)(ConnectionsContainer)
