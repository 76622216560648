import { createSelector } from 'reselect'
import { RootState } from '../../app/store'

export const getProjectsListState = (state: RootState) => state.projects

export const getProjectsState = createSelector(
  getProjectsListState,
  (state) => state.projects
)

export const getProjectsByIdState = (project_id: string) => {
  return createSelector(getProjectsState, (projects) =>
    projects.filter((project) => project.project_id === project_id)[0]
  )
}

export const getActiveViewState = createSelector(
  getProjectsListState,
  (state) => state.activeView
)

export const getLoadingState = createSelector(
  getProjectsListState,
  (state) => state.loading
)
