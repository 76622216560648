import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Typography } from 'shared'

interface NoFoundTypes {
  name: string
  className?: string
}

export const NoFound: FunctionComponent<NoFoundTypes> = ({
  name = '',
  className = '',
}) => {
  return (
    <div
      className={classNames(
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
        className
      )}
    >
      <Typography size="md" color='default-600'>{name}</Typography>
    </div>
  )
}
