import { FC, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { isArray } from 'lodash'
import { ChevronDownIcon, ChevronRightIcon } from 'assets/general'
import { getSelectedTabState } from 'pages/Queries/selectors'
import { COLOR_TYPES } from 'utils/theme'
import { QueriesItemType } from 'pages/Queries/types'
import { resourceActions } from 'pages/Resource/reducer'
import { getResourceState } from 'pages/Resource/selectors'
import { IResources } from 'pages/Resources/types'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { resourcesService } from 'utils/services'
import { ITableColumn } from 'pages/Resource/types'

// const commonClasses: any = {
//   nodeCaseContainer:
//     'relative group flex items-center cursor-pointer w-full hover:bg-default-200 py-1.5',
//   selectedNode: 'bg-default-300 hover:bg-default-300',
// }

interface ToggleIconProps {
  node: any
  toggled: boolean
  size?: string
  className?: string
}

export const ToggleIcon: FC<ToggleIconProps> = ({
  node,
  toggled,
  size = 'sm',
  className = 'text-neutral-300 mt-[2px] mr-1.5',
}) => {
  return toggled ? (
    <ChevronDownIcon className={className} size={size} color={null} />
  ) : !toggled && isArray(node.items) ? (
    <ChevronRightIcon className={className} size={size} color={null} />
  ) : null
}

interface StateProps {
  selectedTab: QueriesItemType | null
  resource: IResources | null
}

type DispatchProps = typeof mapDispatchToProps

const FieldsTab: FunctionComponent<StateProps & DispatchProps> = ({
  selectedTab,
  resource,
  fetchResource,
}) => {
  const [tables, setTables] = useState<any>()

  useEffect(() => {
    if (selectedTab?.resource_id)
      fetchResource({ resource_id: selectedTab.resource_id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab?.resource_id])

  const items: any[] = useMemo(
    () =>
      (resource?.tables || []).map((d) => ({
        id: d.table_name,
        heading: d.table_name,
      })),
    [resource?.tables]
  )

  if (!resource) return null

  return (
    <div className="py-2 px-2">
      <div className="text-default-400 text-sm pl-2 py-1">Related tables</div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        // preExpanded={items.map((d) => d.id)}
        onChange={async (expandedList: any) => {
          if (
            !tables ||
            (tables &&
              !Object.keys(tables).some(
                (d) => d === expandedList[expandedList.length - 1]
              ))
          ) {
            const result = await resourcesService.getTableData({
              workspace_id: resource.workspace_id,
              resource_id: resource.resource_id,
              table_name: expandedList[expandedList.length - 1],
            })
            setTables((prev: any) => ({
              ...prev,
              [expandedList[expandedList.length - 1]]: result.columns,
            }))
          }
        }}
      >
        {items.map((item) => (
          <AccordionItem key={item.id} uuid={item.id}>
            <AccordionItemHeading className="">
              <AccordionItemButton className="group flex items-center space-x-1 px-2 py-1 text-default-800 text-xs font-medium hover:bg-neutral-100 focus:outline-none rounded-sm">
                <AccordionItemState>
                  {({ expanded }) => (
                    <>
                      {expanded ? (
                        <ChevronDownIcon
                          size="xs"
                          color={null}
                          className="text-neutral-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          size="xs"
                          color={null}
                          className="text-neutral-400"
                          aria-hidden="true"
                        />
                      )}
                      <div className="font-bold">
                        {item.heading}
                      </div>
                    </>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="pl-5 text-default-600">
              {tables && tables[item.id] ? (
                (tables[item.id] || []).map((col: ITableColumn) => (
                  <div
                    key={col.column_name + col.data_type}
                    className="flex items-center justify-between text-xs space-x-1.5 py-0.5 px-2 hover:bg-default-100 rounded-sm"
                  >
                    <p className="font-medium">{col.column_name}</p>
                    <p className="truncate text-default-400">{col.data_type}</p>
                  </div>
                ))
              ) : (
                <p className="text-xs">Loading...</p>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>

    // <TreeView
    //   tree={datasetsTreeData}
    //   hoverFull
    //   classes={{
    //     root: 'relative grow overflow-auto',
    //     rootUl: 'absolute top-0 left-0 overflow-auto h-full w-full px-2.5',
    //   }}
    //   nodeCases={({ node, toggled, selectedNode, onClick, onSelect }: any) => {
    //     return {
    //       [nodeCasesTypes[0]]: (
    //         <NodeCasesContainer
    //           className={`pl-${5 * node.level}`}
    //           node={node}
    //           selectedNode={selectedNode}
    //           commonClasses={commonClasses}
    //           onClick={(e: any) => {
    //             if (isArray(node.items)) onClick(e)
    //             onSelect()
    //           }}
    //         >
    //           <div className="flex pl-1.5">
    //             <ToggleIcon node={node} toggled={toggled} />
    //             <MenuIcon className="mr-2" />
    //           </div>
    //           <Typography size="md">{node.name}</Typography>
    //         </NodeCasesContainer>
    //       ),
    //       [nodeCasesTypes[1]]: (
    //         <NodeCasesContainer
    //           className={`pl-${5 * node.level}`}
    //           node={node}
    //           selectedNode={selectedNode}
    //           commonClasses={commonClasses}
    //           onClick={(e: any) => {
    //             if (isArray(node.items)) onClick(e)
    //             onSelect()
    //           }}
    //         >
    //           <div className="flex pl-1.5">
    //             <ToggleIcon node={node} toggled={toggled} />
    //             <TableIcon className="mr-2" />
    //           </div>
    //           <Typography size="md">{node.name}</Typography>
    //         </NodeCasesContainer>
    //       ),
    //       [nodeCasesTypes[2]]: (
    //         <NodeCasesContainer
    //           className={`pl-${5 * node.level}`}
    //           node={node}
    //           selectedNode={selectedNode}
    //           commonClasses={commonClasses}
    //           onClick={(e: any) => {
    //             onSelect()
    //           }}
    //         >
    //           <div className="w-full flex items-center justify-between">
    //             <Typography className="truncate w-3/5 mr-1.5" size="md">
    //               {node.name}
    //             </Typography>
    //             <Typography
    //               className="truncate text-right w-2/5 pr-1.5"
    //               size="md"
    //               weight="light"
    //               color="default-600"
    //             >
    //               boolean
    //             </Typography>
    //           </div>
    //         </NodeCasesContainer>
    //       ),
    //     }
    //   }}
    // />
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedTab: getSelectedTabState,
  resource: getResourceState,
})

const mapDispatchToProps = {
  fetchResource: resourceActions.fetchResource,
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldsTab)
