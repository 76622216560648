import React from 'react'
import { Switcher } from 'shared'
import { GridIcon, ViewListIcon } from 'assets/general'

interface SwitcherContainerProps {
  activeView: number
  onClick: (id: number) => void
}

const SwitcherContainer: React.FunctionComponent<SwitcherContainerProps> = ({
  activeView,
  onClick,
}) => {
  return (
    <Switcher
      items={[
        {
          id: 0,
          iconComponent: GridIcon,
        },
        {
          id: 1,
          iconComponent: ViewListIcon,
        },
      ]}
      active={activeView}
      onClick={(id) => onClick(id)}
    />
  )
}

export default SwitcherContainer
