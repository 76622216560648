import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ClustersItemType,
  CreateClusterPayload,
  DeleteClusterPayload,
} from './types'

const prefix = 'clusters'

export interface ClustersState {
  activeView: number
  loading: boolean
  clusters: ClustersItemType[]
  checked: boolean
}

const initialState: ClustersState = {
  activeView: 0,
  loading: false,
  clusters: [],
  checked: false,
}

const clustersReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchClusters: (state) => {
      state.loading = true
    },
    didFetchClusters: (
      state,
      { payload }: PayloadAction<ClustersItemType[]>
    ) => {
      state.clusters = payload
      state.loading = false
    },
    didFetchClustersFail: (state) => {
      state.loading = false
    },
    createCluster: (
      state,
      { payload }: PayloadAction<CreateClusterPayload>
    ) => {},
    didCreateCluster: (state, { payload }: PayloadAction<ClustersItemType>) => {
      state.clusters.push(payload)
    },
    updateCluster: (
      state,
      { payload }: PayloadAction<CreateClusterPayload>
    ) => {},
    didUpdateCluster: (
      state,
      { payload }: PayloadAction<CreateClusterPayload>
    ) => {
      const idx = state.clusters.findIndex(
        (d) => d.cluster_id === payload.cluster_id
      )
      state.clusters[idx] = { ...state.clusters[idx], ...payload }
    },
    deleteCluster: (
      state,
      { payload }: PayloadAction<DeleteClusterPayload>
    ) => {},
    didDeleteCluster: (
      state,
      { payload }: PayloadAction<DeleteClusterPayload>
    ) => {
      state.clusters = state.clusters.filter(
        (d) => d.cluster_id !== payload.cluster_id
      )
    },
    checkCluster: (
      state,
      { payload }: PayloadAction<CreateClusterPayload>
    ) => {},
    didCheckCluster: (state, { payload }: PayloadAction<boolean>) => {
      state.checked = payload
    },
    changeView: (state, { payload }: PayloadAction<any>) => {
      state.activeView = payload
    },
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
  },
})

export const clustersActions = clustersReducer.actions

export default clustersReducer.reducer
