import { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Routes } from 'utils/router'

import AppLayout from 'containers/AppLayout'
import ContentLayout from 'containers/ContentLayout'
import Sidebar from 'containers/Sidebar'
import PageLayout from 'containers/Page/PageLayout'
import Header from 'containers/Header'
import { Home } from 'pages/Home'
import Main from 'pages/Main'
import ProjectsN from 'pages/ProjectsN'
import Project from 'pages/Project'
import Dataset from 'pages/Dataset'
import Queries from 'pages/Queries'
import Notification from 'containers/Notification'
import ModalContainer from 'containers/Modal/ModalContainer'
import DataCatalog from 'pages/DataCatalog'
import Clusters from 'pages/Clusters'
import Monitoring from 'pages/Monitoring'
import SlideOverContainer from 'containers/SlideOver/SlideOverContainer'
import Workspace from 'pages/Workspace'
import { DefaultWorkspaces } from 'pages/Workspace/containers/DefaultWorkspaces'
import Resources from 'pages/Resources'
import { DefaultResources } from 'pages/Resources/containers/DefaultResources'
import Resource from 'pages/Resource'
import { DefaultQueries } from 'pages/Queries/containers/DefaultQueries'

const App: FunctionComponent = () => {
  return (
    <AppLayout>
      <Sidebar />
      <ContentLayout>
        <Header />
        <PageLayout>
          <Switch>
            <Route exact path={Routes.path.Root} component={Home} />
            <Route
              exact
              path={Routes.path.DefaultResources}
              component={DefaultResources}
            />
            <Route exact path={Routes.path.Resources} component={Resources} />
            <Route exact path={Routes.path.Resource} component={Resource} />
            <Route
              path={Routes.path.DefaultQueries}
              component={DefaultQueries}
            />
            <Route path={Routes.path.Queries} component={Queries} />

            <Route exact path={Routes.path.Workspace} component={Workspace} />
            <Route
              exact
              path={Routes.path.DefaultWorkspaces}
              component={DefaultWorkspaces}
            />

            {/* Legacy routes */}

            <Route path={Routes.path.Main} component={Main} />
            <Route path={Routes.path.Projects} component={ProjectsN} exact />
            <Route path={Routes.path.Project} component={Project} exact />
            <Route path={Routes.path.Dataset} component={Dataset} />
            <Route path={Routes.path.DataCatalog} component={DataCatalog} />
            <Route path={Routes.path.Clusters} component={Clusters} />
            <Route path={Routes.path.Monitoring} component={Monitoring} />
            <Route path={Routes.path.Admin} component={() => <div></div>} />
          </Switch>
        </PageLayout>
      </ContentLayout>
      <Notification />
      <ModalContainer />
      <SlideOverContainer />
    </AppLayout>
  )
}

export default App
