// import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'
import { dataCatalogService } from 'utils/services'
import { dataCatalogActions } from './reducer'
import { DatasetsItemType } from 'pages/Project/types'

function* handleFetchDatasets() {
  try {
    const data: DatasetsItemType[] = yield call(dataCatalogService.getDatasets)
    yield put(dataCatalogActions.didFetchDatasets(data))
  } catch (error) {
    yield put(dataCatalogActions.didFetchDatasetsFail())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

export default function* watchDataCatalogSaga() {
  yield takeLatest(dataCatalogActions.fetchDatasets.type, handleFetchDatasets)
}
