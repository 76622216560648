import React, { FunctionComponent } from 'react'
import { Typography } from 'shared'
import { QueriesItemType } from '../../types'
import ApiInputContainer from '../ApiInputContainer'

interface CollectionTabContentProps {
  selectedTab: QueriesItemType
  className?: string
}

const CollectionTabContent: FunctionComponent<CollectionTabContentProps> =
  React.memo(
    ({
      selectedTab,
      // className,
    }) => {
      return (
        <div className={`${selectedTab.deleted ? 'pointer-events-none' : ''}`}>
          <div className="flex p-5">
            <Typography
              className="whitespace-nowrap flex items-center mr-2.5"
              size="md"
              weight="medium"
            >
              Api path:
            </Typography>
            <ApiInputContainer selectedTab={selectedTab} />
          </div>
        </div>
      )
    },
    (prev, next) =>
      JSON.parse(JSON.stringify(prev.selectedTab)) ===
      JSON.parse(JSON.stringify(next.selectedTab))
  )

export default CollectionTabContent
