import { FunctionComponent } from 'react'
import classnames from 'classnames'
import { PageTypes } from '../Page/types'

const ModalContentContainer: FunctionComponent<PageTypes> = ({
  children,
  className,
}) => {
  return (
    <div className={classnames('bg-white p-5', className)}>{children}</div>
  )
}

export default ModalContentContainer
