import { FC } from 'react'
import { Select } from 'shared'
import { QueriesItemType } from '../types'
import ApiInputContainer from './ApiInputContainer'

interface IApiMethodOptionsType {
  id: number
  name: string
  title: string
  color: string // COLOR_TYPES
  border: string
}

export const apiMethodOptions: IApiMethodOptionsType[] = [
  {
    id: 0,
    name: 'GET',
    title: 'GET',
    color: 'text-green-500',
    border: 'border-green-200',
  },
  {
    id: 1,
    name: 'POST',
    title: 'POST',
    color: 'text-blue-500',
    border: 'border-blue-200',
  },
  {
    id: 2,
    name: 'PUT',
    title: 'PUT',
    color: 'text-yellow-500',
    border: 'border-yellow-200',
  },
  {
    id: 3,
    name: 'DELETE',
    title: 'DELETE',
    color: 'text-red-500',
    border: 'border-red-200',
  },
]

interface ApiSettingsProps {
  apiUrl: string
  apiType: string
  selectedTab: QueriesItemType
  setApiUrl: (value: string) => void
  setApiType: (value: any) => void
}

const ApiSettings: FC<ApiSettingsProps> = ({
  apiUrl,
  apiType,
  selectedTab,
  setApiType,
  setApiUrl,
}) => {
  return (
    <div className="flex">
      <Select
        classes={{ root: 'mr-2.5' }}
        options={apiMethodOptions}
        size={130}
        color="default-100"
        value={apiMethodOptions.filter((opt: any) => opt.name === apiType)[0]}
        onChange={(value: any) => setApiType(value.name)}
      />
      <ApiInputContainer
        selectedTab={selectedTab}
        api={apiUrl}
        handleChange={setApiUrl}
      />
    </div>
  )
}

export default ApiSettings
