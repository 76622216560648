import { PayloadAction } from '@reduxjs/toolkit'
import { modalActions } from 'containers/Modal/reducer'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { takeLatest, put, call } from 'redux-saga/effects'
import { clustersService } from 'utils/services'
import { clustersActions } from './reducer'
import {
  ClustersItemType,
  CreateClusterPayload,
  DeleteClusterPayload,
} from './types'
import { v4 as uuidv4 } from 'uuid'

const clusters: ClustersItemType[] = [
  {
    provider: 'AWS',
    cluster_id: 'cluster_id_0',
    owner_id: 'owner_id',
    name: 'Cluster 0',
    description: 'Cluster 0 description',
    status: 'inactive',
    type: 'clickhouse',
    credentials: {
      url: 'http://qwerty.clickhouse.com',
      user: 'Clickhouse user',
      password: 'clickhouse_pass',
    },
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
    number_of_processors: 4,
    number_of_projects: 5,
    number_of_datasets: 9,
    memory_size: '100Mb',
    price: '15',
  },
  {
    provider: 'GCP',
    cluster_id: 'cluster_id_1',
    owner_id: 'owner_id',
    name: 'Cluster 1',
    description: 'Cluster 1 description',
    status: 'active',
    type: 'postgresql',
    credentials: {
      host: 'qwertyhost',
      port: '4321',
      user: 'GCP user',
      password: 'GCP_pass',
    },
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
    number_of_processors: 2,
    number_of_projects: 3,
    number_of_datasets: 3,
    memory_size: '27Mb',
    price: '7',
  },
  {
    provider: 'Azure',
    cluster_id: 'cluster_id_2',
    owner_id: 'owner_id',
    name: 'Cluster 2',
    description: 'Cluster 2 description',
    status: 'active',
    type: 'clickhouse',
    credentials: {
      url: 'http://qwerty.azure.com',
      user: 'Azure user',
      password: 'azure_pass',
    },
    config: {},
    props: {},
    created_at: '2021-07-11T21:42:39.051Z',
    updated_at: '2021-07-11T21:42:39.051Z',
    number_of_processors: 2,
    number_of_projects: 3,
    number_of_datasets: 3,
    memory_size: '34Mb',
    price: '24',
  },
]

function* handleFetchClusters() {
  try {
    yield call(clustersService.getClusters)
    yield put(clustersActions.didFetchClusters(clusters))
  } catch (error) {
    yield put(clustersActions.didFetchClusters(clusters))
    // yield put(clustersActions.didFetchClustersFail())
    // TODO add notification  message: error.message ? error.message : 'Failed'
  }
}

function* handleCreateCluster({
  payload,
}: PayloadAction<CreateClusterPayload>) {
  try {
    yield put(modalActions.startLoading('create'))
    // const data: ClustersItemType = yield call(
    //   clustersService.createCluster,
    //   payload
    // )
    yield put(
      clustersActions.didCreateCluster({
        ...clusters[0],
        ...payload,
        cluster_id: uuidv4(),
      })
    ) // TODO to data
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `Cluster not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateCluster({
  payload,
}: PayloadAction<CreateClusterPayload>) {
  try {
    yield put(modalActions.startLoading('save'))
    // yield call(clustersService.updateCluster, {
    //   cluster_id: payload.cluster_id,
    //   data: {
    //     name: payload.name,
    //     description: payload.description,
    //   },
    // })
    yield put(clustersActions.didUpdateCluster(payload))
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} updated successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `Cluster not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleCheckCluster({ payload }: PayloadAction<CreateClusterPayload>) {
  try {
    yield put(modalActions.startLoading('check'))
    yield call(clustersService.checkCluster, payload)
    yield put(clustersActions.didCheckCluster(true))
    yield put(modalActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Verification passed successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(clustersActions.didCheckCluster(true)) // TODO false
    yield put(modalActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `Verification failed!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteCluster({
  payload,
}: PayloadAction<DeleteClusterPayload>) {
  try {
    yield put(modalActions.startLoading('delete'))
    // yield call(clustersService.deleteCluster, payload)
    yield put(clustersActions.didDeleteCluster(payload))
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `Cluster deleted successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
  } catch (error) {
    yield put(modalActions.stopLoading())
    yield put(modalActions.hideModal())
    yield put(
      notificationActions.showNotification({
        message: `Cluster not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

export default function* watchClustersSaga() {
  yield takeLatest(clustersActions.fetchClusters.type, handleFetchClusters)
  yield takeLatest(clustersActions.createCluster.type, handleCreateCluster)
  yield takeLatest(clustersActions.updateCluster.type, handleUpdateCluster)
  yield takeLatest(clustersActions.checkCluster.type, handleCheckCluster)
  yield takeLatest(clustersActions.deleteCluster.type, handleDeleteCluster)
}
