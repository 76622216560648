/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useMemo } from 'react'
import { RootState } from 'app/store'
import { CloseIcon, TableIcon } from 'assets/general'
import classnames from 'classnames'
import { typeIcons } from 'pages/Connections/containers/drawer/config'
import {
  ConnectionsItemType,
  ConnectionsTablesType,
  ConnectionsTypeTypeT,
} from 'pages/Connections/types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Typography } from 'shared'
import { sliderActions } from '../reducer'
import {
  getSelectedConnectionIdState,
  getSelectedConnectionsState,
  getSelectedTablesState,
} from '../selectors'
import { getSlideOverParamsState } from 'containers/SlideOver/selectors'
import {
  getConnectionsState,
  getConnectionTypeState,
} from 'pages/Connections/selectors'
import ReactTooltip from 'react-tooltip'

import '../styles.css'

interface StateProps {
  connections: ConnectionsItemType[]
  connectionType: ConnectionsTypeTypeT
  selectedConnections: ConnectionsItemType[]
  selectedConnectionId: string | number
  selectedTables: any[]
  params: any
}

type DispatchProps = typeof mapDispatchToProps

const SourceTab: FunctionComponent<StateProps & DispatchProps> = ({
  params,
  connections,
  connectionType,
  selectedConnections,
  selectedConnectionId,
  selectedTables,
  setSelectedConnections,
  setSelectedConnectionId,
  setSelectedTables,
  showConnectionPopover,
}) => {
  const { dataset } = params

  useEffect(() => {
    if (dataset) {
      setSelectedConnections(dataset.connections || [])
      setSelectedConnectionId(dataset.selectedConnectionId)
    } else if (
      !selectedConnectionId &&
      connections.filter((d) => d.type === connectionType).length
    ) {
      setSelectedConnectionId(connections[0].connection_id)
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const connectonTables: ConnectionsTablesType[] = useMemo(() => {
    const filterConnections = connections.filter(
      (d) => d.connection_id === selectedConnectionId
    )
    return filterConnections.length ? filterConnections[0].tables || [] : []
  }, [selectedConnectionId])

  return (
    <div className="h-full relative">
      <div className="flex h-full absolute top-0 bottom-0 left-0 right-0">
        <div className="w-t250 h-full bg-default-50 border-0 border-l-0 border-t-0 border-default-200 overflow-y-auto">
          <div className="px-t20 py-t10 min-h-t50 border-b border-default-200">
            <Typography size="lg" weight="bold">
              Connections
            </Typography>
            {connections
              .filter((d) => d.type === connectionType)
              .map((d) => {
                const TypeIcon = typeIcons[d.type]
                return (
                  <div
                    key={d.connection_id}
                    data-for="connections-tooltip"
                    data-tip={JSON.stringify({
                      ...d,
                      tables: [...(d.tables || [])].map((d) => d.name),
                    })}
                    className={classnames(
                      'flex items-center px-t10 py-t10 cursor-pointer hover:bg-default-100',
                      {
                        'bg-default-200 hover:bg-default-200':
                          d.connection_id === selectedConnectionId,
                      }
                    )}
                    onClick={() => setSelectedConnectionId(d.connection_id)}
                  >
                    <TypeIcon width="20px" size={null} />
                    <Typography className="ml-t10 truncate" size="lg">
                      {d.name}
                    </Typography>
                  </div>
                )
              })}
            {/* <ButtonSimple
              className="flex w-full mt-t10 bg-default-100 hover:bg-default-300 text-default-700 text-md px-t10 py-t5 rounded-3"
              onClick={() => showConnectionPopover(true)}
            >
              <PlusIcon />
              <Typography className="ml-t5" size='md>
                Add
              </Typography>
            </ButtonSimple> */}
          </div>
          <div className="px-t20 py-t10 min-h-t50">
            <Typography size="lg" weight="bold">
              Tables
            </Typography>
            {connectonTables.map((d: ConnectionsTablesType) => {
              const tableName = d.name || 'untitled'
              const isSelected =
                selectedTables.findIndex((table) => table.id === d.id) !== -1
              return (
                <div
                  key={d.id}
                  className={classnames(
                    'flex items-center px-t10 py-t10 cursor-pointer hover:bg-default-100',
                    {
                      'bg-default-200 hover:bg-default-200': isSelected,
                    }
                  )}
                  onClick={() => setSelectedTables([d])}
                >
                  <TableIcon width="20px" size={null} />
                  <Typography className="ml-t10 truncate" size="lg">
                    {tableName}
                  </Typography>
                </div>
              )
            })}
          </div>
        </div>
        <div className="grow">
          {selectedTables.map((d: any) => {
            const tableName = d.name || 'untitled'
            return (
              <div
                key={d.id}
                className={classnames(
                  'relative flex items-center m-t10 px-t10 py-t10 bg-default-100'
                )}
              >
                <TableIcon width="20px" size={null} />
                <Typography className="ml-t10 truncate" size="lg">
                  {tableName}
                </Typography>
                <CloseIcon
                  className="absolute right-t10 cursor-pointer hover:text-secondary"
                  width="20px"
                  size={null}
                  onClick={() => setSelectedTables([])}
                />
              </div>
            )
          })}
        </div>
      </div>
      <ReactTooltip
        id="connections-tooltip"
        className="custom-tooltip-connection"
        getContent={(dataTip) => {
          const parseDataTip = JSON.parse(dataTip) || {}
          return (
            <div className="bg-green-600 rounded p-t10">
              <pre>
                <code>{JSON.stringify(parseDataTip, null, '\t')}</code>
              </pre>
            </div>
          )
        }}
        effect="solid"
        delayHide={100}
        delayShow={400}
        delayUpdate={400}
        place="left"
        border={false}
        type="dark"
        arrowColor="rgba(5, 150, 105, 1)" // green-600
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  connections: getConnectionsState,
  connectionType: getConnectionTypeState,
  selectedConnections: getSelectedConnectionsState,
  selectedConnectionId: getSelectedConnectionIdState,
  selectedTables: getSelectedTablesState,
  params: getSlideOverParamsState,
})

const mapDispatchToProps = {
  setSelectedConnections: sliderActions.setSelectedConnections,
  setSelectedConnectionId: sliderActions.setSelectedConnectionId,
  setSelectedTables: sliderActions.setSelectedTables,
  showConnectionPopover: sliderActions.showConnectionPopover,
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceTab)
