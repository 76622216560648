import { createSelector } from 'reselect'
import { RootState } from 'app/store'

export const getSignInListState = (state: RootState) => state.signIn

export const getLoadingState = createSelector(
  getSignInListState,
  (state) => state.loading
)

export const getErrorState = createSelector(
  getSignInListState,
  (state) => state.error
)
