import { FC, useEffect } from 'react'
import HeaderContainer from './containers/HeaderContainer'
import { MainContainer } from './containers/MainContainer'
import PRootContainer from 'containers/Page/PRootContainer'
import { resourcesActions } from './reducer'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'

type PropsFromRedux = ConnectedProps<typeof connector>

const Resources: FC<PropsFromRedux> = ({ fetchResources }) => {
  const { workspace_id } = useParams<any>()
  useEffect(() => {
    if (workspace_id) fetchResources(workspace_id)
  }, [workspace_id])

  return (
    <PRootContainer
      data-label="resources-container"
      className="flex flex-col h-full px-20 py-5"
    >
      <HeaderContainer />
      <MainContainer />
    </PRootContainer>
  )
}

const mapDispatchToProps = {
  fetchResources: resourcesActions.fetchResources,
}

const connector = connect(null, mapDispatchToProps)

export default connector(Resources)
