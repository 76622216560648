import { FunctionComponent } from 'react'
import { Button, Breadcrumb } from 'shared'
import { PlusIcon } from 'assets/general/PlusIcon'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'
import { Routes } from 'utils/router'
import { connect } from 'react-redux'
import { slideOverActions } from 'containers/SlideOver/reducer'
import {
  SlideOverOptions,
  SlideOverTypes,
} from 'containers/SlideOver/constants'

interface HeaderContainerProps {
  idProject: string
}

type DispatchProps = typeof mapDispatchToProps

const HeaderContainer: FunctionComponent<HeaderContainerProps & DispatchProps> =
  ({ idProject, showSlideOver }) => {
    return (
      <div data-label="project-header-container">
        <PHeaderContainer>
          <PageName name="Projects" />
          <Button
            onClick={() =>
              showSlideOver({
                type: SlideOverTypes.ManageDataset,
                config: {
                  ...SlideOverOptions.ManageDataset,
                  headerName: 'Create new dataset',
                },
                params: {
                  idProject,
                },
              })
            }
          >
            <PlusIcon className="mr-xs" />
            Add Dataset
          </Button>
        </PHeaderContainer>
        <Breadcrumb
          paths={[
            { path: Routes.url.Projects(), name: 'Projects' },
            {
              path: `/projects/${idProject}`,
              name: `${idProject}`,
            },
          ]}
        />
      </div>
    )
  }

const mapDispatchToProps = {
  showSlideOver: slideOverActions.showSlideOver,
}

export default connect(null, mapDispatchToProps)(HeaderContainer)
