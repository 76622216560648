import React, { useEffect } from 'react'
import classnames from 'classnames'
import {
  COLOR_TYPES,
  SIZE_TYPES,
  bgColor,
  // textSize,
  // pxSize,
  borderColor,
  wSize,
  hSize,
} from 'utils/theme'

const SIZE_MAPS: Record<SIZE_TYPES, string> = {
  xs: 'py-xs',
  sm: 'text-sm',
  md: 'px-4 text-lg',
  md_plus: 'px-4 text-lg',
  lg: 'py-md',
  xl: 'py-lg',
  '2xl': 'py-xl',
  '3xl': 'py-2xl',
  '4xl': 'py-3xl',
}

export interface InputProps {
  type?: string
  name?: string
  onChange?: (e: any) => void
  size?: SIZE_TYPES
  color?: COLOR_TYPES | string
  placeholder?: string | undefined
  className?: string | undefined
  width?: string | undefined
  error?: boolean
  disabled?: boolean
  height?: string
  textSize?: string
  InputComponent?: React.FC<any>
  rows?: number // textarea
  [propName: string]: any
}

export const Input: React.FunctionComponent<InputProps> = React.forwardRef(
  (
    {
      size = 'sm',
      color = 'default-100',
      type = 'text',
      name = '',
      onChange = () => {},
      className,
      placeholder = '',
      width = '64',
      error = false,
      disabled = false,
      height = 'H',
      InputComponent,
      ...other
    },
    ref: any
  ) => {
    useEffect(() => {
      if (ref && ref.current) ref.current.focus()
    }, [ref])

    const props = {
      ref,
      type,
      name,
      autoComplete: 'given-name',
      tabIndex: -1,
      placeholder: placeholder,
      className: classnames(
        'block z-40 border-2 text-default-900 placeholder-default-500 rounded',
        { disabled: disabled },
        SIZE_MAPS[size],
        // pxSize(size),
        // textSize(size),
        wSize(width),
        hSize(height),
        bgColor(color),
        borderColor(error ? 'secondary' : color),
        className
      ),
      onChange,
      ...other,
    }

    return InputComponent ? <InputComponent {...props} /> : <input {...props} />
  }
)
