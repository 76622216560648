import { Fragment, FunctionComponent, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ModalHeaderContainer from 'containers/Modal/ModalHeaderContainer'
import { Typography } from 'shared/typography'

interface ModalProps {
  content: ReactNode
  open: boolean | undefined
  headerName?: string | undefined
  hideModal: () => void
}

export const Modal: FunctionComponent<ModalProps> = ({
  content,
  open = false,
  headerName = undefined,
  hideModal = () => {},
}) => {
  // const cancelButtonRef = useRef(null) // TODO initial focus

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-40 inset-0 overflow-y-auto"
        // initialFocus={cancelButtonRef}
        open={open}
        onClose={hideModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-default-400/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="inline-block align-bottom bg-white rounded text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                {headerName ? (
                  <ModalHeaderContainer>
                    <Typography size="xl" weight="medium">
                      {headerName}
                    </Typography>
                  </ModalHeaderContainer>
                ) : null}
                {content}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
