import { FunctionComponent, ReactNode, useEffect } from 'react'
import { COLOR_TYPES } from 'utils/theme'
import classnames from 'classnames'
import { queriesActions } from '../reducer'
import { connect } from 'react-redux'
import { getHoveredPathSelectedState } from '../selectors'
import { RootState } from 'app/store'
import { createStructuredSelector } from 'reselect'
import { checkHoveredPath } from '../utils'

interface NodeCasesContainerProps {
  children: ReactNode
  node: any
  selectedNode: any
  commonClasses: any
  onClick: (e: any) => void
  isHoverPath?: boolean
  apiPath?: string
  className?: string
  style?: any
  hoverColor?: COLOR_TYPES
  saveRootParentId?: () => void
}

interface StateProps {
  hoveredPathSelected: string | undefined
}

type DispatchProps = typeof mapDispatchToProps

const NodeCasesContainer: FunctionComponent<
  NodeCasesContainerProps & StateProps & DispatchProps
> = ({
  children,
  className,
  style = {},
  commonClasses,
  hoverColor = 'default-100',
  node,
  selectedNode,
  apiPath,
  isHoverPath = false,
  hoveredPathSelected,
  saveRootParentId,
  setHoveredPath,
  onClick,
}) => {
  useEffect(() => {
    if (selectedNode?.id === node.id && saveRootParentId) saveRootParentId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode?.id === node.id])

  useEffect(() => {
    if (selectedNode?.id === node.id && isHoverPath) {
      const path = apiPath + (node?.api_path || '/')
      setHoveredPath({
        active: checkHoveredPath(path),
        selected: checkHoveredPath(path),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode?.id, node?.api_url, node?.api_path])

  return (
    <div
      className={classnames(
        commonClasses.nodeCaseContainer,
        {
          [commonClasses.selectedNode]:
            selectedNode !== null && selectedNode.id === node.id,
        },
        `hover:bg-${hoverColor}`,
        className
      )}
      style={style}
      onMouseEnter={() => {
        if (isHoverPath)
          setHoveredPath({
            active: checkHoveredPath(apiPath + (node?.api_path || '/')),
          })
      }}
      onMouseLeave={() => {
        if (isHoverPath)
          setHoveredPath({
            active: hoveredPathSelected,
          })
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  hoveredPathSelected: getHoveredPathSelectedState,
})

const mapDispatchToProps = {
  setHoveredPath: queriesActions.setHoveredPath,
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeCasesContainer)
