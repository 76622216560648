import { FunctionComponent } from 'react'
import { Typography, Indicator } from 'shared'
import { COLOR_TYPES } from 'utils/theme'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import { connectionsActions } from 'pages/Connections/reducer'
import { getSelectedConnectionState } from 'pages/Connections/selectors'
import { ConnectionsItemType } from 'pages/Connections/types'
import classnames from 'classnames'
import { typeIcons } from './drawer/config'

const indicatorColor: { [name: string]: COLOR_TYPES } = {
  inactive: 'default-700',
  active: 'success',
}

interface ConnectionsItemProps {
  item: ConnectionsItemType
}

interface StateProps {
  selectedConnection: ConnectionsItemType | undefined
}

type DispatchProps = typeof mapDispathToProps

const ConnectionsItem: FunctionComponent<
  ConnectionsItemProps & StateProps & DispatchProps
> = ({ item, selectedConnection, selectConnection }) => {
  const TypeIcon = typeIcons[item.type]
  return (
    <div
      key={item.connection_id}
      className={classnames(
        'relative bg-white shadow rounded w-full p-t20 cursor-pointer',
        {
          'shadow-primary':
            selectedConnection?.connection_id === item.connection_id,
        }
      )}
      onClick={() => selectConnection(item)}
    >
      <div className="absolute right-t20 top-t20">
        {TypeIcon ? <TypeIcon width="50px" size={null} /> : null}
      </div>
      <div className="flex flex-row justify-between mb-t5">
        <Typography size="md" color="default-600">
          Updated at {item.updated_at}
        </Typography>
      </div>
      <div className="flex flex-row items-center space-x-t15 mb-t5">
        <Indicator
          style={{ width: 16, height: 16 }}
          color={indicatorColor[item.status]}
        />
        <Typography
          className="truncate"
          size="xl"
          color="primary-500"
          weight="bold"
        >
          {item.name}
        </Typography>
      </div>
      <div className="flex flex-row items-baseline space-x-t30 mb-t15">
        <Typography className="truncate" color="default-600" size="lg">
          {item.description}
        </Typography>
      </div>
      <div className="absolute right-t20 bottom-t20">
        <Typography size="md" color="default-600">
          Created at {item.created_at}
        </Typography>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  selectedConnection: getSelectedConnectionState,
})

const mapDispathToProps = {
  selectConnection: connectionsActions.selectConnection,
}

export default connect(mapStateToProps, mapDispathToProps)(ConnectionsItem)
