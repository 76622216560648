import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getIsOpenState, getTypeState, getConfigState } from './selectors'
import { Modal } from 'shared'
import { modalActions, ModalState } from './reducer'
import { ModalTypes } from './constants'
import CreateNewProject from 'pages/ProjectsN/modals/CreateNewProject'
import CreateNewDataset from 'pages/Project/modals/CreateNewDataset'
import CreateNewCluster from 'pages/Clusters/modals/CreateNewCluster'
import ConfirmationCloseTab from 'pages/Queries/modals/ConfirmationCloseTab'
import ConfirmationDelete from './modals/ConfirmationDelete'
import { RootState } from 'app/store'
import CreateWorkspace from './modals/CreateWorkspace'
import TreeMove from './modals/TreeMove'

const MODALS = {
  [ModalTypes.CreateNewProject]: <CreateNewProject />,
  [ModalTypes.CreateNewDataset]: <CreateNewDataset />,
  [ModalTypes.CreateNewCluster]: <CreateNewCluster />,
  [ModalTypes.ConfirmationCloseTab]: <ConfirmationCloseTab />,
  [ModalTypes.ConfirmationDelete]: <ConfirmationDelete />,
  [ModalTypes.CreateWorkspace]: <CreateWorkspace />,
  [ModalTypes.TreeMove]: <TreeMove />,
}

interface ModalProps extends ModalState {}

type DispatchProps = typeof mapDispatchToProps

const ModalContainer: FunctionComponent<ModalProps & DispatchProps> = ({
  isOpen,
  type,
  config,
  hideModal,
}) => {
  if (type) {
    return (
      <Modal
        content={MODALS[type]}
        open={isOpen}
        hideModal={hideModal}
        headerName={config ? config.headerName : undefined}
        // noPadding={config ? config.noPadding : false}
        // headerClassName={classnames(classes.header, {
        //   [classes.headerNoPadding]: config && config.noPadding,
        // })}
        // contentClassName={classes.content}
      />
    )
  }

  return null
}

const mapStateToProps = createStructuredSelector<RootState, ModalState>({
  isOpen: getIsOpenState,
  type: getTypeState,
  config: getConfigState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
