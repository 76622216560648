import { RootState } from 'app/store'
import { getSlideOverParamsState } from 'containers/SlideOver/selectors'
import { FunctionComponent, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Tabs } from 'shared'
import SqlViewTab from './SqlViewTab'
import TableViewTab from './TableViewTab'

const sqlTestValue = (project: string) => `CREATE TABLE ${project} (
company_id INT,
company_name VARCHAR(255),
is_buildable BOOLEAN
);
`

interface StateProps {
  params: any
}

const SchemeTab: FunctionComponent<StateProps> = ({ params }) => {
  const { idProject } = params
  const [sqlValue, setSqlValue] = useState<string>(() =>
    sqlTestValue(idProject)
  )
  const tabs = useMemo(() => {
    return [
      {
        id: 'table',
        title: 'Table',
        content: <TableViewTab />,
      },
      {
        id: 'sql',
        title: 'SQL',
        content: <SqlViewTab sqlValue={sqlValue} setSqlValue={setSqlValue} />,
      },
    ]
  }, [sqlValue])

  return (
    <div className="relative h-full">
      <Tabs
        className="h-full flex flex-col grow"
        headerClassName="flex justify-center space-x-t15 px-t20 mt-t5"
        tabs={tabs}
        size="lg"
      ></Tabs>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  params: getSlideOverParamsState,
})

export default connect(mapStateToProps)(SchemeTab)
