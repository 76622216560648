import { FunctionComponent } from 'react'
import PMainContainer from 'containers/Page/PMainContainer'
import AddNewConnection from './AddNewConnection'
import ConnectionsContainer from './ConnectionsContainer'

const MainContainer: FunctionComponent = () => {
  return (
    <PMainContainer className="flex-1 relative">
      <AddNewConnection />
      <ConnectionsContainer />
    </PMainContainer>
  )
}

export default MainContainer
