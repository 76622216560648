import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  getIsOpenState,
  getTypeState,
  getConfigState,
  getSlideOverLoadingState,
  getSlideOverParamsState,
} from './selectors'
import { SlideOver } from 'shared'
import { slideOverActions, SlideOverState } from './reducer'
import { SlideOverTypes } from './constants'
import { RootState } from 'app/store'
import ManageDataset from 'pages/Project/containers/slider'
import { CreateResource } from './slideOvers'

export const SLIDE_OVERS = {
  [SlideOverTypes.ManageDataset]: () => <ManageDataset />,
  [SlideOverTypes.CreateResource]: (props: any) => (
    <CreateResource {...props} />
  ),
}

interface SlideOverContainerProps extends SlideOverState {}

type DispatchProps = typeof mapDispatchToProps

const SlideOverContainer: FunctionComponent<
  SlideOverContainerProps & DispatchProps
> = ({ isOpen = false, type, config, loading, params, hideSlideOver }) => {
  return (
    <SlideOver
      type={type}
      isOpen={isOpen}
      loading={loading}
      params={params}
      hideSlideOver={hideSlideOver}
      // headerName={config ? config.headerName : undefined}
    />
  )
}

const mapStateToProps = createStructuredSelector<RootState, SlideOverState>({
  isOpen: getIsOpenState,
  type: getTypeState,
  config: getConfigState,
  loading: getSlideOverLoadingState,
  params: getSlideOverParamsState,
})

const mapDispatchToProps = {
  hideSlideOver: slideOverActions.hideSlideOver,
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideOverContainer)
