import React, { FunctionComponent } from 'react'
import QueryEditorContainer from '../QueryEditorContainer'
import QueryResultsContainer from '../QueryResultsContainer'
import { QueriesItemType } from '../../types'
import QueryParamsDrawer from '../drawer/QueryParamsDrawer'
import classnames from 'classnames'
import QueryResourceContainer from '../QueryResourceContainer'

interface QueryTabsProps {
  selectedTab: QueriesItemType
  className?: string
}

const QueryTabContainer: FunctionComponent<QueryTabsProps> = React.memo(
  ({ selectedTab, className = '' }) => {
    return (
      <div
        data-label="query-tab-container"
        className={classnames(
          'relative overflow-hidden flex-1 flex flex-col',
          className
        )}
      >
        <QueryResourceContainer query={selectedTab} />
        <QueryEditorContainer className="w-full flex-1 boundary" query={selectedTab} />
        <QueryResultsContainer
          selectedTab={selectedTab}
          className="w-full h-full"
        />
        <QueryParamsDrawer />
      </div>
    )
  },
  (prev, next) =>
    JSON.parse(JSON.stringify(prev.selectedTab)) ===
    JSON.parse(JSON.stringify(next.selectedTab))
)

export default QueryTabContainer
