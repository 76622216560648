/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import { Typography, Input } from 'shared'
import { COLOR_TYPES, wSize, hSize, bgColor } from 'utils/theme'
import { WarningIcon, PlusIcon } from 'assets/general'
import { IconButton } from 'shared/icon-button'
import { ButtonSimple } from 'shared/button'

const previewSize = 5 * 1024

interface FileUploadProps {
  accept: Accept
  type: 'file' | 'url'
  ref?: any
  name?: string
  id?: string
  className?: string
  inputClass?: string
  setFile?: (file: any) => void
  setUrl?: (file: any) => void
  getPreview?: (result: any, set: (value: any) => void) => void
  disabled?: boolean
  error?: string
  color?: COLOR_TYPES
  placeholder?: string | undefined
  width?: string | undefined
  height?: string
}

export const FileUpload: FC<FileUploadProps> = memo(
  forwardRef(
    (
      {
        name,
        id,
        placeholder,
        setFile = undefined,
        setUrl = () => {},
        className,
        inputClass = '',
        disabled,
        error,
        accept,
        getPreview,
        width = '64',
        height = 'H',
        color = 'default-100',
        type = 'file',
      },
      ref
    ) => {
      const [, forceUpdate] = useReducer((x) => x + 1, 0) // Need after reset
      const [_error, _setError] = useState(error)
      const [url, _setUrl] = useState('')

      const [preview, setPreview] = useState<any>('')
      const [openPreview, setOpenPreview] = useState(false)

      const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        acceptedFiles,
        inputRef,
      } = useDropzone({ accept, disabled })

      useEffect(() => {
        _setError(error)
      }, [error])

      useEffect(() => {
        if (setFile && acceptedFiles && acceptedFiles[0]) {
          setFile(acceptedFiles[0])

          if (getPreview) {
            const reader = new FileReader()
            var blob = acceptedFiles[0].slice(0, previewSize)
            reader.readAsText(blob)

            reader.onload = () => {
              getPreview(reader.result, setPreview)
            }

            reader.onerror = () => {
              setPreview(false)
            }
          }
        }
      }, [acceptedFiles, setFile, getPreview])

      useEffect(() => {
        if (
          getPreview &&
          (url.indexOf('http://') === 0 || url.indexOf('https://') === 0)
        ) {
          setPreview(null)
          fetch(`https://cors-anywhere.herokuapp.com/${url}`)
            .then((res) => {
              if (res.ok) {
                res
                  .blob()
                  .then((file) => {
                    const reader = new FileReader()
                    reader.readAsText(file)

                    reader.onload = () => {
                      getPreview(reader.result, setPreview)
                    }

                    reader.onerror = () => {
                      setPreview(false)
                    }
                  })
                  .catch((err) => {
                    _setError(err.message)
                    setPreview(false)
                  })
              } else {
                throw new Error(res.statusText)
              }
            })
            .catch((err) => {
              _setError(err.message)
              setPreview(false)
            })
        }
      }, [url, getPreview])

      useImperativeHandle(ref, () => ({
        reset: () => {
          handleReset()
          forceUpdate()
        },
      }))

      const handleReset = () => {
        acceptedFiles.splice(0, acceptedFiles.length)
        if (inputRef && inputRef.current !== null) inputRef.current.value = ''
      }

      const inputClassName = useMemo(
        () =>
          classnames(
            'flex items-center outline-none rounded cursor-pointer px-[14px] border-2',
            wSize(width),
            hSize(height),
            bgColor(color),
            isDragActive
              ? 'border-primary border-dashed'
              : isDragAccept || acceptedFiles[0]
              ? 'focus:border-primary border-default-100'
              : _error
              ? 'border-secondary'
              : 'border-dashed border-default-300',
            inputClass
          ),
        [
          isDragActive,
          isDragAccept,
          acceptedFiles,
          preview,
          _error,
          width,
          height,
          color,
        ]
      )

      const fileName =
        acceptedFiles && acceptedFiles[0] && acceptedFiles[0].name
          ? acceptedFiles[0].name
          : ''

      return (
        <div
          data-label="file-upload"
          className={classnames('flex items-center', className)}
        >
          {type === 'url' ? (
            <div>
              <Input
                placeholder={placeholder || 'Enter url to file'}
                onChange={(e) => {
                  _setUrl(e.target.value)
                  setUrl(e.target.value)
                }}
                color={color}
                height={height}
                width={width}
              />
            </div>
          ) : (
            <div {...getRootProps({ className: inputClassName })}>
              <input className="hidden" name={name} {...getInputProps()} />
              <Typography className={'items-center truncate'} size="md">
                {fileName || placeholder || 'Select or drop a file'}
              </Typography>
            </div>
          )}
          {preview ? (
            <div className="ml-2.5">
              <ButtonSimple
                className="bg-default-100 hover:bg-default-300 text-default-700 text-md px-2.5 py-1.5 rounded-3"
                onClick={() => setOpenPreview(true)}
              >
                Preview
              </ButtonSimple>
              {openPreview ? (
                <>
                  <div className="absolute top-0 left-0 right-0 bottom-0 z-30 bg-white rounded overflow-auto">
                    {preview}
                  </div>
                  <IconButton
                    className="absolute z-40 right-2.5 top-2.5 bg-default-100"
                    onClick={() => setOpenPreview(false)}
                  >
                    <PlusIcon
                      className="transform rotate-45"
                      size="lg"
                      color="secondary"
                    />
                  </IconButton>
                </>
              ) : (
                ''
              )}
            </div>
          ) : preview === null ? null : _error ? ( // /> //   color={COLOR_TYPES.PRIMARY} //   loading={true} //   className="absolute right-0" // <Loader
            <WarningIcon
              className="absolute right-1.5"
              color="secondary"
              size="lg"
            />
          ) : (
            ''
          )}
        </div>
      )
    }
  ),
  (prev, next) => true
)
