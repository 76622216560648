import { combineReducers } from '@reduxjs/toolkit'

import mainReducer from 'pages/Main/reducer'
import signInReducer from 'pages/SignIn/reducer'
import datasetReducer from 'pages/Dataset/reducer'
import projectsReducer from 'pages/ProjectsN/reducer'
import projectReducer from 'pages/Project/reducer'
import notificationReducer from 'containers/Notification/reducer'
import modalReducer from 'containers/Modal/reducer'
import slideOverReducer from 'containers/SlideOver/reducer'
import queriesReducer from 'pages/Queries/reducer'
import dataCatalogReducer from 'pages/DataCatalog/reducer'
import clusterReducer from 'pages/Clusters/reducer'
import connectionsReducer from 'pages/Connections/reducer'
import sidebarReducer from 'containers/Sidebar/reducer'
import resourcesReducer from 'pages/Resources/reducer'
import resourceReducer from 'pages/Resource/reducer'

export const rootReducer = combineReducers({
  main: mainReducer,
  signIn: signInReducer,
  dataset: datasetReducer,
  projects: projectsReducer,
  project: projectReducer,
  notification: notificationReducer,
  modal: modalReducer,
  slideOver: slideOverReducer,
  queries: queriesReducer,
  dataCatalog: dataCatalogReducer,
  clusters: clusterReducer,
  connections: connectionsReducer,
  sidebar: sidebarReducer,
  resources: resourcesReducer,
  resource: resourceReducer,
})
