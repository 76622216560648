import { FunctionComponent } from 'react'
import { Typography, Indicator, IconButton } from 'shared'
import { LinkIcon } from 'assets/general/LinkIcon'

interface DatasetDescriptionProps {}

const DatasetDescription: FunctionComponent<DatasetDescriptionProps> = () => {
  return (
    <div
      data-label="dataset-description"
      className="flex justify-between p-t20 rounded bg-default-100"
    >
      <div className="pr-t30">
        <Typography
          className="flex items-center"
          color="primary-500"
          size="2xl"
          weight="bold"
        >
          Motor Insurance: Internal Claims Data
          <Indicator className="ml-md" color="success" size="md" />
        </Typography>
        <Typography className="mt-t12" size="lg">
          Owner by James
        </Typography>
        <Typography className="mt-t12" color="default-600" size="lg">
          Updated on 3rd Feb, 2020 08:41:16 am
        </Typography>
      </div>
      <div className="flex">
        <div className="border-l-2 px-t30 border-default-300">
          <div className="flex">
            <Typography size="xl" weight="bold">
              39
            </Typography>
            <Typography className="ml-sm" size="xl">
              columns
            </Typography>
          </div>
          <div className="flex my-t5">
            <Typography size="xl" weight="bold">
              176,152
            </Typography>
            <Typography className="ml-sm" size="xl">
              rows
            </Typography>
          </div>
          <div className="flex mt-t5">
            <Typography size="xl" weight="bold">
              19,168
            </Typography>
            <Typography className="ml-sm" size="xl">
              rows 3 months ago
            </Typography>
          </div>
        </div>
        <div className="flex items-start">
          <IconButton>
            <LinkIcon
              width={'20px'}
              height={'20px'}
              color="secondary"
              size={null}
              // className="transform rotate-90"
            />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default DatasetDescription
