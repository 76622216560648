import { createSelector } from 'reselect'
import { RootState } from '../../app/store'

export const getResourceListState = (state: RootState) => state.resource

export const getResourceState = createSelector(
  getResourceListState,
  (state) => state.resource
)

export const getResourceIdState = createSelector(
  getResourceListState,
  (state) => state.resource?.resource_id
)

export const getResourceTablesState = createSelector(
  getResourceListState,
  (state) => state.resource?.tables || []
)

export const getSelectedTableState = createSelector(
  getResourceListState,
  (state) => state.selectedTable
)

export const getTableDataState = createSelector(
  getResourceListState,
  (state) => state.tableData
)
