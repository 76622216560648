import { WorkspaceId } from 'containers/Sidebar/types'
import { createBrowserHistory } from 'history'
import { ResourceId } from 'pages/Resources/types'

export const history = createBrowserHistory()

export const pushRoute = (route: string) => history.push(route)

interface IRoutes {
  path: {
    Root: string
    SignIn: string
    Workspaces: string
    Workspace: string
    Resources: string
    DefaultResources: string
    Resource: string
    Queries: string
    DefaultQueries: string
    [route: string]: string
  }
  url: {
    Root: () => string
    SignIn: () => string
    Workspaces: () => string
    Workspace: (workspace_id: WorkspaceId) => string
    Resources: (workspace_id: WorkspaceId) => string
    DefaultResources: () => string
    Resource: (workspace_id: WorkspaceId, resource_id: ResourceId) => string
    Queries: (workspace_id: WorkspaceId) => string
    DefaultQueries: () => string
    [route: string]: (a?: any, b?: any) => string
  }
}

export const Routes: IRoutes = {
  path: {
    Root: '/',
    SignIn: '/login',
    Workspaces: '/workspaces',
    Workspace: `/workspaces/:workspace_id`,
    Resources: `/workspaces/:workspace_id/resources`,
    DefaultResources: `/resources`,
    Resource: `/workspaces/:workspace_id/resources/:resource_id`,
    Queries: `/workspaces/:workspace_id/queries`,
    DefaultQueries: `/queries`,

    Main: '/main',
    Projects: `/projects`,
    Project: `/projects/:id`,
    Dataset: `/projects/:project_id/datasets/:dataset_id`,
    DataCatalog: '/datacatalog',
    Clusters: '/clusters',
    Monitoring: '/monitoring',
    Admin: '/admin',
  },
  url: {
    Root: () => Routes.path.Root,
    SignIn: () => Routes.path.SignIn,
    Workspaces: () => Routes.path.Workspaces,
    DefaultWorkspaces: () => `/workspaces`,
    Workspace: (workspace_id) => `${Routes.path.Workspaces}/${workspace_id}`,
    Resources: (workspace_id) =>
      `${Routes.url.Workspace(workspace_id)}/resources`,
    DefaultResources: () => `/resources`,
    Resource: (workspace_id, resource_id) =>
      `${Routes.url.Resources(workspace_id)}/${resource_id}`,
    Queries: (workspace_id) => `${Routes.url.Workspace(workspace_id)}/queries`,
    DefaultQueries: () => `/queries`,

    Main: () => Routes.path.Main,
    Projects: () => Routes.path.Projects,
    Project: (id: number | string) => `${Routes.path.Projects}/${id}`,
    Dataset: ({
      project_id,
      dataset_id,
    }: {
      project_id: number | string
      dataset_id: number | string
    }) => `${Routes.url.Project(project_id)}/datasets/${dataset_id}`,
    DataCatalog: () => `${Routes.path.DataCatalog}`,
    Clusters: () => `${Routes.path.Clusters}`,
    Monitoring: () => `${Routes.path.Monitoring}`,
    Admin: () => `${Routes.path.Admin}`,
  },
}

// useParams interface
export interface ParamsType {
  id: string
  id_dataset?: string
}
