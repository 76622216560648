import { FC } from 'react'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'
import { Routes } from 'utils/router'
import { useHistory, useParams } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { IconButton } from 'shared'

export const Header: FC = () => {
  const history = useHistory()
  const { workspace_id } = useParams<any>()
  return (
    <div data-label="resource-header-container">
      <PHeaderContainer>
        <div className="flex space-x-4">
          <IconButton
            onClick={() => {
              history.replace(
                workspace_id
                  ? Routes.url.Resources(workspace_id)
                  : Routes.url.DefaultResources()
              )
            }}
            className="group bg-default-100 rounded-full flex items-center justify-center hover:bg-default-200"
          >
            <ChevronLeftIcon className="w-5 h-5 text-default-400 group-hover:text-default-500" />
          </IconButton>
          <PageName name="Resource Editor" />
        </div>
      </PHeaderContainer>
    </div>
  )
}
