import React, { FunctionComponent, useMemo } from 'react'
import classnames from 'classnames'
import { LoaderWithOverlay, Typography, VirtualizedRTable } from 'shared'
import { NoFound } from 'containers/NoFound'
import { dateFormater } from '../chart/helpers'

const tableData = [
  {
    id: 1,
    user: 'test_user_1',
    datasource: 'datasource_1',
    action_type: 'type_1',
    date: new Date(),
  },
  {
    id: 12,
    user: 'test_user_12',
    datasource: 'datasource_41',
    action_type: 'type_11',
    date: new Date(),
  },
  {
    id: 111,
    user: 'test_user_41',
    datasource: 'datasource_31',
    action_type: 'type_71',
    date: new Date(),
  },
  {
    id: 41,
    user: 'test_user_51',
    datasource: 'datasource_11',
    action_type: 'type_12',
    date: new Date(),
  },
  {
    id: 61,
    user: 'test_user_41',
    datasource: 'datasource_11',
    action_type: 'type_1',
    date: new Date(),
  },
]

interface MonitoringTableProps {}

export const MonitoringTable: FunctionComponent<MonitoringTableProps> = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        type: 'Number',
        width: 50,
        minWidth: 50,
      },
      {
        Header: 'User',
        accessor: 'user',
        type: 'string',
        width: '100%',
      },
      {
        Header: 'Datasource',
        accessor: 'datasource',
        type: 'string',
        width: '100%',
      },
      {
        Header: 'Action Type',
        accessor: 'action_type',
        type: 'string',
        width: '100%',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: (rowData: any) => (
          <Typography>{dateFormater(rowData.value)}</Typography>
        ),
        type: 'Date',
        width: '100%',
      },
    ],
    []
  )

  const table = useMemo(
    () => (
      <>
        <LoaderWithOverlay loading={false} className="items-center" />
        {tableData ? (
          <div className="flex w-full">
            <VirtualizedRTable
              tableClassName="w-full"
              data={tableData}
              columns={columns}
              contentHeight={170}
              itemSize={30}
              fullWidth
              getHeaderProps={(header, i) => ({
                className: classnames(
                  'text-md text-white p-t15 bg-primary-light first:rounded-tl last:rounded-tr'
                ),
                style: {
                  width: header.width,
                  minWidth: header.minWidth,
                },
              })}
              getCellProps={(cell, i) => ({
                className: classnames(
                  'text-md py-t8 px-t15 text-left truncate border-b text-default-700',
                  i === 0 ? 'bg-default-150' : ''
                ),
                style: {
                  width: cell.column.width,
                  minWidth: cell.column.minWidth,
                },
              })}
            />
          </div>
        ) : (
          <NoFound name="No Data Found" />
        )}
      </>
    ),
    [columns]
  )

  return (
    <div
      data-label="monitoring-table-container"
      className="grow relative w-full h-min overflow-x-auto overflow-y-hidden mt-t25 p-t25 shadow"
    >
      {table}
    </div>
  )
}
