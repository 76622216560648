import { FunctionComponent } from 'react'
import { useTable } from 'react-table'
import { RTableProps, HeaderGroupTypeT, RowTypeT, CellTypeT } from './types'

// Create a default prop getter
const defaultPropGetter = () => ({})

export const RTable: FunctionComponent<RTableProps> = ({
  columns,
  data,
  tableClassName = '',
  theadClassName = '',
  tbodyClassName = '',
  getHeaderGroupProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()} className={tableClassName}>
      <thead className={theadClassName}>
        {headerGroups.map((headerGroup: HeaderGroupTypeT, ind: number) => (
          <tr
            {...headerGroup.getHeaderGroupProps(
              getHeaderGroupProps(headerGroup, ind)
            )}
          >
            {headerGroup.headers.map((column: HeaderGroupTypeT, i: number) => (
              <th {...column.getHeaderProps(getHeaderProps(column, i, ind))}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={tbodyClassName}>
        {rows.map((row: RowTypeT, i: number) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps(getRowProps(row, i))}>
              {row.cells.map((cell: CellTypeT, ind: number) => {
                return (
                  <td {...cell.getCellProps(getCellProps(cell, ind, i))}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
