export const defineCurrentStepIndex = (order: string[], activeStep: any) => {
  return order.indexOf(activeStep) || 0
}

export const definePrevStep = (order: string[], activeStep: any) => {
  const currentStepIndex = defineCurrentStepIndex(order, activeStep)
  return currentStepIndex - 1 > 0 ? currentStepIndex - 1 : 0
}

export const defineNextStep = (order: string[], activeStep: any) => {
  const currentStepIndex = defineCurrentStepIndex(order, activeStep)
  return currentStepIndex + 1 > order.length - 1
    ? currentStepIndex
    : currentStepIndex + 1
}
