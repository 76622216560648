import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkspaceId } from 'containers/Sidebar/types'
import { IResources, IResourcesState, ResourceId } from './types'

const prefix = 'resources'

const initialState: IResourcesState = {
  resources: [],
  checked: false
}

const resourcesReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchResources: (state, action: PayloadAction<WorkspaceId>) => {},
    didFetchResources: (state, { payload }: PayloadAction<IResources[]>) => {
      state.resources = payload || []
    },
    createResource: (state, action: PayloadAction<any>) => {},
    didCreateResource: (state, { payload }: PayloadAction<any>) => {
      state.resources.push(payload)
    },
    updateResource: (state, action: PayloadAction<any>) => {},
    didUpdateResource: (state, { payload }: PayloadAction<any>) => {
      const idx: number = state.resources.findIndex(
        (d) => d.resource_id === payload.resource_id
      )
      state.resources.splice(idx, 1, {
        ...state.resources[idx],
        ...payload.data,
      })
    },
    deleteResource: (
      state,
      action: PayloadAction<{ resource_id: ResourceId; name: string }>
    ) => {},
    didDeleteResource: (state, { payload }: PayloadAction<ResourceId>) => {
      state.resources = state.resources.filter(
        (d: IResources) => d.resource_id !== payload
      )
    },
    checkResource: (state, action: PayloadAction<any>) => {},
    didCheckResource: (state, { payload }: PayloadAction<boolean>) => {
      state.checked = payload
    },
  },
})

export const resourcesActions = resourcesReducer.actions

export default resourcesReducer.reducer
