import { PayloadAction } from '@reduxjs/toolkit'
import { TOAST_TYPES } from 'containers/Notification/constants'
import { notificationActions } from 'containers/Notification/reducer'
import { sidebarActions } from 'containers/Sidebar/reducer'
import { getSelectedWorkspaceState } from 'containers/Sidebar/selectors'
import { WorkspaceId } from 'containers/Sidebar/types'
import { slideOverActions } from 'containers/SlideOver/reducer'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import { resourcesService } from 'utils/services'
import { resourcesActions } from './reducer'
import { getResourceByIdState } from './selectors'
import { IResources, ResourceId } from './types'

function* handleFetchResources({ payload }: PayloadAction<WorkspaceId>) {
  try {
    yield put(sidebarActions.selectWorkspace(payload))
    const data: IResources[] = yield call(
      resourcesService.getResources,
      payload
    )
    yield put(resourcesActions.didFetchResources(data))
  } catch (error) {}
}

function* handleCreateResource({ payload }: PayloadAction<any>): any {
  try {
    yield put(slideOverActions.startLoading(''))
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const data: any = yield call(resourcesService.createResource, {
      workspace_id,
      data: payload,
    })
    yield put(resourcesActions.didCreateResource(data))
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} created successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
    yield put(slideOverActions.hideSlideOver())
  } catch (error) {
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not created!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleUpdateResource({ payload }: PayloadAction<any>) {
  const resource: IResources = yield select(
    getResourceByIdState(payload.resource_id)
  )
  try {
    yield put(slideOverActions.startLoading(''))
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield call(resourcesService.updateResource, {
      workspace_id,
      resource_id: payload.resource_id,
      data: payload.data,
    })
    yield put(resourcesActions.didUpdateResource(payload))
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${resource.name} update successfully!`,
        options: {
          type: TOAST_TYPES.SUCCESS,
        },
      })
    )
    yield put(slideOverActions.hideSlideOver())
  } catch (error) {
    yield put(slideOverActions.stopLoading())
    yield put(
      notificationActions.showNotification({
        message: `${resource.name} not updated!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleDeleteResource({
  payload,
}: PayloadAction<{ resource_id: ResourceId; name: string }>): any {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    const data: any = yield call(resourcesService.deleteResource, {
      workspace_id,
      resource_id: payload.resource_id,
    })
    // TODO if status 400 not show error
    if (data) {
      yield put(resourcesActions.didDeleteResource(payload.resource_id))
      yield put(
        notificationActions.showNotification({
          message: `${payload.name} deleted successfully!`,
          options: {
            type: TOAST_TYPES.SUCCESS,
          },
        })
      )
    } else
      yield put(
        notificationActions.showNotification({
          message: `${payload.name} not deleted!`,
          options: {
            type: TOAST_TYPES.ERROR,
          },
        })
      )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `${payload.name} not deleted!`,
        options: {
          type: TOAST_TYPES.ERROR,
        },
      })
    )
  }
}

function* handleCheckResource({ payload }: PayloadAction<any>) {
  try {
    const workspace_id: WorkspaceId = yield select(getSelectedWorkspaceState)
    yield put(slideOverActions.startLoading(''))
    const isChecked: boolean = yield call(resourcesService.checkResource, {
      workspace_id,
      data: payload,
    })
    yield put(resourcesActions.didCheckResource(isChecked))
    yield put(slideOverActions.stopLoading())
  } catch (error) {}
}

export default function* watchResourcesSaga() {
  yield takeLatest(resourcesActions.fetchResources.type, handleFetchResources)
  yield takeLatest(resourcesActions.createResource.type, handleCreateResource)
  yield takeLatest(resourcesActions.updateResource.type, handleUpdateResource)
  yield takeLatest(resourcesActions.deleteResource.type, handleDeleteResource)
  yield takeLatest(resourcesActions.checkResource.type, handleCheckResource)
}
