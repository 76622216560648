import { FC } from 'react'
import { Button, Typography } from 'shared'
import { PlusIcon } from 'assets/general/PlusIcon'
import PHeaderContainer from 'containers/Page/PHeaderContainer'
import PageName from 'containers/Page/PageName'
import { connect } from 'react-redux'
import { slideOverActions } from 'containers/SlideOver/reducer'
import {
  SlideOverOptions,
  SlideOverTypes,
} from 'containers/SlideOver/constants'

interface HeaderContainerProps {}

type DispatchProps = typeof mapDispatchToProps

export const HeaderContainer: FC<HeaderContainerProps & DispatchProps> = ({
  showSlideOver,
}) => {
  return (
    <div data-label="resources-header-container">
      <PHeaderContainer>
        <PageName name="Resources" />
        <Button
          onClick={() =>
            showSlideOver({
              type: SlideOverTypes.CreateResource,
              config: {
                ...SlideOverOptions.CreateResource,
              },
            })
          }
        >
          <PlusIcon className="mr-xs" />
          <Typography size="sm">Create New</Typography>
        </Button>
      </PHeaderContainer>
    </div>
  )
}

const mapDispatchToProps = {
  showSlideOver: slideOverActions.showSlideOver,
}

export default connect(null, mapDispatchToProps)(HeaderContainer)
