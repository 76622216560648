import { FunctionComponent, useMemo, useState } from 'react'
import { Button, TreeView, Typography } from 'shared'
import { modalActions } from 'containers/Modal/reducer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ModalContentContainer from 'containers/Modal/ModalContentContainer'
import ModalFooterContainer from 'containers/Modal/ModalFooterContainer'
import { RootState } from 'app/store'
import {
  getModalLoadingState,
  getModalParamsState,
} from 'containers/Modal/selectors'
import { getQueryCollectionsState } from 'pages/Queries/selectors'
import NodeCasesContainer from 'pages/Queries/containers/NodeCasesContainer'
import { ToggleIcon } from 'pages/Queries/containers/right-panel/FieldsTab'

const commonClasses = (props?: any) => ({
  nodeCaseContainer: `relative flex group items-center cursor-pointer w-full hover:bg-default-200 py-1.5 ${
    props?.border || ''
  }`,
  selectedNode: 'bg-default-300 hover:!bg-default-300',
})

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

const TreeMove: FunctionComponent<SelectedProps & DispatchProps> = ({
  loading,
  params,
  hideModal,
  queryCollections,
}) => {
  const [selectedObj, setSelectedObj] = useState<any>({
    indexes: [],
    apiPath: '',
  }) // needed data when move to tree

  const handleUpdateIndexes = ({
    toNodeKey,
    apiPath,
  }: {
    toNodeKey: string
    apiPath: string
  }) => {
    setSelectedObj({ indexes: findIndexes(toNodeKey), apiPath })
  }

  const _queryCollections = useMemo(() => {
    return queryCollections.map((d: any, idx: number) => ({
      ...d,
      disabled: idx !== findIndexes(params.moveNode)[0],
    }))
  }, [queryCollections, params])

  return (
    <div data-label="modal-confirmation-close-tab">
      <ModalContentContainer>
        <TreeView
          tree={_queryCollections}
          updateTreeDnd={() => {}}
          disabledDrag={['collection', 'folder', 'query']}
          nodeCases={({
            node,
            toggled,
            selectedNode,
            rootParentId,
            memoryFields,
            toNodeKey,
            onClick,
            onSelect,
          }: any) => {
            return {
              collection: (
                <NodeCasesContainer
                  style={{ paddingLeft: `${16 * node.level}px` }}
                  hoverColor="default-200"
                  // isHoverPath
                  node={node}
                  selectedNode={selectedNode}
                  apiPath={memoryFields.apiPath}
                  commonClasses={commonClasses()}
                  onClick={(e: any) => {
                    onSelect()
                    onClick(e)
                    handleUpdateIndexes({
                      toNodeKey,
                      apiPath: [
                        memoryFields.apiPath,
                        node.api_path || '',
                        params.node.api_path,
                      ].join(''),
                    })
                  }}
                >
                  <div className="flex px-2.5">
                    <ToggleIcon node={node} toggled={toggled} />
                    <div>
                      <Typography className="mb-0.5" color="default-600">
                        {node.name}
                      </Typography>
                    </div>
                  </div>
                </NodeCasesContainer>
              ),
              folder: (
                <NodeCasesContainer
                  hoverColor="default-200"
                  node={node}
                  selectedNode={selectedNode}
                  apiPath={memoryFields.apiPath}
                  commonClasses={commonClasses()}
                  onClick={(e: any) => {
                    onSelect()
                    onClick(e)
                    handleUpdateIndexes({
                      toNodeKey,
                      apiPath: [
                        memoryFields.apiPath,
                        node.api_path || '',
                        params.node.api_path,
                      ].join(''),
                    })
                  }}
                >
                  <div className="flex px-2.5">
                    <ToggleIcon node={node} toggled={toggled} />
                    <div>
                      <Typography className="mb-0.5" color="default-600">
                        {node.name}
                      </Typography>
                    </div>
                  </div>
                </NodeCasesContainer>
              ),
              query: (
                <NodeCasesContainer
                  hoverColor="default-200"
                  node={node}
                  selectedNode={selectedNode}
                  apiPath={memoryFields.apiPath}
                  commonClasses={commonClasses()}
                  onClick={(e: any) => {
                    onSelect()
                    onClick(e)
                    handleUpdateIndexes({
                      toNodeKey,
                      apiPath: [
                        memoryFields.apiPath,
                        params.node.api_path,
                      ].join(''),
                    })
                  }}
                >
                  <div className="flex px-8">
                    <div>
                      <Typography
                        className="mb-0.5 truncate"
                        color="default-600"
                      >
                        {node.name}
                      </Typography>
                    </div>
                  </div>
                </NodeCasesContainer>
              ),
            }
          }}
        />
      </ModalContentContainer>
      <ModalFooterContainer>
        <div></div>
        <div className="flex space-x-4">
          <Button variant="OUTLINED" color="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            loading={loading.name === 'move' && loading.is}
            disabled={loading.is}
            onClick={() => {
              params.handleMove({
                old: findIndexes(params.moveNode),
                new: selectedObj.indexes,
                apiPath: selectedObj.apiPath,
                node: params.node,
              })
            }}
          >
            Save
          </Button>
        </div>
      </ModalFooterContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, any>({
  loading: getModalLoadingState,
  params: getModalParamsState,
  queryCollections: getQueryCollectionsState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeMove)

const findIndexes = (key: string) => {
  const idxs: number[] = []
  key.split('/').forEach((d: any) => {
    const _key = d.split('_')
    if (_key[0] !== 'query' && _key[1]) idxs.push(Number(_key[1]))
  })

  return idxs
}
