import { FunctionComponent, useMemo } from 'react'
import cn from 'classnames'
import { ButtonSimple, IconButton, MenuNew, Typography } from 'shared'
import { createStructuredSelector } from 'reselect'
import { RootState } from 'app/store'
import {
  getLoadingButtonsState,
  getQueryIdState,
  getSelectedParamsState,
  getSavedParamsState,
  getTabsState,
} from '../../selectors'
import { connect } from 'react-redux'
import { LoadingButtonsTypes, QueriesItemType } from '../../types'
import { queriesActions } from '../..//reducer'
import { MenuVerticalDotIcon, PlusIcon } from 'assets/general'
import { v4 as uuidv4 } from 'uuid'
import { modalActions } from 'containers/Modal/reducer'
import { ModalOptions, ModalTypes } from 'containers/Modal/constants'
import { cloneDeep } from 'lodash'
import { SavedParamsTypes } from '../../types'
import DrawerFooter from '../drawer/DrawerFooter'
import { NoFound } from 'containers/NoFound'

interface SavedParamsTabProps {
  className?: string
}

type StateProps = {
  queryId: string
  tabs: QueriesItemType[]
  savedParams: SavedParamsTypes[]
  selectedParams: string | null
  loadingButtons: LoadingButtonsTypes
}

type DispatchProps = typeof mapDispatchToProps

const SavedParamsTab: FunctionComponent<
  SavedParamsTabProps & StateProps & DispatchProps
> = ({
  className,
  queryId,
  tabs,
  savedParams,
  selectedParams,
  addNewSavedParams,
  duplicateSavedParams,
  deleteSavedParams,
  loadingButtons,
  updateSelectedParams,
  showModal,
}) => {
  const serverSavedParams = useMemo(() => {
    const tab = tabs.filter((d) => d.query_id === queryId)[0]
    return tab?.saved_params ? tab.saved_params.filter((d) => 'id' in d) : []
  }, [tabs, queryId])
  return (
    <div
      data-label="rp-saved-params-tab"
      className={cn('flex flex-col grow px-2.5', className)}
    >
      <div className="flex items-center mt-2.5">
        <ButtonSimple
          loading={
            loadingButtons.name === 'add_new_params' && loadingButtons.is
          }
          self="auto"
          className="bg-default-100 hover:bg-default-300 text-default-700 text-sm p-1.5 rounded-sm mr-1"
          disabled={loadingButtons.is}
          onClick={() => {
            const newId = uuidv4()
            addNewSavedParams({
              query_id: queryId,
              params_id: newId,
              newParams: { id: newId, params: {}, body: {} },
              savedParams: serverSavedParams,
              changedSavedParams: savedParams,
            })
          }}
        >
          <PlusIcon />
        </ButtonSimple>
        <DrawerFooter />
      </div>
      <div className="mt-2.5 relative flex flex-col grow">
        {serverSavedParams.length ? (
          serverSavedParams.map((param) => {
            const name = param.name
              ? param.name
              : JSON.stringify({ ...param.params, ...param.body })
            return (
              <div
                key={param.id}
                className={cn(
                  {
                    'bg-default-300 hover:bg-default-300':
                      selectedParams === param.id,
                  },
                  'relative flex flex-col group cursor-pointer w-full bg-default-50 hover:bg-default-200 px-2.5 py-1.5 rounded mt-1.5'
                )}
                onClick={() => updateSelectedParams(param.id || null)}
              >
                <Typography className="truncate" size="md">
                  {name}
                </Typography>
                <MenuNew
                  className="!absolute right-1 top-2"
                  items={[
                    {
                      label: 'Duplicate',
                      onClick: () => {
                        const newId = uuidv4()
                        const newParam = serverSavedParams.find(
                          (d) => d?.id === param.id
                        )
                        duplicateSavedParams({
                          query_id: queryId,
                          params_id: newId,
                          newParams: { ...newParam, id: newId },
                          savedParams: serverSavedParams,
                          changedSavedParams: savedParams,
                        })
                      },
                    },
                    {
                      label: 'Delete',
                      onClick: () => {
                        let newIdx = -1
                        const newParams = cloneDeep(savedParams)
                        const idxParam = newParams.findIndex(
                          (d) => d.id === param.id
                        )
                        newParams.splice(idxParam, 1)
                        if (selectedParams === param.id) {
                          newIdx = idxParam - 1 >= 0 ? idxParam - 1 : 0
                        }

                        showModal({
                          type: ModalTypes.ConfirmationDelete,
                          config: {
                            ...ModalOptions.ConfirmationDelete,
                            headerName: 'Delete',
                          },
                          params: {
                            tab: {
                              query_id: queryId,
                              params_id: newParams.length
                                ? newIdx !== -1
                                  ? newParams[newIdx].id
                                  : selectedParams
                                : null,
                              name,
                              data: {
                                saved_params: newParams.length
                                  ? newParams
                                  : [{ params: {}, body: {} }],
                              },
                            },
                            handleDelete: deleteSavedParams,
                          },
                        })
                      },
                    },
                  ]}
                >
                  <IconButton
                    className={cn(
                      '!p-1 opacity-0 group-hover:opacity-100',
                      selectedParams === param.id
                        ? 'hover:bg-default-400'
                        : 'hover:bg-default-300'
                    )}
                  >
                    <MenuVerticalDotIcon className="w-[12px] h-[12px]" />
                  </IconButton>
                </MenuNew>
              </div>
            )
          })
        ) : (
          <NoFound name="Params list is empty." />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  queryId: getQueryIdState,
  tabs: getTabsState,
  savedParams: getSavedParamsState,
  selectedParams: getSelectedParamsState,
  loadingButtons: getLoadingButtonsState,
})

const mapDispatchToProps = {
  addNewSavedParams: queriesActions.addNewSavedParams,
  duplicateSavedParams: queriesActions.duplicateSavedParams,
  deleteSavedParams: queriesActions.deleteSavedParams,
  updateSelectedParams: queriesActions.updateSelectedParams,
  showModal: modalActions.showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedParamsTab)
