import React from 'react'
import { Typography } from 'shared'

interface RevisionContainerProps {}

export const RevisionContainer: React.FunctionComponent<
  RevisionContainerProps
> = () => {
  return (
    <div>
      <Typography size="md">Current Revision:</Typography>
      <Typography className="mt-t5" size="xl" weight="bold" color="primary-500">
        plain_hall_9653
      </Typography>
      <Typography className="mt-t20" size="md" color="default-600">
        38,760 rows appended on 3rd Feb, 2020 08:41:16 am
      </Typography>
    </div>
  )
}
