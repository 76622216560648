import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Editor, { loader } from '@monaco-editor/react'

import { sqlSuggestions } from './sql'
import { MonacoEditorProps, MONACO_LANGUAGES_TYPES } from './types'

// import or create own formatter if needed
const formatter = (value: any) => value

const defaultSuggestions = (monaco: any) => [
  ...sqlSuggestions.keywords.map((el) => ({
    label: el,
    insertText: el,
    kind: monaco.languages.CompletionItemKind.Keyword,
  })),
  ...sqlSuggestions.operators.map((el) => ({
    label: el,
    insertText: el,
    kind: monaco.languages.CompletionItemKind.Operator,
  })),
  ...sqlSuggestions.builtinFunctions.map((el) => ({
    label: el,
    insertText: el,
    kind: monaco.languages.CompletionItemKind.Function,
  })),
  ...sqlSuggestions.builtinVariables.map((el) => ({
    label: el,
    insertText: el,
    kind: monaco.languages.CompletionItemKind.Variable,
  })),
  ...sqlSuggestions.pseudoColumns.map((el) => ({
    label: el,
    insertText: el,
    kind: monaco.languages.CompletionItemKind.Snippet,
  })),
]

interface SqlMonacoEditorProps extends MonacoEditorProps {
  suggestions?: {
    label: string
    insertText: string
  }[]
}

export const SqlMonacoEditor: FunctionComponent<SqlMonacoEditorProps> = ({
  language = MONACO_LANGUAGES_TYPES.SQL,
  value,
  onChange = () => null,
  suggestions = [],
  classes = {},
  readOnly,
  minimap = true,
  lineNumbers = true,
}) => {
  const [monaco, setMonaco] = useState<any>(loader.__getMonacoInstance())
  // const monaco = useMonaco() // TODO uncaught in promise
  const monacoRef = useRef<any>()

  useEffect(() => {
    if (!loader.__getMonacoInstance())
      loader.init().then((monaco) => setMonaco(monaco))
  }, [])

  useEffect(() => {
    monacoRef.current = monaco?.languages.registerCompletionItemProvider(
      language,
      {
        triggerCharacters: ['@', '$'],
        provideCompletionItems: (
          model: any,
          position: any,
          context: any,
          token: any
        ) => {
          return {
            suggestions: [
              ...defaultSuggestions(monaco),
              ...suggestions.map((el) => ({
                label: el.label,
                insertText: el.insertText,
                kind: monaco.languages.CompletionItemKind.Constant,
              })),
            ],
          }
        },
      }
    )
    return () => {
      monacoRef.current?.dispose()
    }
  }, [monaco, suggestions])

  const onMount = (editor: any, monaco: any) => {
    monaco?.languages.registerDocumentFormattingEditProvider(language, {
      provideDocumentFormattingEdits(model: any, options: any) {
        const formatted = formatter(model.getValue())
        return [
          {
            range: model.getFullModelRange(),
            text: formatted,
          },
        ]
      },
    })
    monaco?.languages.registerDocumentRangeFormattingEditProvider(language, {
      provideDocumentRangeFormattingEdits(
        model: any,
        range: any,
        options: any
      ) {
        const formatted = formatter(model.getValueInRange(range))
        return [
          {
            range: range,
            text: formatted,
          },
        ]
      },
    })

    // mannully tirgger selection formatting by:
    editor.trigger('editor', 'editor.action.formatSelection')
    // mannually trigger document formatting by:
    editor.trigger('editor', 'editor.action.formatDocument')
  }

  return (
    <Editor
      // wrapperClassName={classes.root}
      className={classes?.editor || ''}
      language={language}
      value={value}
      onChange={onChange}
      onMount={onMount}
      options={{
        readOnly,
        minimap: { enabled: minimap },
        lineNumbers,
        scrollbar: { horizontalScrollbarSize: 5, verticalScrollbarSize: 5 },
      }}
    />
  )
}

// const formatter = (sql: string) => {
//   const postFormat = (sql: string) => {
//     const chars = sql.split('')
//     const newChars: any[] = []

//     chars.forEach((char: string, index: number) => {
//       if (char === ' ' && index < chars.length - 1) {
//         switch (chars[index + 1]) {
//           case ',':
//           case '(':
//           case ')':
//           case '[':
//           case ']':
//           case '{':
//           case '}':
//             break

//           default:
//             return newChars.push(char)
//         }
//       }

//       return newChars.push(char)

//     })

//     return newChars.join('')
//   }

//   return postFormat(
//     sql
//       .split(/[\n\p]/)
//       .filter((word) => word !== '')
//       .map((word, i) =>
//         sqlSuggestions.keywords.findIndex(
//           (el) => el.toLowerCase() === word.toLowerCase()
//         ) >= 0
//           ? (i > 0 ? '\n' : '') + word + '\n  '
//           : word + ' '
//       )
//       .join('')
//   )
// }

// {
//   label: 'aaClass',
//   insertText: 'Class',
//   kind: monaco.languages.CompletionItemKind.Class,
// },
// {
//   label: 'aaColor',
//   insertText: 'Color',
//   kind: monaco.languages.CompletionItemKind.Color,
// },
// {
//   label: 'aaConstant',
//   insertText: 'Constant',
//   kind: monaco.languages.CompletionItemKind.Constant,
// },
// {
//   label: 'aaConstructor',
//   insertText: 'Constructor',
//   kind: monaco.languages.CompletionItemKind.Constructor,
// },
// {
//   label: 'aaCustomcolor',
//   insertText: 'Customcolor',
//   kind: monaco.languages.CompletionItemKind.Customcolor,
// },
// {
//   label: 'aaEnum',
//   insertText: 'Enum',
//   kind: monaco.languages.CompletionItemKind.Enum,
// },
// {
//   label: 'aaEnumMember',
//   insertText: 'EnumMember',
//   kind: monaco.languages.CompletionItemKind.EnumMember,
// },
// {
//   label: 'aaEvent',
//   insertText: 'Event',
//   kind: monaco.languages.CompletionItemKind.Event,
// },
// {
//   label: 'aaField',
//   insertText: 'Field',
//   kind: monaco.languages.CompletionItemKind.Field,
// },
// {
//   label: 'aaFile',
//   insertText: 'File',
//   kind: monaco.languages.CompletionItemKind.File,
// },
// {
//   label: 'aaInterface',
//   insertText: 'Interface',
//   kind: monaco.languages.CompletionItemKind.Interface,
// },
// {
//   label: 'aaIssue',
//   insertText: 'Issue',
//   kind: monaco.languages.CompletionItemKind.Issue,
// },
// {
//   label: 'aaKeyword',
//   insertText: 'Keyword',
//   kind: monaco.languages.CompletionItemKind.Keyword,
// },
// {
//   label: 'aaMethod',
//   insertText: 'Method',
//   kind: monaco.languages.CompletionItemKind.Method,
// },
// {
//   label: 'aaModule',
//   insertText: 'Module',
//   kind: monaco.languages.CompletionItemKind.Module,
// },
// {
//   label: 'aaOperator',
//   insertText: 'Operator',
//   kind: monaco.languages.CompletionItemKind.Operator,
// },
// {
//   label: 'aaProperty',
//   insertText: 'Property',
//   kind: monaco.languages.CompletionItemKind.Property,
// },
// {
//   label: 'aaReference',
//   insertText: 'Reference',
//   kind: monaco.languages.CompletionItemKind.Reference,
// },
// {
//   label: 'aaSnippet',
//   insertText: 'Snippet',
//   kind: monaco.languages.CompletionItemKind.Snippet,
// },
// {
//   label: 'aaStruct',
//   insertText: 'Struct',
//   kind: monaco.languages.CompletionItemKind.Struct,
// },
// {
//   label: 'aaTypeParameter',
//   insertText: 'TypeParameter',
//   kind: monaco.languages.CompletionItemKind.TypeParameter,
// },
// {
//   label: 'aaUnit',
//   insertText: 'Unit',
//   kind: monaco.languages.CompletionItemKind.Unit,
// },
// {
//   label: 'aaUser',
//   insertText: 'User',
//   kind: monaco.languages.CompletionItemKind.User,
// },
// {
//   label: 'aaValue',
//   insertText: 'Value',
//   kind: monaco.languages.CompletionItemKind.Value,
// }
